import { formatDate } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { SortType } from '@swimlane/ngx-datatable';

import { SubSink } from 'subsink';

import { ExportService, FormulariosService, TallerJsPdfService, InformesTallerService, UsuarioService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';

type Maquina = {id: string, codigo: string, nombre: string};

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css']
})
export class InformesTallerComponent implements OnInit, OnDestroy {

  ahora = new Date();
  mesActual = this.ahora.getFullYear().toString() + '-' + ('0' + (this.ahora.getMonth() + 1)).slice(-2);
  desdeHoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  hastaHoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  maquinaria: any = [];
  averias: any = [];
  averiasTodas: any = [];
  mantenimientos: any = [];
  mantenimientosTodos: any = [];
  horometros: any = [];
  horometrosTodos: any = [];
  portes: any = [];
  portesTodos: any = [];

  buscando: boolean = false;
  cargando: boolean = false;
  inputFechas: boolean = false;
  txtBtnSubmit: string = 'Buscar';
  disableSubmit: boolean = false;
  sortType = SortType;
  registrosPagina: number = 10;
  totalRegistros: number = 0;

  private subs = new SubSink();

  public formSubmitted = false;

  public informeForm = this.fb.group({

    seccion: [ '', Validators.required ],

    maquina: [ null, Validators.required ],
    tipo: [ '', Validators.required ],
    estado: [ '', Validators.required ],

    gondola: [ '', Validators.required ],

    rango: [ '', Validators.required ],
    mes: [ null ],
    desde: [ null ],
    hasta: [ null ],

  });

  maquinaformat = (maquina: Maquina) => maquina.codigo + ' - ' + maquina.nombre;

  maquinasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map(term => this.maquinaria.filter( ( maquina: any ) => maquina?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                            maquina?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  constructor( private informesTallerService: InformesTallerService,
               private formulariosService: FormulariosService,
               private exportService: ExportService,
               private tallerJsPdfService: TallerJsPdfService,
               private usuarioService: UsuarioService,
               private fb: FormBuilder ) { }

  ngOnInit(): void {
    // Si el usuario es tipo "Seguridad" solo permitir informes de maquinaria
    if ( this.usuarioService.usuario.role === 'seguridad' ) {
      this.informeForm.get('seccion').disable();
      this.informeForm.get('seccion').setValue('maquinaria');
      this.cambioSeccion();
    } else {
      this.informeForm.get('seccion').enable();
      this.informeForm.get('seccion').setValue('');
    }
    this.cargarMaquinas();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ExportarExcel( tipo: string ): void {
    if ( tipo === 'a' && this.averias.length > 0 ) {
      this.exportService.exportAverias( this.averiasTodas );
    } else if ( tipo === 'm' && this.mantenimientos.length > 0 ) {
      this.exportService.exportMantenimientos( this.mantenimientosTodos );
    } else if ( tipo === 'h' && this.horometros.length > 0 ) {
      this.exportService.exportHorometros( this.horometrosTodos );
    }
  }

  CrearInformePDF( tipo: string ): void {
    if ( tipo === 'a' && this.averias.length > 0 ) {
      this.tallerJsPdfService.crearInformeAverias( this.informeForm.value, this.averias );
    } else if ( tipo === 'm' && this.mantenimientos.length > 0 ) {
      this.tallerJsPdfService.crearInformeMantenimientos( this.informeForm.value, this.mantenimientos );
    } else if ( tipo === 'h' && this.horometros.length > 0 ) {
      this.tallerJsPdfService.crearInformeHorometros( this.informeForm.value, this.horometros );
    } else if ( tipo === 'g' ) {
      this.tallerJsPdfService.crearInformeMensualGondola( this.informeForm.value, this.portes )
        .then( doc => {
          //Imprimir
          doc.autoPrint();
          doc.output('dataurlnewwindow');
          this.informeForm.get('seccion').enable();
          this.informeForm.get('gondola').enable();
          this.informeForm.get('tipo').enable();
          this.informeForm.get('rango').enable();
          this.inputFechas = false;
          this.txtBtnSubmit = 'Crear';
          this.disableSubmit = false;
        });
    }
  }

  cargarMaquinas(): void {
    this.subs.sink = this.formulariosService.selectMaquinas()
      .subscribe( maquinasBD => {
        if ( maquinasBD.length > 0 ) {
          this.maquinaria = maquinasBD.sort( (a, b) => a.codigo.localeCompare(b.codigo, 'es', { numeric: true } ) );
        }
      });
  }

  verificarMaquina(): void {
    this.buscando = false;
  }

  cambioRango(): void {

    this.buscando = false;
    switch (this.informeForm.value.rango) {
      case 'mes':
        this.informeForm.get('mes').setValue(this.mesActual);
        this.informeForm.get('desde').setValue(null);
        this.informeForm.get('hasta').setValue(null);
        break;
      case 'dh':
        this.informeForm.get('mes').setValue(null);
        this.informeForm.get('desde').setValue(this.desdeHoy);
        this.informeForm.get('hasta').setValue(this.hastaHoy);
        break;

      default:
        this.informeForm.get('mes').setValue(null);
        this.informeForm.get('desde').setValue(null);
        this.informeForm.get('hasta').setValue(null);
        break;
    }

  }

  cambioMes(): void {
    this.buscando = false;
  }

  cambioSeccion(): void {
    this.buscando = false;
    this.txtBtnSubmit = 'Buscar';
    switch (this.informeForm.get('seccion').value) {
      case 'maquinaria':
        this.informeForm.get('maquina').enable();
        this.informeForm.get('estado').enable();
        this.informeForm.get('gondola').disable();
        break;
      case 'gondola':
        this.informeForm.get('maquina').disable();
        this.informeForm.get('estado').disable();
        this.informeForm.get('gondola').enable();
        break;
    }
  }

  cambioTipo(): void {
    this.buscando = false;
    this.txtBtnSubmit = 'Buscar';
    this.selectNoValido('estado');
    this.informeForm.get('estado').disable();
    this.informeForm.get('estado').clearValidators();
    this.informeForm.get('estado').updateValueAndValidity();
    if ( this.informeForm.controls.tipo.value === 'resumen' ) {
      this.txtBtnSubmit = 'Crear';
      this.informeForm.get('rango').setValue('mes');
      this.cambioRango();
    } else if ( this.informeForm.controls.tipo.value === 'averias' || this.informeForm.controls.tipo.value === 'mantenimientos' ) {
      this.informeForm.get('estado').enable();
      this.informeForm.get('estado').setValidators([Validators.required]);
      this.informeForm.get('estado').updateValueAndValidity();
    }
  }

  crearInforme(): void {

    this.formSubmitted = true;

    if ( this.informeForm.invalid ) {
      console.log(this.informeForm);
      return;
    }

    if ( this.informeForm.get('seccion').value === 'maquinaria' ) {

      this.buscando = true;
      this.cargando = true;
      this.txtBtnSubmit = 'Buscando...';
      this.disableSubmit = true;
      this.subs.sink = this.informesTallerService.buscarRegistrosMaquinaria( this.informeForm.value )
        .pipe(
          // first()
          debounceTime(250)
        )
        .subscribe( async (res) => {
          this.totalRegistros = res.length;
          switch (this.informeForm.controls.tipo.value) {
            case 'averias':
              await Promise.all(
                res.map( async (averia: any) => {
                  averia.maquina = (averia.maquina) ? (await getDoc( averia.maquina )).data() : null;
                })
              );
              this.averias = res;
              this.averiasTodas = res;
              this.cargando = false;
              break;
            case 'mantenimientos':
              await Promise.all(
                res.map( async (mantenimiento: any) => {
                  mantenimiento.maquina = (mantenimiento.maquina) ? (await getDoc( mantenimiento.maquina )).data() : null;
                })
              );
              this.mantenimientos = res;
              this.mantenimientosTodos = res;
              this.cargando = false;
              break;
            case 'horometros':
              this.horometros = res;
              this.horometrosTodos = res;
              this.cargando = false;
              break;
          }
          this.txtBtnSubmit = 'Buscar';
          this.disableSubmit = false;
        });

    } else {

      this.buscando = false;
      this.disableSubmit = true;
      if ( this.informeForm.get('tipo').value === 'resumen' ) {
        this.portes = [];
        this.txtBtnSubmit = 'Creando...';
        this.CrearInformePDF('g');
        this.informeForm.get('seccion').disable();
        this.informeForm.get('gondola').disable();
        this.informeForm.get('tipo').disable();
        this.informeForm.get('rango').disable();
        this.inputFechas = true;
      } else {
        this.buscando = true;
        this.cargando = true;
        this.txtBtnSubmit = 'Buscando...';
        this.subs.sink = this.informesTallerService.buscarRegistrosGondola( this.informeForm.value )
          .subscribe( async (res) => {
            this.totalRegistros = 0;
            this.portes = [];
            if ( res.length > 0 ) {
              await Promise.all(
                res.map( async (porte: any) => {
                  if ( porte.cargadoObra ) {
                    porte.cargadoObra = (await getDoc( porte.cargadoObra )).data();
                    porte.cargadoObra.cliente = (await getDoc( porte.cargadoObra.cliente )).data();
                  }
                  if ( porte.descargadoObra ) {
                    porte.descargadoObra = (await getDoc( porte.descargadoObra )).data();
                    porte.descargadoObra.cliente = (await getDoc( porte.descargadoObra.cliente )).data();
                  }
                })
              );
              this.totalRegistros = res.length; // TODO: Obtener total usuarios de la BD
              this.portes = res;
              this.portesTodos = res;
            }
            this.cargando = false;
            this.txtBtnSubmit = 'Buscar';
            this.disableSubmit = false;
          });

      }

    }

  }

  restablecer(): void {
    this.formSubmitted = false;
    this.buscando = false;
    this.cargando = false;
    this.txtBtnSubmit = 'Buscar';
    this.disableSubmit = false;
    this.informeForm.get('seccion').enable();
    this.informeForm.get('gondola').enable();
    this.informeForm.get('tipo').enable();
    this.informeForm.get('rango').enable();
    this.inputFechas = false;
    this.informeForm.reset({
      maquina: null,
      seccion: '',
      gondola: '',
      tipo: '',
      rango: ''
    });
  }

  campoNoValido( campo: string ): boolean {

    if ( this.informeForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( (this.informeForm.get(campo).enabled && this.informeForm.get(campo).value === '') && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  getBtnClass({ row, column, value }): any {
    return ' botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  horometroComparator(propA: any, propB: any) {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  maquinaComparator(propA: any, propB: any) {
    return propA.codigo.localeCompare(propB.codigo, 'es', { numeric: true } )
  }

}
