<app-breadcrumbs></app-breadcrumbs>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                Bienvenido
            </div>
        </div>
    </div>
</div>
