import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { first } from 'rxjs/operators';

import Swal from 'sweetalert2';

import { UsuarioService } from '../../../services/service.index';
import { FormulariosService } from '../../../services/service.index';

import { getDoc } from '@angular/fire/firestore';


@Component({
  selector: 'app-nuevo',
  templateUrl: './usuario.component.html',
  styleUrls: [ './usuario.component.css' ],
})
export class UsuarioComponent implements OnInit, OnDestroy {

  titulo: string = 'Nuevo usuario';
  cargando: boolean = true;
  userID: string;
  obras: any = [];
  personal: any = [];
  responsables: any = [];
  roles: any = [];

  private subs = new SubSink();

  public formSubmitted = false;

  public usuarioForm = this.fb.group({
    nombre: [ null, Validators.required ],
    email: [{value: null, disabled: false}, [ Validators.required, Validators.email ] ],
    password: [ null, [ Validators.required, Validators.minLength(6) ] ],
    password2: [ null, [ Validators.required, Validators.minLength(6) ] ],
    role: [ null, Validators.required ],
    obra: [ null ],
    operario: [ null ],
    parte: [ 'no' ],
    responsables: [ { value: [], disabled: true }, Validators.required ],
    firmar: [ false ],
    estado: [ true ]
  }, {
    validators: this.passwordsIguales('password', 'password2')
  });

  constructor( private fb: FormBuilder,
               private usuarioService: UsuarioService,
               private formulariosService: FormulariosService,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.userID = params.id;
          this.cargarUsuario( params.id );
          this.usuarioForm.get('password').clearValidators();
          this.usuarioForm.get('password').updateValueAndValidity();
          this.usuarioForm.get('password2').clearValidators();
          this.usuarioForm.get('password2').updateValueAndValidity();
        } else {
          this.cargando = false;
        }

      });

    this.cargarObras();
    this.cargarRoles();
    this.cargarOperarios();
    this.cargarResponsables();

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarObras() {
    this.subs.sink = this.formulariosService.selectObras('enCurso')
      .subscribe( obras => {
        this.obras = obras.filter( (obra: any) => obra.tipo !== 'venta' );
      });
  }

  cargarRoles() {
    this.subs.sink = this.formulariosService.selectData('roles')
      .subscribe( roles => {
        this.roles = roles;
      });
  }

  cargarOperarios() {
    this.subs.sink = this.formulariosService.selectOperarios()
      .subscribe( personal => {
        this.personal = personal;
      });
  }

  cargarResponsables() {
    this.subs.sink = this.formulariosService.selectResponsables()
      .subscribe( responsables => {
        this.responsables = responsables;
      });
  }

  cargarUsuario( id: string ) {

    this.titulo = 'Editar usuario';

    this.subs.sink = this.usuarioService.obtenerUsuarioPorId( id )
      .pipe(
        first()
      )
      .subscribe( (usuario: any) => {

        if ( !usuario ) {
          Swal.fire('Error', 'Error al cargar los datos del usuario', 'error');
          return this.router.navigateByUrl('/admin/usuarios');
        }
        this.usuarioForm.get('email').disable();
        const responsables = ( usuario?.responsables )
          ? ( Array.isArray(usuario.responsables) )
            ? usuario.responsables.map( responsable => {
                return responsable.id;
              })
            : [ usuario.responsables ]
          : [];
        this.usuarioForm.reset(
          {
            nombre: usuario.nombre,
            email: usuario.email,
            password: '',
            password2: '',
            role: usuario.role,
            obra: usuario.obra?.id,
            operario: usuario.operario?.id,
            parte: usuario?.parte || 'no',
            responsables: responsables,
            firmar: usuario?.firmar || false,
            estado: usuario?.estado || true
          }
        );
        if (usuario.parte === 'individual' || usuario.parte === 'equipo') {
          this.usuarioForm.get('responsables').enable();
        }
        this.cargando = false;
      });

  }

  crearUsuario() {

    this.formSubmitted = true;

    if ( this.usuarioForm.invalid ) {
      this.usuarioForm.markAllAsTouched();
      return;
    }

    // Realizar el posteo
    this.usuarioService.crearUsuario( this.usuarioForm.value )
      .then( () => this.router.navigateByUrl('/admin/usuarios') )
      .catch( err => {
        console.log(err);
        let mensajeError: string;
        switch ( err.code ) {
          case 'auth/weak-password':
            mensajeError = 'La contraseña debe tener al menos 6 digitos';
        }
        Swal.fire('Error', mensajeError, 'error' );
      });

  }

  actualizarUsuario() {

    this.formSubmitted = true;

    if ( this.usuarioForm.invalid ) {
      this.usuarioForm.markAllAsTouched();
      return;
    }

    if ( !this.usuarioForm.pristine ) {
      // Realizar el posteo
      this.usuarioService.actualizarUsuario( this.userID, this.usuarioForm.value )
        .then( () => this.router.navigateByUrl('/admin/usuarios') )
        .catch( err => {
          Swal.fire('Error', 'error', 'error' );
        });
    } else {
      this.cancelarFormUsuario();
    }

  }

  cancelarFormUsuario() {

    if ( this.usuarioForm.pristine ) {
      this.router.navigate(['/admin/usuarios']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {
        this.router.navigate(['/admin/usuarios']);

      }

    });

  }

  cambiarParte(event: any) {
    if (event === 'no') {
      this.usuarioForm.get('responsables').disable();
      this.usuarioForm.get('responsables').setValue(null);
      this.usuarioForm.get('operario').clearValidators();
      this.usuarioForm.get('operario').updateValueAndValidity();
    } else {
      this.usuarioForm.get('responsables').enable();
      this.usuarioForm.get('operario').addValidators(Validators.required);
      this.usuarioForm.get('operario').updateValueAndValidity();
    }
  }

  buscarObra(term: string, item: any) {
    term = term.toLowerCase();
    return item.nombreCompleto?.toLowerCase().includes(term);
  }

  campoNoValido( campo: string ): boolean {

    if ( this.usuarioForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( ( this.usuarioForm.get(campo).value === null || this.usuarioForm.get(campo).value === '' ) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  contrasenasNoValidas(): boolean {

    const pass1 = this.usuarioForm.get('password').value;
    const pass2 = this.usuarioForm.get('password2').value;

    if ( (pass1 !== pass2) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  contrasenasDebiles(): boolean {

    if ( !this.usuarioForm.get('password').value ) {
      return;
    }
    const pass1 = this.usuarioForm.get('password').value;

    if ( (pass1.length < 6) && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  passwordsIguales(pass1Name: string, pass2Name: string ) {

    return ( formGroup: FormGroup ) => {

      const pass1Control = formGroup.get(pass1Name);
      const pass2Control = formGroup.get(pass2Name);

      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEsIgual: true });
      }


    }
  }

}
