<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Avería</h4>
            </div>

            <div class="card-body">
                <ul class="nav nav-pills justify-content-end mt-2" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="btn nav-link active" id="pills-datos-tab" data-toggle="pill" data-target="#datos" type="button" role="tab" aria-controls="pills-datos" aria-selected="true" (click)="tabClic('datos')">
                        Datos básicos
                      </button>
                      <!-- <a class="nav-link active" data-toggle="tab" href="#datos" role="tab" (click)="tabClic('datos')">
                          <span>Datos básicos</span>
                      </a> -->
                    </li>
                    <li class="nav-item"  role="presentation" *ngIf="!modoLectura || (modoLectura && bbddImages)">
                      <button class="btn nav-link" id="pills-imagenes-tab" data-toggle="pill" data-target="#imagenes" type="button" role="tab" aria-controls="pills-imagenes" aria-selected="true" [disabled]="!averiaID" (click)="tabClic('imagenes')">
                        Imágenes
                      </button>
                      <!-- <a class="nav-link" data-toggle="tab" href="#imagenes" role="tab" (click)="tabClic('imagenes')">
                          <span>Imágenes</span>
                      </a> -->
                    </li>
                </ul>
                <div class="tab-content border mt-2">

                    <div class="tab-pane active p-3" id="datos" role="tabpanel">
                        <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="averiaForm" (ngSubmit)="crearAveria()">
                            <div class="form-row">
                                <div class="col-sm-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Fecha*</label>
                                        <input type="date" class="form-control" placeholder="Fecha" required [ngClass]="{ 'is-invalid': campoNoValido('fechaAviso') }" formControlName="fechaAviso" [readonly]="modoLectura">
                                        <div class="invalid-feedback">
                                            La fecha es obligatoria
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Maquina*</label>
                                        <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Maquina'" required [ngClass]="{ 'is-invalid': campoNoValido('maquina') }" formControlName="maquina" [ngbTypeahead]="maquinasearch" [inputFormatter]="maquinaformat" [resultFormatter]="maquinaformat" [editable]="false" (selectItem)="selecMaquina($event)"
                                            (ngModelChange)="verificarMaquina()" [readonly]="modoLectura">
                                        <div class="invalid-feedback">
                                            La maquina es obligatoria
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Prioridad*</label>
                                        <div class="input-group" *ngIf="!modoLectura">
                                            <select id="prioridad" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('prioridad') }" formControlName="prioridad">
                                                <option value="">Elige una prioridad...</option>
                                                <option value="urgente">Urgente</option>
                                                <option value="alta">Alta</option>
                                                <option value="normal">Normal</option>
                                                <option value="baja">Baja</option>
                                            </select>
                                            <div class="invalid-feedback">
                                                La prioridad es obligatoria. Elige una.
                                            </div>
                                        </div>
                                        <input type="text" class="form-control" [value]="prioridad" [readonly]="modoLectura" *ngIf="modoLectura">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <label>Horometro/Km</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" [value]="txtHorometro + ' ' + txtUdHorometro" [readonly]="modoLectura" *ngIf="modoLectura">
                                        <input type="number" class="form-control" formControlName="horometro" *ngIf="!modoLectura">
                                        <select class="custom-select col-2" formControlName="udHorometro" *ngIf="!modoLectura">
                                            <option value="h">h.</option>
                                            <option value="km">Km.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm-12 col-lg-8">
                                    <div class="form-group">
                                        <label>Descripción</label>
                                        <textarea class="form-control" [placeholder]="(modoLectura) ? '' : 'Descripción de la avería...'" rows="2" formControlName="descripcion" [readonly]="modoLectura"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="modoLectura">
                                <div class="col-sm-12 col-lg-4">
                                    <div class="form-group">
                                        <label>Reportado por</label>
                                        <input type="text" class="form-control" [value]="reportadoPor" [readonly]="modoLectura">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4" *ngIf="modificadoPor">
                                    <div class="form-group">
                                        <label>Modificado el {{ modificadoEl }} por</label>
                                        <input type="text" class="form-control" [value]="modificadoPor" [readonly]="modoLectura">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-sm-12 col-lg-4" *ngIf="ubicacionTxt">
                                    <div class="form-group">
                                        <label>Ubicación</label>
                                        <input type="text" class="form-control" [value]="ubicacionTxt" [readonly]="modoLectura" *ngIf="modoLectura">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <label>Estado*</label>
                                    <div class="input-group" *ngIf="!modoLectura">
                                        <select id="situacion" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('estado') }" formControlName="estado">
                                            <option value="">Elige un estado...</option>
                                            <option value="pendiente">Pendiente</option>
                                            <option value="en_curso">En curso</option>
                                            <option value="reparado">Reparado</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            El estado es obligatorio. Elige uno.
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" [value]="estado" [readonly]="modoLectura" *ngIf="modoLectura">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col text-danger">

                                    <p *ngIf="campoNoValido('fechaAviso') || campoNoValido('maquina') || selectNoValido('prioridad') || selectNoValido('estado')">
                                        Hay errores en el formulario. Corrígelos para poder guardar la información.
                                    </p>

                                </div>
                            </div>
                            <hr>
                            <div class="card-body">
                                <div class="form-group mb-0 text-right">
                                    <button *ngIf="!averiaID && !modoLectura" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                                    <button *ngIf="averiaID && !modoLectura" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarAveria()">Actualizar</button>
                                    <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarAveria()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="tab-pane p-3" id="imagenes" role="tabpanel" *ngIf="!modoLectura || (modoLectura && bbddImages)">

                        <div id="carouselIndicators" class="carousel slide" data-ride="carousel" *ngIf="modoLectura">
                            <ol class="carousel-indicators">
                                <li data-target="#carouselIndicators" *ngFor="let item of bbddImages; let i=index" data-slide-to="i" [ngClass]="i === 0 ? 'active' : ''"></li>
                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item" *ngFor="let item of bbddImages; let i=index" [ngClass]="i === 0 ? 'active' : ''">
                                    <img [src]="item.webviewPath" class="d-block w-100" alt="...">
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            </a>
                            <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            </a>
                        </div>

                        <div class="card-body" *ngIf="!modoLectura">

                            <div class="row">

                                <div class="col-sm-12 col-lg-4 mb-2">

                                    <form class="form-horizontal" id="docsform" autocomplete="off" [formGroup]="docsForm">
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Nombre*</label>
                                                    <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoDocsNoValido('nombredoc') }" formControlName="nombredoc">
                                                    <div class="invalid-feedback">
                                                        El nombre es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <label>Categoría*</label>
                                                <div class="input-group">
                                                    <select id="situacion" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectDocsNoValido('categoriadoc') }" formControlName="categoriadoc">
                                                            <option value="">Elige una categoría...</option>
                                                            <option value="foto">Foto</option>
                                                            <option value="otro">Otro</option>
                                                        </select>
                                                    <div class="invalid-feedback">
                                                        La categoría es obligatoria. Elige una.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div class="row mt-3">

                                        <div class="col-sm-12 col-lg-12">

                                            <ngx-dropzone [multiple]="false" (change)="onChangeDropzone($event)">
                                                <ngx-dropzone-label>Suelte los archivos aquí o haga clic para cargarlos</ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemoveDoc(f)">
                                                    <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>

                                        </div>

                                    </div>

                                    <hr>

                                    <div class="row">

                                        <div class="col-sm-12 col-lg-12">

                                            <div class="row">
                                                <div class="col text-danger">

                                                    <p *ngIf="campoDocsNoValido('nombredoc') || selectDocsNoValido('categoriadoc')">
                                                        Hay errores en el formulario. Corrígelos para poder subir el documento.
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="form-group mb-0 text-center">
                                                <button type="button" class="btn btn-success waves-effect waves-light mr-1" *ngIf="!uploadPercent" (click)="subirDoc(files)">Subir</button>
                                                <button class="btn btn-success mr-1" type="button" disabled *ngIf="uploadPercent">
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Subiendo...
                                                    </button>
                                                <button type="button" class="btn btn-secondary waves-effect waves-light" (click)="limpiarDocs()">Limpiar</button>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="row mt-3 mb-3" *ngIf="uploadPercent">
                                        <div class="col-sm-12 col-lg-12">

                                            <div class="progress" style="height: 15px;">
                                                <div class="progress-bar progress-bar-striped bg-info" [style.width.%]="uploadPercent" role="progressbar">{{ uploadPercent }}%</div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12 col-lg-8">

                                    <div class="row animated fadeIn fast" *ngIf="cargandoDocs">

                                        <div class="col-sm-12">

                                            <div class="alert alert-warning text-center">
                                                <strong>Cargando</strong>
                                                <br>
                                                <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                                                <br>
                                                <span>Espere por favor</span>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="table-responsive" *ngIf="!cargandoDocs">
                                        <table class="table table-striped search-table v-middle dataTable">
                                            <thead class="header-item">
                                                <th class="text-dark font-weight-bold">Nombre</th>
                                                <th class="text-dark font-weight-bold">Categoría</th>
                                                <th class="text-dark font-weight-bold">Tipo</th>
                                                <th class="text-center"></th>
                                            </thead>
                                            <tbody>
                                                <tr class="search-items" *ngFor="let doc of bbddImages">
                                                    <td class="text-nowrap">
                                                        <span>{{ doc.nombredoc }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ doc.categoriadoc }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ doc.tipo | uppercase }}</span>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="btn-group" role="group">
                                                            <button type="button" class="btn btn-light-info text-info waves-effect waves-light" (click)="verDocumento( doc.url )"><i class="mdi mdi-eye"></i></button>
                                                            <button type="button" class="btn btn-light-danger text-danger waves-effect waves-light" (click)="borrarDocumento( doc )"><i class="mdi mdi-delete"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="col-sm-12" *ngIf="bbddImages.length === 0">

                                            <div class="alert alert-info text-center">
                                                <strong>No hay documentos</strong> <br>
                                                <i class="fas fa-file fa-3x mt-2 mb-2"></i>
                                                <br>
                                                <span>Por favor agregue un documento para empezar</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <hr>
                        <div class="card-body">
                            <div class="form-group mb-0 text-right">
                                <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarAveria()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
