import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

import { CamionesService } from 'src/app/services/service.index';

@Component({
  selector: 'app-camiones',
  templateUrl: './camiones.component.html',
  styles: [
  ]
})
export class CamionesComponent implements OnInit, OnDestroy {

  camiones: any = [];
  camionesTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private camionesService: CamionesService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.camionesService.getRegistrosPagina();
    this.sorts = this.camionesService.getSorts();
    this.offset = this.camionesService.getPage();
    this.cargarCamiones();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarCamiones() {

    this.cargando = true;

    this.subs.sink = this.camionesService.cargarCamiones()
      .subscribe( (camionesDB: any) => {
        this.totalRegistros = 0;
        this.camiones = [];
        if ( camionesDB.length > 0 ) {
          this.totalRegistros = camionesDB.length; // TODO: Obtener total usuarios de la BD
          this.camiones = camionesDB;
          this.camionesTodos = camionesDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarCamion( termino: string ) {

    if ( termino.length <= 0 ) {
      // TODO: buscador indiferente a las fechas
      this.totalRegistros = this.camionesTodos.length;
      this.camiones = this.camionesTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.camiones = this.camionesTodos.filter( ( camion: any ) =>
        camion?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.remolque?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.chofer?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.dni?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.telefono?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.agencia?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        camion?.transportista?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.camiones.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.camionesTodos.length;
      this.camiones = this.camionesTodos;
    }
  }

  editarCamion( id: string ) {

    this.router.navigate( ['/maestros/camion/editar', id ] );

  }

  borrarCamion( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {
        this.camionesService.borrarCamion( id );
      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.camionesService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.camionesService.setPage( event.offset );
  }

  onSort(event) {
    this.camionesService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
