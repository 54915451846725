import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, catchError, first, map, of } from "rxjs";
import { CamionesService } from "src/app/services/service.index";
import { SubSink } from "subsink";
import Swal from "sweetalert2";

@Component({
  selector: 'app-camion-modal',
  templateUrl: './camion-modal.component.html',
  styleUrls: ['./camion-modal.component.css']
})
export class CamionModalComponent implements OnInit, OnDestroy {

  @Input() camionId: string;
  txtTituloModal: string = 'Crear camión';

  private subs = new SubSink();

  formSubmitted = false;

  public camionForm = this.fb.group({
    matricula: [
      '',
      {
        validators: [
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        asyncValidators: [ this.matriculaValidator() ],
        updateOn: 'blur'
      }
    ],
    remolque: [
      '',
      {
        validators: [
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        updateOn: 'blur'
      }
    ],
    tara: null,
    chofer: [ '' ],
    dni: [
      '',
      {
        validators: [
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        updateOn: 'blur'
      }
    ],
    telefono: [ '' ],
    transportista: [ '' ],
    agencia: [ '' ]
  });

  constructor( private camionesService: CamionesService,
               public activeModal: NgbActiveModal,
               private fb: FormBuilder ) {}

  ngOnInit(): void {

    if ( this.camionId ) {
      this.txtTituloModal = 'Editar camión';
      this.cargarCamion( this.camionId );
    }

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  cargarCamion( id: string ) {

    this.subs.sink = this.camionesService.obtenerCamionPorId( id )
      .subscribe( (camion: any) => {
        this.camionForm.get('matricula').clearAsyncValidators();
        this.camionForm.get('matricula').updateValueAndValidity();
        this.camionForm.reset({
          matricula: camion.matricula,
          remolque: camion.remolque,
          tara: camion.tara,
          chofer: camion.chofer,
          dni: camion.dni,
          telefono: camion.telefono,
          transportista: camion.transportista,
          agencia: camion.agencia
        });
      });

  }

  crearCamion() {

    this.formSubmitted = true;

    if ( this.camionForm.invalid ) {
      return;
    }

    this.camionesService.crearCamion( this.camionForm.value )
      .then( (res) => {
        if ( res ) {
          const camionCreado = this.camionForm.value;
          camionCreado['id'] = res;
          this.activeModal.close( camionCreado );
        } else {
          this.activeModal.close();
        }
      })
      .catch( err => {
        Swal.fire('Error', `Error: ${err}`, 'error' );
      });

  }

  actualizarCamion() {

    this.formSubmitted = true;

    if ( this.camionForm.invalid ) {
      return;
    }

    if ( this.camionForm.pristine ) {
      this.activeModal.close();
      return;
    }

    // Realizar el posteo
    this.camionesService.actualizarCamion( this.camionId, this.camionForm.value )
      .then( (res) => {
        if ( res ) {
          const camionCreado = this.camionForm.value;
          camionCreado['id'] = this.camionId;
          this.activeModal.close( camionCreado );
        } else {
          Swal.fire('Matrícula duplicada', 'La matrícula ya existe.', 'error');
          this.camionForm.controls.matricula.setErrors({ matriculaExists: true });
        }
      })
      .catch( err => {
        Swal.fire('Error', `Error: ${err}`, 'error' );
      });

  }

  matriculaValidator(): AsyncValidatorFn {
    return ( control: AbstractControl ): Observable<ValidationErrors | null> => {
      return this.camionesService.buscarMatricula(control.value).pipe(
        first(),
        map( (res: any) => {
          if ( res.length ) {
            if (!res[0].estado) {
              Swal.fire({
                title: '¿Volver a activar?',
                text: 'La matricula ya existe pero esta dada de baja.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
              })
              .then( (result) => {

                if (result.value) {

                  this.camionesService.activarCamion( res[0].id )
                    .then( () => {
                      this.activeModal.close( res[0] );
                    });

                } else {
                  this.activeModal.close();
                }

              });
              return { matriculaBaja: true };
            } else {
              return { matriculaExists: true };
            }
          } else {
            return null;
          }
        }),
        catchError( () => of(null))
      );
    };
  }

  campoNoValido( campo: string ): boolean {

    if ( this.camionForm.dirty ) {

      if ( this.camionForm.get(campo).invalid && this.camionForm.get(campo).touched ) {
        return true;
      } else {
        return false;
      }

    }

  }

}
