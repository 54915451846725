import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, first, map, of } from 'rxjs';
import { SubSink } from 'subsink';

import { CalendariosService } from '../../../../services/service.index';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-calendario-form',
  templateUrl: './calendario-form.component.html',
  styleUrls: ['./calendario-form.component.css']
})
export class CalendarioFormComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Calendario';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  calendarioID: string;
  convenio: string;
  tiposDescanso: any;
  modoLectura: boolean = false;

  private subs = new SubSink();

  public formSubmitted = false;

  public calendarioForm = this.fb.group({
    anio: [ null, { Validators: [ Validators.required ], asyncValidators: [ this.calendarioValidator() ], updateOn: 'change' } ],
    convenio: [ '', { Validators: [ Validators.required ], asyncValidators: [ this.calendarioValidator() ], updateOn: 'change' } ],
    horas: null,
    vacacionesEmpresa: 11,
    vacacionesTrabajador: 11,
    vacacionesTotal: 22,
    fiestaLocal: 1,
    libreDisposicion: 0,
    events: this.fb.array([
      this.fb.group({
        backgroundColor: [ '', Validators.required ],
        comentario: [ null ],
        display: [ 'background' ],
        end: [ null ],
        start: [ null, Validators.required ],
        id: [ null ],
      })
    ]),
  });

  constructor( private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private calendariosService: CalendariosService,
               private location: Location,
               private title: Title, ) { }

  ngOnInit(): void {
    this.tiposDescanso = this.calendariosService.getTiposDescansos();
    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {
        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Calendario';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.calendarioID = params.id;
            this.cargarCalendario( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.cargarCalendario( params.id );
          } else {
            this.router.navigateByUrl('/personal/calendarios/lista');
          }
        } else {
          this.subs.sink = this.activatedRoute.queryParams.subscribe( queryParams => {
            const anio = ( queryParams?.anio ) ? Number( queryParams?.anio ) : null;
            this.calendarioForm.get( 'anio' ).setValue( anio );
            this.calendarioForm.get( 'convenio' ).setValue( queryParams?.convenio ?? '' );
            this.cargando = false;
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get events(): FormArray {
    return this.calendarioForm.get('events') as FormArray;
  }

  get eventsControlsArray(): FormGroup[] {
    return this.events.controls as FormGroup[];
  }

  agregarEvento( click: boolean = false ): void {
    if (click) {
      this.calendarioForm.markAsDirty();
    }
    this.events.push(
      this.fb.group({
        backgroundColor: [ '', Validators.required ],
        comentario: [ null ],
        display: [ 'background' ],
        end: [ null ],
        start: [ null, Validators.required ],
        id: [ null ],
      })
    );
  }

  eliminarEvento( value: any, i: number ): void {

    if ( !value.id ) {
      this.calendarioForm.markAsDirty();
      this.events.removeAt(i);
      return;
    }
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if ( result.value ) {
        
        this.calendariosService.borrarEventoCalendario( this.calendarioID, value.id );
      
      }

    });

  }

  cargarCalendario( id: string ) {

    this.subs.sink = this.calendariosService.cargarCalendarioPorID( id )
      .pipe(
        first()
      )
      .subscribe( calendario => {
        if ( !calendario ) {
          Swal.fire('Error', 'Error al cargar los datos del calendario', 'error');
          return this.router.navigateByUrl('/personal/calendarios/lista');
        }
        this.calendarioForm.controls.anio.clearAsyncValidators();
        this.calendarioForm.controls.anio.updateValueAndValidity();
        this.calendarioForm.controls.convenio.clearAsyncValidators();
        this.calendarioForm.controls.convenio.updateValueAndValidity();
        this.calendarioForm.reset({
          anio: calendario.anio,
          convenio: calendario.convenio,
          horas: calendario.horas,
          vacacionesEmpresa: calendario?.vacacionesEmpresa ?? 11,
          vacacionesTrabajador: calendario?.vacacionesTrabajador ?? 11,
          vacacionesTotal: calendario?.vacacionesTotal ?? 22,
          fiestaLocal: calendario?.fiestaLocal ?? 1,
          libreDisposicion: calendario?.libreDisposicion ?? 0,
        });
        this.convenio = calendario.convenio[0].toUpperCase() + calendario.convenio.slice(1).toLowerCase();
        this.subs.sink = this.calendariosService.cargarEventosCalendarioPorID(id)
          .pipe(
            first()
          )
          .subscribe( eventos => {
            if ( eventos.length ) {
              this.events.clear();
              eventos.forEach( evento => {
                this.events.push(
                  this.fb.group({
                    tipo: this.obtenerTipoFecha( evento.backgroundColor ),
                    backgroundColor: evento.backgroundColor,
                    comentario: evento.comentario,
                    display: evento.display,
                    end: formatDate( evento.end.toDate(), 'yyyy-MM-dd', 'en' ),
                    start: formatDate( evento.start.toDate(), 'yyyy-MM-dd', 'en' ),
                    id: evento.id,
                  })
                );
              });
              this.cargando = false;
            }
          });
      });

  }

  crearCalendario() {

    this.formSubmitted = true;

    if ( this.calendarioForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;
    
    this.calendariosService.crearCalendario( this.calendarioForm.value )
      .then( () => this.router.navigateByUrl('/personal/calendarios/lista') )
      .catch( ( e ) => console.log( e ) );

  }

  actualizarCalendario() {

    this.formSubmitted = true;

    if ( this.calendarioForm.invalid ) {
      return;
    }

    if ( this.calendarioForm.pristine ) {
      this.cancelarCalendario();
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.calendariosService.actualizarCalendario( this.calendarioID, this.calendarioForm.value )
      .then( () => this.router.navigateByUrl( '/personal/calendarios/lista' ) )
      .catch( ( e ) => console.log( e ) );

  }

  obtenerTipoFecha( tipo: string ): string {
    const index = this.tiposDescanso.map( e => e.color ).indexOf( tipo );
    return this.tiposDescanso[index]['nombre'];
  }

  // Cancelar el formulario y regresar al listado
  cancelarCalendario(): void {

    if ( this.calendarioForm.pristine ) {
      this.router.navigateByUrl('/personal/calendarios/lista');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigateByUrl('/personal/calendarios/lista');

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.calendarioForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.calendarioForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  calendarioValidator(): AsyncValidatorFn {
    return ( control: AbstractControl ): Observable<ValidationErrors | null> => {
      const anio = this.calendarioForm?.get('anio')?.value;
      const convenio = this.calendarioForm?.get('convenio')?.value;
      if (!convenio || !anio) {
        return of(null);
      }
      return this.calendariosService.calendarioValidator( convenio, anio )
        .pipe(
          first(),
          map( (res: any) => {
            if ( res.length ) {
              this.calendarioForm.get('convenio').setErrors({ 'calendarioExists': true });
              this.calendarioForm.get('anio').setErrors({ 'calendarioExists': true });
              return null;
            } else {
              this.calendarioForm.get('convenio').setErrors(null);
              this.calendarioForm.get('anio').setErrors(null);
              return null;
            }
          }),
          catchError( () => of(null)),
        );
    }
  }

}
