import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';

import { UsuarioService } from '../../services/usuario/usuario.service';

import { AddCamiForm } from '../../interfaces/add-camion-form.interface';

import { Observable, firstValueFrom } from 'rxjs';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CamionesService {

  camionesCol = collection( this.firestore, 'camiones' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
              ) { }

  cargarCamiones( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.camionesCol, where('estado', '==', true), orderBy('matricula', 'asc') );
    } else {
      consulta = query( this.camionesCol, orderBy('matricula', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerCamionPorId( camionID: string ) {

    const camionRef = doc( this.firestore, 'camiones', camionID );
    return docData( camionRef, { idField: 'id' } );

  }

  crearCamion( formData: AddCamiForm ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.matricula = formData.matricula.toLocaleUpperCase().trim();
    formData.nombre = formData.matricula;
    formData.estado = true;

    return addDoc( this.camionesCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
        return docRef.id;
      });

}

  actualizarCamion( camionID: string, formData: AddCamiForm ) {

    formData.id = camionID;
    formData.matricula = formData.matricula.toLocaleUpperCase().trim();
    formData.nombre = formData.matricula;

    return firstValueFrom( this.buscarMatricula( formData.matricula ) )
      .then( (res: any) => {
        if (res.length && res[0].id !== camionID) {
          return false;
        } else {
          const camionRef = doc( this.firestore, 'camiones', camionID );
          return updateDoc( camionRef, {
            ...formData,
            'meta.modificado': {
              modificadoel: new Date(),
              modificadopor: {
                nombre: this.usuarioService.usuario.nombre,
                uid: this.usuarioService.usuario.uid
              }
            }
          })
          .then( () => true );
        }
      });

  }

  activarCamion( camionID: string ) {

    const camionRef = doc( this.firestore, 'camiones', camionID );
    return updateDoc( camionRef, {
        estado: true,
        'meta.modificado': {
          modificadoel: new Date(),
          modificadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          }
        },
        'meta.eliminado': {
          eliminadoel: null,
          eliminadopor: {
            nombre: null,
            uid: null
          }
        }
      });

  }

  borrarCamion( camionID: string ) {

    const camionRef = doc( this.firestore, 'camiones', camionID );
    let consultaSalidas = query( collection( this.firestore, 'salidas' ), where('matricula', '==', camionRef ) );
    firstValueFrom( collectionData( consultaSalidas, { idField: 'id' } ) )
      .then( salidas => {
        let consultaEntradas = query( collection( this.firestore, 'entradas' ), where('matricula', '==', camionRef ) );
        firstValueFrom( collectionData( consultaEntradas, { idField: 'id' } ) )
          .then( entradas => {
            if (salidas.length || entradas.length) {

              Swal.fire({
                title: '¡Hay registros con esta matricula!',
                text: 'Si se elimina la matrícula podría haber inconsistencias en las entradas y/o salidas.',
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonColor: '#3085d6',
                denyButtonColor: '#6e7881',
                cancelButtonColor: '#dd3333',
                confirmButtonText: 'Dar de baja',
                denyButtonText: 'Sustituir',
                cancelButtonText: 'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {

                  updateDoc( camionRef, {
                      estado: false,
                      'meta.modificado': {
                        eliminadoel: new Date(),
                        eliminadopor: {
                          nombre: this.usuarioService.usuario.nombre,
                          uid: this.usuarioService.usuario.uid
                        }
                      }
                    }).then( res => {
                      Swal.fire('Dado de baja!', '', 'success');
                    });

                  } else if (result.isDenied) {
                  // TODO: dar opción de asignar otra matricula
                  Swal.fire('Seleccione la matrícula', 'En desarrollo', 'info');
                }

              });

            } else {

              deleteDoc( camionRef )
                .then( res => {
                  Swal.fire(
                    '¡Eliminado!',
                    'El camión ha sido eliminado.',
                    'success'
                  );
                }).catch( err => {
                  console.error(err);
                });

            }

          });
      });

  }

  buscarMatricula( matricula: string ): Observable<unknown> {

    matricula = matricula.toLocaleUpperCase();
    let consulta: Query<DocumentData>;
    consulta = query( this.camionesCol, where('matricula', '==', matricula) );
    return collectionData( consulta, { idField: 'id' } );

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
