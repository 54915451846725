import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { SortType } from '@swimlane/ngx-datatable';
import { debounceTime } from 'rxjs';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { SellosService } from '../../../services/service.index';

@Component({
  selector: 'app-sellosce',
  templateUrl: './sellosce.component.html',
  styleUrls: ['./sellosce.component.css']
})
export class SellosceComponent implements OnInit {

  sellos: any = [];
  sellosTodos: any = [];

  registrosPagina: number = 10;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private fb: FormBuilder,
               private sellosService: SellosService,
               private router: Router ) { }

  ngOnInit(): void {
    this.cargarSellos();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarSellos() {

    this.cargando = true;

    this.subs.sink = this.sellosService.listaSellos()
      .pipe(
        debounceTime(250)
      )
      .subscribe( (sellosDB: any) => {
        this.totalRegistros = 0;
        this.sellos = [];
        if ( sellosDB.length > 0 ) {
          this.totalRegistros = sellosDB.length; // TODO: Obtener total usuarios de la BD
          this.sellos = sellosDB;
          this.sellosTodos = sellosDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarSello( termino: string ) {

    this.cargando = true;

    if (termino.length <= 0) {
      this.totalRegistros = this.sellosTodos.length;
      this.sellos = this.sellosTodos;
      this.cargando = false;
      return;
    }

    if (termino !== '' ) {
      this.sellos = this.sellosTodos.filter( ( sello: any ) =>
        sello?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        sello?.anio?.toString().includes( termino.toLocaleLowerCase() ) ||
        sello?.certificado?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        sello?.norma?.toLowerCase().includes( termino.toLocaleLowerCase() ) );

      this.totalRegistros = this.sellos.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.sellosTodos.length;
      this.sellos = this.sellosTodos;
    }
  }

  editarSello( id: string ) {

    this.router.navigate( ['/laboratorio/selloce/editar', id ] );

  }

  borrarSello( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {
        this.sellosService.borrarSello( id );
      }

    });

  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
