import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import { SortType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';

import { ClientesService } from 'src/app/services/service.index';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styles: [
  ]
})
export class ClientesComponent implements OnInit, OnDestroy {

  clientes: any = [];
  clientesTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private clientesService: ClientesService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.clientesService.getRegistrosPagina();
    this.sorts = this.clientesService.getSorts();
    this.offset = this.clientesService.getPage();
    this.cargarClientes();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarClientes() {

    this.cargando = true;

    this.subs.sink = this.clientesService.cargarClientes()
      .subscribe( (clientesDB: any) => {
        this.totalRegistros = 0;
        this.clientes = [];
        if ( clientesDB.length > 0 ) {
          this.totalRegistros = clientesDB.length; // TODO: Obtener total usuarios de la BD
          this.clientes = clientesDB;
          this.clientesTodos = clientesDB;
          this.searchForm.controls.termino.enable();
        }
        this.cargando = false;

      });

  }

  buscarCliente( termino: string ) {

    if ( termino.length === 0 ) {
      this.totalRegistros = this.clientesTodos.length;
      this.clientes = this.clientesTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.clientes = this.clientesTodos.filter( ( cliente: any ) =>
        cliente?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        cliente?.cif?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        cliente?.numnavision?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.clientes.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.clientesTodos.length;
      this.clientes = this.clientesTodos;
    }
  }

  editarCliente( id: string ) {

    this.router.navigate( ['/admin/cliente/editar', id ] );

  }

  borrarCliente( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.clientesService.borrarCliente( id )
          .then( res => {
            //this.cargarClientes();
            Swal.fire(
              '¡Eliminado!',
              'El cliente ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.clientesService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.clientesService.setPage( event.offset );
  }

  onSort(event) {
    this.clientesService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
