import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { addDoc, collection, collectionData, doc, docData, DocumentData, Firestore, orderBy, query, Query, updateDoc, where } from '@angular/fire/firestore';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class EquiposService {

  equiposCol = collection( this.firestore, 'personal_equipos' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
             ) { }

  cargarEquipos( activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.equiposCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( this.equiposCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  crearEquipo( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.encargado = formData.encargado ? doc( this.firestore, 'usuarios', formData.encargado ) : null;
    formData.estado = true;

    // TODO: Comprobar si existe y en caso positivo poner en activo
    return addDoc( this.equiposCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id });
      });

  }

  obtenerEquipoPorId( equipoID: string ) {

    const equipoRef = doc( this.firestore, 'personal_equipos', equipoID );
    return docData( equipoRef, { idField: 'id' } );

  }

  obtenerOperariosPorEquipo( equipoID: string ) {


    const equipoRef = doc( this.firestore, 'personal_equipos', equipoID );
    const operariosCol = collection( this.firestore, 'personal' );
    const q = query( operariosCol, where('estado', '==', true), where('equipo', '==', equipoRef), orderBy('nombre', 'asc') );
    return collectionData( q, { idField: 'id' } );

  }

  actualizarEquipo( equipoID: string, formData: any ) {

    formData.encargado = formData.encargado ? doc( this.firestore, 'usuarios', formData.encargado ) : null;
    return updateDoc( doc( this.firestore, 'personal_equipos', equipoID ), {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarEquipo( equipoID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    return updateDoc( doc( this.firestore, 'personal_equipos', equipoID ), {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
