import { Component, OnInit } from '@angular/core';

declare function app();
declare function app_init();
declare function app_init_dark();
declare function feather_init();
declare function sidebar_menu();
declare function init_plugins();

@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.css']
})
export class NopagefoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    app();
    app_init();
    feather_init();
    sidebar_menu();
    init_plugins();
  }

}
