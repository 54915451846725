import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {

  personalCol = collection( this.firestore, 'personal' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
              ) { }

  crearTrabajador( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.dni = formData.dni ? formData.dni.toString() : null;
    if (!formData.provincia) {
      formData.provincia = null;
    }
    if ( formData.localidad ) {
      delete formData.codpostal;
    } else if ( formData.codpostal ) {
      formData.localidad = formData.codpostal;
      delete formData.codpostal;
    } else {
      formData.localidad = null;
      delete formData.codpostal;
    }
    formData.equipo = formData.equipo ? doc( this.firestore, 'personal_equipos', formData.equipo ) : null;
    formData.responsables = ( formData.responsables )
    ? formData.responsables.map( responsable => {
        return doc( this.firestore, 'usuarios', responsable );
      })
    : [];

    // TODO: Comprobar si existe y en caso positivo poner en activo
    return addDoc( this.personalCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id });
      });

  }

  cargarTrabajadores( activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.personalCol, where('estado', '==', true), orderBy('numnavision', 'asc') );
    } else {
      consulta = query( this.personalCol, orderBy('numnavision') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarUsuariosParteEquipo( activos: boolean = true ) {

    const usuariosCol = collection( this.firestore, 'usuarios' );

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( usuariosCol, where('estado', '==', true), where('parte', '==', 'equipo'), orderBy('nombre', 'asc') );
    } else {
      consulta = query( usuariosCol, where('parte', '==', 'equipo'), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerTrabajadorPorId( trabajadorID: string ) {

    const trabajadorRef = doc( this.firestore, 'personal', trabajadorID );
    return docData( trabajadorRef, { idField: 'id' } );

  }

  actualizarTrabajador( trabajadorID: string, formData: any ) {

    formData.dni = formData.dni ? formData.dni.toString() : null;
    if (!formData.provincia) {
      formData.provincia = null;
    }
    if ( formData.localidad ) {
      delete formData.codpostal;
    } else if ( formData.codpostal ) {
      formData.localidad = formData.codpostal;
      delete formData.codpostal;
    } else {
      formData.localidad = null;
      delete formData.codpostal;
    }
    formData.equipo = formData.equipo ? doc( this.firestore, 'personal_equipos', formData.equipo ) : null;
    formData.responsables = ( formData.responsables )
    ? formData.responsables.map( responsable => {
        return doc( this.firestore, 'usuarios', responsable );
      })
    : [];

    return updateDoc( doc( this.firestore, 'personal', trabajadorID ), {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarTrabajador( trabajadorID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    return updateDoc( doc( this.firestore, 'personal', trabajadorID ), {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
