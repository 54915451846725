import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { getDoc } from '@angular/fire/firestore';
import { SubSink } from 'subsink';
import { SortType } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';

import { ObrasService } from 'src/app/services/service.index';

@Component({
  selector: 'app-obras',
  templateUrl: './obras.component.html',
  styles: [
  ]
})
export class ObrasComponent implements OnInit, OnDestroy {

  @Input() sololectura: boolean = false;

  obras: any = [];
  obrasTodas: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private obrasService: ObrasService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.obrasService.getRegistrosPagina();
    this.sorts = this.obrasService.getSorts();
    this.offset = this.obrasService.getPage();
    this.cargarObras();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarObras() {

    this.cargando = true;

    this.subs.sink = this.obrasService.cargarObras()
      .subscribe( async (obrasBD: any) => {
        this.totalRegistros = 0;
        this.obras = [];
        if ( obrasBD.length > 0 ) {
          await Promise.all( obrasBD.map( async ( obra: any ) => {
            obra.cliente = ( obra.cliente ) ? (await getDoc( obra.cliente )).data() : null;
          }));

          this.totalRegistros = obrasBD.length; // TODO: Obtener total usuarios de la BD
          this.obras = obrasBD;
          this.obrasTodas = obrasBD;
          this.cargando = false;
          this.searchForm.controls.termino.enable();
        } else  {
          this.cargando = false;
        }

      });

  }

  buscarObra( termino: string ) {

    if ( termino.length === 0 ) {
      // TODO: buscador indiferente a las fechas
      this.totalRegistros = this.obrasTodas.length;
      this.obras = this.obrasTodas;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.obras = this.obrasTodas.filter( ( obra: any ) =>
        obra?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        obra?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        obra?.cliente?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) );

      this.totalRegistros = this.obras.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.obrasTodas.length;
      this.obras = this.obrasTodas;
    }
  }

  verObra( id: string ): void {

    this.router.navigate( ['/admin/obra', id, 'ver'] );

  }

  editarObra( id: string ): void {

    this.router.navigate( ['/admin/obra', id, 'editar'] );

  }

  borrarObra( id: string ): void {

    // TODO: comprobar si la obra tiene registros relacionados. En tal caso desactivar

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.obrasService.borrarObra( id )
          .then( res => {
            //this.cargarObras();
            Swal.fire(
              '¡Eliminado!',
              'La obra ha sido eliminada.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.obrasService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.obrasService.setPage( event.offset );
  }

  onSort(event) {
    this.obrasService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
