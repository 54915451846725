import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { getDoc } from '@angular/fire/firestore';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { AveriasService } from '../../../services/service.index';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-averias',
  templateUrl: './averias.component.html',
  styleUrls: ['./averias.component.css']
})
export class AveriasComponent implements OnInit, OnDestroy {

  averias: any = [];
  averiasTodas: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  estadoAverias: string = 'activos';
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private fb: FormBuilder,
               private router: Router,
               private averiasService: AveriasService ) { }

  ngOnInit(): void {
    this.registrosPagina = this.averiasService.getRegistrosPagina();
    this.sorts = this.averiasService.getSorts();
    this.offset = this.averiasService.getPage();
    this.estadoAverias = this.averiasService.getEstadoAverias();
    this.cargarAverias(this.estadoAverias);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarAverias( estado: string ) {

    this.cargando = true;

    this.subs.sink = this.averiasService.listaAverias(estado)
      .subscribe( async (averiasDB: any) => {
        this.totalRegistros = 0;
        this.averias = [];
        if ( averiasDB.length > 0 ) {
          await Promise.all(
            averiasDB.map( async (averia: any) => {
              averia.maquina = (averia?.maquina) ? (await getDoc( averia.maquina)).data() : null;
            })
          );
          this.totalRegistros = averiasDB.length; // TODO: Obtener total usuarios de la BD
          this.averias = averiasDB;
          this.averiasTodas = averiasDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarAveria( termino: string ) {

    if (termino.length === 0) {
      this.totalRegistros = this.averiasTodas.length;
      this.averias = this.averiasTodas;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      // TODO
      this.averias = this.averiasTodas.filter( ( averia: any ) =>
        averia?.descripcion?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.prioridad?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.maquina?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.maquina?.numserie?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.maquina?.codnavision?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.maquina?.codcontable?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        averia?.maquina?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.averias.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.averiasTodas.length;
      this.averias = this.averiasTodas;
    }
  }

  verAveria( id: string ): void {

    this.router.navigate( ['/maquinaria/averia', id, 'ver'] );

  }

  editarAveria( id: string ) {

    this.router.navigate( ['/maquinaria/averia', id, 'editar'] );

  }

  borrarAveria( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.averiasService.borrarAveria( id )
          .then( res => {
            Swal.fire(
              '¡Eliminado!',
              'La avería ha sido eliminada.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.averiasService.setRegistrosPagina( Number( event.target.value ) );
  }

  actualizarEstadoAverias(event) {
    this.subs.unsubscribe();
    this.averiasService.setEstadoAverias(event.target.value);
    this.cargarAverias(event.target.value);
  }

  onPage(event) {
    this.averiasService.setPage( event.offset );
  }

  onSort(event) {
    this.averiasService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  maquinaComparator(propA: any, propB: any) {
    return propA.codnavision.localeCompare(propB.codnavision, 'es', { numeric: true } )
  }

}
