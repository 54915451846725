import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, AsyncValidatorFn, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable, of } from 'rxjs';
import { catchError, delay, first, map } from 'rxjs/operators';

import { CamionesService } from '../../../../services/service.index';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-camion',
  templateUrl: './camion.component.html',
  styles: [
  ]
})
export class CamionComponent implements OnInit, OnDestroy {

  titulo: string = 'Nuevo camión';
  cargando: boolean = true;
  camionID: string;
  private subs = new SubSink();

  public formSubmitted = false;

  public camionForm = this.fb.group({
    matricula: [
      '',
      {
        validators: [
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        asyncValidators: [ this.matriculaValidator() ],
        updateOn: 'blur'
      }
    ],
    remolque: [
      '',
      {
        validators: [
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        updateOn: 'blur'
      }
    ],
    tara: null,
    chofer: [ '' ],
    dni: [
      '',
      {
        validators: [
          Validators.pattern(/^[0-9a-zA-Z]*$[^\-\S]*/) // No se admiten ni guiones ni espacios en blanco
        ],
        updateOn: 'blur'
      }
    ],
    telefono: [ '' ],
    transportista: [ '' ],
    agencia: [ '' ]
  });

  constructor( private camionesService: CamionesService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.activatedRoute.params
    .subscribe( params => {

      if ( params.id ) {
        this.camionID = params.id;
        this.cargarCamion( params.id );
      } else {
        this.cargando = false;
      }

    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarCamion( id: string ) {

    this.camionID = id;

    this.subs.sink = this.camionesService.obtenerCamionPorId( id )
      .pipe(
        delay(100)
      )
      .subscribe( (camion: any) => {

        if ( !camion ) {
          Swal.fire('Error', 'Error al cargar los datos del camion', 'error');
          return this.router.navigateByUrl('/maestros/camiones');
        }
        this.camionForm.controls.matricula.clearAsyncValidators();
        this.camionForm.controls.matricula.updateValueAndValidity();
        this.camionForm.reset(
          {
            matricula: camion.matricula,
            remolque: camion.remolque,
            tara: camion.tara,
            chofer: camion.chofer,
            dni: camion.dni,
            telefono: camion.telefono,
            transportista: camion.transportista,
            agencia: camion.agencia
          });
        this.cargando = false;
      });

  }

  crearCamion() {

    this.formSubmitted = true;

    if ( this.camionForm.invalid ) {
      return;
    }

    this.camionesService.crearCamion( this.camionForm.value )
      .then( () => this.router.navigateByUrl('/maestros/camiones') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarCamion() {

    this.formSubmitted = true;

    if ( this.camionForm.invalid ) {
      return;
    }

    if ( this.camionForm.pristine ) {
      this.cancelarCamion();
      return;
    }

    // Realizar el posteo
    this.camionesService.actualizarCamion( this.camionID, this.camionForm.value )
      .then( (res) => {
        if ( res ) {
          this.router.navigateByUrl('/maestros/camiones');
        } else {
          Swal.fire('Matrícula duplicada', 'La matrícula ya existe.', 'error');
          this.camionForm.controls.matricula.setErrors({ matriculaExists: true });
        }
      })
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  // Cancelar el formulario y regresar al listado
  cancelarCamion() {

    if ( this.camionForm.pristine ) {
      this.router.navigate(['/maestros/camiones']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/maestros/camiones']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.camionForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  // Comprueba si la matricula ya existe y en caso de existir, si esta dada de baja
  matriculaValidator(): AsyncValidatorFn {
    return ( control: AbstractControl ): Observable<ValidationErrors | null> => {
      return this.camionesService.buscarMatricula(control.value).pipe(
        first(),
        map( (res: any) => {
          if ( res.length ) {
            if (!res[0].estado) {
              Swal.fire({
                title: '¿Volver a activar?',
                text: 'La matricula ya existe pero esta dada de baja.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
              })
              .then( (result) => {

                if (result.value) {

                  this.cargando = true;
                  this.camionesService.activarCamion( res[0].id )
                    .then( () => this.router.navigate(['/maestros/camion/editar/', res[0].id]));

                }

              });
              return { matriculaBaja: true };
            } else {
              return { matriculaExists: true };
            }
          } else {
            return null;
          }
        }),
        catchError( () => of(null))
      );
    };
  }

}
