import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

import { UsuarioService } from '../../services/usuario/usuario.service';

import { AddProvForm } from '../../interfaces/add-prov-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ProveedoresService {

  proveedoresCol = collection( this.firestore, 'proveedores' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
              ) { }

  crearProveedor( formData: AddProvForm, tipo: string = 'general' ) {

      formData.meta = {
        creado: {
          creadoel : new Date(),
          creadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          },
        },
        modificado: {
          modificadoel: null,
          modificadopor: {
            nombre: null,
            uid: null
          },
        },
        eliminado: {
          eliminadoel: null,
          eliminadopor: {
            nombre: null,
            uid: null
          },
        }
      };
      if ( formData.cif === null ) {
        formData.cif = '';
      }
      formData.tipo = tipo;
      formData.estado = true;

      // TODO: Comprobar si existe y en caso positivo poner en activo
      return addDoc( this.proveedoresCol, formData )
        .then( docRef => {
          updateDoc( docRef, { id: docRef.id } );
        });

  }

  cargarProveedores( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.proveedoresCol, where( 'estado', '==', true ), orderBy('nombre', 'asc') );
    } else {
      consulta = query( this.proveedoresCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerProveedorPorId( proveedorID: string ) {

    const proveedorRef = doc( this.firestore, 'proveedores', proveedorID );
    return docData( proveedorRef, { idField: 'id' } );

  }

  actualizarProveedor( proveedorID: string, formData: AddProvForm ) {

    if ( formData.cif === null ) {
      formData.cif = '';
    }

    const proveedorRef = doc( this.firestore, 'proveedores', proveedorID );
    return updateDoc( proveedorRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarProveedor( proveedorID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    const proveedorRef = doc( this.firestore, 'proveedores', proveedorID );
    return updateDoc( proveedorRef, {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
