import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, doc, docData, getDocs, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

import { UsuarioService } from '../../services/usuario/usuario.service';

import { AddClieForm } from '../../interfaces/add-cliente-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  clientesCol = collection( this.firestore, 'clientes' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService ) { }

  cargarClientes( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.clientesCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( this.clientesCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerClientePorId( clienteID: string ) {

    const clienteRef = doc( this.firestore, 'clientes', clienteID );
    return docData( clienteRef, { idField: 'id' } );

  }

  crearCliente( formData: AddClieForm ) {

      formData.meta = {
        creado: {
          creadoel : new Date(),
          creadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          },
        },
        modificado: {
          modificadoel: null,
          modificadopor: {
            nombre: null,
            uid: null
          },
        },
        eliminado: {
          eliminadoel: null,
          eliminadopor: {
            nombre: null,
            uid: null
          },
        }
      };
      formData.cif = formData.cif ? formData.cif.toString() : null;
      formData.numnavision = formData.numnavision ? formData.numnavision.toString() : null;
      formData.estado = true;
      if (!formData.provincia) {
        formData.provincia = null;
      }
      if ( formData.localidad ) {
        delete formData.codpostal;
      } else if ( formData.codpostal ) {
        formData.localidad = formData.codpostal;
        delete formData.codpostal;
      } else {
        formData.localidad = null;
        delete formData.codpostal;
      }

      // TODO: Comprobar si existe y en caso positivo poner en activo
      return addDoc( this.clientesCol, formData )
        .then( docRef => {
          updateDoc( docRef, { id: docRef.id } );
          return docRef.id;
        });

  }

  actualizarCliente( clienteID: string, formData: AddClieForm ) {

    const nombreActualizado = formData.nombreActualizado;
    delete formData.nombreActualizado;
    formData.cif = formData.cif ? formData.cif.toString() : null;
    formData.numnavision = formData.numnavision ? formData.numnavision.toString() : null;
    if (!formData.provincia) {
      formData.provincia = null;
    }
    if ( formData.localidad ) {
      delete formData.codpostal;
    } else if ( formData.codpostal ) {
      formData.localidad = formData.codpostal;
      delete formData.codpostal;
    } else {
      formData.localidad = null;
      delete formData.codpostal;
    }
    const clienteRef = doc( this.firestore, 'clientes', clienteID );

    const update = updateDoc( clienteRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

    // Actualizar el nombre en la colección de obras si se ha modificado
    if ( nombreActualizado ) {
      return update.then( async () => {

        const obrasCol = collection( this.firestore, 'obras' );
        const obrasQuery = query( obrasCol, where('cliente', '==', clienteRef) );
        const obrasSnapshot = await getDocs( obrasQuery );

        obrasSnapshot.forEach( obra => {
          updateDoc( obra.ref, { 'nombrecliente': formData.nombre } );
        });

      });
    } else {
      return update;
    }

  }

  borrarCliente( clienteID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    const clienteRef = doc( this.firestore, 'clientes', clienteID );
    return updateDoc( clienteRef, {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }


  // FUNCIONES DE MANTENIMIENTO

  updNumNavision() {

    // this.firestore.collection('clientes').valueChanges()
      // .pipe(
      //   first()
      // )
      // .subscribe( (clientes: any) => {
      //   clientes.forEach( (cliente: any) => {

      //       if (Number.isInteger( cliente.numnavision )) {

      //         this.firestore.collection('clientes').doc( cliente.id )
      //           .update({
      //             numnavision: cliente.numnavision.toString()
      //           });

      //       }

      //   });
      // });

  }

}
