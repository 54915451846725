import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoTxt'
})
export class EstadoTxtPipe implements PipeTransform {

  transform( estado: string ): string {
    let estadoTxt = '';
    switch (estado) {
      case 'aviso':
        estadoTxt = 'Aviso';
        break;
      case 'pendiente':
        estadoTxt = 'Pendiente';
        break;
      case 'en_curso':
        estadoTxt = 'En curso';
        break;
      case 'reparado':
        estadoTxt = 'Reparado';
        break;
      case 'realizado':
        estadoTxt = 'Realizado';
        break;
    }
    return estadoTxt;
  }

}
