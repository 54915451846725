import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { getDoc } from '@angular/fire/firestore';
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, first, firstValueFrom, map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { AveriasService, FirebaseStorageService, FormulariosService, MaquinariaService } from 'src/app/services/service.index';

import Swal from 'sweetalert2';

type Maquina = {id: string, codigo: string, nombre: string, codnom: string};

@Component({
  selector: 'app-averia',
  templateUrl: './averia.component.html',
  styleUrls: ['./averia.component.css']
})
export class AveriaComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Avería';
  hoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  averiaID: string;
  modoLectura: boolean = false;
  maquinaria!: any[];
  maquinaID = '';
  txtHorometro = 0;
  txtUdHorometro = 'h';
  prioridad = '';
  ubicacion!: any | null;
  ubicacionTxt!: string | null;
  reportadoPor = '';
  modificadoPor!: string | null;
  modificadoEl!: string | null;
  bbddImages: any[] = [];
  estado = 'Pendiente';
  cargando: boolean = true;
  cargandoDocs: boolean = true;
  files: File[] = [];
  uploadPercent: number;
  documentos: any[];

  private subs = new SubSink();

  public formSubmitted = false;
  public formDocsSubmitted = false;

  public averiaForm = this.fb.group({
    fechaAviso: [null, Validators.required ],
    maquina: [ null, Validators.required ],
    prioridad: 'normal',
    horometro: null,
    udHorometro: 'h',
    descripcion: null,
    toggleUbicacion: false,
    geo: null,
    estado: 'pendiente',
  });

  public docsForm = this.fb.group({
    nombredoc: ['', Validators.required ],
    categoriadoc: ['foto', Validators.required ],
  });

  maquinaformat = (maquina: Maquina) => maquina.codigo + ' - ' + maquina.nombre;

  maquinasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map(term => this.maquinaria.filter( ( maquina: any ) => maquina?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                            maquina?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  constructor( private averiasService: AveriasService,
               private maquinariaService: MaquinariaService,
               private formulariosService: FormulariosService,
               private firebaseStorageService: FirebaseStorageService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private location: Location,
               private title: Title, ) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe( params => {
        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Avería';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          this.averiaID = params.id;
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.cargarAveria( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.cargarAveria( params.id );
          } else {
            this.router.navigate(['/maquinaria/averias']);
          }
        } else {
          this.averiaForm.get('fechaAviso').setValue( this.hoy );
          this.cargando = false;
        }
      });

    this.cargarMaquinas();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarMaquinas() {
    this.subs.sink = this.formulariosService.selectMaquinas()
      .subscribe( maquinasBD => {
        if ( maquinasBD.length > 0 ) {
          this.maquinaria = maquinasBD.sort( (a, b) => a.codigo.localeCompare(b.codigo, 'es', { numeric: true } ) );
        }
      });
  }

  cargarAveria( id: string ) {

    this.averiaID = id;

    this.subs.sink = this.averiasService.obtenerAveriaPorId( id )
        .pipe(
          first()
        )
        .subscribe( async (averiaData: any) => {
          if ( !averiaData ) {
            Swal.fire('Error', 'Error al cargar los datos de la avería', 'error');
            return this.router.navigateByUrl('/maquinaria/averias');
          }
          const maquinaData: any = (await getDoc(averiaData.maquina)).data();
          averiaData.maquina = maquinaData;
          averiaData.maquina.codnombre = maquinaData.codnavision + ' (' + maquinaData.nombre + ')';
          averiaData.maquina.codigo = maquinaData.codnavision;
          const fechaBD = formatDate(  averiaData.fechaAviso.toDate(), 'yyyy-MM-dd', 'en' );
          const maquina = {
            id: averiaData.maquina.id,
            codigo: averiaData.maquina.codigo,
            codnom: averiaData.maquina.codigo + ' - ' + averiaData.maquina.nombre,
            nombre: averiaData.maquina.nombre
          };
          this.maquinaID = maquina.id;
          this.prioridad = averiaData.prioridad.charAt(0).toUpperCase() + averiaData.prioridad.slice(1);
          this.txtHorometro = averiaData?.horometro || '';
          this.txtUdHorometro = ( averiaData.horometro ) ? ( (averiaData?.udHorometro) ? averiaData.udHorometro : 'h') : '';
          switch (averiaData.estado) {
            case 'pendiente':
              this.estado = 'Pendiente';
              break;
            case 'en_curso':
              this.estado = 'En curso';
              break;
            case 'reparado':
              this.estado = 'Reparado';
              break;
          }
          this.averiaForm.reset({
            fechaAviso: fechaBD,
            maquina,
            prioridad: averiaData.prioridad,
            horometro: averiaData.horometro,
            udHorometro: averiaData?.udHorometro ?? 'h',
            descripcion: averiaData.descripcion,
            toggleUbicacion: averiaData.toggleUbicacion,
            geo: averiaData.geo,
            estado: averiaData.estado,
          });
          if (averiaData.geo) {
            const geo = averiaData.geo;
            this.ubicacionTxt = geo.latitude + ' , ' + geo.longitude;
            this.ubicacion = {
              lat: geo.latitude,
              lng: geo.longitude
            };
          }
          if (averiaData?.meta?.creado?.creadopor?.uid) {
            const usuario: any = await this.getUserName(averiaData.meta.creado.creadopor.uid);
            this.reportadoPor = usuario?.nombre + ' (' + usuario?.email + ')';
          }
          if (averiaData?.meta?.modificado?.modificadopor?.uid) {
            const usuario: any = await this.getUserName(averiaData.meta.modificado.modificadopor.uid);
            const options = { weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit' };
            this.modificadoEl = averiaData.meta.modificado.modificadoel.toDate().toLocaleDateString('es-ES', options);
            this.modificadoPor = usuario?.nombre + ' (' + usuario?.email + ')';
          }
          this.cargando = false;
        });

  }

  crearAveria() {

    this.formSubmitted = true;

    if ( this.averiaForm.invalid ) {
      return;
    }

    this.averiasService.crearAveria( this.averiaForm.value )
      .then( (idAveria) => {
        if ( this.averiaForm.get('horometro').value && (this.averiaForm.get('horometro').value > this.txtHorometro ) ) {
          // Crear horometro
          const horometro = {
            fecha: this.averiaForm.controls['fechaAviso'].value,
            maquina: this.averiaForm.controls['maquina'].value,
            horometro: this.averiaForm.controls['horometro'].value,
            udHorometro: this.averiaForm.controls['udHorometro'].value,
            idAveria
          };
          this.maquinariaService.addHorometro(horometro)
            .then(() => this.router.navigateByUrl('/maquinaria/averias') )
            .catch( e => console.log(e) );
        } else {
          return this.router.navigateByUrl('/maquinaria/averias');
        }
      })
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarAveria() {

    this.formSubmitted = true;

    if ( this.averiaForm.invalid ) {
      return;
    }

    if ( this.averiaForm.pristine ) {
      this.cancelarAveria();
      return;
    }

    // Realizar el posteo
    this.averiasService.actualizarAveria( this.averiaID, this.averiaForm.value )
      .then( () => {
        if ( this.averiaForm.get('horometro').value && (this.averiaForm.get('horometro').value !== this.txtHorometro || this.averiaForm.get('udHorometro').value !== this?.txtUdHorometro ) ) {
          // Actualizar horometro
          const horometro = {
            fecha: this.averiaForm.controls['fechaAviso'].value,
            maquina: this.averiaForm.controls['maquina'].value,
            horometro: this.averiaForm.controls['horometro'].value,
            udHorometro: this.averiaForm.controls['udHorometro'].value,
            idAveria: this.averiaID
          };
          this.maquinariaService.updateHorometro(horometro)
            .then( () => this.router.navigateByUrl('/maquinaria/averias') )
            .catch( e => console.log(e) );
        } else {
          return this.router.navigateByUrl('/maquinaria/averias');
        }
      })
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  // Cancelar el formulario y regresar al listado
  cancelarAveria(): void {

    if ( this.averiaForm.pristine && this.docsForm.pristine ) {
      this.router.navigateByUrl('/maquinaria/averias');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigateByUrl('/maquinaria/averias');

      }

    });


  }

  selecMaquina( event: NgbTypeaheadSelectItemEvent ) {

  }

  verificarMaquina() {

  }

  // Obtener el nombre de usuario
  async getUserName( uid: string ) {
    return await firstValueFrom( this.formulariosService.getUserName(uid) );
  }

  campoNoValido( campo: string ): boolean {

    if ( this.averiaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.averiaForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }


  //////////////////
  // Documentos
  //////////////////

  tabClic( tab ) {

    if (tab === 'imagenes') {
      this.cargarDocs();
    }

  }

  onChangeDropzone(event) {
    this.files.push(...event.addedFiles);
  }

  onRemoveDoc(file) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  subirDoc( archivos: File[] ) {

    this.formDocsSubmitted = true;
    this.uploadPercent = null;

    if ( this.docsForm.invalid ) {
      return;
    }

    if (archivos.length > 0) {

      const maquina = this.averiaForm.get('maquina').value;
      const codMaquina = maquina.codigo;
      if (codMaquina) {
        const existeDoc = this.firebaseStorageService.existeDocAveria(archivos, codMaquina);
        existeDoc.then( res => {
          if (!res) {
            const task = this.firebaseStorageService.subirDocAveria(archivos, this.averiaID, codMaquina, this.docsForm.value);
            task.on('state_changed',
              (snapshot) => {
                this.uploadPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.limpiarDocs();
              }
            );
          } else {
            Swal.fire('Error', 'El documento ya existe.', 'error');
          }
        });
      } else {
        Swal.fire('Error', 'La maquina no existe.', 'error');
      }
    }
  }

  cargarDocs(): void {

    this.cargandoDocs = true;
    this.averiasService.obtenerAveriaDocs( this.averiaID )
    .subscribe( docs => {
      const storage = getStorage();
      docs.forEach( async (foto: any) => {
        foto.nombredoc = foto?.nombredoc ?? 'Sin nombre';
        foto.categoriadoc = (foto?.categoriadoc) ? foto.categoriadoc[0].toUpperCase() + foto.categoriadoc.slice(1).toLowerCase() : 'Foto';
        foto.webviewPath = await getDownloadURL(ref(storage, foto.url));
      });
      if (docs.length) {
        this.bbddImages = docs;
      }
      this.cargandoDocs = false;
    });
  }

  verDocumento( urlDoc: string ) {

    this.firebaseStorageService.verDocumento(urlDoc)
      .then( url => window.open(url, '_blank') );

  }

  borrarDocumento( doc: any ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.firebaseStorageService.borrarDocAveria(this.averiaID, doc.id);
        this.bbddImages.splice(this.bbddImages.indexOf(doc), 1);
      }
    });

  }

  limpiarDocs() {
    this.files = [];
    this.uploadPercent = null;
    this.formDocsSubmitted = false;
    this.docsForm.reset();
    this.docsForm.controls.categoriadoc.setValue('foto');
  }

  campoDocsNoValido( campo: string ): boolean {

    if ( this.docsForm.get(campo).invalid && this.formDocsSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectDocsNoValido( campo: string ): boolean {

    if ( this.docsForm.get(campo).value === '' && this.formDocsSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
