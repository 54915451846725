import { Injectable } from '@angular/core';
import { DocumentData, Firestore, GeoPoint, Query, addDoc, collection, collectionData, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

import { UsuarioService } from '../../services/usuario/usuario.service';

import { AddObraForm } from '../../interfaces/add-obra-form.interface';

@Injectable({
  providedIn: 'root'
})
export class ObrasService {

  obrasCol = collection( this.firestore, 'obras' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
              ) { }

  crearObra( formData: AddObraForm ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.estado = true;
    if ( formData?.cliente ) {
      formData.cliente = doc( this.firestore, 'clientes', formData.cliente )
    } else {
      return Promise.reject('No se ha seleccionado un cliente');
    }
    if (!formData.provincia) {
      formData.provincia = null;
    }
    if ( formData.localidad ) {
      delete formData.cp;
    } else if ( formData.cp ) {
      formData.localidad = formData.cp;
      delete formData.cp;
    } else {
      formData.localidad = null;
      delete formData.cp;
    }

    if ( formData.lat && formData.lng ) {
      formData.localizacion = new GeoPoint( formData.lat, formData.lng );
    }
    delete formData.lat;
    delete formData.lng;

    // TODO: Comprobar si existe y en caso positivo poner en activo
    return addDoc( this.obrasCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
        return docRef.id;
      });

  }

  cargarObras( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.obrasCol, where('estado', '==', true), orderBy('codigo', 'asc') );
    } else {
      consulta = query( this.obrasCol, orderBy('codigo', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerObraPorId( obraID: string ) {

    const obraRef = doc( this.firestore, 'obras', obraID );
    return docData( obraRef, { idField: 'id' } );

  }

  actualizarObra( obraID: string, formData: AddObraForm ) {

    if ( formData?.cliente ) {
      formData.cliente = doc( this.firestore, 'clientes', formData.cliente )
    } else {
      return Promise.reject('No se ha seleccionado un cliente');
    }
    if (!formData.provincia) {
      formData.provincia = null;
    }
    if ( formData.localidad ) {
      delete formData.cp;
    } else if ( formData.cp ) {
      formData.localidad = formData.cp;
      delete formData.cp;
    } else {
      formData.localidad = null;
      delete formData.cp;
    }
    if ( formData.lat && formData.lng ) {
      formData.localizacion = new GeoPoint( formData.lat, formData.lng );
    }
    delete formData.lat;
    delete formData.lng;

    return updateDoc( doc( this.firestore, 'obras', obraID ), {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarObra( obraID: string ) {

      // TODO: Implementar solución igual que en camiones.service.ts
      return updateDoc( doc( this.firestore, 'obras', obraID ), {
        estado: false,
        'meta.eliminado': {
          eliminadoel: new Date(),
          eliminadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          }
        }
      });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
