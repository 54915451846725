<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Articulo</h4>
            </div>
            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="articuloForm" (ngSubmit)="crearArticulo()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="form-row mb-3">
                        <div class="col-sm-12 col-lg-6">
                            <label>Proveedor*</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('proveedor') }" formControlName="proveedor" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultFormatter]="formatter"
                                    [editable]="false" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-success" type="button" (click)="proveedorModal()">
                                        <i class="mdi mdi-plus"></i>
                                    </button>
                                </div>
                                <div class="invalid-feedback">
                                    El proveedor es obligatorio. Elige uno de la lista
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <label>Familia*</label>
                            <div class="input-group">
                                <select class="custom-select col-10" style="display: block;" required [ngClass]="{ 'is-invalid': selectNoValido('familia') }" formControlName="familia">
                                    <option value="" selected>Elige una opción...</option>
                                    <option *ngFor="let familia of familias" [ngValue]="familia.id">{{ familia.nombre | titlecase }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    La familia es obligatoria. Elige una.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-9">
                            <div class="form-group">
                                <label>Nombre*</label>
                                <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                                <div class="invalid-feedback">
                                    El nombre es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-3">
                            <label class="">Unidades*</label>
                            <div class="input-group">
                                <select class="custom-select" style="display: block;" required [ngClass]="{ 'is-invalid': selectNoValido('unidades') }" formControlName="unidades">
                                    <option value="" selected>Elige una opción...</option>
                                    <option *ngFor="let unidad of unidades" [ngValue]="unidad.id">{{ unidad.codigo | uppercase }}</option>
                                </select>
                                <!-- <div class="input-group-append">
                                    <button class="btn btn-outline-success" type="button">
                                        <i class="mdi mdi-plus"></i>
                                    </button>
                                </div> -->
                                <div class="invalid-feedback">
                                    La unidad es obligatoria. Elige una.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Norma</label>
                                <input type="text" class="form-control" placeholder="Norma" formControlName="norma">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Nº de Navision</label>
                                <input type="text" class="form-control" placeholder="Nº de Navision" formControlName="numnavision">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="selectNoValido('familia') || campoNoValido('nombre') || selectNoValido('unidades')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!articuloID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="articuloID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarArticulo()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarArticulo()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Calendario
<div class="row">
    <div class="col-sm-12 col-lg-6">

        <div class="form-group">
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
            </div>
        </div>

    </div>
</div> -->