import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SortType } from '@swimlane/ngx-datatable';
import { FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { CalendariosService } from '../../../../services/service.index';

@Component({
  selector: 'app-lista-calendarios',
  templateUrl: './lista-calendarios.component.html',
  styleUrls: ['./lista-calendarios.component.css']
})
export class ListaCalendariosComponent implements OnInit, OnDestroy {

  calendarios: any = [];
  calendariosTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;
  convenio = 'gipuzkoa';
  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private fb: FormBuilder,
               private router: Router,
               private calendariosService: CalendariosService ) { }

  ngOnInit(): void {
    this.registrosPagina = this.calendariosService.getRegistrosPagina();
    this.sorts = this.calendariosService.getSorts();
    this.offset = this.calendariosService.getPage();
    this.convenio = this.calendariosService.getConvenioCalendarios();
    this.cargarCalendarios( this.convenio );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarCalendarios( convenio: string = '' ) {

    this.cargando = true;

    this.subs.sink = this.calendariosService.cargarCalendarios(convenio)
      .subscribe( calendariosDB => {
        this.totalRegistros = 0;
        this.calendarios = [];
        if ( calendariosDB.length > 0 ) {
          this.totalRegistros = calendariosDB.length; // TODO: Obtener total usuarios de la BD
          this.calendarios = calendariosDB;
          this.calendariosTodos = calendariosDB;
          this.searchForm.controls.termino.enable();
        }
        this.cargando = false;
      });

  }

  buscarCalendario( termino: string ) {

    if (termino.length === 0) {
      this.totalRegistros = this.calendariosTodos.length;
      this.calendarios = this.calendariosTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      // TODO
      this.calendarios = this.calendariosTodos.filter( ( calendario: any ) =>
        calendario?.anio?.toString().includes( termino.toLocaleLowerCase() ) ||
        calendario?.convenio?.toLowerCase().includes( termino.toLocaleLowerCase() ) );

      this.totalRegistros = this.calendarios.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.calendariosTodos.length;
      this.calendarios = this.calendariosTodos;
    }
  }

  verCalendario( id: string ): void {
    this.router.navigate( ['/personal/calendario', id, 'ver'] );
  }

  editarCalendario( id: string ): void {
    this.router.navigate( ['/personal/calendario', id, 'editar'] );
  }

  borrarCalendario( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if (result.value) {

        this.calendariosService.borrarCalendario( id )
          .then( res => {
            Swal.fire(
              '¡Eliminado!',
              'El calendario ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number(event.target.value);
    this.calendariosService.setRegistrosPagina(Number(event.target.value));
  }

  actualizarConvenio(event) {
    this.subs.unsubscribe();
    this.calendariosService.setConvenioCalendarios(event.target.value);
    this.cargarCalendarios(event.target.value);
  }

  onPage(event) {
    this.calendariosService.setPage(event.offset);
  }

  onSort(event) {
    this.calendariosService.setSorts(event.sorts);
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

}
