<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Cliente</h4>
            </div>
            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="clienteForm" (ngSubmit)="crearCliente()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Nombre*</label>
                                <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                                <div class="invalid-feedback">
                                    El nombre es obligatorio.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Alias</label>
                                <input type="text" class="form-control" placeholder="Alias" formControlName="alias">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>CIF</label>
                                <input type="text" class="form-control" placeholder="CIF A12345678" formControlName="cif">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Nº de Navision</label>
                                <input type="text" class="form-control" id="lname2" placeholder="Nº de Navision" formControlName="numnavision">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group">
                                <label>Dirección</label>
                                <input type="text" class="form-control" placeholder="Dirección" formControlName="direccion">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Provincia</label>
                                <input type="search" class="form-control" placeholder="Provincia" formControlName="provincia" [ngbTypeahead]="provsearch" [inputFormatter]="provformat" [resultFormatter]="provformat" [editable]="false" (selectItem)="selecProvincia($event)" (ngModelChange)="verificarProvincia()">
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Localidad</label>
                                <input type="search" class="form-control" placeholder="Localidad" formControlName="localidad" [ngbTypeahead]="locasearch" [inputFormatter]="locaformat" [resultFormatter]="locaformat" [editable]="false" (selectItem)="selecLocalidad($event)">
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Código postal</label>
                                <input type="search" class="form-control" placeholder="Código postal" formControlName="codpostal" [ngbTypeahead]="cpsearch" [inputFormatter]="cpformat" [resultFormatter]="cpformat" [editable]="false" (selectItem)="selecCP($event)">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input type="tel" class="form-control" placeholder="Teléfono" formControlName="telefono">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('nombre')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>


                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!clienteID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="clienteID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarCliente()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarCliente()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
