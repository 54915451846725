import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, NgForm } from '@angular/forms';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { EntradasService } from 'src/app/services/service.index';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { ExportService } from '../../../services/shared/export.service';
import { getDoc } from '@angular/fire/firestore';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styles: [
  ]
})
export class EntradasPlantaComponent implements OnInit, OnDestroy {

  entradas: any = [];
  entradasTodas: any = [];

  fechaDesde: string;
  fechaHasta: string;
  obra: string = '';

  registrosPagina: number;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;
  verHasta: boolean = false;
  verObra: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( public entradasService: EntradasService,
               private usuarioService: UsuarioService,
               private exportService: ExportService,
               private fb: FormBuilder,
               private router: Router ) {
    this.obra = this.usuarioService.usuario.obra;
  }

  ngOnInit(): void {

    if ( this.obra === '') {
      this.verObra = true;
    }
    this.fechaDesde = this.entradasService.fechaListadoDesde;
    this.fechaHasta = this.entradasService.fechaListadoHasta;
    this.registrosPagina = this.entradasService.registrosPagina;
    this.sorts = this.entradasService.getSorts();
    this.offset = this.entradasService.getPage();
    if (this.entradasService.verHasta) {
      this.verocultarHasta(false);
    } else {
      this.fechaHasta = this.fechaDesde;
    }
    this.cargarEntradas( this.obra, this.fechaDesde, this.fechaHasta );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ExportarExcel(): void {
    if (this.entradas.length > 0) {
      this.exportService.exportEntradas( this.entradas );
    }
  }

  cargarEntradas( obra: string = '', desde: string, hasta: string ) {

    this.cargando = true;
    const desdeDate = new Date( desde + ' 00:00' );
    const hastaDate = new Date( hasta + ' 00:00' );
    hastaDate.setDate( hastaDate.getDate() + 1 );
    this.subs.sink = this.entradasService.cargarEntradas( obra, desdeDate, hastaDate )
      .subscribe( async (entradasDB: any) => {
        this.totalRegistros = 0;
        this.entradas = [];
        this.entradasTodas = [];
        if ( entradasDB.length > 0 ) {
          await Promise.all( entradasDB.map( async ( entrada: any ) => {
            entrada.proveedor = ( entrada.proveedor ) ? (await getDoc( entrada.proveedor )).data() : null;
            entrada.articulo = ( entrada.articulo ) ? (await getDoc( entrada.articulo )).data() : null;
            entrada.unidad = ( entrada.unidad ) ? (await getDoc( entrada.unidad )).data() : null;
            entrada.obra = ( entrada.obra ) ? (await getDoc( entrada.obra )).data() : null;
            entrada.matricula = ( entrada.matricula ) ? (await getDoc( entrada.matricula )).data() : null;
            }));
          this.totalRegistros = entradasDB.length; // TODO: Obtener total usuarios de la BD
          this.entradas = entradasDB;
          this.entradasTodas = entradasDB;
          this.searchForm.controls.termino.enable();
          this.cargando = false;
        } else {
          this.cargando = false;
        }

      });

  }

  buscarEntrada( termino: string ): void {

    if ( termino.length <= 0 ) {
      // TODO: buscador indiferente a las fechas
      this.totalRegistros = this.entradasTodas.length;
      this.entradas = this.entradasTodas;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.entradas = this.entradasTodas.filter( ( entrada: any ) =>
        entrada?.proveedor?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        entrada?.articulo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        entrada?.matricula?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) );

      this.totalRegistros = this.entradas.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.entradasTodas.length;
      this.entradas = this.entradasTodas;
    }
  }

  editarEntrada( id: string ) {

    this.router.navigate( ['/planta/entrada/editar', id ] );

  }

  borrarEntrada( id: string ): void {

    this.entradasService.borrarEntrada( id );

  }

  cambiarFecha( form: NgForm ) {

    const fechaDesde = new Date( form.value.fechaDesde );
    let fechaHasta: Date;
    if (this.verHasta) {
      fechaHasta = new Date( form.value.fechaHasta );
    } else {
      fechaHasta = fechaDesde;
    }
    if ( fechaDesde instanceof Date && !isNaN(fechaDesde.getTime()) && fechaHasta instanceof Date && !isNaN(fechaHasta.getTime()) ) {
      this.entradasService.fechaListadoDesde = formatDate( fechaDesde, 'yyyy-MM-dd', 'en' );
      this.fechaDesde = this.entradasService.fechaListadoDesde;
      this.entradasService.fechaListadoHasta = formatDate( fechaHasta, 'yyyy-MM-dd', 'en' );
      this.fechaHasta = this.entradasService.fechaListadoHasta;
      this.subs.unsubscribe();
      this.cargarEntradas( this.obra, this.fechaDesde, this.fechaHasta );
    }

  }

  verocultarHasta( resetearFecha: boolean ): void {

    this.verHasta = !this.verHasta;
    this.entradasService.verHasta = this.verHasta;
    if (resetearFecha && this.verHasta) {
      this.entradasService.fechaListadoHasta = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
    }
    this.fechaHasta = this.entradasService.fechaListadoHasta;

  }

  actualizarRegistros() {
    this.entradasService.registrosPagina = this.registrosPagina;
  }

  onPage(event) {
    this.entradasService.setPage(event.offset);
  }

  onSort(event) {
    this.entradasService.setSorts(event.sorts);
  }

  getBtnClass({ row, column, value }): any {
    return ' botones-tabla';
  }

}
