import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, doc, docData, limit, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { first, firstValueFrom } from 'rxjs';

import { UsuarioService } from '../../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class MaquinariaService {

  maquinariaCol = collection( this.firestore, 'maquinaria' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
              ) { }

  crearMaquina( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.itvFecha = formData.itvFecha ? new Date( formData.itvFecha ) : null;
    formData.matricula = formData.matricula ? formData.matricula.toString() : null;
    formData.familia = formData.familia ? doc( this.firestore, 'maquinaria_familias', formData.familia ) : null;

    // TODO: Comprobar si existe y en caso positivo poner en activo
    return addDoc( this.maquinariaCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
      });

  }

  cargarMaquinaria( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.maquinariaCol, where('estado', '==', true), orderBy('codnavision', 'asc') );
    } else {
      consulta = query( this.maquinariaCol, orderBy('codnavision', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarFamilias( activos: boolean = true ) {

    const familiasMaquinariasCol = collection( this.firestore, 'maquinaria_familias' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( familiasMaquinariasCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( familiasMaquinariasCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerMaquinaPorId( maquinaID: string ) {

    const maquinaRef = doc( this.firestore, 'maquinaria', maquinaID );
    return docData( maquinaRef, { idField: 'id' } );

  }

  actualizarMaquina( maquinaID: string, formData: any ) {

    formData.itvFecha = formData.itvFecha ? new Date( formData.itvFecha ) : null;
    formData.matricula = formData.matricula ? formData.matricula.toString() : null;
    formData.familia = formData.familia ? doc( this.firestore, 'maquinaria_familias', formData.familia ) : null;

    return updateDoc( doc( this.firestore, 'maquinaria', maquinaID ), {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarMaquina( maquinaID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    return updateDoc( doc( this.firestore, 'maquinaria', maquinaID ), {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  async ultimoRegistroHorometro( maquinaId: string ): Promise<DocumentData[]> {

    const horometrosCol = collection( this.firestore, 'maquinaria', maquinaId, 'registros_horometro' );
    const consulta = query( horometrosCol, orderBy('fecha', 'desc'), orderBy('horometro', 'desc'), limit(1) );
    const lastReg = collectionData( consulta, { idField: 'id' } );

    return await firstValueFrom( lastReg );
  }

  async addHorometro(horometro: any) {

    const maquinaId = horometro.maquina.id;
    delete horometro.maquina;
    horometro.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    horometro.fecha = new Date( horometro.fecha );
    const maquinaRef = doc( this.firestore, 'maquinaria', maquinaId );
    const registroHorometroCol = collection( this.firestore, 'maquinaria', maquinaId, 'registros_horometro' );
    return addDoc( registroHorometroCol, horometro )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } )
          .then(() => {
            this.ultimoRegistroHorometro( maquinaId )
              .then( lastReg => {
                updateDoc( maquinaRef, {
                  fechaHorometro: lastReg[0]['fecha'],
                  horometro: lastReg[0]['horometro'],
                  udHorometro: lastReg[0]['udHorometro'],
                });
              });
          })
      });
  }

  async updateHorometro( horometro: any ) {
    const maquinaId = horometro.maquina.id;
    const horometroCol = collection( this.firestore, 'maquinaria', maquinaId, 'registros_horometro' );
    let consulta: Query<DocumentData>;
    if (horometro?.idAveria) {
      consulta = query( horometroCol, where('idAveria', '==', horometro.idAveria), limit(1) );
    } else if ( horometro?.idMantenimiento ) {
      consulta = query( horometroCol, where('idMantenimiento', '==', horometro.idMantenimiento), limit(1) );
    } else {
      return;
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        first()
      )
      .subscribe( (averiaDoc: DocumentData[]) => {
        if ( averiaDoc.length > 0 ) {
          horometro.fecha = new Date( horometro.fecha );
          const maquinaRef = doc( this.firestore, 'maquinaria', maquinaId );
          delete horometro.maquina;
          const horometroRef = doc( this.firestore, 'maquinaria', maquinaId, 'registros_horometro', averiaDoc[0]['id'] );
          return updateDoc( horometroRef, {
              ...horometro,
              'meta.modificado': {
                modificadoel: new Date(),
                modificadopor: {
                  nombre: this.usuarioService.usuario.nombre,
                  uid: this.usuarioService.usuario.uid
                }
              }
            })
            .then( () => {
              this.ultimoRegistroHorometro(maquinaId)
                .then( lastReg => {
                  updateDoc( maquinaRef, {
                    fechaHorometro: lastReg[0]['fecha'],
                    horometro: lastReg[0]['horometro'],
                    udHorometro: lastReg[0]['udHorometro'],
                  });
                })
                .catch( e => console.log(e) );
            })
            .catch( e => console.log(e) );
        } else {
          return this.addHorometro(horometro);
        }
      });
  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
