<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center" [ngClass]="cargandoClass">
            <strong>{{ txtCargando }}</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Entrada</h4>
            </div>
            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="entradaForm" (ngSubmit)="crearEntrada()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Obra*</label>
                                <input type="search" class="form-control" placeholder="Escribe el código o nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('obra') }" formControlName="obra" [ngbTypeahead]="obrasearch" [inputFormatter]="obraformat" [resultFormatter]="obraformat"
                                    [editable]="false" (selectItem)="cargarNumInterno($event)" (ngModelChange)="verificarObra()">
                                <div class="invalid-feedback">
                                    La obra es obligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Fecha*</label>
                                <input type="date" class="form-control" placeholder="Fecha" required [ngClass]="{ 'is-invalid': campoNoValido('fecha') }" formControlName="fecha">
                                <div class="invalid-feedback">
                                    La fecha es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Hora*</label>
                                <input type="time" class="form-control" placeholder="Hora" required [ngClass]="{ 'is-invalid': campoNoValido('hora') }" formControlName="hora">
                                <div class="invalid-feedback">
                                    La hora es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Nº interno*</label>
                                <input type="number" class="form-control" placeholder="Nº interno" required [ngClass]="{ 'is-invalid': campoNoValido('numinterno') }" formControlName="numinterno">
                                <div class="invalid-feedback">
                                    El albarán es obligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Proveedor*</label>
                                <input type="search" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('proveedor') }" formControlName="proveedor" [ngbTypeahead]="provsearch" [inputFormatter]="provformat" [resultFormatter]="provformat"
                                    [editable]="false" (selectItem)="proveedorSeleccionado($event)" (ngModelChange)="verificarProv()">
                                <div class="invalid-feedback">
                                    El proveedor es obligatorio. Elija uno de la lista.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Articulo*</label>
                                <input type="search" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('articulo') }" formControlName="articulo" [ngbTypeahead]="artsearch" [inputFormatter]="artformat" [resultFormatter]="artformat"
                                    [editable]="false" (selectItem)="articuloSeleccionado($event)" (ngModelChange)="verificarArt()">
                                <div class="invalid-feedback">
                                    El articulo es obligatorio. Elija uno de la lista.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <label>Matricula</label>
                            <div class="input-group">
                              <input type="search" class="form-control" placeholder="Escribe la matricula..." formControlName="matricula" [ngbTypeahead]="matsearch" [inputFormatter]="matformat" [resultFormatter]="matformat" [editable]="false"
                                (selectItem)="cargarCamion($event)" (ngModelChange)="verificarMat()">
                              <div class="input-group-append" *ngIf="!editarCamion">
                                  <button class="btn btn-outline-success" type="button" (click)="camionModal()">
                                      <i class="mdi mdi-plus"></i>
                                  </button>
                              </div>
                              <div class="input-group-append" *ngIf="editarCamion">
                                <button class="btn btn-outline-info" type="button" (click)="camionModal()">
                                    <i class="mdi mdi-pencil"></i>
                                </button>
                              </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Chofer</label>
                                <input type="text" class="form-control" placeholder="Chofer" formControlName="chofer">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Transportista</label>
                                <input type="text" class="form-control" placeholder="Transportista" formControlName="transportista">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="bascula" class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <label>Bruto báscula</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Bruto" step=".01" min="0" formControlName="bruto" (change)="calcularPesos('b')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <label>Tara báscula</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Tara" step=".01" min="0" formControlName="tara" (change)="calcularPesos('t')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <label>Neto báscula</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Neto" step=".01" min="0" formControlName="neto" (change)="calcularPesos('n')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="bascula" class="form-row">

                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <div class="d-flex">
                                    <div class="checkbox checkbox-info pt-4 mt-2">
                                        <input name="retorno" id="checkRetorno" type="checkbox" class="material-inputs chk-col-indigo" formControlName="retorno">
                                        <label for="checkRetorno"> Retorno </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <h6 class="card-subtitle"> Datos del albarán </h6>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <label>Fecha albaran*</label>
                            <div class="input-group">
                                <input type="date" class="form-control" placeholder="Fecha albaran" required [ngClass]="{ 'is-invalid': campoNoValido('fechaalbaran') }" formControlName="fechaalbaran">
                                <div class="input-group-append">
                                    <button class="btn btn-secondary" type="button" (click)="copiarFecha()">
                                        <i class="fas fa-calendar-plus"></i>
                                    </button>
                                </div>
                                <div class="invalid-feedback">
                                    La fecha albarán es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Hora albaran</label>
                                <input type="time" class="form-control" placeholder="Hora albaran" formControlName="horaalbaran">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Nº albarán*</label>
                                <input type="text" class="form-control" placeholder="Nº albarán" required [ngClass]="{ 'is-invalid': campoNoValido('numalbaran') }" formControlName="numalbaran">
                                <div class="invalid-feedback">
                                    El número albarán es obligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div *ngIf="bascula" class="col-sm-12 col-lg-4">
                            <label>Bruto albarán</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Bruto albarán" step=".01" formControlName="brutoalbaran" (change)="calcularPesos('ba')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="bascula" class="col-sm-12 col-lg-4">
                            <label>Tara albarán</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="Tara albarán" step=".01" formControlName="taraalbaran" (change)="calcularPesos('ta')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="verNeto" class="col-sm-12 col-lg-4">
                            <label>{{ unidad }} albarán*</label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="{{ unidad }} albarán" step=".01" required [ngClass]="{ 'is-invalid': campoNoValido('netoalbaran') }" formControlName="netoalbaran" (change)="calcularPesos('na')">
                                <div class="input-group-append">
                                    <span class="input-group-text">{{ codigoUnidad }}</span>
                                </div>
                                <div class="invalid-feedback">
                                    {{ unidad }} albarán es obligatorio
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- TODO: Hacer visible solo para usuarios "oficina" -->
                    <div class="form-row" *ngIf="false">

                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <div class="d-flex">
                                    <div class="checkbox checkbox-info pt-4 mt-2">
                                        <input name="facturado" id="checkFacturado" type="checkbox" class="material-inputs chk-col-indigo" formControlName="facturado">
                                        <label for="checkFacturado"> Facturado </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group">
                                <label>Comentarios</label>
                                <textarea class="form-control" placeholder="Comentarios" rows="2" formControlName="comentarios"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('obra') || campoNoValido('fecha') ||
                                      campoNoValido('hora') || campoNoValido('numinterno') ||
                                      campoNoValido('proveedor') || campoNoValido('articulo') ||
                                      campoNoValido('fechaalbaran') || campoNoValido('numalbaran') ||
                                      campoNoValido('netoalbaran')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!entradaID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="entradaID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarEntrada()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarEntrada()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
