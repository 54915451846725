import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { first } from 'rxjs';
import { SubSink } from 'subsink';

import { SellosService } from '../../../../services/service.index';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-selloce',
  templateUrl: './selloce.component.html',
  styleUrls: ['./selloce.component.css']
})
export class SelloceComponent implements OnInit, OnDestroy {

  titulo: string = 'Nuevo sello';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  selloID: string;

  private subs = new SubSink();

  public formSubmitted = false;

  public selloForm = this.fb.group({
    nombre: [ null, Validators.required ],
    certificado: [ null, Validators.required ],
    norma: [ null ],
    anio: [ null ],
  })

  constructor( private fb: FormBuilder,
               private sellosService: SellosService,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.selloID = params.id;
          this.cargarSello( params.id );
        } else {
          this.cargando = false;
        }

      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarSello( id: string ) {

    this.selloID = id;
    this.subs.sink = this.sellosService.obtenerSelloPorId( id )
      .pipe(
        first()
      )
      .subscribe( (sello: any) => {
        if ( !sello ) {
          Swal.fire('Error', 'Error al cargar los datos del sello', 'error');
          return this.router.navigateByUrl('/laboratorio/sellosce');
        }
        this.selloForm.reset({
          nombre: sello.nombre,
          certificado: sello.certificado,
          norma: sello.norma,
          anio: sello.anio,
        });
      });
    this.cargando = false;
  }

  crearSello() {

    this.formSubmitted = true;

    if ( this.selloForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.sellosService.crearSello( this.selloForm.value )
      .then( () => this.router.navigateByUrl('/laboratorio/sellosce') )
      .catch( err => {
        console.error( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarSello() {

    this.formSubmitted = true;

    if ( this.selloForm.invalid ) {
      return;
    }

    if ( !this.selloForm.pristine ) {
      this.txtCargando = 'Guardando...';
      this.cargandoClass = 'alert-secondary';
      this.cargando = true;
      this.sellosService.actualizarSello( this.selloID, this.selloForm.value )
        .then( () => this.router.navigateByUrl('/laboratorio/sellosce') )
        .catch( err => {
          console.log( err );
          Swal.fire('Error', 'error', 'error' );
        });
    } else {
      this.cancelarFormSello();
    }

  }

  // Salir al listado de sellos
  cancelarFormSello() {

    if ( this.selloForm.pristine ) {
      this.router.navigate(['/laboratorio/sellosce']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {
        this.router.navigate(['/laboratorio/sellosce']);

      }

    });

  }

  // Comprobar si el campo es valido
  campoNoValido( campo: string ): boolean {

    if ( this.selloForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
