import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Modulos
import { NgbTypeaheadModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Pipes Module
import { PipesModule } from '../pipes/pipes.module';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { CamionModalComponent } from './modals/camion/camion-modal.component';
import { MapaLlegadaComponent } from './mapas/mapa-llegada/mapa-llegada.component';
import { MapaSalidaComponent } from './mapas/mapa-salida/mapa-salida.component';
import { MapaDescargandoComponent } from './mapas/mapa-descargando/mapa-descargando.component';
import { MapaDescargadoComponent } from './mapas/mapa-descargado/mapa-descargado.component';

@NgModule({
    declarations: [
        HeaderComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        CamionModalComponent,
        MapaLlegadaComponent,
        MapaSalidaComponent,
        MapaDescargandoComponent,
        MapaDescargadoComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        NgbTypeaheadModule,
        NgbTimepickerModule,
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        NopagefoundComponent,
        CamionModalComponent,
        MapaLlegadaComponent,
        MapaSalidaComponent,
        MapaDescargandoComponent,
        MapaDescargadoComponent,
    ]
})
export class SharedModule { }
