import { Component, OnDestroy, OnInit } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SortType } from '@swimlane/ngx-datatable';
import { EquiposService } from 'src/app/services/service.index';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.css']
})
export class EquiposComponent implements OnInit, OnDestroy {

  equipos: any = [];
  equiposTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private equiposService: EquiposService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.equiposService.getRegistrosPagina();
    this.sorts = this.equiposService.getSorts();
    this.offset = this.equiposService.getPage();
    this.cargarEquipos();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarEquipos() {

    this.cargando = true;

    this.subs.sink = this.equiposService.cargarEquipos()
      .subscribe( async ( equiposDB: any ) => {
        this.totalRegistros = 0;
        this.equipos = [];
        if ( equiposDB.length > 0 ) {
          await Promise.all( equiposDB.map( async ( equipo: any ) => {
            equipo.encargado = (equipo.encargado) ? (await getDoc( equipo.encargado )).data() : null;
            equipo.encargado = {
              id: equipo.encargado?.uid,
              nombre: equipo.encargado?.nombre
            }
          }) );
          this.totalRegistros = equiposDB.length; // TODO: Obtener total usuarios de la BD
          this.equipos = equiposDB;
          this.equiposTodos = equiposDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarEquipo( termino: string ) {

    if ( termino.length <= 0 ) {
      this.totalRegistros = this.equiposTodos.length;
      this.equipos = this.equiposTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.equipos = this.equiposTodos.filter( ( equipo: any ) =>
        equipo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() )
      );

      this.totalRegistros = this.equipos.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.equiposTodos.length;
      this.equipos = this.equiposTodos;
    }
  }

  editarEquipo( id: string ) {

    this.router.navigate( ['/rrhh/equipos', id, 'editar' ] );

  }

  borrarEquipo( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.equiposService.borrarEquipo( id )
          .then( () => {
            Swal.fire(
              '¡Eliminado!',
              'El equipo ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.equiposService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.equiposService.setPage( event.offset );
  }

  onSort(event) {
    this.equiposService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
