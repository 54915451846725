<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Porte</h4>
            </div>

            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="porteForm" (ngSubmit)="crearPorte()">

                    <div class="form-row">
                        <div class="col-sm-12 col-lg-3">
                            <div class="form-group">
                                <label>Góndola*</label>
                                <div class="input-group" *ngIf="!modoLectura">
                                    <select id="prioridad" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('gondola') }" formControlName="gondola">
                                        <option value="">Elige una góndola...</option>
                                        <option value="3118LBY">V-4 - 3118LBY</option>
                                        <option value="9186DTV">V-3 - 9186DTV</option>
                                        <option value="3229GBH">CR-1 - 3229GBH</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        La góndola es obligatoria. Elige una.
                                    </div>
                                </div>
                                <input type="text" class="form-control" [value]="gondola" [readonly]="modoLectura" *ngIf="modoLectura">
                            </div>
                        </div>

                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Fecha de carga*</label>
                                <input type="date" class="form-control" placeholder="Fecha carga" required [ngClass]="{ 'is-invalid': campoNoValido('fechaCarga') }" formControlName="fechaCarga" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La fecha de carga es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Hora de carga*</label>
                                <input type="time" class="form-control" placeholder="Hora carga" required [ngClass]="{ 'is-invalid': campoNoValido('horaCarga') }" formControlName="horaCarga" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La hora de carga es obligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3" *ngIf="!modoLectura">
                        <div class="col-sm-12 col-lg-6">

                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioLugarCarga" formControlName="radioObraCarga">
                              <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('radioObraCarga').value === 'obra') ? 'btn-primary' : ''">
                                <input ngbButton type="radio" value="obra" [disabled]="modoLectura" (change)="verificarRadioObraCarga($event)"> En obra
                              </label>
                              <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('radioObraCarga').value === 'poblacion') ? 'btn-primary' : ''">
                                <input ngbButton type="radio" value="poblacion" [disabled]="modoLectura" (change)="verificarRadioObraCarga($event)"> Población
                              </label>
                            </div>

                        </div>
                    </div>
                    <div class="form-row" *ngIf="verObraCarga">
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Obra de carga*</label>
                                <input type="search" class="form-control" placeholder="Escribe el código o nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('cargadoObra') }" formControlName="cargadoObra" [ngbTypeahead]="obrasearch" [inputFormatter]="obraformat" [resultFormatter]="obraformat"
                                    [editable]="false" (selectItem)="obraCargaSeleccionada($event)" (ngModelChange)="verificarObraCarga()" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La obra de carga es obligatoria. Elija uno de la lista.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Cliente de carga*</label>
                                <input type="search" class="form-control" placeholder="Cliente" formControlName="cargadoCliente" [ngbTypeahead]="cliesearch" [inputFormatter]="clieformat" [resultFormatter]="clieformat" [editable]="false" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="verPoblacionCarga">
                        <div class="col-sm-12 col-lg-8">

                            <div class="form-group">

                                <label>Localización de carga*</label>
                                <input type="text" class="form-control" placeholder="Población o lugar de carga" required [ngClass]="{ 'is-invalid': campoNoValido('cargadoEn') }" formControlName="cargadoEn" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La localización de carga es obligatoria.
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-row" *ngIf="!modoLectura">
                        <div class="col-sm-12 col-lg-3">
                            <div class="form-group">
                                <div class="d-flex">
                                    <div class="checkbox checkbox-info pt-0">
                                        <input name="descargado" id="check-Descarga" type="checkbox" class="material-inputs chk-col-indigo" formControlName="descargado" (change)="verificarDescarga()">
                                        <label for="check-Descarga"> Descargado </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="verDescarga">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Fecha de descarga*</label>
                                <input type="date" class="form-control" placeholder="Fecha descarga" required [ngClass]="{ 'is-invalid': campoNoValido('fechaDescarga') }" formControlName="fechaDescarga" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La fecha de descarga es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Hora de descarga*</label>
                                <input type="time" class="form-control" placeholder="Hora descarga" required [ngClass]="{ 'is-invalid': campoNoValido('horaDescarga') }" formControlName="horaDescarga" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La hora de descarga es obligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3" *ngIf="verDescarga && !modoLectura">
                        <div class="col-sm-12 col-lg-6">

                            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioLugarDescarga" formControlName="radioObraDescarga">
                              <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('radioObraDescarga').value === 'obra') ? 'btn-primary' : ''">
                                <input ngbButton type="radio" value="obra" [disabled]="modoLectura" (change)="verificarRadioObraDescarga($event)"> En obra
                              </label>
                              <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('radioObraDescarga').value === 'poblacion') ? 'btn-primary' : ''">
                                <input ngbButton type="radio" value="poblacion" [disabled]="modoLectura" (change)="verificarRadioObraDescarga($event)"> Población
                              </label>
                            </div>
 -->
                        </div>
                    </div>
                    <div class="form-row" *ngIf="verDescarga && verObraDescarga">
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Obra de descarga*</label>
                                <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Escribe el código o nombre...'" required [ngClass]="{ 'is-invalid': campoNoValido('descargadoObra') }" formControlName="descargadoObra" [ngbTypeahead]="obrasearch" [inputFormatter]="obraformat" [resultFormatter]="obraformat"
                                    [editable]="false" (selectItem)="obraDescargaSeleccionada($event)" (ngModelChange)="verificarObraDescarga()" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La obra de descarga es obligatoria. Elija uno de la lista.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Cliente de descarga*</label>
                                <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Cliente'" formControlName="descargadoCliente" [ngbTypeahead]="cliesearch" [inputFormatter]="clieformat" [resultFormatter]="clieformat" [editable]="false" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="verDescarga && verPoblacionDescarga">
                        <div class="col-sm-12 col-lg-8">

                            <div class="form-group">

                                <label>Localización de descarga*</label>
                                <input type="text" class="form-control" placeholder="Población o lugar de descarga" required [ngClass]="{ 'is-invalid': campoNoValido('descargadoEn') }" formControlName="descargadoEn" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La localización de descarga es obligatoria.
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="col-12">
                            <h4>Maquinaria</h4>
                        </div>
                    </div>
                    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" formControlName="tipoCarga">
                      <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('tipoCarga').value === 'propia') ? 'btn-primary' : ''">
                        <input ngbButton type="radio" value="propia" [disabled]="modoLectura" (change)="verificarTipoCarga()"> Propia
                      </label>
                      <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('tipoCarga').value === 'externa') ? 'btn-primary' : ''">
                        <input ngbButton type="radio" value="externa" [disabled]="modoLectura" (change)="verificarTipoCarga()"> Externa
                      </label>
                      <label ngbButtonLabel [ngClass]="(!modoLectura || porteForm.get('tipoCarga').value === 'vacio') ? 'btn-primary' : ''">
                        <input ngbButton type="radio" value="vacio" [disabled]="modoLectura" (change)="verificarTipoCarga()"> Vacío
                      </label>
                    </div>
                    <div class="form-row mt-3" *ngIf="verMaquinas">
                        <div class="col-sm-12 col-lg-6">
                            <label>Nombre*</label>
                        </div>
                    </div>
                    <ng-container formArrayName="maquinas" *ngIf="verMaquinas">
                        <div class="form-row mb-2" *ngFor="let control of eventsControlsArray; let i = index">
                            <ng-container [formGroup]="control">
                                <div class="col-sm-12 col-lg-8">
                                    <input type="search" class="form-control" placeholder="Escribe el código o nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('maquinas.'+ i + '.maquina') }" formControlName="maquina" [ngbTypeahead]="maquinasearch" [inputFormatter]="maquinaformat" [resultFormatter]="maquinaformat"
                                    [editable]="false" [readonly]="modoLectura">
                                    <div class="invalid-feedback">
                                        La maquina es obligatoria
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-1" *ngIf="!modoLectura">
                                    <button type="button" class="btn btn-danger ml-2" (click)="eliminarMaquina( control.value, i )">
                                        <i class="mdi mdi-delete"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-row" *ngIf="!modoLectura">
                            <div class="col-sm-12 col-lg-2">
                                <button type="button" class="btn btn-secondary mt-3" (click)="agregarMaquina(true)">Añadir</button>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row mt-3">
                        <div class="col text-danger">

                            <p *ngIf="selectNoValido('gondola') || campoNoValido('fechaCarga') || campoNoValido('horaCarga') || campoNoValido('cargadoObra')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>

                    <hr>

                    <div class="card-body">
                        <div class="form-group mb-0 text-right">
                            <button *ngIf="!porteID && !modoLectura" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                            <button *ngIf="porteID && !modoLectura" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarPorte()">Actualizar</button>
                            <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarPorte()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                        </div>
                    </div>

                </form>

            </div>

        </div>

    </div>

</div>


