import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Firestore } from '@angular/fire/firestore';
import { Observable, switchMap } from 'rxjs';

import jspdf from 'jspdf';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PartesJspdfService {

  imgLogo: string;
  imgFirma: string;

  colorOrsa: string = '#ff4700';

  constructor( private http: HttpClient,
               private firestore: Firestore,
   ) {

    // Logo Orsa
    this.http.get('../../assets/images/logo-albaran-nuevo.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgLogo = base64ImageUrl;
      });

    // TODO: cambiar por la firma de cada uno
    this.http.get('../../assets/images/firma-marga.png', { responseType: 'blob' })
    .pipe(
      switchMap(blob => this.convertBlobToBase64(blob))
    )
    .subscribe( (base64ImageUrl: string) => {
      this.imgFirma = base64ImageUrl;
    });

   }

   anadirMarcaAgua( jspdf: any, x: number, y: number, size: number, texto: any[] ) {

    jspdf.setTextColor('#f5f5f5');
    jspdf.setFontSize(size);
    jspdf.setFont('helvetica', 'bold');
    jspdf.text(texto, x, y, {angle: 45});
    jspdf.setTextColor('#000000');

  }

  /////////////////////////////////////////////////
  // PARTE MENSUAL                               //
  /////////////////////////////////////////////////

  cabeceraParteMensual( doc: jspdf, formData: any ) {

    // Añadir logo
    if ( this.imgLogo ) {
      doc.addImage(this.imgLogo, 'PNG', 7, 5, 55, 23);
    }

    // Añadir textos fijos
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');

  }

  pieParteMensual( doc: jspdf ) {

    // Firma y sello
    if ( this.imgFirma ) {
      // modificar aleatoriamente la posición y rotación
      const x = Math.floor( Math.random() * (140 - 150 + 1) ) + 140;
      const y = Math.floor( Math.random() * (250 - 260 + 1) ) + 250;
      const rotation = Math.floor( Math.random() * (-10 - 10 + 1) ) + 10;
      doc.addImage(this.imgFirma, 'PNG', x, y, 70, 40, undefined, undefined, rotation);
    }

    // Pie de pagina
    doc.setFontSize(9);
    doc.line(7, 287, 203, 287);
    const fechaLarga = formatDate( new Date(), 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm:ss', 'es'  );
    doc.text(fechaLarga, 7, 290);

  }

  crearParteMensual( formData: any, registros: any ) {

    const doc = new jspdf();

    // Marca de agua
    this.anadirMarcaAgua( doc, 35, 192, 95, ['PARTE', 'MENSUAL']);

    this.cabeceraParteMensual( doc, formData );

    let despRegistros = 0;

    this.pieParteMensual( doc );

    const pageCount = (doc as any).internal.getCurrentPageInfo();
    for (let i = 1; i <= pageCount.pageNumber; i++) {
      doc.setFontSize(9);
      // Go to page i
      doc.setPage(i);
      doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 268, 203);
    }

    //Imprimir
    doc.autoPrint();
    doc.output('dataurlnewwindow');

  }


  /////////////////////////////////////////////////
  // IMAGEN LOCAL A BASE64                       //
  /////////////////////////////////////////////////

  convertBlobToBase64(blob: Blob) {
    return new Observable(observer => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

  /////////////////////////////////////////////////
  // UTILIDADES                                  //
  /////////////////////////////////////////////////

  capitalizarString( txt: string ): string {
    return txt.charAt(0).toUpperCase() + txt.slice(1);;
  }

}
