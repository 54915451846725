<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row mb-1">
                    <div [ngClass]="!calendarioID ? 'col-3' : 'col-6'">
                        <small>Convenio </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="convenio" (change)="actualizarConvenio($event)">
                            <option value="gipuzkoa">Gipuzkoa</option>
                            <option value="bizkaia">Bizkaia</option>
                        </select>
                    </div>
                    <div class="col-6 text-center" *ngIf="!calendarioID">
                        <h4 class="text-danger">No hay un calendario para este año y convenio</h4>
                    </div>
                    <div class="col-3" *ngIf="!calendarioID">
                        <div class="text-right">
                            <button type="button" class="btn btn-primary" [routerLink]="[ '/personal/calendario/nuevo', ]" [queryParams]="{ anio: anio, convenio: convenio }">
                                <i class="mdi mdi-plus" aria-hidden="true"></i>
                                Crear calendario
                            </button>
                        </div>
                    </div>
                    <div class="col-6" *ngIf="calendarioID">
                        <div class="text-right">
                            <button type="button" class="btn btn-primary" [routerLink]="[ '/personal/calendario', calendarioID, 'editar' ]">
                                <i class="mdi mdi-pencil" aria-hidden="true"></i>
                                Editar calendario
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-12">

                        <full-calendar [options]='calendarOptions'> </full-calendar>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
