<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar equipo" #input formControlName="termino" (keyup)="buscarEquipo( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

  <div class="col-sm-12">

      <div class="alert alert-warning text-center">
          <strong>Cargando</strong>
          <br>
          <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
          <br>
          <span>Espere por favor</span>
      </div>

  </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

  <div class="col-12">
      <div class="card">
          <div class="card-body">

              <div class="row mb-1">
                  <div class="col-lg-8 col-sm-12">
                      <h4 class="card-title">Equipos ({{ totalRegistros }})</h4>
                  </div>

                  <div class="col-lg-4 col-sm-12">
                      <div class="text-right">
                          <button type="button" class="btn btn-primary" routerLink="/rrhh/equipos/nuevo">
                              <i class="fas fa-address-book"></i>
                              Crear equipo
                          </button>
                      </div>
                  </div>

              </div>

              <div class="row mb-1">
                  <div class="col-lg-12 col-sm-12">
                    <small>Mostrar </small>
                    <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                    <small> registros</small>
                  </div>
              </div>

              <ngx-datatable #table class='bootstrap'
                [columnMode]="'flex'"
                [scrollbarH]="true"
                [headerHeight]="40"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [sortType]="sortType.multi"
                [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                [limit]="registrosPagina"
                [rows]='equipos'
                [sorts]="sorts"
                (sort)="onSort($event)"
                [offset]="offset"
                (page)="onPage($event)"
              >
                <ngx-datatable-column name="Nombre" prop="nombre" [flexGrow]="2">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row['nombre'] | uppercase }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Encargado" [flexGrow]="3">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <span>{{ row['encargado']?.['nombre'] ?? '' | uppercase }}</span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="1.5">
                  <!-- <ng-template let-value="value" ngx-datatable-header-template>
                    <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                  </ng-template> -->
                  <ng-template let-value="value" ngx-datatable-cell-template>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-info" (click)="editarEquipo( value )"><i class="mdi mdi-pencil"></i></button>
                      <button type="button" class="btn btn-danger" (click)="borrarEquipo( value )"><i class="mdi mdi-delete"></i></button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer>
                  <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                    <div style="padding: 5px 10px">
                      <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                    </div>
                    <datatable-pager
                      [pagerLeftArrowIcon]="'datatable-icon-left'"
                      [pagerRightArrowIcon]="'datatable-icon-right'"
                      [pagerPreviousIcon]="'datatable-icon-prev'"
                      [pagerNextIcon]="'datatable-icon-skip'"
                      [page]="curPage"
                      [size]="pageSize"
                      [count]="rowCount"
                      [hidden]="!((rowCount / pageSize)> 1)"
                      (change)="table.onFooterPage($event)"
                    >
                    </datatable-pager>
                  </ng-template>
                </ngx-datatable-footer>

              </ngx-datatable>

          </div>
      </div>
  </div>

</div>
