import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { MantenimientosService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-mantenimientos',
  templateUrl: './mantenimientos.component.html',
  styleUrls: ['./mantenimientos.component.css']
})
export class MantenimientosComponent implements OnInit, OnDestroy {

  mantenimientos: any = [];
  mantenimientosTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  estadoMantenimientos: string = 'activos';
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private fb: FormBuilder,
               private router: Router,
               private mantenimientosService: MantenimientosService ) { }

  ngOnInit(): void {
    this.registrosPagina = this.mantenimientosService.getRegistrosPagina();
    this.sorts = this.mantenimientosService.getSorts();
    this.offset = this.mantenimientosService.getPage();
    this.estadoMantenimientos = this.mantenimientosService.getEstadoMantenimientos();
    this.cargarMantenimientos(this.estadoMantenimientos);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarMantenimientos( estado: string ) {

    this.cargando = true;

    this.subs.sink = this.mantenimientosService.listaMantenimientos(estado)
    .subscribe( async (mantenimientosDB: any) => {
      this.totalRegistros = 0;
      this.mantenimientos = [];
      if ( mantenimientosDB.length > 0 ) {
        await Promise.all(
          mantenimientosDB.map( async (mantenimiento: any) => {
            mantenimiento.maquina = (mantenimiento?.maquina) ? (await getDoc(mantenimiento.maquina)).data() : null;
          })
        );
        this.totalRegistros = mantenimientosDB.length; // TODO: Obtener total usuarios de la BD
        this.mantenimientos = mantenimientosDB;
        this.mantenimientosTodos = mantenimientosDB;
        this.searchForm.controls.termino.enable();
      }

      this.cargando = false;

    });

  }

  buscarMantenimiento( termino: string ) {

    if (termino.length === 0) {
      this.totalRegistros = this.mantenimientosTodos.length;
      this.mantenimientos = this.mantenimientosTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      // TODO
      this.mantenimientos = this.mantenimientosTodos.filter( ( mantenimiento: any ) =>
        mantenimiento?.descripcion?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.prioridad?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.maquina?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.maquina?.numserie?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.maquina?.codnavision?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.maquina?.codcontable?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        mantenimiento?.maquina?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.mantenimientos.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.mantenimientosTodos.length;
      this.mantenimientos = this.mantenimientosTodos;
    }
  }

  verMantenimiento( id: string ): void {
    this.router.navigate( ['/maquinaria/mantenimiento', id, 'ver'] );
  }

  editarMantenimiento( id: string ) {

    this.router.navigate( ['/maquinaria/mantenimiento', id, 'editar'] );

  }

  borrarMantenimiento( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.mantenimientosService.borrarMantenimiento( id )
          .then( () => {
            Swal.fire(
              '¡Eliminado!',
              'El mantenimineto ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }


  // Estado de tabla ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number(event.target.value);
    this.mantenimientosService.setRegistrosPagina(Number(event.target.value));
  }

  actualizarEstadoMantenimientos(event) {
    this.subs.unsubscribe();
    this.mantenimientosService.setEstadoMantenimientos(event.target.value);
    this.cargarMantenimientos(event.target.value);
  }

  onPage(event) {
    this.mantenimientosService.setPage(event.offset);
  }

  onSort(event) {
    this.mantenimientosService.setSorts(event.sorts);
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  maquinaComparator(propA: any, propB: any) {
    return propA.codnavision.localeCompare(propB.codnavision, 'es', { numeric: true } )
  }

}
