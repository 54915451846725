<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar porte" #input formControlName="termino" (keyup)="buscarPorte( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <h4 class="card-title">Portes ({{ totalRegistros }})</h4>
                    </div>
                </div>

                <form #f="ngForm" ngNativeValidate>

                    <div class="row mb-1">
                        <div class="col-lg-3 col-sm-12">
                            <div class="input-group">
                                <input name="fechaDesde" type="date" class="form-control" [ngModel]="fecha" (keyup.enter)="cambiarFecha( f )">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" (click)="cambiarFecha( f )">
                                        <i class="mdi mdi-update"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-9 col-sm-12">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary" routerLink="/maquinaria/porte-gondola/nuevo">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                    Crear porte
                                </button>
                            </div>
                        </div>

                    </div>

                </form>

                <div class="row mb-1">
                    <div class="col-lg-6 col-sm-12">
                        <small>Mostrar </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <small> registros</small>
                    </div>
                </div>

                <ngx-datatable #table class='bootstrap' [columnMode]="'flex'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]="portes" [sorts]="sorts" (sort)="onSort($event)" [offset]="offset" (page)="onPage($event)">

                    <ngx-datatable-column name="" prop="id" [sortable]="false" [flexGrow]="0.5">
                      <!-- <ng-template let-value="value" ngx-datatable-header-template>
                          <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                      </ng-template> -->

                      <ng-template let-row="row" ngx-datatable-cell-template>
                          <div class="d-flex justify-content-center">
                              <ng-template #tipRevisadoVerificado>
                                <div class="d-flex flex-column">
                                    <span>Revisado por: {{ row['revisadoPor'] }}</span>
                                </div>
                              </ng-template>
                              <i [ngbTooltip]="tipRevisadoVerificado" tooltipClass="tooltip-custom" container="body" class="mdi mdi-checkbox-marked-circle-outline" *ngIf="row['revisado']" style="color: green;"></i>
                              <i class="mdi mdi-checkbox-blank-circle-outline" *ngIf="!row['revisado']" style="color: blue;"></i>
                          </div>
                      </ng-template>

                    </ngx-datatable-column>

                    <ngx-datatable-column name="Fecha carga" prop="fechaCarga" [comparator]="fechaComparator" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <i class="mdi mdi-clock-start"></i>
                          <span>&nbsp;{{ row['fechaCarga']?.toDate() | date: 'dd/MM/yyyy - H:mm' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Cargado en" prop="cargadoEn" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row['cargadoObra']; else elseBlock">
                                <ng-template #tipObraCarga>
                                    <div class="d-flex flex-column">
                                        <span>{{ row['cargadoObra']['nombre'] | uppercase }}</span>
                                        <span>({{ row['cargadoObra']['cliente']['nombre'] | uppercase }})</span>
                                    </div>
                                </ng-template>
                                <span [ngbTooltip]="tipObraCarga" tooltipClass="tooltip-custom" container="body">{{ row['cargadoObra']['codigo'] }}</span>
                            </div>
                            <ng-template #elseBlock><span>{{ row['cargadoEn'] | uppercase }}</span></ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Fecha descarga" prop="fechaDescarga" [comparator]="fechaComparator" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <i class="mdi mdi-clock-end" *ngIf="row['fechaDescarga']"></i>
                          <span>&nbsp;{{ row['fechaDescarga']?.toDate() | date: 'dd/MM/yyyy - H:mm' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Descargado en" prop="descargadoEn" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div *ngIf="row['descargadoObra']; else elseBlock">
                                <ng-template #tipObraDescarga>
                                    <div class="d-flex flex-column">
                                        <span>{{ row['descargadoObra']['nombre'] | uppercase }}</span>
                                        <span>({{ row['descargadoObra']['cliente']['nombre'] | uppercase }})</span>
                                    </div>
                                </ng-template>
                                <span [ngbTooltip]="tipObraDescarga" tooltipClass="tooltip-custom" container="body">{{ row['descargadoObra']['codigo'] }}</span>
                            </div>
                            <ng-template #elseBlock><span>{{ row['descargadoEn'] | uppercase }}</span></ng-template>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Nº maquinas" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div [ngSwitch]="row['tipoCarga']">
                            <span *ngSwitchCase="'vacio'">Porte vacío</span>
                            <span *ngSwitchCase="'externa'">Maquinaria externa</span>
                            <ng-container *ngSwitchDefault>
                              <div *ngIf="row['maquinas'].length > 0; else elseBlock">
                                <ng-template #tipMaquinas>
                                    <div class="d-flex flex-column">
                                        <span *ngFor="let maquina of row['maquinas']">{{ maquina['nombre'] | uppercase }}</span>
                                    </div>
                                </ng-template>
                                <span [ngbTooltip]="tipMaquinas" tooltipClass="tooltip-custom" container="body">{{ row['maquinas'].length }}</span>
                              </div>
                              <ng-template #elseBlock><span>{{ row['maquinas'].length }}</span></ng-template>
                            </ng-container>
                          </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Horas" prop="horas" [comparator]="fechaComparator" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['horas'] | number: '1.2-2' }} h</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="2">
                        <!-- <ng-template let-value="value" ngx-datatable-header-template>
                            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                        </ng-template> -->
                        <ng-template let-value="value" ngx-datatable-cell-template>
                             <div class="btn-group text-right" role="group">
                                <button type="button" class="btn btn-secondary" (click)="verPorte( value )"><i class="mdi mdi-eye"></i></button>
                                <button type="button" class="btn btn-info" (click)="editarPorte( value )"><i class="mdi mdi-pencil"></i></button>
                                <button type="button" class="btn btn-danger" (click)="borrarPorte( value )"><i class="mdi mdi-delete"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px">
                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>

                </ngx-datatable>

            </div>
        </div>
    </div>

</div>
