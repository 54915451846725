import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { getDoc } from '@angular/fire/firestore';
import { Observable, delay, debounceTime, distinctUntilChanged, map, filter  } from 'rxjs';
import { SubSink } from 'subsink';

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ArticulosService, ProveedoresService, FormulariosService } from '../../../../services/service.index';

import Swal from 'sweetalert2';

type Proveedor = {id: string, nombre: string};

@Component({
  selector: 'app-proveedor-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Crear proveedor</h4>
    </div>
    <div class="modal-body">
      <div class="form-row">
        <div class="col-sm-12 col-lg-12">
            <div class="form-group">

              <label>Nombre*</label>
              <input name="nombre" class="form-control" placeholder="Nombre" autocomplete="off" [(ngModel)]="nombre">

            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(nombre)">Guardar</button>
      <button type="button" class="btn btn-danger" (click)="activeModal.dismiss(false)">Cancelar</button>

    </div>
  `
})
export class ProveedorModalComponent {
  @Input() nombre;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: [ './articulo.component.css' ]
})
export class ArticuloComponent implements OnInit, OnDestroy {

  titulo: string = 'Nuevo articulo';
  cargando: boolean = true;
  articuloID: string;
  proveedores: any[] = [];
  familias: any[] = [];
  unidades: any[] = [];

  private subs = new SubSink();

  public formSubmitted = false;

  public articuloForm = this.fb.group({
    proveedor: ['', Validators.required ],
    familia: ['', Validators.required ],
    nombre: ['', Validators.required ],
    unidades: ['', Validators.required ],
    norma: [''],
    numnavision: [''],
  });

  formatter = (proveedor: Proveedor) => proveedor.nombre;

  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.proveedores.filter(proveedor => new RegExp(term, 'mi').test(proveedor.nombre)).slice(0, 10))
  )

  constructor( private articulosService: ArticulosService,
               private proveedoresService: ProveedoresService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private modalService: NgbModal ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
    .subscribe( params => {

      if ( params.id ) {
        this.articuloID = params.id;
        this.cargarArticulo( params.id );
      } else {
        this.cargando = false;
      }

    });

    this.subs.sink = this.formulariosService.selectData('proveedores')
        .subscribe( res => {
          this.proveedores = res;
        });

    this.subs.sink = this.formulariosService.selectData('familias_pb')
        .subscribe( res => {
          this.familias = res;
        });

    this.subs.sink = this.formulariosService.selectData('unidades', true)
      .subscribe( res => {
        this.unidades = res;
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarArticulo( id: string ) {

    this.articuloID = id;

    this.subs.sink = this.articulosService.obtenerArticuloPorId( id )
    .pipe(
      delay(100)
    )
    .subscribe( (articulo: any) => {

      if ( !articulo ) {
        Swal.fire('Error', 'Error al cargar los datos del articulo', 'error');
        return this.router.navigateByUrl('/maestros/materias-primas');
      }
      getDoc( articulo.proveedor )
        .then( (res: any) => {
          const prov = res.data();
          this.articuloForm.reset({
              proveedor: {
                id: prov.id,
                nombre: prov.nombre
              },
              familia: articulo.familia.id,
              nombre: articulo.nombre,
              unidades: articulo.unidades.id,
              norma: articulo.norma,
              numnavision: articulo.numnavision,
          });
          this.cargando = false;
        });
    });

  }

  crearArticulo() {

    this.formSubmitted = true;

    if ( this.articuloForm.invalid ) {
      console.log(this.articuloForm);
      return;
    }

    this.articulosService.crearArticulo( this.articuloForm.value )
      .then( () => this.router.navigateByUrl('/maestros/materias-primas') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarArticulo() {

    this.formSubmitted = true;

    if ( this.articuloForm.invalid ) {
      return;
    }

    if ( !this.articuloForm.pristine ) {
      // Realizar el posteo
      this.articulosService.actualizarArticulo( this.articuloID, this.articuloForm.value )
        .then( () => this.router.navigateByUrl('/maestros/materias-primas') )
        .catch( err => {
          Swal.fire('Error', 'error', 'error' );
        });
    } else {
      this.cancelarArticulo();
    }

  }

  // Cancelar el formulario y regresar al listado
  cancelarArticulo() {

    if ( this.articuloForm.pristine ) {
      this.router.navigate(['/maestros/materias-primas']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/maestros/materias-primas']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.articuloForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.articuloForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  proveedorModal() {

    let indexProv: number;

    const modalRef = this.modalService.open(ProveedorModalComponent);

    modalRef.result.then( res => {
      if ( res ) {
        const nombre = { nombre: res };
        this.proveedoresService.crearProveedor( nombre )
          .then( () => {
            // TODO: Seleccionar la opcion recien creada

          });
      }
    })
    .catch( err => {
      if (err) {
        console.log('error ', err);
      }
    });

  }

}
