import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, limit, orderBy, query, updateDoc, where } from '@angular/fire/firestore';

import { UsuarioService } from '../../services/service.index';
import { Observable, firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendariosService {

  private _tiposDescanso = [
      {
        nombre: 'Festivo', color: 'Red',
      },
      {
        nombre: 'Recuperado', color: 'Green',
      },
      {
        nombre: 'Vacaciones Empresa', color: 'DarkOrange',
      }
  ];
  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;
  private _convenioDatatable: string = 'gipuzkoa';
  private _convenioView: string = 'gipuzkoa';
  private _initialDate = new Date();
  private _anio = this._initialDate.getFullYear();

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService ) { }

  cargarCalendarios( convenio: string ) {
    const calendariosCol = collection(this.firestore, 'personal_calendarios');
    let consulta = query(calendariosCol, orderBy('anio', 'desc'));

    return collectionData( consulta, { idField: 'id' } );
  }

  async cargarCalendario( convenio: string, anio: number ) {
    const calendariosCol = collection(this.firestore, 'personal_calendarios');
    let consulta = query(calendariosCol, where('anio', '==', anio), where('convenio', '==', convenio), limit(1));

    return await firstValueFrom( collectionData( consulta, { idField: 'id' } ) );
  }

  cargarEventosCalendario( convenio: string, anio: number ) {
    const calendarioData = this.cargarCalendario(convenio, anio);
    return calendarioData.then( calendarioDB => {
      if (!calendarioDB.length) {
        return { calendarioID: null, events: of(null) };
      }
      const eventosCalendarioCol = collection(this.firestore, `personal_calendarios/${calendarioDB[0].id}/events`);
      const consulta = query(eventosCalendarioCol, orderBy( 'start', 'asc' ) );
      return { calendarioID: calendarioDB[0].id, events: collectionData( consulta, { idField: 'id' } ) };
    });

  }

  cargarCalendarioPorID( id: string ) {

    const calendarioDoc = doc( this.firestore, `personal_calendarios/${ id }` );
    return docData( calendarioDoc, { idField: 'id' } );

  }

  cargarEventosCalendarioPorID ( id: string ) {

    const eventosCalendarioCol = collection( this.firestore, `personal_calendarios/${ id }/events` );
    const consulta = query(eventosCalendarioCol, orderBy( 'start', 'asc' ) );
    return collectionData( consulta, { idField: 'id' } );

  }

  crearCalendario( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.events.forEach( event => {
      event.start = new Date( event.start );
      event.end = (event?.end) ? new Date( event.end ) : new Date( event.start );
      const inicio: Date = event.start;
      inicio.setHours(0, 0, 0, 0);
      const fin: Date = event.end;
      fin.setHours(0, 0, 0, 0);
      event.esMultiDia = inicio < fin;
      switch (event.tipo) {
        case 'Festivo':
          event.tipoEvento = 'festivo';
          break;
        case 'Recuperado':
          event.tipoEvento = 'recuperado';
          break;
        case 'Vacaciones Empresa':
          event.tipoEvento = 'vacacionesEmpresa';
          break;
      }
    });
    const eventos = [...formData.events];
    delete formData.events;
    formData.estado = true;
    const calendariosCol = collection(this.firestore, 'personal_calendarios');
    return addDoc( calendariosCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id } )
          .then( () => {
            eventos.forEach( event => {
              const eventsCol = collection(this.firestore, `personal_calendarios/${ docRef.id }/events`);
              addDoc(eventsCol, event)
                .then( eventRef => {
                  updateDoc( eventRef, { id: eventRef.id } );
                })
            });
          });
      });
  }

  actualizarCalendario( calendarioID: string, formData: any ) {

    formData.events.forEach( event => {
      event.start = new Date( event.start );
      event.end = (event?.end) ? new Date( event.end ) : new Date( event.start );
      const inicio: Date = event.start;
      inicio.setHours(0, 0, 0, 0);
      const fin: Date = event.end;
      fin.setHours(0, 0, 0, 0);
      console.log(inicio, fin, inicio < fin);
      event.esMultiDia = inicio < fin;
      switch (event.tipo) {
        case 'Festivo':
          event.tipoEvento = 'festivo';
          break;
        case 'Recuperado':
          event.tipoEvento = 'recuperado';
          break;
        case 'Vacaciones Empresa':
          event.tipoEvento = 'vacacionesEmpresa';
          break;
      }
    });
    const eventos = [...formData.events];
    delete formData.events;
    const calendariosRef = doc( this.firestore, 'personal_calendarios', calendarioID );
    return updateDoc( calendariosRef, {
        ...formData,
        'meta.modificado': {
          modificadoel: new Date(),
          modificadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          }
        }
      })
      .then( () => {

        if ( eventos.length > 0 ) {

          eventos.forEach( (evento) => {

            delete evento.tipo;

            if ( evento.id ) {

              const eventoCalendarioRef = doc( this.firestore, 'personal_calendarios', calendarioID, 'events', evento.id );
              updateDoc( eventoCalendarioRef, { ...evento } );

            } else {

              delete evento.id;
              const eventosCalendarioCol = collection( this.firestore, 'personal_calendarios', calendarioID, 'events' );
              addDoc( eventosCalendarioCol, evento )
                .then( (eventRef) =>  {
                  updateDoc( eventRef, { id: eventRef.id } );
                });

            }

          });

        }

      })
      .catch( (e) => console.log(e) );


  }

  borrarCalendario( calendarioID: string ) {

    const calendarioDocRef = doc( this.firestore, 'personal_calendarios', calendarioID );
    const eventsCol = collection( this.firestore, 'personal_calendarios', calendarioID, 'events' );
    const eventosColData = collectionData( eventsCol, { idField: 'id' } );
    const eventosData = firstValueFrom( eventosColData );
    return eventosData.then( eventos => {
      eventos.forEach( evento => {
        const eventoDocRef = doc( this.firestore, 'personal_calendarios', calendarioID, 'events', evento.id );
        deleteDoc( eventoDocRef );
      });
      return deleteDoc( calendarioDocRef );
    });

  }

  borrarEventoCalendario ( calendarioID: string, eventoID: string ) {

    const eventoDocRef = doc( this.firestore, 'personal_calendarios', calendarioID, 'events', eventoID );
    return deleteDoc( eventoDocRef );

  }

  calendarioValidator( convenio: string, anio: number ): Observable<unknown> {

    const calendariosCol = collection(this.firestore, 'personal_calendarios');
    const consulta = query(calendariosCol, where('anio', '==', anio), where('convenio', '==', convenio), limit(1));
    return collectionData( consulta, { idField: 'id' } );

  }

  public getTiposDescansos() {
    return this._tiposDescanso;
  }

  // Estado de calendario view

  public setConvenioCalendarioView(convenio: string): void {
    this._convenioView = convenio;
  }

  public getConvenioCalendarioView(): string {
    return this._convenioView;
  }

  public setInitialDateCalendarioView(initialDate: Date): void {
    this._initialDate = initialDate;
  }

  public getInitialDateCalendarioView(): Date {
    return this._initialDate;
  }

  public setAnioCalendarioView(anio: number): void {
    this._anio = anio;
  }

  public getAnioCalendarioView(): number {
    return this._anio;
  }

  // Estado de ngx-datatable de calendarios

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

  public setConvenioCalendarios(convenio: string): void {
    this._convenioDatatable = convenio;
  }

  public getConvenioCalendarios(): string {
    return this._convenioDatatable;
  }


}
