<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center" [ngClass]="cargandoClass">
            <strong>{{ txtCargando }}</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">{{ titulo }}</h4>
            </div>
            <form class="form-horizontal" id="loginform" autocomplete="off" [formGroup]="selloForm" (ngSubmit)="crearSello()">
                <div class="card-body">

                    <div class="form-group row">
                        <label for="fnombre" class="col-sm-3 text-right control-label col-form-label">Nombre*</label>
                        <div class="col-sm-9">
                            <input id="fnombre" type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                            <div class="invalid-feedback">
                                El nombre es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="fcertificado" class="col-sm-3 text-right control-label col-form-label">Nº de certificado*</label>
                        <div class="col-sm-9">
                            <input id="fcertificado" type="text" class="form-control" placeholder="Nº de certificado" required [ngClass]="{ 'is-invalid': campoNoValido('certificado') }" formControlName="certificado">
                            <div class="invalid-feedback">
                                El nº de certificado es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="fnorma" class="col-sm-3 text-right control-label col-form-label">Norma de referencia</label>
                        <div class="col-sm-9">
                            <input id="fnorma" type="text" class="form-control" placeholder="Norma de referencia" formControlName="norma">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="fanio" class="col-sm-3 text-right control-label col-form-label">Año del certificado</label>
                        <div class="col-sm-9">
                            <input id="fanio" type="number" class="form-control" placeholder="Año del certificado" formControlName="anio">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('nombre') || campoNoValido('certificado')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!selloID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="selloID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarSello()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarFormSello()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
