<div class="left-part bg-light dashboard-right-part fixed-left-part">

    <div class="px-3 pt-3" *ngFor="let planta of produccion">
        <h4>{{ planta?.nombre ?? '' }}</h4>
        <div class="d-flex mt-1">
            <div class="display-5 text-info"><i class="mdi mdi-factory"></i></div>
            <div class="ml-3 mt-4">
                <h2 class="mb-0">{{ planta.totalPlanta | number:'1.2-2' }} TN</h2>
            </div>
        </div>
        <hr>
        <div class="table-resposive">
            <table class="table-responsive-sm table-borderless">
                <tbody>
                    <ng-container *ngFor="let formula of planta.totalFormulas">
                        <tr *ngIf="formula.totalFormula > 0">
                            <td class="d-inline-block text-truncate" style="max-width: 226px">{{ formula?.nombre ?? '' }}</td>
                        </tr>
                        <tr *ngIf="formula.totalFormula > 0">
                            <td class="text-right font-weight-medium">{{ formula.totalFormula | number:'1.2-2' }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <hr class="mt-0">
    </div>

</div>

<div class="right-part overflow-auto dashboard-part">

    <app-breadcrumbs></app-breadcrumbs>

    <div class="row animated fadeIn fast">
        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                        <input type="search" class="form-control" placeholder="Buscar salida" #buscar formControlName="termino" (keyup)="buscarSalida( buscar.value )" (search)="verificarBusqueda( buscar.value )">

                    </form>

                </div>
            </div>
        </div>
    </div>

    <div class="row animated fadeIn fast" *ngIf="cargando">

        <div class="col-sm-12">

            <div class="alert alert-warning text-center">
                <strong>Cargando</strong>
                <br>
                <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                <br>
                <span>Espere por favor</span>
            </div>

        </div>

    </div>

    <div class="row animated fadeIn fast" *ngIf="!cargando">

        <div class="col-12">
            <div class="card">
                <div class="card-body">

                    <div class="row">
                        <div class="col-lg-12 col-sm-12">
                            <h4 class="card-title">Salidas ({{ totalRegistros }})</h4>
                        </div>
                    </div>

                    <form #f="ngForm" ngNativeValidate>
                        <div class="row">
                            <div class="col-sm-12 mb-1" [ngClass]="verHasta ? 'col-lg-6' : 'col-lg-3'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button type="button" class="btn" [ngClass]="verHasta ? 'btn-danger' : 'btn-success'" (click)="verocultarHasta(true)">
                                            <i class="mdi" [ngClass]="verHasta ? 'mdi-calendar-remove' : 'mdi-calendar-plus'"></i>
                                        </button>
                                    </div>
                                    <input name="fechaDesde" type="date" class="form-control" [ngModel]="fechaDesde" (keyup.enter)="cambiarFecha( f )">
                                    <input *ngIf="verHasta" name="fechaHasta" type="date" class="form-control" [ngModel]="fechaHasta" (keyup.enter)="cambiarFecha( f )">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-primary" (click)="cambiarFecha( f )">
                                            <i class="mdi mdi-update"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 mb-1" [ngClass]="verHasta ? 'col-lg-6' : 'col-lg-9'">
                                <div class="text-right">
                                    <button type="button" class="btn btn-primary" routerLink="/planta/salida/nueva">
                                        <i class="mdi mdi-plus"></i>
                                        Crear salida
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="row mb-1">
                        <div class="col-lg-12 col-sm-12">
                            <small>Mostrar </small>
                            <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </select>
                            <small> registros</small>
                        </div>
                    </div>

                    <ngx-datatable #table
                      class='bootstrap'
                      [columnMode]="'flex'"
                      [scrollbarH]="true"
                      [headerHeight]="40"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                      [sortType]="sortType"
                      [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                      [limit]="registrosPagina"
                      [rows]='salidas'
                      [sorts]="sorts"
                      (sort)="onSort($event)"
                      [offset]="offset"
                      (page)="onPage($event)"
                    >
                      <ngx-datatable-column name="" prop="situacion.estado" [sortable]="false" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div [ngSwitch]="row['situacion']['estado']">
                            <div *ngSwitchCase="'enObra'">
                              <ng-template #tipSituacion>
                                <div class="d-flex flex-column">
                                  <span>En obra</span>
                                </div>
                              </ng-template>
                              <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-map-marker"></i>
                            </div>
                            <div *ngSwitchCase="'descargando'">
                              <ng-template #tipSituacion>
                                <div class="d-flex flex-column">
                                  <span>Descargando</span>
                                </div>
                              </ng-template>
                              <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-arrow-down-bold"></i>
                            </div>
                            <div *ngSwitchCase="'descargado'">
                              <ng-template #tipSituacion>
                                <div class="d-flex flex-column">
                                  <span>Descargado</span>
                                </div>
                              </ng-template>
                              <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-thumb-up"></i>
                            </div>
                            <div *ngSwitchCase="'salida'">
                              <ng-template #tipSituacion>
                                <div class="d-flex flex-column">
                                  <span>Salida obra</span>
                                </div>
                              </ng-template>
                              <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-logout"></i>
                            </div>
                            <div *ngSwitchDefault>

                              <div [ngSwitch]="row['antiguo']">
                                <div *ngSwitchCase="true">
                                  <ng-template #tipSituacion>
                                    <div class="d-flex flex-column">
                                      <span>Descargado*</span>
                                    </div>
                                  </ng-template>
                                  <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-thumb-up"></i>
                                </div>
                                <div *ngSwitchDefault>
                                  <ng-template #tipSituacion>
                                    <div class="d-flex flex-column">
                                      <span>En ruta</span>
                                    </div>
                                  </ng-template>
                                  <i [ngbTooltip]="tipSituacion" tooltipClass="tooltip-custom" container="body" class="mdi mdi-truck"></i>
                                </div>
                              </div>



                            </div>
                          </div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Fecha" prop="fecha" [comparator]="fechaComparator" [flexGrow]="1.5">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <p-overlayPanel #timeline [style]="{ 'min-width': '300px' }">
                              <ng-template pTemplate>
                                <p-timeline [value]="row['timeline']">
                                  <ng-template pTemplate="content" let-event>
                                    {{ event.title }}
                                  </ng-template>
                                  <ng-template pTemplate="opposite" let-event>
                                    {{ event.hour }}
                                </ng-template>
                                </p-timeline>
                              </ng-template>
                            </p-overlayPanel>
                            <span
                              [ngClass]="row['tieneTimeline'] ? 'text-primary font-weight-bold pointer' : ''"
                              (mouseover)="row['tieneTimeline'] ? timeline.toggle($event) : null"
                              (mouseout)="row['tieneTimeline'] ? timeline.toggle($event) : null"
                            >
                              {{ row['fecha'].toDate() | date: 'dd/MM/yyyy' }}
                            </span>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Hora" prop="fecha" [comparator]="fechaComparator" [flexGrow]="1">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row['fecha'].toDate() | date: 'HH:mm' }}
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Albarán" prop="albaran" [comparator]="albaranComparator" [flexGrow]="1.5">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row['prefalbaran'] | uppercase }}-{{ row['albaran'] }}
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Planta" prop="planta" [comparator]="obraComparator" [flexGrow]="1.5" *ngIf="verPlanta">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <ng-template #tipPlanta>
                                  <div class="d-flex flex-column">
                                      <span>{{ row['planta']?.['nombre'] ?? '' | uppercase }}</span>
                                  </div>
                              </ng-template>
                              <span [ngbTooltip]="tipPlanta" tooltipClass="tooltip-custom" container="body">{{ row['planta']['codigo'] }}</span>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Obra" prop="obra" [comparator]="obraComparator" [flexGrow]="1.5">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <ng-template #tipObra>
                                  <div class="d-flex flex-column">
                                      <span>{{ row['obra']?.['nombre'] ?? '' | uppercase }}</span>
                                      <span>({{ row['cliente']?.['nombre'] ?? '' | uppercase }})</span>
                                  </div>
                              </ng-template>
                              <span [ngbTooltip]="tipObra" tooltipClass="tooltip-custom" container="body">{{ row['obra']['codigo'] }}</span>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Formula" prop="" [comparator]="formulaComparator" [flexGrow]="4">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div [ngSwitch]="row['tipo']">
                                  <ng-template #tipFormula>
                                      <div class="d-flex flex-column">
                                          <span>{{( row['formula']['codigo'] ?? '') + ' - ' + (row['formula']?.['nombre'] ?? '') }}</span>
                                      </div>
                                  </ng-template>
                                  <span *ngSwitchCase="'pt'" [ngbTooltip]="tipFormula" tooltipClass="tooltip-custom" container="body">{{ row['formula']?.['nombre'] }}</span>
                                  <span *ngSwitchCase="'pb'">{{ row['articulo']?.['nombre'] + (row['articulo']?.['nombreProveedor'] ? ' (' + row['articulo']['nombreProveedor'] + ')' : '') | uppercase }}</span>
                                  <span *ngSwitchCase="'re'">{{ row['articulo']?.['nombre'] }}</span>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Matricula" prop="matricula.nombre" [comparator]="matriculaComparator" [flexGrow]="1.5">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row['matricula']?.['nombre'] | uppercase }}
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Neto" prop="neto" [comparator]="netoComparator" [flexGrow]="1.5">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              {{ row['neto'] | number:'1.2-2' }} {{ row['unidad']['codigo'] | titlecase }}
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="2">
                          <ng-template let-value="value" ngx-datatable-header-template>
                              <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                          </ng-template>
                          <ng-template let-value="value" ngx-datatable-cell-template>
                              <div class="btn-group" role="group">
                                  <button type="button" class="btn btn-info" (click)="editarSalida( value )"><i class="mdi mdi-pencil"></i></button>
                                  <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="mdi mdi-printer"></i></button>
                                  <button type="button" class="btn btn-danger" (click)="borrarSalida( value )"><i class="mdi mdi-delete"></i></button>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>

                      <ngx-datatable-footer>
                          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                              <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                  <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                              </div>
                              <datatable-pager
                                [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage"
                                [size]="pageSize"
                                [count]="rowCount"
                                [hidden]="!((rowCount / pageSize)> 1)"
                                (change)="table.onFooterPage($event)"
                              >
                              </datatable-pager>
                          </ng-template>
                      </ngx-datatable-footer>

                    </ngx-datatable>

                </div>
            </div>
        </div>

    </div>

</div>
