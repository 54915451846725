<div class="main-wrapper">
    <!-- ============================================================== -->
    <!-- Preloader - style you can find in spinners.css -->
    <!-- ============================================================== -->
    <div class="preloader">
        <div class="lds-ripple">
            <div class="lds-pos"></div>
            <div class="lds-pos"></div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- Preloader - style you can find in spinners.css -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Login box.scss -->
    <!-- ============================================================== -->
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" [ngClass]="claseBack">
        <div class="auth-box on-sidebar p-4 bg-white m-0">
            <div id="loginform">
                <div class="logo text-center">
                    <span class="db">
                        <img src="../assets/images/logo-text.png" alt="Home" />
                    </span>
                </div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal mt-3 form-material" autocomplete="off" (ngSubmit)="loginFB( f )" #f="ngForm" ngNativeValidate>

                            <div class="form-group mb-3">
                                <div class="col-xs-12">
                                    <input name="email" class="form-control" type="text" required placeholder="Email" [ngModel]="email">
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <div class="col-xs-12">
                                    <input name="password" class="form-control" type="password" required placeholder="Contraseña" [ngModel]="password">
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="d-flex">
                                    <div class="checkbox checkbox-info pt-0">
                                        <input type="checkbox" name="recordar" id="checkbox-login" class="material-inputs chk-col-indigo" [ngModel]="recordar">
                                        <label for="checkbox-login"> Recuérdame</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group text-center mt-3">
                                <div class="col-xs-12">
                                    <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Entrar</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div id="recoverform">
                <div class="logo">
                    <h3 class="font-weight-medium mb-3">Recuperar contraseña</h3>
                    <span>Introduce tu email y se te enviaran las instrucciones</span>
                </div>
                <div class="row mt-3">
                    <!-- Form -->
                    <form class="col-12 form-material" action="index.html">
                        <!-- email -->
                        <div class="form-group row">
                            <div class="col-12">
                                <input class="form-control" type="email" required="" placeholder="Email">
                            </div>
                        </div>
                        <!-- pwd -->
                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-block btn-lg btn-primary text-uppercase" type="submit" name="action">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- ============================================================== -->
    <!-- Login box.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper scss in scafholding.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper scss in scafholding.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar -->
    <!-- ============================================================== -->
</div>
<!-- ============================================================== -->
<!-- All Required js -->
<!-- ============================================================== -->
<script src="../../assets/libs/jquery/dist/jquery.min.js"></script>
<!-- Bootstrap tether Core JavaScript -->
<script src="../../assets/libs/popper.js/dist/umd/popper.min.js"></script>
<script src="../../assets/libs/bootstrap/dist/js/bootstrap.min.js"></script>
<!--Custom JavaScript -->
<script src="../../assets/js/feather.min.js"></script>
<script src="../../assets/js/custom.js"></script>
<!-- app configuration -->
<script src="../../assets/js/app.js"></script>
<script src="../../assets/js/app.init.js"></script>
<script src="../../assets/js/app.init.dark.js"></script>