import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, docData, getDoc, orderBy, query, updateDoc, where } from '@angular/fire/firestore';

import Swal from 'sweetalert2';

import { UsuarioService } from '../../services/usuario/usuario.service';
import { EstadisticasService } from './estadisticas.service';

@Injectable({
  providedIn: 'root'
})
export class EntradasService {

  entradasCol = collection( this.firestore, 'entradas' );
  fechaListadoDesde: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  fechaListadoHasta: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  verHasta: boolean = false;
  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
               private estadisticasService: EstadisticasService,
               private router: Router ) { }


  cargarEntradas( obra: string = '', desde: Date, hasta: Date ) {

    let consulta: Query<DocumentData>;
    if (obra !== '') {
      const obraRef = doc( this.firestore, 'obras', obra );
      consulta = query( this.entradasCol, where('fecha', '>=', desde), where('fecha', '<', hasta), where('obra', '==', obraRef), orderBy('fecha', 'desc') );
    } else {
      consulta = query( this.entradasCol, where('fecha', '>=', desde), where('fecha', '<', hasta), orderBy('fecha', 'desc') );
    }
    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerEntradaPorId( entradaID: string ) {

    const entradaRef = doc( this.firestore, 'entradas', entradaID );
    return docData( entradaRef, { idField: 'id' } );

  }

  crearEntrada( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.fecha = new Date(formData.fecha + ' ' + formData.hora);
    delete formData.hora;
    if ( formData.horaalbaran ) {
      formData.fechaalbaran = new Date(formData.fechaalbaran + ' ' + formData.horaalbaran);
    } else {
      formData.fechaalbaran = new Date(formData.fechaalbaran);
    }
    if (formData?.proveedor?.id) {
      formData.proveedor = doc( this.firestore, 'proveedores', formData.proveedor.id );
    } else {
      return Promise.reject('No se ha seleccionado un proveedor');
    }
    if (formData?.obra?.id) {
      formData.obra = doc( this.firestore, 'obras', formData.obra.id );
    } else {
      return Promise.reject('No se ha seleccionado una obra');
    }
    if (formData?.articulo?.ud?.id) {
      formData.unidad = doc( this.firestore, 'unidades', formData.articulo.ud.id );
    } else {
      return Promise.reject('No se ha seleccionado una unidad');
    }
    if (formData?.articulo?.id) {
      formData.articulo = doc( this.firestore, 'materiasprimas', formData.articulo.id );
    } else {
      return Promise.reject('No se ha seleccionado un artículo');
    }
    if ( formData.matricula ) {
      formData.matricula = doc( this.firestore, 'camiones', formData.matricula.id );
    } else {
      return Promise.reject('No se ha seleccionado un camión');
    }
    formData.estado = true;

    // TODO: Comprobar si existe y en caso positivo poner en activo

    return addDoc( this.entradasCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
        this.estadisticasService.estadisticas( 'entrada', 'add', formData );
      });

  }

  actualizarEntrada( entradaID: string, formData: any ) {

    formData.fecha = new Date(formData.fecha + ' ' + formData.hora);
    delete formData.hora;
    if ( formData.horaalbaran ) {
      formData.fechaalbaran = new Date(formData.fechaalbaran + ' ' + formData.horaalbaran);
    } else {
      formData.fechaalbaran = new Date(formData.fechaalbaran);
    }
    if (formData?.proveedor?.id) {
      formData.proveedor = doc( this.firestore, 'proveedores', formData.proveedor.id );
    } else {
      return Promise.reject('No se ha seleccionado un proveedor');
    }
    if (formData?.obra?.id) {
      formData.obra = doc( this.firestore, 'obras', formData.obra.id );
    } else {
      return Promise.reject('No se ha seleccionado una obra');
    }
    if (formData?.articulo?.ud?.id) {
      formData.unidad = doc( this.firestore, 'unidades', formData.articulo.ud.id );
    } else {
      return Promise.reject('No se ha seleccionado una unidad');
    }
    if (formData?.articulo?.id) {
      formData.articulo = doc( this.firestore, 'materiasprimas', formData.articulo.id );
    } else {
      return Promise.reject('No se ha seleccionado un artículo');
    }
    if ( formData.matricula ) {
      formData.matricula = doc( this.firestore, 'camiones', formData.matricula.id );
    } else {
      return Promise.reject('No se ha seleccionado un camión');
    }
    // Calcular diferencia entre neto anterior y nuevo neto
    const entradaRef = doc( this.firestore, 'entradas', entradaID );
    return getDoc( entradaRef )
      .then( async ref => {
        const netoAnterior = await ref.get('netoalbaran');
        const diferencia = formData.netoalbaran - netoAnterior;
        if ( diferencia !== 0 ) {
          this.estadisticasService.estadisticas( 'entrada', 'mod', ref.data(), diferencia );
        }

        return updateDoc( entradaRef, {
            ...formData,
            'meta.modificado': {
              modificadoel: new Date(),
              modificadopor: {
                nombre: this.usuarioService.usuario.nombre,
                uid: this.usuarioService.usuario.uid
              }
            }
          });
      });

  }

  borrarEntrada( entradaID: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        // TODO: Cambiar delete por update y cambiarle el estado a false y añadir metadatos
        const entradaRef = doc( this.firestore, 'entradas', entradaID );
        docData( entradaRef, { idField: 'id' } )
          .subscribe( ref => {
            this.estadisticasService.estadisticas( 'entrada', 'del', ref );
            deleteDoc( entradaRef )
              .finally( () => {
                Swal.fire(
                  '¡Eliminado!',
                  'La entrada ha sido eliminada.',
                  'success'
                );
              });
          });
      }

    });

  }

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

}
