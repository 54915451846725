<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

  <div class="col-sm-12">

      <div class="alert text-center" [ngClass]="cargandoClass">
          <strong>{{ txtCargando }}</strong>
          <br>
          <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
          <br>
          <span>Espere por favor</span>
      </div>

  </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
  <div class="col-sm-12">
      <div class="card">
          <div class="card-header bg-info">
              <h4 class="card-title text-white">Categoría de documento</h4>
          </div>
          <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="categoriaDocumentoForm" (ngSubmit)="crearCategoriaDocumento()">
              <div class="card-body">
                  <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                  <div class="row">
                      <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                              <label>Nombre*</label>
                              <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                              <div class="invalid-feedback">
                                  El nombre es obligatorio
                              </div>
                          </div>
                      </div>
                      <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                              <label for="tipo">Tipo documento</label>
                              <ng-select
                                [items]="tiposDocumentos"
                                bindValue="id"
                                bindLabel="nombre"
                                labelForId="tipo"
                                placeholder="Elige el tipo de documento..."
                                notFoundText="No hay tipos de documento disponibles"
                                clearAllText="Limpiar"
                                formControlName="tipo"
                                [clearable]="true"
                                [loadingText]="'Cargando...'"
                                required
                              >
                              </ng-select>
                              <div class="invalid-ngselect" *ngIf="formSubmitted && categoriaDocumentoForm.controls['tipo'].hasError('required')">
                                El tipo de documento es obligatorio. Elige uno.
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col text-danger">

                          <p *ngIf="campoNoValido('nombre') || campoNoValido('tipo')">
                              Hay errores en el formulario. Corrígelos para poder guardar la información.
                          </p>

                      </div>
                  </div>


              </div>
              <hr>
              <div class="card-body">
                  <div class="form-group mb-0 text-right">
                      <button *ngIf="!categoriaDocumentoID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                      <button *ngIf="categoriaDocumentoID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarCategoriaDocumento()">Actualizar</button>
                      <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarCategoriaDocumento()">Cancelar</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</div>
