import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { ProveedoresService } from 'src/app/services/service.index';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styles: [
  ]
})
export class ProveedoresComponent implements OnInit, OnDestroy {

  proveedores: any = [];
  proveedoresTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private proveedoresService: ProveedoresService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.proveedoresService.getRegistrosPagina();
    this.sorts = this.proveedoresService.getSorts();
    this.offset = this.proveedoresService.getPage();
    this.cargarProveedores();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarProveedores() {

    this.cargando = true;

    this.subs.sink = this.proveedoresService.cargarProveedores()
      .subscribe( ( proveedoresDB: any ) => {
        this.totalRegistros = 0;
        this.proveedores = [];
        if ( proveedoresDB.length > 0 ) {
          this.totalRegistros = proveedoresDB.length; // TODO: Obtener total usuarios de la BD
          this.proveedores = proveedoresDB;
          this.proveedoresTodos = proveedoresDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarProveedor( termino: string ) {

    if ( termino.length <= 0 ) {
      // TODO: buscador indiferente a las fechas
      this.totalRegistros = this.proveedoresTodos.length;
      this.proveedores = this.proveedoresTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.proveedores = this.proveedoresTodos.filter( ( proveedor: any ) =>
        proveedor?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        proveedor?.cif?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.proveedores.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.proveedoresTodos.length;
      this.proveedores = this.proveedoresTodos;
    }
  }

  editarProveedor( id: string ) {

    this.router.navigate( ['/maestros/proveedor', id, 'editar' ] );

  }

  borrarProveedor( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.proveedoresService.borrarProveedor( id )
          .then( () => {
            Swal.fire(
              '¡Eliminado!',
              'El proveedor ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.proveedoresService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.proveedoresService.setPage( event.offset );
  }

  onSort(event) {
    this.proveedoresService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
