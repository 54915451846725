import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function twoControlRequiredValidator( controlName1: string, controlName2: string ): ValidatorFn {
  return ( form: FormGroup ): ValidationErrors | null => {

    const control1 = form.get(controlName1);
    const control2 = form.get(controlName2);

    if ( (control1.value === null && control2.value === null) || (control1.value !== null && control2.value !== null) ) {
      control1.setErrors( null );
      control2.setErrors( null );
    } else {
      control1.setErrors({ twoControlRequired: true });
      control1.markAsTouched();
      control2.setErrors({ twoControlRequired: true });
      control2.markAsTouched();
    }

    return null;

  };
}
