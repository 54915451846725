import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class MantenimientosService {

  mantenimientosCol = collection( this.firestore, 'taller_mantenimientos' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;
  private _estado: string = 'activos';

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
              ) { }

  listaMantenimientos( estado: string = 'todo' ) {

    let consulta = query( this.mantenimientosCol );
    switch (estado) {
      case 'avisos':
        consulta = query( consulta, where('estado', '==', 'aviso'), orderBy('fechaAviso', 'desc') );
        break;
      case 'pendientes':
        consulta = query( consulta, where('estado', '==', 'pendiente'), orderBy('fecha', 'desc') );
        break;
      case 'en_curso':
        consulta = query( consulta, where('estado', '==', 'en_curso'), orderBy('fecha', 'desc') );
        break;
      case 'realizados':
        consulta = query( consulta, where('estado', '==', 'realizado'), orderBy('fecha', 'desc') );
        break;
      case 'activos':
        consulta = query( consulta, where('estado', '!=', 'realizado'), orderBy('estado', 'desc'), orderBy('fecha', 'desc') );
        break;
      default:
        consulta = query( consulta, orderBy('fecha', 'desc'), orderBy('fechaAviso', 'desc') );
        break;
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  listaMantenimientosMaquina( mantenimientoID: string, estado: string = 'todo' ) {

    const maquinaDocRef = doc( this.firestore, 'maquinaria', mantenimientoID );
    let consulta = query( this.mantenimientosCol );
    switch (estado) {
      case 'avisos':
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), where('estado', '==', 'aviso'), orderBy('fechaAviso', 'desc') );
        break;
      case 'pendientes':
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), where('estado', '==', 'pendiente'), orderBy('fecha', 'desc') );
        break;
      case 'en_curso':
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), where('estado', '==', 'en_curso'), orderBy('fecha', 'desc') );
        break;
      case 'realizados':
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), where('estado', '==', 'realizado'), orderBy('fecha', 'desc') );
        break;
      case 'activos':
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), where('estado', '!=', 'realizado'), orderBy('estado', 'desc'), orderBy('fecha', 'desc') );
        break;
      default:
        consulta = query( consulta, where('maquina', '==', maquinaDocRef), orderBy('fecha', 'desc'), orderBy('fechaAviso', 'desc') );
        break;
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerMantenimientoPorId( mantenimientoID: string ) {

    const mantenimientoRef = doc( this.firestore, 'taller_mantenimientos', mantenimientoID );
    return docData( mantenimientoRef, { idField: 'id' } );

  }

  crearMantenimiento( formData: any ): Promise<string> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.fecha = (formData.fecha) ? new Date( formData.fecha ) : null;
    formData.fechaAviso = (formData.fechaAviso) ? new Date( formData.fechaAviso ) : null;
    formData.maquina = doc( this.firestore, 'maquinaria', formData.maquina.id );

    return addDoc( this.mantenimientosCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id } )
          .then( () => {
            return docRef.id;
          });
      });

  }

  actualizarMantenimiento( mantenimientoID: string, formData: any ) {

    (formData.fecha) ? formData.fecha = new Date( formData.fecha ) : delete formData.fecha;
    (formData.fechaAviso) ? formData.fechaAviso = new Date( formData.fechaAviso ) : delete formData.fechaAviso;
    formData.maquina = doc( this.firestore, 'maquinaria', formData.maquina.id );

    const mantenimientoDocRef = doc( this.firestore, 'taller_mantenimientos', mantenimientoID );
    return updateDoc(mantenimientoDocRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarMantenimiento( mantenimientoID: string ) {

    const mantenimientoDocRef = doc( this.firestore, 'taller_mantenimientos', mantenimientoID );
    return deleteDoc( mantenimientoDocRef );

  }

  // Estado de ngx-datatable de mantenimientos

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

  public setEstadoMantenimientos(estado: string): void {
    this._estado = estado;
  }

  public getEstadoMantenimientos(): string {
    return this._estado;
  }

}
