import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UsuarioService } from '../services/service.index';

@Injectable({
  providedIn: 'root'
})
export class PersonalGuard implements CanActivate {

  constructor( private usuarioService: UsuarioService,
               private router: Router ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      switch (this.usuarioService.role) {
        case 'admin':
        case 'oficina':
          return true;
        default:
          this.router.navigateByUrl('/inicio');
          return false;
      }
  }

}
