import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { UsuarioService } from '../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class MaestrosGuard implements CanActivate {

  constructor( private usuarioService: UsuarioService,
               private router: Router ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (
        this.usuarioService.role === 'admin' ||
        this.usuarioService.role === 'oficina' ||
        this.usuarioService.role === 'laboratorio' ||
        this.usuarioService.role === 'planta'
      ) {
      return true;
    } else {
      this.router.navigateByUrl('/inicio');
      return false;
    }

  }

}
