import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';

import { UsuarioService } from '../service.index';

import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellosService {

  sellosCol = collection( this.firestore, 'sellos_ce' );

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService, ) { }

  listaSellos( activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.sellosCol, where('estado', '==', true), orderBy('anio', 'asc') );
    } else {
      consulta = query( this.sellosCol, orderBy('anio', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerSelloPorId( selloID: string ) {

    const selloRef = doc( this.firestore, 'sellos_ce', selloID );
    return docData( selloRef, { idField: 'id' } );

  }

  crearSello( formData: any ): Promise<void> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };

    formData.estado = true;
    return addDoc( this.sellosCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id });
      });

  }

  async actualizarSello( selloID: string, formData: any ) {

    const selloRef = doc( this.firestore, 'sellos_ce', selloID );
    return updateDoc( selloRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarSello( selloID: string ): void {

    const selloRef = doc( this.firestore, 'sellos_ce', selloID );
    const consulta = query( collection( this.firestore, 'formulas' ), where( 'selloce', '==', selloRef ) );
    firstValueFrom( collectionData( consulta, { idField: 'id' } ) )
      .then( formulas => {

        if (formulas.length) {

          Swal.fire({
            title: '¡Hay registros con este sello!',
            text: 'Si se elimina el sello podría haber inconsistencias en las formulas.',
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            denyButtonColor: '#6e7881',
            cancelButtonColor: '#dd3333',
            confirmButtonText: 'Dar de baja',
            denyButtonText: 'Sustituir',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {

              updateDoc( selloRef, {
                estado: false,
                'meta.modificado': {
                  eliminadoel: new Date(),
                  eliminadopor: {
                    nombre: this.usuarioService.usuario.nombre,
                    uid: this.usuarioService.usuario.uid
                  }
                }
              }).then( res => {
                Swal.fire('Dado de baja!', '', 'success');
              });

              } else if (result.isDenied) {
              // TODO: dar opción de asignar otro sello
              Swal.fire('Seleccione el sello', 'En desarrollo', 'info');
            }

          });

        } else {

          deleteDoc( selloRef )
            .then( res => {
              Swal.fire(
                '¡Eliminado!',
                'El sello ha sido eliminado.',
                'success'
              );
            }).catch( err => {
              console.error(err);
            });

        }

      });

  }

}
