<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
  <div class="col-sm-12">
      <div class="card">
          <div class="card-header bg-info">
              <h4 class="card-title text-white">Saldos del Trabajador</h4>
          </div>

          <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="saldosTrabajadorForm">

            <div class="card-body">

              <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>

              <!-- Nombre, primer apellido, segundo apellido -->
              <div class="row">
                <div class="col-sm-12 col-lg-6">
                    <div class="form-group">
                      <label>Nombre</label>
                      <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                      <div class="invalid-feedback">
                        El nombre es obligatorio.
                      </div>
                    </div>
                </div>
              </div>

              <div class="row mt-3">

                <div class="col-sm-12 col-lg-6">

                  <h3>Saldo horas</h3>

                  <!-- Año -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Año</label>
                        <input type="number" class="form-control" placeholder="Año" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.anio') }" formControlName="anio">
                        <div class="invalid-feedback">
                          El año es obligatorio.
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- Fecha última actualización -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <label>Fecha última actualización</label>
                      <div class="input-group">
                        <input type="date" class="form-control" placeholder="Fecha última actualización" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.fecha_ultima_actualizacion') }" formControlName="fecha_ultima_actualizacion">
                        <div class="input-group-append">
                          <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" (click)="fechaHoyHoras()">
                              <i class="fas fa-calendar-plus"></i>
                            </button>
                        </div>
                      </div>
                        <div class="invalid-feedback">
                          La fecha última actualización es obligatoria.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Horas de años anteriores -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Horas de años anteriores</label>
                        <input type="number" class="form-control" placeholder="Horas de años anteriores" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.horas_anios_anteriores') }" formControlName="horas_anios_anteriores" (change)="calcularSaldoHoras()">
                        <div class="invalid-feedback">
                          Las horas de años anteriores son obligatorias.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Horas extra -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Horas extra</label>
                        <input type="number" class="form-control" placeholder="Horas extra" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.horas_extra') }" formControlName="horas_extra" (change)="calcularSaldoHoras()">
                        <div class="invalid-feedback">
                          Las horas extra son obligatorias.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Horas lluvia -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Horas lluvia</label>
                        <input type="number" class="form-control" placeholder="Horas lluvia" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.horas_lluvia') }" formControlName="horas_lluvia" (change)="calcularSaldoHoras()">
                        <div class="invalid-feedback">
                          Las horas lluvia son obligatorias.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Horas traslados -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Horas traslados</label>
                        <input type="number" class="form-control" placeholder="Horas traslados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.horas_traslados') }" formControlName="horas_traslados">
                        <div class="invalid-feedback">
                          Las horas traslados son obligatorias.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Horas pagadas -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Horas pagadas</label>
                        <input type="number" class="form-control" placeholder="Horas pagadas" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.horas_pagadas') }" formControlName="horas_pagadas" (change)="calcularSaldoHoras()">
                        <div class="invalid-feedback">
                          Las horas pagadas son obligatorias.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Saldo -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_horas">
                      <div class="form-group">
                        <label>Saldo</label>
                        <input type="number" class="form-control" placeholder="Saldo" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_horas.saldo') }" formControlName="saldo" readonly>
                        <div class="invalid-feedback">
                          El saldo es obligatorio.
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

                <div class="col-sm-12 col-lg-6">

                  <h3>Saldo vacaciones</h3>

                  <!-- Año -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_vacaciones">
                      <div class="form-group">
                        <label>Año</label>
                        <input type="number" class="form-control" placeholder="Año" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.anio') }" formControlName="anio">
                        <div class="invalid-feedback">
                          El año es obligatorio.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Fecha última actualización -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_vacaciones">
                      <label>Fecha última actualización</label>
                      <div class="input-group">
                        <input type="date" class="form-control" placeholder="Fecha última actualización" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.fecha_ultima_actualizacion') }" formControlName="fecha_ultima_actualizacion">
                        <div class="input-group-append">
                          <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" (click)="fechaHoyVacaciones()">
                              <i class="fas fa-calendar-plus"></i>
                            </button>
                        </div>
                      </div>
                        <div class="invalid-feedback">
                          La fecha última actualización es obligatoria.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Días de años anteriores y disfrutados -->
                  <div class="row">

                    <!-- Días de años anteriores -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de años anteriores</label>
                        <input type="number" class="form-control" placeholder="Días de años anteriores" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_anios_anteriores') }" formControlName="dias_anios_anteriores" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de años anteriores son obligatorios.
                        </div>
                      </div>

                    </div>

                    <!-- Días de años anteriores disfrutados -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de años anteriores disfrutados</label>
                        <input type="number" class="form-control" placeholder="Días de años anteriores disfrutados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_anios_anteriores_disfrutados') }" formControlName="dias_anios_anteriores_disfrutados" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de años anteriores disfrutados son obligatorios.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Días de empresa y disfrutados -->
                  <div class="row">

                    <!-- Días de empresa -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de empresa</label>
                        <input type="number" class="form-control" placeholder="Días de empresa" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_empresa') }" formControlName="dias_empresa" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de empresa son obligatorios.
                        </div>
                      </div>

                    </div>

                    <!-- Días de empresa disfrutados -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de empresa disfrutados</label>
                        <input type="number" class="form-control" placeholder="Días de empresa disfrutados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_empresa_disfrutados') }" formControlName="dias_empresa_disfrutados" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de empresa disfrutados son obligatorios.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Días de trabajador y disfrutados -->
                  <div class="row">

                    <!-- Días de trabajador -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de trabajador</label>
                        <input type="number" class="form-control" placeholder="Días de trabajador" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_trabajador') }" formControlName="dias_trabajador" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de trabajador son obligatorios.
                        </div>
                      </div>

                    </div>

                    <!-- Días de trabajador disfrutados -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de trabajador disfrutados</label>
                        <input type="number" class="form-control" placeholder="Días de trabajador disfrutados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_trabajador_disfrutados') }" formControlName="dias_trabajador_disfrutados" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de trabajador disfrutados son obligatorios.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Días de fiesta local y disfrutados -->
                  <div class="row">

                    <!-- Días de fiesta local -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de fiesta local</label>
                        <input type="number" class="form-control" placeholder="Días de fiesta local" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_fiesta_local') }" formControlName="dias_fiesta_local" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de fiesta local son obligatorios.
                        </div>
                      </div>

                    </div>

                    <!-- Días de fiesta local disfrutados -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label >Días de fiesta local disfrutados</label>
                        <input type="number" class="form-control" placeholder="Días de fiesta local disfrutados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_fiesta_local_disfrutados') }" formControlName="dias_fiesta_local_disfrutados" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de fiesta local disfrutados son obligatorios.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Días de asuntos propios y disfrutados -->
                  <div class="row">

                    <!-- Días de asuntos propios -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de asuntos propios</label>
                        <input type="number" class="form-control" placeholder="Días de asuntos propios" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_asuntos_propios') }" formControlName="dias_asuntos_propios" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de asuntos propios son obligatorios.
                        </div>
                      </div>

                    </div>

                    <!-- Días de asuntos propios disfrutados -->
                    <div class="col-sm-12 col-lg-4 text-truncate" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Días de asuntos propios disfrutados</label>
                        <input type="number" class="form-control" placeholder="Días de asuntos propios disfrutados" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.dias_asuntos_propios_disfrutados') }" formControlName="dias_asuntos_propios_disfrutados" (change)="calcularSaldoVacaciones()">
                        <div class="invalid-feedback">
                          Los días de asuntos propios disfrutados son obligatorios.
                        </div>
                      </div>

                    </div>

                  </div>

                  <!-- Saldo -->
                  <div class="row">

                    <div class="col-sm-12 col-lg-8" formGroupName="saldo_vacaciones">

                      <div class="form-group">
                        <label>Saldo</label>
                        <input type="number" class="form-control" placeholder="Saldo" required [ngClass]="{ 'is-invalid': campoNoValido('saldo_vacaciones.saldo') }" formControlName="saldo" readonly>
                        <div class="invalid-feedback">
                          El saldo es obligatorio.
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <!-- Errores -->
              <div class="row">
                <div class="col text-danger">

                    <p *ngIf="campoNoValido('saldo_horas') || campoNoValido('saldo_vacaciones')">
                        Hay errores en el formulario. Corrígelos para poder guardar la información.
                    </p>

                </div>
              </div>


            </div>
            <hr>
            <div class="card-body">
              <div class="form-group mb-0 text-right">
                  <button type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarSaldosTrabajador()">Actualizar</button>
                  <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarFormTrabajador()">Cancelar</button>
              </div>
            </div>

          </form>

      </div>
  </div>
</div>
