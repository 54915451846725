import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { ClientesService, FormulariosService } from '../../../../services/service.index';

import Swal from 'sweetalert2';

type Provincia = {id: string, nombre: string};
type Localidad = {id: string, nombre: string; codigo_postal: string};

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styles: [
  ]
})
export class ClienteComponent implements OnInit, OnDestroy {

  titulo: string = 'Nuevo cliente';
  cargando: boolean = true;
  clienteID: string;
  provincias: any = [];
  localidades: any = [];

  private subs = new SubSink();

  public formSubmitted = false;

  public clienteForm = this.fb.group({
    nombre: ['', Validators.required ],
    nombreActualizado: [ false ],
    alias: [ '' ],
    cif: [ '' ],
    numnavision: [ '' ],
    direccion: [ '' ],
    provincia: [ '' ],
    localidad: {value: null, disabled: true},
    codpostal: {value: null, disabled: true},
    telefono: [ '' ],
    email: [ '' ]
  });

  provformat = (provincia: Provincia) => provincia.nombre;

  provsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.provincias.filter( provincia => new RegExp( term, 'mi' ).test( provincia.nombre )).slice(0, 10) )
  )

  locaformat = (localidad: Localidad) => localidad.nombre;

  locasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.nombre )).slice(0, 10) )
  )

  cpformat = (localidad: Localidad) => localidad.codigo_postal;

  cpsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.codigo_postal )).slice(0, 10) )
  )

  constructor( private clientesService: ClientesService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.clienteID = params.id;
          this.cargarCliente( params.id );
        } else {
          this.cargando = false;
        }

      });

    this.subs.sink = this.formulariosService.selectProvincias()
      .subscribe( res => {
        this.provincias = res;
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarCliente( id: string ) {

    this.clienteID = id;

    this.subs.sink = this.clientesService.obtenerClientePorId( id )
    .pipe(
      delay(100)
    )
    .subscribe( (cliente: any) => {

      if ( !cliente ) {
        Swal.fire('Error', 'Error al cargar los datos del cliente', 'error');
        return this.router.navigateByUrl('/admin/clientes');
      }
      if ( cliente.provincia ) {
        this.cargarLocalidades( cliente.provincia.id );
      }
      if ( cliente.localidad ) {
        this.clienteForm.get('localidad').enable();
        this.clienteForm.get('codpostal').enable();
      }
      this.clienteForm.reset(
        {
          nombre: cliente.nombre,
          alias: cliente.alias,
          cif: cliente.cif,
          numnavision: cliente.numnavision,
          direccion: cliente.direccion,
          provincia: cliente.provincia,
          localidad: cliente.localidad,
          codpostal: cliente.localidad,
          telefono: cliente.telefono,
          email: cliente.email
        });
      this.cargando = false;
    });

  }

  crearCliente() {

    this.formSubmitted = true;

    if ( this.clienteForm.invalid ) {
      return;
    }

    this.clientesService.crearCliente( this.clienteForm.value )
      .then( () => this.router.navigateByUrl('/admin/clientes') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarCliente() {

    this.formSubmitted = true;

    if ( this.clienteForm.invalid ) {
      return;
    }

    if ( !this.clienteForm.pristine ) {
      this.clienteForm.get('nombreActualizado').setValue( this.clienteForm.get('nombre').dirty );
      this.clientesService.actualizarCliente( this.clienteID, this.clienteForm.value )
        .then( () => this.router.navigateByUrl('/admin/clientes') )
        .catch( err => {
          Swal.fire('Error', 'error', 'error' );
        });
    } else {
      this.cancelarCliente();
    }

  }

  // Cancelar el formulario y regresar al listado
  cancelarCliente(): void {

    if ( this.clienteForm.pristine ) {
      this.router.navigate(['/admin/clientes']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/admin/clientes']);

      }

    });

  }

  cargarLocalidades( id: any ) {

    this.subs.sink = this.formulariosService.selectLocalidades( id )
      .subscribe( res => {
        this.localidades = res;
        this.clienteForm.get('localidad').enable();
        this.clienteForm.get('codpostal').enable();
      });

  }

  selecProvincia( event: NgbTypeaheadSelectItemEvent ) {

    this.cargarLocalidades( event.item.id );

  }

  verificarProvincia() {

    if ( !this.clienteForm.get('provincia').value ) {
      this.clienteForm.get('localidad').disable();
      this.clienteForm.get('codpostal').disable();
      this.clienteForm.get('localidad').setValue( null );
      this.clienteForm.get('codpostal').setValue( null );
    }

  }

  selecLocalidad( event: NgbTypeaheadSelectItemEvent ) {

    this.clienteForm.get('codpostal').setValue( event.item );

  }

  selecCP( event: NgbTypeaheadSelectItemEvent ) {

    this.clienteForm.get('localidad').setValue( event.item );

  }

  campoNoValido( campo: string ): boolean {

    if ( this.clienteForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
