import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SubSink } from 'subsink';
import { delay } from 'rxjs/operators';

import { MaquinariaService, UsuarioService } from '../../../../services/service.index';

import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-maquina',
  templateUrl: './maquina.component.html',
  styles: [
  ]
})
export class MaquinaComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Maquina';
  cargando: boolean = true;
  maquinaID: string;
  modoLectura: boolean = false;
  foto: string = 'assets/images/svg/no_photo.svg';
  altFoto: string = 'Foto de la máquina';
  familias: any[] = [];
  subfamilias: any[] = [];
  txtHorometro: string = '0';
  txtUdHorometro: string = 'h.';
  isAdmin: boolean = false;

  private subs = new SubSink();

  public formSubmitted = false;

  public maquinaForm = this.fb.group({
    nombre: ['', Validators.required ],
    numserie: [ '' ],
    matricula: [ '' ],
    codnavision: [ '' ],
    codcontable: [ '' ],
    notas: [ '' ],
    tipo: [ '' ],
    familia: [ [], Validators.required],
    subfamilia: [{ value: [], disabled: true }, Validators.required],
    itv: [ false ],
    itvFecha: [ null ],
    notificacionMantenimiento: [ false ],
    mantenimientoCada: [ null ],
    mantenimientoUnidad: [ 'h' ],
    estado: [ true ],
  });

  constructor( private maquinariaService: MaquinariaService,
               private usuarioService: UsuarioService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe( params => {

      if ( params.id ) {
        this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Maquina';
        this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
        this.maquinaID = params.id;
        if (params.accion === 'editar') {
          this.modoLectura = false;
          this.cargarMaquina( params.id );
        } else if (params.accion === 'ver') {
          this.modoLectura = true;
          this.cargarMaquina( params.id );
        } else {
          this.router.navigate(['/maquinaria/listado']);
        }
      } else {
        this.cargando = false;
      }

    });

    this.cargarFamilias();
    this.cargarUserRole();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarUserRole() {
    this.usuarioService.usuario.role === 'admin' ? this.isAdmin = true : this.isAdmin = false;
  }

  cargarFamilias() {
    this.maquinariaService.cargarFamilias().subscribe( (familias: any) => {
      this.familias = familias;
    });
  }

  cargarMaquina( id: string ) {

    this.maquinaID = id;

    this.subs.sink = this.maquinariaService.obtenerMaquinaPorId( id )
    .pipe(
      delay(100)
    )
    .subscribe( (maquina: any) => {

      if ( !maquina ) {
        Swal.fire('Error', 'Error al cargar los datos de la maquina', 'error');
        return this.router.navigateByUrl('/maquinaria/listado');
      }
      const fechaITV = maquina?.itvFecha ? formatDate( maquina.itvFecha.toDate(), 'yyyy-MM-dd', 'en' ) : null;
      this.maquinaForm.reset(
        {
          nombre: maquina.nombre,
          numserie: maquina.numserie,
          matricula: maquina.matricula,
          codnavision: maquina.codnavision,
          codcontable: maquina.codcontable,
          notas: maquina?.notas ?? '',
          tipo: maquina.tipo,
          familia: maquina?.familia?.id,
          subfamilia: maquina?.subfamilia,
          itv: maquina.itv ?? false,
          itvFecha: fechaITV,
          notificacionMantenimiento: maquina?.notificacionMantenimiento ?? false,
          mantenimientoCada: maquina?.mantenimientoCada ?? 0,
          mantenimientoUnidad: maquina?.mantenimientoUnidad ?? 'h',
          estado: maquina.estado,
        }
      );
      if( maquina.subfamilia ) {
        this.maquinaForm.get('subfamilia').enable();
      }
      this.foto = maquina.foto ?? 'assets/images/svg/no_photo.svg';
      this.altFoto = maquina.nombre;
      this.cargando = false;
    });

  }

  crearMaquina() {

    this.formSubmitted = true;

    if ( this.maquinaForm.invalid ) {
      return;
    }

    this.maquinariaService.crearMaquina( this.maquinaForm.value )
      .then( () => this.router.navigateByUrl('/maquinaria/listado') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarMaquina() {

    this.formSubmitted = true;

    if ( this.maquinaForm.invalid ) {
      return;
    }

    if ( !this.maquinaForm.pristine ) {
      // Realizar el posteo
      this.maquinariaService.actualizarMaquina( this.maquinaID, this.maquinaForm.value )
        .then( () => this.router.navigateByUrl('/maquinaria/listado') )
        .catch( err => {
          Swal.fire('Error', 'error', 'error' );
        });
    } else {
      this.cancelarMaquina();
    }

  }

  // Cancelar el formulario y regresar al listado
  cancelarMaquina(): void {

    if ( this.maquinaForm.pristine ) {
      this.router.navigate(['/maquinaria/listado']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/maquinaria/listado']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.maquinaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  cambiarFamilia( e: any ) {
    this.maquinaForm.get('subfamilia').enable();
    this.maquinaForm.get('subfamilia').reset();
    this.subfamilias = e?.subfamilia;
  }

  borrarFamilia( e: any ) {
    this.maquinaForm.get('subfamilia').reset();
    this.maquinaForm.get('subfamilia').disable();
    this.subfamilias = [];
  }

    //////////////////
  // Tabs
  //////////////////

  tabClic( tab ) {

    if (tab === 'documentos') {
      this.cargarDocs();
    }

  }

  //////////////////
  // Documentos
  //////////////////

  cargarDocs() {
    console.log('cargarDocs');
  }

}
