import { Component, OnInit, ViewEncapsulation, forwardRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import { SubSink } from 'subsink';

import { CalendariosService } from '../../../services/service.index';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarioComponent implements OnInit {

  convenio = 'gipuzkoa';
  calendarioID: string;
  initialDate = new Date();
  anio = this.initialDate.getFullYear();
  cargando: boolean = false;
  calendarOptions: CalendarOptions = {
    plugins: [
      multiMonthPlugin,
      interactionPlugin,
    ],
    initialView: 'multiMonthYear',
    locale: esLocale,
    headerToolbar: {
      left: 'listaCalendariosButton',
      center: 'title',
      right: 'today prev,next'
    },
    datesSet: this.actualizarAnio.bind(this),
  };

  private subs = new SubSink();

  constructor( private calendariosService: CalendariosService,
               private router: Router ) { }

  ngOnInit(): void {
    this.anio = this.calendariosService.getAnioCalendarioView();
    this.initialDate = this.calendariosService.getInitialDateCalendarioView();
    this.convenio = this.calendariosService.getConvenioCalendarioView();
    this.cargando = true;
    this.cargarEventosCalendario( this.convenio, this.anio );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarEventosCalendario( convenio: string, anio: number ) {
    this.calendariosService.cargarEventosCalendario( convenio, anio )
      .then( (calendario: any) => {
        this.calendarioID = calendario.calendarioID;
        this.subs.sink =  calendario.events.subscribe( eventos => {
          this.calendarOptions.initialDate = this.initialDate;
          this.calendarOptions.customButtons = {
            listaCalendariosButton: {
              text: 'Ver lista calendarios',
              click: () => {
                this.router.navigate( ['/personal/calendarios/lista'] );
              }
            }
          };
          if ( !eventos ) {
            this.cargando = false;
            return;
          }
          eventos.forEach( evento => {
            evento.start = formatDate( evento.start.toDate(), 'yyyy-MM-dd', 'en' );
            evento.end = formatDate( evento.end.toDate(), 'yyyy-MM-dd', 'en' );
          });
          this.calendarOptions.events =  eventos;
          this.cargando = false;
        });
      });
  }

  actualizarConvenio( event: any ) {
    this.subs.unsubscribe();
    this.calendariosService.setConvenioCalendarioView(event.target.value);
    this.cargarEventosCalendario(event.target.value, this.anio);
  }

  actualizarAnio( dateInfo: any ) {
    const anio = Number( formatDate( dateInfo.start, 'yyyy', 'en') );
    if ( this.anio !== anio ) {
      this.anio = anio;
      this.initialDate = new Date(anio + '-01-01');
      this.calendariosService.setAnioCalendarioView(anio);
      this.calendariosService.setInitialDateCalendarioView(this.initialDate);
      this.subs.unsubscribe();
      this.cargarEventosCalendario(this.convenio, this.anio);
    }
  }

}
