import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, NgForm } from '@angular/forms';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { debounceTime } from 'rxjs/operators';

import { SalidasService, UsuarioService, JspdfService, ExportService } from '../../../services/service.index';
import { getDoc } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-salidas',
  templateUrl: './salidas.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [ './salidas.component.css' ]
})
export class SalidasPlantaComponent implements OnInit, OnDestroy {

  salidas: any = [];
  salidasTodas: any = [];

  fechaDesde: string;
  fechaHasta: string;
  obra: string = '';

  registrosPagina: number;
  sorts: any = [];
  sortType: SortType = SortType.multi;
  offset: number = 0;
  totalRegistros: number = 0;

  produccion: any = [];

  cargando: boolean = false;
  verHasta: boolean = false;
  verPlanta: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( public salidasService: SalidasService,
               private usuarioService: UsuarioService,
               private jspfd: JspdfService,
               private exportService: ExportService,
               private fb: FormBuilder,
               private router: Router ) {
    this.obra = this.usuarioService.usuario.obra;
  }

  ngOnInit(): void {

    if ( this.obra === '') {
      this.verPlanta = true;
    }
    this.fechaDesde = this.salidasService.fechaListadoDesde;
    this.fechaHasta = this.salidasService.fechaListadoHasta;
    this.registrosPagina = this.salidasService.registrosPagina;
    this.sorts = this.salidasService.getSorts();
    this.offset = this.salidasService.getPage();
    if (this.salidasService.verHasta) {
      this.verocultarHasta(false);
    } else {
      this.fechaHasta = this.fechaDesde;
    }
    this.cargarSalidas( this.obra, this.fechaDesde, this.fechaHasta );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ExportarExcel(): void {
    if (this.salidas.length > 0) {
      this.exportService.exportSalidas( this.salidas );
    }
  }

  rellenarConCeros( numero: string, length: number ): string {

    let myString = '' + numero;
    while (myString.length < length) {
      myString = '0' + myString;
    }

    return myString;

  }

  cargarSalidas( obra: string = '', desde: string, hasta: string ): void {

    this.cargando = true;
    const desdeDate = new Date( desde + ' 00:00' );
    const hastaDate = new Date( hasta + ' 00:00' );
    hastaDate.setDate( hastaDate.getDate() + 1 );
    this.subs.sink = this.salidasService.cargarSalidas( obra, desdeDate, hastaDate )
      .pipe(
        debounceTime(250)
      )
      .subscribe( async (salidasDB: any) => {
        this.totalRegistros = 0;
        this.salidas = [];
        this.salidasTodas = [];
        if ( salidasDB.length > 0 ) {
          const hoy = new Date().getTime();
          await Promise.all(
            salidasDB.map( async ( salida: any ) => {
              salida.albaran = this.rellenarConCeros( salida.albaran, 5 );
              salida.formula = ( salida.formula ) ? (await getDoc( salida.formula )).data() : null;
              salida.articulo = ( salida.articulo ) ? (await getDoc( salida.articulo )).data() : null;
              salida.unidad = ( salida.unidad ) ? (await getDoc( salida.unidad )).data() : null;
              salida.cliente = ( salida.cliente ) ? (await getDoc( salida.cliente )).data() : null;
              salida.planta = ( salida.planta ) ? (await getDoc( salida.planta )).data() : null;
              salida.obra = ( salida.obra ) ? (await getDoc( salida.obra )).data() : null;
              salida.matricula = ( salida.matricula ) ? (await getDoc( salida.matricula )).data() : null;
              salida.tieneTimeline = false;
              salida.timeline = [];
              salida.timeline = await firstValueFrom( this.salidasService.obtenerTimelineSalida( salida.id ) );
              salida.tieneTimeline = salida.timeline.length > 1;
              salida.antiguo = (salida.fecha.toDate().getTime() + 43200000 ) <= hoy; // Si han pasado 12 horas marcar como descargado
            }
          ));

          // TODO: Obtener total de produccion por planta y por articulo de la BD
          const plantas = [...new Set(salidasDB.map( item => item.planta.nombre ))];
          const initPlantas: any = [];
          plantas.forEach( (p: string) => {
            initPlantas[p] = 0;
          });
          const articulos = [...new Set(salidasDB.map( item => {
            if ( item.formula?.nombre ) {
              return item.formula?.nombre ?? '';
            } else {
              return item.articulo?.nombre ?? '';
            }
          }))];
          const initArticulos: any = [];
          plantas.forEach( (p: string ) => {
            initArticulos[p] = {};
            articulos.forEach( (a: string) => {
              initArticulos[p][a] = 0;
            });
          });
          const initialValue = {
            produccionTotal:  0,
            totalPlanta: initPlantas,
            totalFormula: initArticulos
          };
          const map = salidasDB.reduce( ( acc, salida ) => {
            acc.produccionTotal += salida.neto;
            acc.totalPlanta[salida.planta.nombre] += salida.neto;
            let articulo = '';
            if (salida.formula?.nombre) {
              articulo = salida.formula?.nombre ?? '';
            } else {
              articulo = salida.articulo?.nombre ?? '';
            }
            acc.totalFormula[salida.planta.nombre][articulo] = (acc.totalFormula[salida.planta.nombre][articulo] || 0) + salida.neto;
            return acc;
          }, initialValue);

          this.produccion = Object.keys(map.totalPlanta).map( (name) => {
            return {
              nombre: name,
              totalPlanta: map.totalPlanta[name],
              totalFormulas: Object.keys(map.totalFormula[name]).map( (formula) => {
                return {
                  nombre: formula,
                  totalFormula: map.totalFormula[name][formula]
                };
              })
            };
          });
          this.totalRegistros = salidasDB.length; // TODO: Obtener total usuarios de la BD
          this.salidas = salidasDB;
          this.salidasTodas = salidasDB;
          this.searchForm.controls.termino.enable();
          this.cargando = false;
        } else {
          this.produccion = [];
          this.cargando = false;
        }

      });

  }

  buscarSalida( termino: string ): void {

    if ( termino.length <= 0 ) {
      this.totalRegistros = this.salidasTodas.length;
      this.salidas = this.salidasTodas;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.salidas = this.salidasTodas.filter( ( salida ) => {
        if ( salida.tipo === 'pt') {
          if ( salida?.cliente?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.formula?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.formula?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.matricula?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )) {
            return true;
          } else {
            return false;
          }
        } else if ( salida.tipo === 'pb') {
          if (salida?.cliente?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.articulo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.articulo?.nombreProveedor?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.matricula?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )) {
            return true;
          } else {
            return false;
          }
        } else {
          if (salida?.cliente?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.obra?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.articulo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
              salida?.matricula?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )) {
            return true;
          } else {
            return false;
          }
        }
      });

      this.totalRegistros = this.salidas.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ): void {
    if ( termino === '' ) {
      this.totalRegistros = this.salidasTodas.length;
      this.salidas = this.salidasTodas;
    }
  }

  editarSalida( id: string ): void {

    this.router.navigate( ['/planta/salida/editar', id ] );

  }

  imprimirSalida( id: any ): void {

    this.jspfd.imprimirAlbaranPlanta( id );
  }

  borrarSalida( id: string ): void {

    this.salidasService.borrarSalida( id );

  }

  cambiarFecha( form: NgForm ): void {

    const fechaDesde = new Date( form.value.fechaDesde );
    let fechaHasta: Date;
    if (this.verHasta) {
      fechaHasta = new Date( form.value.fechaHasta );
    } else {
      fechaHasta = fechaDesde;
    }
    if ( fechaDesde instanceof Date && !isNaN(fechaDesde.getTime()) && fechaHasta instanceof Date && !isNaN(fechaHasta.getTime()) ) {
      this.salidasService.fechaListadoDesde = formatDate( fechaDesde, 'yyyy-MM-dd', 'en' );
      this.fechaDesde = this.salidasService.fechaListadoDesde;
      this.salidasService.fechaListadoHasta = formatDate( fechaHasta, 'yyyy-MM-dd', 'en' );
      this.fechaHasta = this.salidasService.fechaListadoHasta;
      this.subs.unsubscribe();
      this.cargarSalidas( this.obra, this.fechaDesde, this.fechaHasta );
    }

  }

  verocultarHasta( resetearFecha: boolean ): void {

    this.verHasta = !this.verHasta;
    this.salidasService.verHasta = this.verHasta;
    if (resetearFecha && this.verHasta) {
      this.salidasService.fechaListadoHasta = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
    }
    this.fechaHasta = this.salidasService.fechaListadoHasta;

  }

  actualizarRegistros(event) {
    this.registrosPagina = Number(event.target.value);
    this.salidasService.registrosPagina = Number(event.target.value);
  }

  onPage(event) {
    this.salidasService.setPage(event.offset);
  }

  onSort(event) {
    this.salidasService.setSorts(event.sorts);
  }

  getBtnClass({ row, column, value }): any {
    return ' botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  albaranComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.albaran.toLowerCase() < propB.albaran.toLowerCase()) {
      return -1;
    } else if (propA.albaran.toLowerCase() > propB.albaran.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  obraComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.codigo.toLowerCase() < propB.codigo.toLowerCase()) {
      return -1;
    } else if (propA.codigo.toLowerCase() > propB.codigo.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  formulaComparator(propA: any, propB: any): number {
    if (propA.tipo === 'pt') {
      if (propA?.formula?.nombre?.toLowerCase() < propB?.formula?.nombre?.toLowerCase()) {
        return -1;
      } else if (propA?.formula?.nombre?.toLowerCase() > propB?.formula?.nombre?.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (propA?.articulo?.nombre?.toLowerCase() < propB?.articulo?.nombre?.toLowerCase()) {
        return -1;
      } else if (propA?.articulo?.nombre?.toLowerCase() > propB?.articulo?.nombre?.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  matriculaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.toLowerCase() < propB.toLowerCase()) {
      return -1;
    } else if (propA.toLowerCase() > propB.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  netoComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

}
