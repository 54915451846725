<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar avería" #input formControlName="termino" (keyup)="buscarAveria( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row mb-3">
                    <div class="col-lg-8 col-sm-12">
                        <h4 class="card-title">Averías ({{ totalRegistros }})</h4>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="text-right">
                            <button type="button" class="btn btn-primary" routerLink="/maquinaria/averia/nueva">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                Crear averia
                            </button>
                        </div>
                    </div>

                </div>

                <div class="row mb-1">
                    <div class="col-lg-6 col-sm-12">
                        <small>Mostrar </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <small> registros</small>
                    </div>
                    <div class="col-lg-6 col-sm-12 text-right">
                        <small>Estado </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="estadoAverias" (change)="actualizarEstadoAverias($event)">
                            <option value="activos">Activos</option>
                            <option value="pendientes">Pendientes</option>
                            <option value="en_curso">En curso</option>
                            <option value="reparados">Reparados</option>
                            <option value="todo">Todos</option>
                        </select>
                    </div>
                </div>

                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]="averias" [sorts]="sorts" (sort)="onSort($event)" [offset]="offset" (page)="onPage($event)">
                    <ngx-datatable-column name="Fecha" prop="fechaAviso" [comparator]="fechaComparator" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['fechaAviso'].toDate() | date: 'dd/MM/yyyy' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Maquina" prop="maquina" [comparator]="maquinaComparator" [flexGrow]="4">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['maquina']['codnavision'] }} - {{ row['maquina']['nombre'] }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Descripción" prop="descripcion" [sortable]="false" [flexGrow]="3"></ngx-datatable-column>
                    <ngx-datatable-column name="Horometro/Km" prop="horometro" [sortable]="false" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['horometro'] }} {{ (row['horometro']) ? row['udHorometro'] ?? 'h' : '' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Prioridad" prop="prioridad" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['prioridad'] | titlecase }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Estado" prop="estado" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['estado'] | estadoTxt }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="2">
                        <!-- <ng-template let-value="value" ngx-datatable-header-template>
                            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                        </ng-template> -->
                        <ng-template let-value="value" ngx-datatable-cell-template>
                             <div class="btn-group text-right" role="group">
                                <button type="button" class="btn btn-secondary" (click)="verAveria( value )"><i class="mdi mdi-eye"></i></button>
                                <button type="button" class="btn btn-info" (click)="editarAveria( value )"><i class="mdi mdi-pencil"></i></button>
                                <button type="button" class="btn btn-danger" (click)="borrarAveria( value )"><i class="mdi mdi-delete"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px">
                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>

                </ngx-datatable>

            </div>
        </div>
    </div>

</div>
