<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar parte de trabajo" #input formControlName="termino" (keyup)="buscarParteTrabajo( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

  <div class="col-12">
    <div class="card">
      <div class="card-body">

        <div class="row mb-1">

          <div class="col-lg-8 col-sm-12">
              <h4 class="card-title">Partes de trabajo ({{ totalRegistros }})</h4>
          </div>

          <div class="col-lg-4 col-sm-12">
            <div class="text-right">
              <button type="button" class="btn btn-primary mb-2" routerLink="/rrhh/partes-trabajo/diario/nuevo">
                <i class="fas fa-plus"></i>
                Crear parte diario
              </button>
              <button type="button" class="btn btn-secondary ml-2 mb-2" routerLink="/rrhh/partes-trabajo/mensual/nuevo">
                <i class="fas fa-plus"></i>
                Crear parte mensual
              </button>
            </div>
          </div>

        </div>

        <div class="row mb-4 mt-4">

          <div class="col-sm-12">

            <div class="alert alert-warning p-3">

              <form class="form-horizontal" id="filtrarForm" autocomplete="off" [formGroup]="filtrarForm">

                <div class="row">

                  <div class="col-xl-3 col-lg-4 col-sm-12 text-nowrap">
                    <label for="rango">Rango de fechas</label>
                    <div class="form-group">
                      <ng-select placeholder="Elige un período..." [searchable]="false" [clearable]="false" labelForId="rango" (change)="actualizarRangoFechas($event)" formControlName="rango">
                        <ng-option value="dia">Día</ng-option>
                        <ng-option value="mes">Mes</ng-option>
                        <ng-option value="periodo">Entre fechas</ng-option>
                      </ng-select>

                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-4 col-sm-12">
                    <label></label>
                    <div class="input-group mb-2">

                      <input *ngIf="filtrarForm.controls.rango.value !== 'mes'" type="date" class="form-control" placeholder="Fecha inicio" (change)="actualizarFechas($event)" formControlName="fechaInicio">
                      <input *ngIf="filtrarForm.controls.rango.value === 'periodo' " type="date" class="form-control" placeholder="Fecha fin" (change)="actualizarFechas($event)" formControlName="fechaFin">

                      <ng-select *ngIf="filtrarForm.controls.rango.value === 'mes'" placeholder="Elige un mes..." [searchable]="false" [clearable]="false" labelForId="mes" (change)="actualizarMesAnio($event)" formControlName="mes">
                        <ng-option *ngFor="let mes of meses" [value]="mes.id">{{ mes.nombre }}</ng-option>
                      </ng-select>

                      <ng-select *ngIf="filtrarForm.controls.rango.value === 'mes'" placeholder="Elige un año..." [searchable]="false" [clearable]="false" labelForId="anio" (change)="actualizarMesAnio($event)" formControlName="anio">
                        <ng-option *ngFor="let anio of anios" [value]="anio">{{ anio }}</ng-option>
                      </ng-select>



                    </div>
                  </div>

                </div>

                <div class="row">

                  <div class="col-xl-2 col-lg-4 col-sm-12">
                    <div class="form-group">
                      <label for="autor">Autor</label>
                      <ng-select placeholder="Elige un autor..." [searchable]="false" [clearable]="false" labelForId="autor" (change)="actualizarAutor($event)" formControlName="autor">
                        <ng-option value="">Todos</ng-option>
                        <ng-option *ngFor="let autor of autores" [value]="autor.uid">{{ autor.nombre }}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-4 col-sm-12">
                    <div class="form-group">
                      <label for="responsable">Responsable</label>
                      <ng-select placeholder="Elige un responsable..." [searchable]="false" [clearable]="false" labelForId="responsable" (change)="actualizarResponsable($event)" formControlName="responsable">
                        <ng-option value="">Todos</ng-option>
                        <ng-option *ngFor="let responsable of responsables" [value]="responsable.uid">{{ responsable.nombre }}</ng-option>
                      </ng-select>
                    </div>
                  </div>

                </div>

              </form>

            </div>

          </div>

        </div>

        <div class="row mb-1" *ngIf="cargandoTabla">
          <div class="col-sm-12">
            <div class="alert alert-info text-center">
              <strong>Cargando partes de trabajo...</strong>
              <br>
              <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            </div>
          </div>
        </div>

        <div class="row mb-1" *ngIf="!cargandoTabla">
          <div class="col-lg-6 col-sm-12">
              <small>Mostrar </small>
              <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <small> registros</small>
            </div>
            <div class="col-lg-6 col-sm-12 text-right">
              <small>Estado </small>
              <select class="custom-select-sm border-light" [(ngModel)]="estado" (change)="actualizarEstadoPartesTrabajo($event)">
                <option value="pendientes">Pendientes</option>
                <option value="aprobados">Aprobados</option>
                <option value="rechazados">Rechazados</option>
                <option value="todos">Todos</option>
              </select>
          </div>
        </div>

        <ngx-datatable #table class='bootstrap'
          *ngIf="!cargandoTabla"
          [columnMode]="'flex'"
          [scrollbarH]="true"
          [headerHeight]="40"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [sortType]="sortType.multi"
          [messages]="{emptyMessage: 'No hay registros que mostrar'}"
          [limit]="registrosPagina"
          [rows]='partesTrabajo'
          [sorts]="sorts"
          (sort)="onSort($event)"
          [offset]="offset"
          (page)="onPage($event)"
        >

          <ngx-datatable-column name="Fecha" prop="fecha" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['fecha']?.toDate() | date:'dd/MM/yyyy' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Autor" prop="autor" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['autor'].nombre }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Tipo" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['individual'] ? 'Indi.' : 'Equi.' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Obra" prop="nombreObra" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <ng-template #tipObra>
                <div class="d-flex flex-column">
                    <span>{{ row['nombreObra'] ?? '' | uppercase }}</span>
                </div>
              </ng-template>
              <span [ngbTooltip]="tipObra" tooltipClass="tooltip-custom" container="body">{{ row['nombreObra'].split(' - ')[0] | uppercase }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Horario" [flexGrow]="3">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['individual']
                ? row['horarioOperarios'][0]?.['horario']
                : 'ver detalle'
              }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Responsable" [flexGrow]="3">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['responsables'].join(', ') }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Estado" prop="estado" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row['estado'] }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="1">
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-info" (click)="editarParteTrabajo( value )"><i class="mdi mdi-pencil"></i></button>
                <!-- <button type="button" class="btn btn-danger" (click)="borrarParteTrabajo( value )"><i class="mdi mdi-delete"></i></button> -->
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset" *ngIf="partesTrabajo.length > 0">
              <div style="padding: 5px 10px">
                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [page]="curPage"
                [size]="pageSize"
                [count]="rowCount"
                [hidden]="!((rowCount / pageSize)> 1)"
                (change)="table.onFooterPage($event)"
              >
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>

        </ngx-datatable>

      </div>
    </div>
  </div>

</div>
