import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

import { ArticulosService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-materias-primas',
  templateUrl: './materias-primas.component.html',
  styles: [
  ]
})
export class MateriasPrimasComponent implements OnInit, OnDestroy {

  articulos: any = [];
  articulosTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private articulosService: ArticulosService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.articulosService.getRegistrosPagina();
    this.sorts = this.articulosService.getSorts();
    this.offset = this.articulosService.getPage();
    this.cargarArticulos();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarArticulos() {

    this.cargando = true;

    this.subs.sink = this.articulosService.cargarArticulos()
      .subscribe( async (articulosDB: any) => {
        this.totalRegistros = 0;
        this.articulos = [];
        if ( articulosDB.length > 0 ) {
          await Promise.all( articulosDB.map( async ( articulo: any ) => {
            articulo.proveedor = ( articulo.proveedor ) ? (await getDoc( articulo.proveedor )).data() : null;
            articulo.familia = ( articulo.familia ) ? (await getDoc( articulo.familia )).data() : null;
            articulo.unidades = ( articulo.unidades ) ? (await getDoc( articulo.unidades )).data() : null;
          }));
          this.totalRegistros = articulosDB.length; // TODO: Obtener total usuarios de la BD
          this.articulos = articulosDB;
          this.articulosTodos = articulosDB;
          this.cargando = false;
          this.searchForm.controls.termino.enable();
        } else {
          this.cargando = false;
        }

      });

  }

  buscarArticulo( termino: string ) {

    if (termino.length <= 0) {
      // TODO: buscador indiferente a las fechas
      this.totalRegistros = this.articulosTodos.length;
      this.articulos = this.articulosTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      this.articulos = this.articulosTodos.filter( ( articulo: any ) =>
        articulo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        articulo?.proveedor?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        articulo?.familia?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.articulos.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.articulosTodos.length;
      this.articulos = this.articulosTodos;
    }
  }

  editarArticulo( id: string ) {

    this.router.navigate( ['/maestros/articulo/editar', id ] );

  }

  borrarArticulo( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.articulosService.borrarArticulo( id )
          .then( res => {
            //this.cargarArticulos();
            Swal.fire(
              '¡Eliminado!',
              'El articulo ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  familiaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA?.nombre?.toLowerCase() < propB?.nombre?.toLowerCase()) {
      return -1;
    } else if (propA?.nombre?.toLowerCase() > propB?.nombre?.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  proveedorComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA?.nombre?.toLowerCase() < propB?.nombre?.toLowerCase()) {
      return -1;
    } else if (propA?.nombre?.toLowerCase() > propB?.nombre?.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  objetoComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA?.nombre?.toLowerCase() < propB?.nombre?.toLowerCase()) {
      return -1;
    } else if (propA?.nombre?.toLowerCase() > propB?.nombre?.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.articulosService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.articulosService.setPage( event.offset );
  }

  onSort(event) {
    this.articulosService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
