import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import { SortType } from '@swimlane/ngx-datatable';

import Swal from 'sweetalert2';

import { MaquinariaService } from 'src/app/services/service.index';

@Component({
  selector: 'app-maquinaria',
  templateUrl: './maquinaria.component.html',
  styles: [
  ]
})
export class MaquinariaComponent implements OnInit, OnDestroy {

  maquinaria: any = [];
  maquinariaTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private maquinariaService: MaquinariaService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.maquinariaService.getRegistrosPagina();
    this.sorts = this.maquinariaService.getSorts();
    this.offset = this.maquinariaService.getPage();
    this.cargarMaquinaria();
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  cargarMaquinaria(): void {

    this.cargando = true;

    this.subs.sink = this.maquinariaService.cargarMaquinaria()
      .subscribe( (maquinariaDB: any) => {
        this.totalRegistros = 0;
        this.maquinaria = [];
        if ( maquinariaDB.length > 0 ) {
          this.totalRegistros = maquinariaDB.length; // TODO: Obtener total usuarios de la BD
          this.maquinaria = maquinariaDB.sort( (a, b) => a.codnavision.localeCompare(b.codnavision, 'es', { numeric: true } ) );
          this.maquinariaTodos = maquinariaDB.sort( (a, b) => a.codnavision.localeCompare(b.codnavision, 'es', { numeric: true } ) );
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarMaquina( termino: string ) {

    if (termino.length <= 0) {
      this.totalRegistros = this.maquinariaTodos.length;
      this.maquinaria = this.maquinariaTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      this.maquinaria = this.maquinariaTodos.filter( ( maquina: any ) =>
        maquina?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        maquina?.numserie?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        maquina?.codnavision?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        maquina?.codcontable?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        maquina?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        maquina?.notas?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.maquinaria.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.maquinariaTodos.length;
      this.maquinaria = this.maquinariaTodos;
    }
  }

  editarMaquina( id: string ) {

    this.router.navigate( ['/maquinaria/maquina', id, 'editar' ] );

  }

  borrarMaquina( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción desactivara la maquina en este y todos los listados e informes.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, desactivar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.maquinariaService.borrarMaquina( id )
          .then( res => {
            Swal.fire(
              '¡Desactivado!',
              'La maquina ha sido desactivada.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.maquinariaService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.maquinariaService.setPage( event.offset );
  }

  onSort(event) {
    this.maquinariaService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  codigoComparator(propA: any, propB: any) {
    return propA.localeCompare(propB, 'es', { numeric: true } )
  }

}
