<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar entrada" #buscar formControlName="termino" (keyup)="buscarEntrada( buscar.value )" (search)="verificarBusqueda( buscar.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <h4 class="card-title">Entradas ({{ totalRegistros }})</h4>
                    </div>
                </div>
                <form #f="ngForm" ngNativeValidate>
                    <div class="row">
                        <div class="col-sm-12 mb-1" [ngClass]="verHasta ? 'col-lg-6' : 'col-lg-3'">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button type="button" class="btn" [ngClass]="verHasta ? 'btn-danger' : 'btn-success'" (click)="verocultarHasta(true)">
                                        <i class="mdi" [ngClass]="verHasta ? 'mdi-calendar-remove' : 'mdi-calendar-plus'"></i>
                                    </button>
                                </div>
                                <input name="fechaDesde" type="date" class="form-control" [ngModel]="fechaDesde" (keyup.enter)="cambiarFecha( f )">
                                <input *ngIf="verHasta" name="fechaHasta" type="date" class="form-control" [ngModel]="fechaHasta" (keyup.enter)="cambiarFecha( f )">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-primary" (click)="cambiarFecha( f )">
                                        <i class="mdi mdi-update"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 mb-1" [ngClass]="verHasta ? 'col-lg-6' : 'col-lg-9'">
                            <div class="text-right">
                                <button type="button" class="btn btn-primary" routerLink="/planta/entrada/nueva">
                                    <i class="mdi mdi-plus"></i>
                                    Crear entrada
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row mb-1">
                    <div class="col-lg-12 col-sm-12">
                        <small>Mostrar </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros()">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>
                        <small> registros</small>
                    </div>
                </div>

                <ngx-datatable #table
                  class='bootstrap'
                  [columnMode]="'flex'"
                  [scrollbarH]="true"
                  [headerHeight]="40"
                  [footerHeight]="50"
                  [rowHeight]="'auto'"
                  [sortType]="sortType.multi"
                  [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                  [limit]="registrosPagina"
                  [rows]="entradas"
                  [sorts]="sorts"
                  (sort)="onSort($event)"
                  [offset]="offset"
                  (page)="onPage($event)"
                >
                    <ngx-datatable-column name="Fecha" prop="fecha" [flexGrow]="1">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row['fecha'].toDate() | date: 'dd/MM/yyyy' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Hora" prop="fecha" [flexGrow]="1">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row['fecha'].toDate() | date: 'HH:mm' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Albarán" prop="numalbaran" [flexGrow]="1"></ngx-datatable-column>
                    <ngx-datatable-column name="Obra" prop="obra" [flexGrow]="1">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row['obra']['codigo'] | uppercase }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Proveedor" prop="proveedor" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row['proveedor']['nombre'] | uppercase }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Articulo" prop="articulo" [flexGrow]="3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row['articulo']['nombre'] | uppercase }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Matricula" prop="matricula" [flexGrow]="1">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ (row['matricula']?.['nombre']) ? (row['matricula']['nombre'] | uppercase) : '' }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Neto" prop="netoalbaran" [flexGrow]="1">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row['netoalbaran'] | number:'1.2-2' }} {{ row['unidad']?.['codigo'] | titlecase }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                      <ng-template let-value="value" ngx-datatable-header-template>
                        <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                      </ng-template>
                      <ng-template let-value="value" ngx-datatable-cell-template>
                        <div class="btn-group" role="group">
                          <button type="button" class="btn btn-info" (click)="editarEntrada( value )"><i class="mdi mdi-pencil"></i></button>
                          <button type="button" class="btn btn-danger" (click)="borrarEntrada( value )"><i class="mdi mdi-delete"></i></button>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                      <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                        <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                          <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                        </div>
                        <datatable-pager
                          [pagerLeftArrowIcon]="'datatable-icon-left'"
                          [pagerRightArrowIcon]="'datatable-icon-right'"
                          [pagerPreviousIcon]="'datatable-icon-prev'"
                          [pagerNextIcon]="'datatable-icon-skip'"
                          [page]="curPage"
                          [size]="pageSize"
                          [count]="rowCount"
                          [hidden]="!((rowCount / pageSize)> 1)"
                          (change)="table.onFooterPage($event)"
                        >
                        </datatable-pager>
                      </ng-template>
                    </ngx-datatable-footer>

                </ngx-datatable>

            </div>
        </div>
    </div>

</div>
