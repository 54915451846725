import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable, filter, map, debounceTime, distinctUntilChanged, first } from 'rxjs';

import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { InformesPlantaService, FormulariosService, ExportService, JspdfService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';
import { SortType } from '@swimlane/ngx-datatable';

type Obra = {id: string, codigo: string, nombre: string};
type Cliente = {id: string, nombre: string};
type Formula = {id: string, codigo: string, nombre: string};
type Proveedor = {id: string, nombre: string};
type Articulo = {id: string, nombre: string};
type Matricula = {id: string, nombre: string, matricula: string};

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styles: [
  ]
})
export class InformesPlantaComponent implements OnInit, OnDestroy {

  txtPlanta: string;
  ahora = new Date();
  mesActual = this.ahora.getFullYear().toString() + '-' + ('0' + (this.ahora.getMonth() + 1)).slice(-2);
  desdeHoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  hastaHoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  plantas: any = [];
  proveedores: any = [];
  articulos: any = [];
  obras: any = [];
  clientes: any = [];
  obra: any = [];
  formulas: any = [];
  matriculas: any = [];
  salidas: any = [];
  salidasTodas: any = [];
  entradas: any = [];
  entradasTodas: any = [];

  buscando: boolean = false;
  cargando: boolean = false;
  registrosPagina: number = 10;
  totalRegistros: number = 0;
  sortType = SortType;

  private subs = new SubSink();

  public formSubmitted = false;

  public informeForm = this.fb.group({

    tipo: [ '', Validators.required ],
    planta: [ '', Validators.required ],

    rango: [ '', Validators.required ],
    mes: [ null ],
    desde: [ null ],
    hasta: [ null ],

    proveedor: [ null ],
    articulo: [ { value: null, disabled: true } ],

    obra: [ null ],
    cliente: [ null ],

    matricula: [ null ],
    formula: [ null ],

  });

  obraformat = (obra: Obra) => obra.codigo + ' - ' + obra.nombre.toLocaleUpperCase();

  obrasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.obras.filter( ( obra: any ) => obra?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                              obra?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  clieformat = (cliente: Cliente) => cliente.nombre;

  cliesearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.clientes.filter( ( cliente: any ) => cliente?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  formformat = (formula: Formula) => formula.codigo + ' - ' + formula.nombre;

  formsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.formulas.filter( ( formula: any ) => formula?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                 formula?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  provformat = (proveedor: Proveedor) => proveedor.nombre;

  provsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.proveedores.filter( ( proveedor: any ) => new RegExp(term, 'mi').test( proveedor.nombre )).slice(0, 10) )
  )

  artformat = (articulo: Articulo) => articulo.nombre;

  artsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.articulos.filter( ( articulo: any ) => new RegExp(term, 'mi').test( articulo.nombre )).slice(0, 10) )
  )

  matformat = (matricula: Matricula) => matricula.nombre;

  matsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.matriculas.filter( ( matricula: any ) => new RegExp(term, 'mi').test(matricula.nombre)).slice(0, 10) )
  )

  constructor( private InformesPlantaService: InformesPlantaService,
               private formulariosService: FormulariosService,
               private exportService: ExportService,
               private jspfd: JspdfService,
               private fb: FormBuilder ) { }

  ngOnInit(): void {
    this.subs.sink = this.formulariosService.selectObrasTipo('planta')
      .pipe(
        first()
      )
      .subscribe( (plantasDB: any) => {
        this.plantas = plantasDB;
      });

    this.subs.sink = this.formulariosService.selectObrasClientes()
      .pipe(
        first()
      )
      .subscribe( async (obrasCliente) => {
        if ( obrasCliente.length > 0 ) {
          const auxArray: any = [];
          await Promise.all(
            obrasCliente.map( async (obraCliente: any) => {
              obraCliente.cliente = (obraCliente?.cliente) ? (await getDoc( obraCliente.cliente )).data() : null;
              obraCliente.cliente = {
                id: obraCliente.cliente.id,
                nombre: obraCliente.cliente.nombre
              };
            })
          );
          this.obras = obrasCliente;
        }
      });

    this.subs.sink = this.formulariosService.selectData('clientes')
      .pipe(
        first()
      )
      .subscribe( res => {
        this.clientes = res;
      });

    this.subs.sink = this.formulariosService.selectData('proveedores')
      .pipe(
        first()
      )
      .subscribe( res => {
        this.proveedores = res;
      });

    this.subs.sink = this.formulariosService.selectData('camiones')
      .pipe(
        first()
      )
      .subscribe( res => {
        this.matriculas = res;
      });

    this.subs.sink = this.formulariosService.selectData('formulas', true)
      .pipe(
        first()
      )
      .subscribe( res => {
        this.formulas = res;
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ExportarExcel( tipo: string ): void {
    if ( tipo === 's' && this.salidas.length > 0 ) {
      this.exportService.exportSalidas( this.salidas );
    } else if ( tipo === 'e' && this.entradas.length > 0 ) {
      this.exportService.exportEntradas( this.entradas );
    }
  }

  imprimirSalida( id: any ): void {

    this.jspfd.imprimirAlbaranPlanta( id );

  }

  descargarSalida( id: any ): void {

    this.jspfd.imprimirAlbaranPlanta( id, true );

  }

  rellenarConCeros( numero: string, length: number ): string {

    let myString = '' + numero;
    while (myString.length < length) {
      myString = '0' + myString;
    }

    return myString;

  }

  cambioTipo(): void {

    this.buscando = false;
    if ( this.informeForm.get('tipo').value === 'entradas' ) {
      this.txtPlanta = 'destino';
      this.informeForm.get('planta').setValue('');
      this.informeForm.get('obra').setValue(null);
      this.informeForm.get('cliente').setValue(null);
    } else if ( this.informeForm.get('tipo').value === 'salidas' ) {
      this.txtPlanta = 'origen';
      this.informeForm.get('planta').setValue('');
      this.informeForm.get('proveedor').setValue(null);
      this.informeForm.get('articulo').setValue(null);
    } else {
      this.txtPlanta = '';
      this.informeForm.get('planta').setValue('');
      this.informeForm.get('obra').setValue(null);
      this.informeForm.get('cliente').setValue(null);
      this.informeForm.get('proveedor').setValue(null);
      this.informeForm.get('articulo').setValue(null);
    }

  }

  cambioPlanta(): void {
    this.buscando = false;
  }

  cambioRango(): void {

    this.buscando = false;
    switch (this.informeForm.value.rango) {
      case 'mes':
        this.informeForm.get('mes').setValue(this.mesActual);
        this.informeForm.get('desde').setValue(null);
        this.informeForm.get('hasta').setValue(null);
        break;
      case 'dh':
        this.informeForm.get('mes').setValue(null);
        this.informeForm.get('desde').setValue(this.desdeHoy);
        this.informeForm.get('hasta').setValue(this.hastaHoy);
        break;

      default:
        this.informeForm.get('mes').setValue(null);
        this.informeForm.get('desde').setValue(null);
        this.informeForm.get('hasta').setValue(null);
        break;
    }

  }

  cambioMes(): void {
    this.buscando = false;
  }

  cargarArticulos( id: string ): void {

    this.informeForm.get('articulo').setValue( null );
    this.informeForm.get('articulo').enable();
    this.subs.sink = this.formulariosService.selectArticulosProv( id )
      .pipe(
        first()
      )
      .subscribe( (articulosBD: any) => {
        articulosBD.forEach( (articulo: any) => {
          delete articulo.ud;
        });
        this.articulos = articulosBD;
      });
  }

  proveedorSeleccionado( event: NgbTypeaheadSelectItemEvent ): void {

    this.cargarArticulos( event.item.id );

  }

  verificarProv(): void {
    if ( !this.informeForm.get('proveedor').value ) {
      this.articulos = [];
      this.informeForm.get('articulo').setValue( null );
      this.informeForm.get('articulo').disable();
    }
  }

  obraSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    this.informeForm.get('cliente').setValue({
      id: event.item.cliente.id,
      nombre: event.item.cliente.nombre
    });

  }

  verificarObra(): void {

    if ( !this.informeForm.get('obra').value ) {

      this.informeForm.get('cliente').setValue( null );

    }

  }

  clienteSeleccionado( event: NgbTypeaheadSelectItemEvent ): void {

    this.informeForm.get('obra').setValue( null );

  }

  verificarCliente(): void {

    if ( !this.informeForm.get('cliente').value ) {

      this.informeForm.get('obra').setValue( null );

    }

  }

  crearInforme(): void {

    this.formSubmitted = true;

    if ( this.informeForm.invalid ) {
      console.log(this.informeForm);
      return;
    }

    this.buscando = true;
    this.cargando = true;
    this.subs.sink = this.InformesPlantaService.buscarRegistros(this.informeForm.value)
      .pipe(
        // first()
        debounceTime(250)
      )
      .subscribe( async (res) => {
        this.totalRegistros = res.length;
        // Salidas
        if (this.informeForm.controls.tipo.value === 'salidas') {
          await Promise.all(
            res.map( async (salida: any) => {
              salida.albaran = this.rellenarConCeros( salida.albaran, 5 );
              salida.planta = (await getDoc( salida.planta )).data();
              salida.obra = (await getDoc( salida.obra )).data();
              salida.cliente = (await getDoc( salida.cliente )).data();
              salida.matricula = (salida?.matricula) ? (await getDoc( salida.matricula )).data() : '';
              salida.unidad = (await getDoc( salida.unidad )).data();
              if (salida.tipo === 'pt') {
                salida.formula = (await getDoc( salida.formula )).data();
              } else {
                salida.articulo = (await getDoc( salida.articulo )).data();
              }
            })
          );
          this.salidas = res;
          this.salidasTodas = res;
          this.cargando = false;
        // Entradas
        } else {
          await Promise.all(
            res.map( async (entrada: any) => {
              entrada.obra = (await getDoc( entrada.obra )).data();
              entrada.matricula = (entrada?.matricula) ? (await getDoc( entrada.matricula )).data() : '';
              entrada.unidad = (await getDoc( entrada.unidad )).data();
              entrada.articulo = (await getDoc( entrada.articulo )).data();
              entrada.proveedor = (await getDoc( entrada.proveedor )).data();
            })
          );
          this.entradas = res;
          this.entradasTodas = res;
          this.cargando = false;
        }
      });

  }

  restablecer(): void {
    this.formSubmitted = false;
    this.buscando = false;
    this.cargando = false;
    this.informeForm.reset({
      tipo: '',
      planta: '',
      rango: ''
    });
  }

  campoNoValido( campo: string ): boolean {

    if ( this.informeForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.informeForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  getBtnClass({ row, column, value }): any {
    return ' botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

  albaranComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.albaran.toLowerCase() < propB.albaran.toLowerCase()) {
      return -1;
    } else if (propA.albaran.toLowerCase() > propB.albaran.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  obraComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.codigo.toLowerCase() < propB.codigo.toLowerCase()) {
      return -1;
    } else if (propA.codigo.toLowerCase() > propB.codigo.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  formulaComparator(propA: any, propB: any): number {
    if (propA.tipo === 'pt') {
      if (propA?.formula?.nombre?.toLowerCase() < propB?.formula?.nombre?.toLowerCase()) {
        return -1;
      } else if (propA?.formula?.nombre?.toLowerCase() > propB?.formula?.nombre?.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (propA?.articulo?.nombre?.toLowerCase() < propB?.articulo?.nombre?.toLowerCase()) {
        return -1;
      } else if (propA?.articulo?.nombre?.toLowerCase() > propB?.articulo?.nombre?.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  matriculaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.toLowerCase() < propB.toLowerCase()) {
      return -1;
    } else if (propA.toLowerCase() > propB.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  netoComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

}
