<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Trabajador</h4>
            </div>

            <div class="card-body">

              <!-- Nav tabs -->
              <ul class="nav nav-pills justify-content-end mt-2" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#datos" role="tab" (click)="tabClic('datos')">
                        <span>Datos básicos</span>
                    </a>
                </li>
                <li class="nav-item" *ngIf="trabajadorID">
                  <a class="nav-link" data-toggle="tab" href="#saldos" role="tab" (click)="tabClic('saldos')">
                      <span>Saldos</span>
                  </a>
              </li>
                <li class="nav-item" *ngIf="trabajadorID">
                    <a class="nav-link" data-toggle="tab" href="#documentos" role="tab" (click)="tabClic('documentos')">
                        <span>Documentos</span>
                    </a>
                </li>
              </ul>

              <div class="tab-content border mt-2">

                <div class="tab-pane active p-3" id="datos" role="tabpanel">

                  <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="trabajadorForm" (ngSubmit)="crearTrabajador()">

                    <div class="card-body">

                      <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>

                      <h3 class="card-subtitle mb-3 mt-3"> Datos básicos </h3>

                      <!-- Nombre, primer apellido, segundo apellido -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Nombre*</label>
                                  <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                                  <div class="invalid-feedback">
                                      El nombre es obligatorio.
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Primer apellido*</label>
                                  <input type="text" class="form-control" placeholder="Primer apellido" required [ngClass]="{ 'is-invalid': campoNoValido('apellido1') }" formControlName="apellido1">
                                  <div class="invalid-feedback">
                                      El primer apellido es obligatorio.
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Segundo apellido*</label>
                                  <input type="text" class="form-control" placeholder="Segundo apellido" required [ngClass]="{ 'is-invalid': campoNoValido('apellido2') }" formControlName="apellido2">
                                  <div class="invalid-feedback">
                                      El segundo apellido es obligatorio.
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!-- DNI/NIE, género, números de Navision e Izaro -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-3">
                              <div class="form-group">
                                  <label>DNI/NIE*</label>
                                  <input type="text" class="form-control" placeholder="DNI/NIE" required [ngClass]="{ 'is-invalid': campoNoValido('dni') }" formControlName="dni">
                                  <div class="invalid-feedback">
                                      El DNI/NIE es obligatorio.
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                              <div class="form-group">
                                  <label>Género</label>
                                  <ng-select placeholder="Elige un género..." [searchable]="false" [clearable]="false" labelForId="genero" formControlName="genero" required>
                                    <ng-option value="masculino">Masculino</ng-option>
                                    <ng-option value="femenino">Femenino</ng-option>
                                  </ng-select>
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                              <div class="form-group">
                                  <label>Nº de Navision</label>
                                  <input type="number" class="form-control" placeholder="Nº de Navision" formControlName="numnavision">
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-3">
                              <div class="form-group">
                                  <label>Nº de Izaro</label>
                                  <input type="number" class="form-control" placeholder="Nº de Izaro" formControlName="numizaro">
                              </div>
                          </div>
                      </div>

                      <!-- Equipo, Convenio y Bolsa de horas -->
                      <div class="row mb-3">

                        <div class="col-sm-12 col-lg-3">
                          <div class="form-group">

                            <label for="equipo">Equipo*</label>
                            <ng-select
                              [items]="equipos"
                              bindValue="id"
                              bindLabel="nombre"
                              labelForId="equipo"
                              placeholder="Elige un equipo..."
                              notFoundText="No hay equipos disponibles"
                              clearAllText="Limpiar"
                              formControlName="equipo"
                              [clearable]="false"
                              [loadingText]="'Cargando...'"
                              required
                            >
                            </ng-select>
                            <div class="invalid-ngselect" *ngIf="formSubmitted && trabajadorForm.controls['equipo'].hasError('required')">
                              El equipo es obligatorio. Elige uno.
                            </div>

                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-3">
                          <div class="form-group">

                            <label for="convenio">Convenio*</label>
                            <ng-select placeholder="Elige un convenio..." [searchable]="false" [clearable]="false" labelForId="convenio" formControlName="convenio" required>
                              <ng-option value="gipuzkoa">Gipuzkoa</ng-option>
                              <ng-option value="bizkaia">Bizkaia</ng-option>
                            </ng-select>
                            <div class="invalid-ngselect" *ngIf="formSubmitted && trabajadorForm.controls['convenio'].hasError('required')">
                                El convenio es obligatorio. Elige uno.
                            </div>

                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-2 text-nowrap">
                          <label>Bolsa de horas</label>
                          <div class="form-group">
                            <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="bolsaHoras">
                            </ng-toggle>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-2 text-nowrap" *ngIf="trabajadorForm.controls['bolsaHoras'].value">
                          <label>Solo festivos</label>
                          <div class="form-group">
                              <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="soloFestivos">
                              </ng-toggle>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-2 text-nowrap" *ngIf="trabajadorForm.controls['bolsaHoras'].value">
                          <div class="form-group">
                            <label>Mínimo</label>
                            <input type="number" class="form-control" placeholder="Mínimo de bolsa" formControlName="minimoBolsa">
                          </div>
                        </div>

                      </div>

                      <!-- Responsables -->
                      <div class="row">
                        <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                            <label>Responsables*</label>
                            <ng-select
                              [items]="responsables"
                              [multiple]="true"
                              bindValue="id"
                              bindLabel="nombreCompleto"
                              labelForId="responsables"
                              placeholder="Elige los responsables..."
                              notFoundText="No hay responsables disponibles"
                              clearAllText="Limpiar"
                              formControlName="responsables"
                              [clearable]="true"
                              [loadingText]="'Cargando...'"
                              required
                            >
                            </ng-select>
                            <div class="invalid-ngselect" *ngIf="formSubmitted && trabajadorForm.controls['responsables'].hasError('required')">
                              Los responsables son obligatorios. Elige al menos uno.
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr>

                      <h3 class="card-subtitle mb-3 mt-3"> Dirección y contacto </h3>

                      <!-- Dirección -->
                      <div class="row">
                        <div class="col-sm-12 col-lg-12">
                          <div class="form-group">
                            <label>Dirección</label>
                            <input type="text" class="form-control" placeholder="Dirección" formControlName="direccion">
                          </div>
                        </div>
                      </div>

                      <!-- Provincia, localidad y código postal -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Provincia</label>
                                  <input type="search" class="form-control" placeholder="Provincia" formControlName="provincia" [ngbTypeahead]="provsearch" [inputFormatter]="provformat" [resultFormatter]="provformat" [editable]="false" (selectItem)="selecProvincia($event)" (ngModelChange)="verificarProvincia()">
                              </div>
                          </div>

                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Localidad</label>
                                  <input type="search" class="form-control" placeholder="Localidad" formControlName="localidad" [ngbTypeahead]="locasearch" [inputFormatter]="locaformat" [resultFormatter]="locaformat" [editable]="false" (selectItem)="selecLocalidad($event)">
                              </div>
                          </div>

                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Código postal</label>
                                  <input type="search" class="form-control" placeholder="Código postal" formControlName="codpostal" [ngbTypeahead]="cpsearch" [inputFormatter]="cpformat" [resultFormatter]="cpformat" [editable]="false" (selectItem)="selecCP($event)">
                              </div>
                          </div>
                      </div>

                      <!-- Teléfono y email -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-6">
                              <div class="form-group">
                                  <label>Teléfono</label>
                                  <input type="tel" class="form-control" placeholder="Teléfono" formControlName="telefono">
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-6">
                              <div class="form-group">
                                  <label>Email</label>
                                  <input type="email" class="form-control" placeholder="Email" formControlName="email">
                              </div>
                          </div>
                      </div>

                      <!-- Estado -->
                      <div class="row mt-4">

                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Activo" offColor="secondary" offText="Inactivo" [size]="" formControlName="estado">
                                </ng-toggle>
                            </div>
                        </div>

                      </div>

                      <!-- Errores -->
                      <div class="row">
                          <div class="col text-danger">

                              <p *ngIf="campoNoValido('nombre') || campoNoValido('apellido1') || campoNoValido('apellido2') || campoNoValido('dni') || campoNoValido('equipo') || campoNoValido('convenio')">
                                  Hay errores en el formulario. Corrígelos para poder guardar la información.
                              </p>

                          </div>
                      </div>

                    </div>
                    <hr>
                    <div class="card-body">
                      <div class="form-group mb-0 text-right">
                          <button *ngIf="!trabajadorID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                          <button *ngIf="!trabajadorID" type="button" class="btn btn-secondary waves-effect waves-light mr-1" (click)="guardarYAnadirDocumentos()">Guardar y añadir documentos</button>
                          <button *ngIf="trabajadorID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarTrabajador()">Actualizar</button>
                          <button *ngIf="trabajadorID" type="button" class="btn btn-secondary waves-effect waves-light mr-1" (click)="actualizarTrabajadorYDocumentos()">Actualizar y añadir documentos</button>
                          <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarTrabajador()">Cancelar</button>
                      </div>
                    </div>

                  </form>

                </div>

                <div class="tab-pane p-3" id="saldos" role="tabpanel" *ngIf="trabajadorID">



                </div>

                <div class="tab-pane p-3" id="documentos" role="tabpanel" *ngIf="trabajadorID"></div>

              </div>

        </div>
    </div>
</div>
<!-- End Row -->
