<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">

    <div class="col-12">
        <div class="card">

            <div class="card-body">

              <div class="row mb-2">

                <button type="button" class="btn btn-danger" (click)="reiniciarEstadisticas()">
                    <i class="mdi mdi-cached"></i>
                    Reiniciar estadísticas
                </button>

              </div>

              <div class="row mb-2">

                <button type="button" class="btn btn-warning" (click)="rehacerPartesTrabajo()" disabled>
                  <i class="mdi mdi-cached"></i>
                  Rehacer partes de trabajo
                </button>

              </div>


              <div class="row mb-2">

                <button type="button" class="btn btn-warning" (click)="actualizarNombreClientesObras()" disabled>
                  <i class="mdi mdi-cached"></i>
                  Actualizar nombre de clientes en obras
                </button>

              </div>

            </div>

        </div>
    </div>

</div>
