import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SubSink } from 'subsink';
import { Observable, first, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { EquiposService, FormulariosService, PersonalService } from '../../../../services/service.index';

import Swal from 'sweetalert2';

type Provincia = {id: string, nombre: string};
type Localidad = {id: string, nombre: string; codigo_postal: string};

@Component({
  selector: 'app-trabajador',
  templateUrl: './trabajador.component.html',
  styles: [
  ]
})
export class TrabajadorComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Trabajador';
  cargando: boolean = true;
  trabajadorID: string;
  modoLectura: boolean = false;
  equipos: any = [];
  provincias: any = [];
  localidades: any = [];
  responsables: any = [];

  private subs = new SubSink();

  public formSubmitted = false;

  public trabajadorForm = this.fb.group({
    nombre: ['', Validators.required ],
    apellido1: [ '', Validators.required ],
    apellido2: [ '', Validators.required ],
    dni: [ '', Validators.required ],
    genero: [ null, Validators.required ],
    numnavision: [ '' ],
    numizaro: [ '' ],
    equipo: [ null, Validators.required ],
    convenio: [ null, Validators.required ],
    bolsaHoras: [ false ],
    soloFestivos: [ false ],
    minimoBolsa: [ 0 ],
    direccion: [ '' ],
    provincia: [ '' ],
    localidad: {value: null, disabled: true},
    codpostal: {value: null, disabled: true},
    telefono: [ '' ],
    email: [ '' ],
    responsables: [ [], Validators.required ],
    estado: [ true ]
  });

  public documentosTrabajadorForm = this.fb.group({
    categoria: [ null, Validators.required ],
    documento: [ null, Validators.required ]
  });

  provformat = (provincia: Provincia) => provincia.nombre;

  provsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.provincias.filter( provincia => new RegExp( term, 'mi' ).test( provincia.nombre )).slice(0, 10) )
  )

  locaformat = (localidad: Localidad) => localidad.nombre;

  locasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.nombre )).slice(0, 10) )
  )

  cpformat = (localidad: Localidad) => localidad.codigo_postal;

  cpsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.codigo_postal )).slice(0, 10) )
  )

  constructor( private personalService: PersonalService,
               private equiposService: EquiposService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Trabajador';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          this.trabajadorID = params.id;
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.cargarTrabajador( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.cargarTrabajador( params.id );
          } else {
            this.router.navigate(['/rrhh/trabajadores']);
          }
        } else {
          this.cargando = false;
        }

      });


    this.cargarEquipos();
    this.cargarProvicias();
    this.cargarResponsables();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarResponsables() {
    this.subs.sink = this.formulariosService.selectResponsables()
      .subscribe( responsables => {
        this.responsables = responsables;
      });
  }

  cargarTrabajador( id: string ) {

    this.trabajadorID = id;

    this.subs.sink = this.personalService.obtenerTrabajadorPorId( id )
    .pipe(
      first()
    )
    .subscribe( (trabajador: any) => {

      if ( !trabajador ) {
        Swal.fire('Error', 'Error al cargar los datos del trabajador', 'error');
        return this.router.navigateByUrl('/rrhh/trabajadores');
      }
      if ( trabajador.provincia ) {
        this.cargarLocalidades( trabajador.provincia.id );
      }
      if ( trabajador.localidad ) {
        this.trabajadorForm.get('localidad').enable();
        this.trabajadorForm.get('codpostal').enable();
      }
      const responsables = ( trabajador?.responsables )
        ? ( Array.isArray(trabajador.responsables) )
          ? trabajador.responsables.map( responsable => {
              return responsable.id;
            })
          : [ trabajador.responsables ]
        : [];
      this.trabajadorForm.reset(
        {
          nombre: trabajador.nombre,
          apellido1: trabajador.apellido1,
          apellido2: trabajador.apellido2,
          dni: trabajador.dni,
          genero: trabajador?.genero || 'masculino',
          numnavision: trabajador.numnavision,
          numizaro: trabajador.numizaro,
          equipo: trabajador?.equipo?.id,
          convenio: trabajador.convenio,
          bolsaHoras: trabajador?.bolsaHoras || false,
          soloFestivos: trabajador?.soloFestivos || false,
          minimoBolsa: trabajador?.minimoBolsa || 0,
          direccion: trabajador.direccion,
          provincia: trabajador.provincia,
          localidad: trabajador.localidad,
          codpostal: trabajador.localidad,
          telefono: trabajador.telefono,
          email: trabajador.email,
          responsables: responsables,
          estado: trabajador.estado,
        });
      this.cargando = false;
    });

  }

  crearTrabajador() {

    this.formSubmitted = true;

    if ( this.trabajadorForm.invalid ) {
      return;
    }

    this.personalService.crearTrabajador( this.trabajadorForm.value )
      .then( () => this.router.navigateByUrl('/rrhh/trabajadores') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  guardarYAnadirDocumentos() {
    console.log('guardarYAnadirDocumentos');
  }

  actualizarTrabajador() {

    this.formSubmitted = true;

    if ( this.trabajadorForm.invalid ) {
      return;
    }

    if ( this.trabajadorForm.pristine ) {
      this.cancelarTrabajador();
      return;
    }

    // Realizar el posteo
    this.personalService.actualizarTrabajador( this.trabajadorID, this.trabajadorForm.value )
      .then( () => this.router.navigateByUrl('/rrhh/trabajadores') )
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  actualizarTrabajadorYDocumentos() {

    console.log('actualizarTrabajadorYDocumentos');

  }

  // Cancelar el formulario y regresar al listado
  cancelarTrabajador() {

    if ( this.trabajadorForm.pristine ) {
      this.router.navigate(['/rrhh/trabajadores']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/rrhh/trabajadores']);

      }

    });

  }

  cargarEquipos() {
    this.subs.sink = this.equiposService.cargarEquipos()
      .subscribe( equiposB => {
        this.equipos = equiposB;
      });
  }

  cargarProvicias() {
    this.subs.sink = this.formulariosService.selectProvincias()
      .subscribe( provinciasBD  => {
        this.provincias = provinciasBD;
      });
  }

  cargarLocalidades( id: any ) {

    this.subs.sink = this.formulariosService.selectLocalidades( id )
      .subscribe( res => {
        this.localidades = res;
        this.trabajadorForm.get('localidad').enable();
        this.trabajadorForm.get('codpostal').enable();
      });

  }

  selecProvincia( event: NgbTypeaheadSelectItemEvent ) {

    this.cargarLocalidades( event.item.id );

  }

  verificarProvincia() {

    if ( !this.trabajadorForm.get('provincia').value ) {
      this.trabajadorForm.get('localidad').disable();
      this.trabajadorForm.get('codpostal').disable();
      this.trabajadorForm.get('localidad').setValue( null );
      this.trabajadorForm.get('codpostal').setValue( null );
    }

  }

  selecLocalidad( event: NgbTypeaheadSelectItemEvent ) {

    this.trabajadorForm.get('codpostal').setValue( event.item );

  }

  selecCP( event: NgbTypeaheadSelectItemEvent ) {

    this.trabajadorForm.get('localidad').setValue( event.item );

  }

  campoNoValido( campo: string ): boolean {

    if ( this.trabajadorForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  //////////////////
  // Tabs
  //////////////////

  tabClic( tab ) {

    if (tab === 'documentos') {
      this.cargarDocs();
    }

  }

  //////////////////
  // Documentos
  //////////////////

  cargarDocs() {
    console.log('cargarDocs');
  }

}
