<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar trabajador" #input formControlName="termino" (keyup)="buscarTrabajador( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

  <div class="col-12">
      <div class="card">
          <div class="card-body">

              <div class="row mb-1">
                  <div class="col-lg-8 col-sm-12">
                      <!-- <h4 class="card-title">Trabajadores ({{ totalRegistros }})</h4> -->
                  </div>

                  <div class="col-lg-4 col-sm-12">
                    <div class="text-right">
                        <button type="button" class="btn btn-primary mr-1" routerLink="/rrhh/saldos-personal/cierre-horas">
                          <i class="fas fa-calculator"></i>
                          Cierre horas
                        </button>
                    </div>
                  </div>

              </div>

              <div class="row mb-1">
                  <div class="col-lg-8 col-sm-12">
                      <small>Mostrar </small>
                      <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      <small> registros</small>
                  </div>
              </div>

              <ngx-datatable
                #table
                class='bootstrap'
                [columnMode]="'flex'"
                [scrollbarH]="true"
                [headerHeight]="40"
                [footerHeight]="50"
                [rowHeight]="'auto'"
                [sortType]="sortType.multi"
                [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                [limit]="registrosPagina"
                [rows]='trabajadores'
                [sorts]="sorts"
                (sort)="onSort($event)"
                [offset]="offset"
                (page)="onPage($event)"
              >
                <!-- Detalle de la fila -->
                <ngx-datatable-row-detail [rowHeight]="150" #myDetailRow (toggle)="onDetailToggle($event)">
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                    <div class="row mb-2 mt-2 ">
                      <div class="col-12 pl-5">
                        <div><strong>{{ row['apellido1'] | uppercase }} {{ row['apellido2'] | uppercase }}, {{ row['nombre'] | uppercase }}</strong></div>
                      </div>
                    </div>
                    <div class="row mb-2 mt-2 ">
                      <div class="col-6">
                        <div class="row mb-2 mt-2 pl-5">Saldo horas:&nbsp;<b>{{ row['saldo_horas']['saldo'] }}</b>&nbsp;h.&nbsp;<em>(revisado el {{ row['saldo_horas']['fecha_ultima_actualizacion'].toDate() | date: 'dd/MM/yyyy' }})</em></div>
                        <div class="row mb-2 mt-2 pl-5">
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <span>Años Ant.</span>
                            <span><b>{{ row['saldo_horas']?.['horas_anios_anteriores'] ?? 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <span>Extras</span>
                            <span><b>{{ row['saldo_horas']?.['horas_extra'] ?? 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <span>Lluvia</span>
                            <span><b>{{ row['saldo_horas']?.['horas_lluvia'] ?? 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <span>Traslados</span>
                            <span><b>{{ row['saldo_horas']?.['horas_traslados'] ?? 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <span>Pagadas</span>
                            <span><b>{{ row['saldo_horas']?.['horas_pagadas'] ?? 'N/D' }}</b></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="row mb-2 mt-2 pl-5">Saldo vacaciones:&nbsp;<b>{{ row['saldo_vacaciones']['saldo'] }}</b>&nbsp;días pendientes&nbsp;<em>(revisado el {{ row['saldo_vacaciones']['fecha_ultima_actualizacion'].toDate() | date: 'dd/MM/yyyy' }})</em></div>
                        <div class="row mb-2 mt-2 pl-5">
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <ng-template #tipAniosAnteriores>
                              <div class="d-flex flex-column">
                                <span>Días años anteriores</span>
                                <span>Disfrutados {{ row['saldo_vacaciones']?.['dias_anios_anteriores_disfrutados'] ?? 0 }} / Total {{ row['saldo_vacaciones']?.['dias_anios_anteriores'] ?? 0 }}</span>
                                <span>Pendientes {{ (row['saldo_vacaciones']?.['dias_anios_anteriores'] ?? 0) - (row['saldo_vacaciones']?.['dias_anios_anteriores_disfrutados'] ?? 0) }} </span>
                              </div>
                            </ng-template>
                            <span [ngbTooltip]="tipAniosAnteriores" tooltipClass="tooltip-custom" container="body">Años Ant.</span>
                            <span><b>{{ row['saldo_vacaciones']?.['dias_anios_anteriores'] ? (row['saldo_vacaciones']?.['dias_anios_anteriores_disfrutados'] ?? 0) + '/' + (row['saldo_vacaciones']?.['dias_anios_anteriores'] ?? 0) : 'N/D' }}</b></span>

                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <ng-template #tipEmpresa>
                              <div class="d-flex flex-column">
                                <span>Días empresa</span>
                                <span>Disfrutados {{ row['saldo_vacaciones']?.['dias_empresa_disfrutados'] ?? 0 }} / Total {{ row['saldo_vacaciones']?.['dias_empresa'] ?? 0 }}</span>
                                <span>Pendientes {{ (row['saldo_vacaciones']?.['dias_empresa'] ?? 0) - (row['saldo_vacaciones']?.['dias_empresa_disfrutados'] ?? 0) }} </span>
                              </div>
                            </ng-template>
                            <span [ngbTooltip]="tipEmpresa" tooltipClass="tooltip-custom" container="body">Empresa</span>
                            <span><b>{{ row['saldo_vacaciones']?.['dias_empresa'] ? (row['saldo_vacaciones']?.['dias_empresa_disfrutados'] ?? 0) + '/' + (row['saldo_vacaciones']?.['dias_empresa'] ?? 0) : 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <ng-template #tipTrabajador>
                              <div class="d-flex flex-column">
                                <span>Días trabajador</span>
                                <span>Disfrutados {{ row['saldo_vacaciones']?.['dias_trabajador_disfrutados'] ?? 0 }} / Total {{ row['saldo_vacaciones']?.['dias_trabajador'] ?? 0 }}</span>
                                <span>Pendientes {{ (row['saldo_vacaciones']?.['dias_trabajador'] ?? 0) - (row['saldo_vacaciones']?.['dias_trabajador_disfrutados'] ?? 0) }} </span>
                              </div>
                            </ng-template>
                            <span [ngbTooltip]="tipTrabajador" tooltipClass="tooltip-custom" container="body">Trabajador</span>
                            <span><b>{{ row['saldo_vacaciones']?.['dias_trabajador'] ? (row['saldo_vacaciones']?.['dias_trabajador_disfrutados'] ?? 0) + '/' + (row['saldo_vacaciones']?.['dias_trabajador'] ?? 0) : 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <ng-template #tipFiestaLocal>
                              <div class="d-flex flex-column">
                                <span>Días fiesta local</span>
                                <span>Disfrutados {{ row['saldo_vacaciones']?.['dias_fiesta_local_disfrutados'] ?? 0 }} / Total {{ row['saldo_vacaciones']?.['dias_fiesta_local'] ?? 0 }}</span>
                                <span>Pendientes {{ (row['saldo_vacaciones']?.['dias_fiesta_local'] ?? 0) - (row['saldo_vacaciones']?.['dias_fiesta_local_disfrutados'] ?? 0) }} </span>
                              </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiestaLocal" tooltipClass="tooltip-custom" container="body">F.L.</span>
                            <span><b>{{ row['saldo_vacaciones']?.['dias_fiesta_local'] ? (row['saldo_vacaciones']?.['dias_fiesta_local_disfrutados'] ?? 0) + '/' + (row['saldo_vacaciones']?.['dias_fiesta_local'] ?? 0) : 'N/D' }}</b></span>
                          </div>
                          <div class="col-2 text-nowrap d-flex flex-column">
                            <ng-template #tipAsuntosPropios>
                              <div class="d-flex flex-column">
                                <span>Días asuntos propios</span>
                                <span>Disfrutados {{ row['saldo_vacaciones']?.['dias_asuntos_propios_disfrutados'] ?? 0 }} / Total {{ row['saldo_vacaciones']?.['dias_asuntos_propios'] ?? 0 }}</span>
                                <span>Pendientes {{ (row['saldo_vacaciones']?.['dias_asuntos_propios'] ?? 0) - (row['saldo_vacaciones']?.['dias_asuntos_propios_disfrutados'] ?? 0) }} </span>
                              </div>
                            </ng-template>
                            <span [ngbTooltip]="tipAsuntosPropios" tooltipClass="tooltip-custom" container="body">A.P.</span>
                            <span><b>{{ row['saldo_vacaciones']?.['dias_asuntos_propios'] ? (row['saldo_vacaciones']?.['dias_asuntos_propios_disfrutados'] ?? 0) + '/' + (row['saldo_vacaciones']?.['dias_asuntos_propios'] ?? 0) : 'N/D' }}</b></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngx-datatable-row-detail>

                <!-- Columnas -->
                <ngx-datatable-column
                  [width]="50"
                  [resizeable]="false"
                  [sortable]="false"
                  [draggable]="false"
                  [canAutoResize]="false"
                >
                  <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                    <a
                      href="javascript:void(0)"
                      [class.datatable-icon-right]="!expanded"
                      [class.datatable-icon-down]="expanded"
                      title="Expand/Collapse Row"
                      (click)="toggleExpandRow(row)"
                    >
                    </a>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Nº" prop="numnavision" [flexGrow]="1">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row['numnavision'] }}
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Trabajador" prop="apellido1" [flexGrow]="2">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{ row['apellido1'] | uppercase }} {{ row['apellido2'] | uppercase }}, {{ row['nombre'] | uppercase }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Saldo horas" prop="saldo_horas.saldo" [flexGrow]="2">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <ng-template #tipSaldoHoras>
                      <div class="d-flex flex-column">
                        <span>Última actualización:</span>
                        <span>{{ row['saldo_horas']['fecha_ultima_actualizacion'].toDate() | date: 'dd/MM/yyyy' }}</span>
                      </div>
                    </ng-template>
                    <!-- si valor es negativo poner texto en rojo y negrita -->
                    <span
                      [ngbTooltip]="tipSaldoHoras"
                      [disableTooltip]="row['saldo_horas']['saldo'] === 'N/D' ? true : false"
                      tooltipClass="tooltip-custom"
                      container="body"
                      [ngClass]="{'saldo-negativo': row['saldo_horas']['saldo'] < 0, 'saldo-positivo': row['saldo_horas']['saldo'] > row['minimoBolsa'] }"
                    >
                      {{ row['saldo_horas']['saldo'] }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Saldo vacaciones" prop="saldo_vacaciones.saldo" [flexGrow]="2">
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <ng-template #tipSaldoVacaciones>
                      <div class="d-flex flex-column">
                        <span>Última actualización:</span>
                        <span>{{ row['saldo_vacaciones']['fecha_ultima_actualizacion'].toDate() | date: 'dd/MM/yyyy' }}</span>
                      </div>
                    </ng-template>
                    <span
                      [ngbTooltip]="tipSaldoVacaciones"
                      [disableTooltip]="row['saldo_vacaciones']['saldo'] === 'N/D' ? true : false"
                      tooltipClass="tooltip-custom"
                      container="body"
                    >
                      {{ row['saldo_vacaciones']['saldo'] }}
                    </span>
                  </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="1">
                  <ng-template let-value="value" ngx-datatable-cell-template>
                    <div class="btn-group" role="group">
                      <button type="button" class="btn btn-info" (click)="editarSaldosTrabajador( value )"><i class="mdi mdi-pencil"></i></button>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset" *ngIf="trabajadores.length > 0">
                        <div style="padding: 5px 10px">
                            <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                        </div>
                        <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                            [hidden]="!((rowCount / pageSize)> 1)" (change)="table.onFooterPage($event)">
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>

              </ngx-datatable>

          </div>
      </div>
  </div>

</div>
