import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CollectionReference, DocumentData, Firestore, Query, collection, collectionData, doc, docData, limit, orderBy, query, where } from '@angular/fire/firestore';
import { Observable, merge, map } from 'rxjs';

import { UsuarioService } from '../../services/usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class FormulariosService {

  constructor( private firestore: Firestore,
               private http: HttpClient ) { }

  // Carga datos para los formularios Select
  // coleccion: la coleccion de la que queremos obtener los datos
  // cod: si queremos recibir el campo codigo o no. false por defecto
  // activos: si solo queremos recibir los registros que esten activos. true por defecto
  selectData( coleccion: string, cod: boolean = false, activos: boolean = true ) {

    let registros: Observable<any>;
    const colRef = collection( this.firestore, coleccion );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( colRef, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( colRef, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const nombre = a.nombre;
            if ( cod ) {
              const codigo = a.codigo;
              return { id, codigo, nombre };
            } else {
              return { id, nombre };
            }
          });
        })
      );

  }

  selectProvincias() {

    const url = '../../../assets/json/provincias.json';

    return this.http.get( url )
      .pipe(
        map( (res: any) => {
          const provincias =  res.map(
              (provincia: any) => {
                  return { id: provincia.provincia_id, nombre: provincia.nombre };
                }
          );
          return provincias;
        })
      );

  }

  selectLocalidades( provincia: string = '' ) {

    const url = '../../../assets/json/municipios_cp.json';

    function filtrarPorProvincia( obj ): boolean {
      if ( provincia === '' ) {
        return true;
      } else {
        if ( obj.provincia_id === provincia ) {
          return true;
        } else {
          return false;
        }
      }
    }

    return this.http.get( url )
      .pipe(
        map( (res: any) => {
          const localidades = res
            .filter( filtrarPorProvincia )
            .map(
              (localidad: any) => {
                  return { id: localidad.municipio_id, provincia: localidad.provincia_id, codigo_postal: localidad.codigo_postal, nombre: localidad.nombre };
                }
            );
          return localidades;
        })
      );

  }

  selectResponsables( activos: boolean = true ) {

    const responsablesCol = collection( this.firestore, 'usuarios' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( responsablesCol, where('firmar', '==', true), where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( responsablesCol, where('firmar', '==', true), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.uid;
            const uid = a.uid;
            const nombreCompleto = a.nombre + ' (' + a.email + ')';
            const nombre = a.nombre;
            const email = a.email;
            const role = a.role;
            return { id, uid, nombreCompleto, nombre, email, role };
          });
        })
      );

  }

  selectOperarios( activos: boolean = true ) {

    const personalCol = collection( this.firestore, 'personal' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( personalCol, where('estado', '==', true), orderBy('apellido1', 'asc'), orderBy('apellido2', 'asc'), orderBy('nombre', 'asc') );
    } else {
      consulta = query( personalCol, orderBy('apellido1', 'asc'), orderBy('apellido2', 'asc'), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const nombreCompleto = a.apellido1 + ' ' + a.apellido2 + ', ' + a.nombre;
            return {
              id,
              nombreCompleto,
              nombre: a.nombre,
              apellido1: a.apellido1,
              apellido2: a.apellido2,
              bolsaHoras: a.bolsaHoras,
              convenio: a.convenio,
              responsables: a.responsables,
            };
          });
        })
      );

  }

  selectFamiliasPB( activos: boolean = true ) {

    const familiasCol = collection( this.firestore, 'familias_pb' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( familiasCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( familiasCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (prov: any) => {
          return prov.map( (a: any) => {
            const id = a.id;
            const nombre = a.nombre;
            return { id, nombre };
          });
        })
      );

  }

  selectMatriculas( activos: boolean = true ) {

    const camionesCol = collection( this.firestore, 'camiones' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( camionesCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( camionesCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {

            const id = a.id;
            const matricula = a.nombre;
            const remolque = a.remolque;
            const tara = a.tara;
            const transportista = a.transportista;
            const agencia = a.agencia;
            const chofer = a.chofer;
            const dnichofer = a.dni;
            const telefonochofer = a.telefono;

            return { id, matricula, remolque, tara, transportista, agencia, chofer, dnichofer, telefonochofer };

          });
        })
      );

  }

  selectMaquinas( activos: boolean = true ) {

    const maquinasCol = collection( this.firestore, 'maquinaria' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( maquinasCol, where('estado', '==', true), orderBy('codnavision', 'asc') );
    } else {
      consulta = query( maquinasCol, orderBy('codnavision', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {

            const id = a.id;
            const codigo = a.codnavision;
            const nombre = a.nombre;
            const codcontable = a.codcontable;
            const matricula = a.matricula;
            const numserie = a.numserie;
            const estado = a.estado;

            return { id, codigo, nombre, codcontable, matricula, numserie, estado };

          });
        })
      );
  }

  selectArticulos( tipo: string, activos: boolean = true ) {

    let coleccion: CollectionReference<DocumentData>;
    if ( tipo === 'pb' ) {
      coleccion = collection( this.firestore, 'materiasprimas' );
    } else if ( tipo === 're' ) {
      coleccion = collection( this.firestore, 'residuos' );
    }
    if ( coleccion ) {

      let consulta: Query<DocumentData>;
      if ( activos ) {
        consulta = query( coleccion, where('estado', '==', true), orderBy('nombre', 'asc') );
      } else {
        consulta = query( coleccion, orderBy('nombre', 'asc') );
      }

      return collectionData( consulta, { idField: 'id' } )
        .pipe(
            map( (reg: any) => {
              return reg.map( (a: any) => {
                const id = a.id;
                const nombre = a.nombre;
                const ud = a.unidades;
                if ( tipo === 'pb' ) {
                  const nombreProveedor = a.nombreProveedor;
                  return { id, nombre, nombreProveedor, ud };
                } else {
                  return { id, nombre, ud };
                }
              });
            })
        );

    } else {
      return;
    }

  }

  selectArticulosProv( proveedor: string, activos: boolean = true ) {

    const materialPrimasCol = collection( this.firestore, 'materiasprimas' );
    const proveedorDoc = doc( this.firestore, 'proveedores', proveedor );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( materialPrimasCol, where('proveedor', '==', proveedorDoc), where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( materialPrimasCol, where('proveedor', '==', proveedorDoc), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const nombre = a.nombre;
            const ud = a.unidades;
            return { id, nombre, ud };
          });
        })
      );
  }

  selectArticulosFami( familia: string, activos: boolean = true ) {

    const materialPrimasCol = collection( this.firestore, 'materiasprimas' );
    const familiaDoc = doc( this.firestore, 'familias_pb', familia );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( materialPrimasCol, where('familia', '==', familiaDoc), where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( materialPrimasCol, where('familia', '==', familiaDoc), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const nombre = a.nombre;
            const ud = a.unidades;
            return { id, nombre, ud };
          });
        })
      );
  }

  selectFormulasPlanta( planta: string, activos: boolean = true ) {

    let articulosTodos: Observable<any>;
    const refPanta = doc( this.firestore, 'obras', planta );
    const formulasCol = collection( this.firestore, 'formulas' );
    let consultaArticulos: Query<DocumentData>;
    let consultaArticulosNull: Query<DocumentData>;
    if (activos) {

      consultaArticulos = query( formulasCol, where('planta', '==', refPanta), where('estado', '==', true), orderBy('codigo', 'asc'));
      consultaArticulosNull = query( formulasCol, where('planta', '==', null), where('estado', '==', true), orderBy('codigo', 'asc'));
      articulosTodos = merge( collectionData( consultaArticulos, { idField: 'id' } ), collectionData( consultaArticulosNull, { idField: 'id' } ) );

    } else {

      // TODO: traer los null tambien?
      consultaArticulos = query( formulasCol, where('planta', '==', refPanta), orderBy('codigo', 'asc'));
      articulosTodos = collectionData( consultaArticulos, { idField: 'id' } );

    }

    return articulosTodos
      .pipe(
          map( (reg: any) => {
            return reg.map( (a: any) => {
              const id = a.id;
              const codigo = a.codigo;
              const nombre = a.nombre;
              const ud = a.unidades;
              return { id, codigo, nombre, ud };
            });
          })
      );
  }

  selectObras( situacion: string = '', activos: boolean = true ) {

    const obrasCol = collection( this.firestore, 'obras' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( obrasCol, where('estado', '==', true) );
      if ( situacion !== '' ) {
        consulta = query( consulta, where('situacion', '==', situacion) );
      }
    }

    consulta = query( consulta, orderBy('codigo', 'asc') );

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const codigo = a.codigo;
            const nombre = a.nombre?.toUpperCase();
            const nombreCompleto = a.codigo ? a.codigo?.toUpperCase() + ' - ' + a.nombre?.toUpperCase() : a.nombre?.toUpperCase();
            const situacion = a.situacion;
            const tipo = a.tipo;
            return { id, codigo, nombre, nombreCompleto, situacion, tipo };
          });
        })
      );
  }

  selectObrasTipo( tipo: string, activos: boolean = true ) {

    const obrasCol = collection( this.firestore, 'obras' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( obrasCol, where('tipo', '==', tipo), where('estado', '==', true), orderBy('codigo', 'asc'));
    } else {
      consulta = query( obrasCol, where('tipo', '==', tipo), orderBy('codigo', 'asc'));
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const codigo = a.codigo;
            const nombre = a.nombre;
            if ( tipo === 'planta' ) {
              const codform = a.codigoformulas;
              return { id, codigo, nombre, codform };
            } else  {
              return { id, codigo, nombre };
            }
          });
        })
      );
  }

  selectObrasClientes( activos: boolean = true ) {

    const obrasCol = collection( this.firestore, 'obras' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( obrasCol, where('estado', '==', true), orderBy('codigo', 'asc'));
    } else {
      consulta = query( obrasCol, orderBy('codigo', 'asc'));
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const id = a.id;
            const codigo = a.codigo;
            const nombre = a.nombre;
            const cliente = a.cliente;
            return { id, codigo, nombre, cliente };

          });
        })
      );
  }

  selectSellosCE( activos: boolean = true ) {

    const sellosCol = collection( this.firestore, 'sellos_ce' );
    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( sellosCol, where('estado', '==', true), orderBy('nombre', 'asc'));
    } else {
      consulta = query( sellosCol, orderBy('nombre', 'asc'));
    }

    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (prov: any) => {
          return prov.map( (a: any) => {
            const id = a.id;
            const nombre = a.nombre;
            return { id, nombre };
          });
        })
      );

  }

  ultimoAlbaranEntrada ( obra: string ) {

    const obraRef = doc( this.firestore, 'obras', obra );
    const entradasCol = collection( this.firestore, 'entradas' );
    const consulta = query( entradasCol,
      where('obra', '==', obraRef),
      orderBy('numinterno', 'desc'),
      limit(1)
    );
    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const numinterno = a.numinterno;
            return { numinterno };
          });
        })
      );

  }

  ultimoAlbaranSalida ( obra: string, prefijo: string ) {

    const obraRef = doc( this.firestore, 'obras', obra );
    const salidasCol = collection( this.firestore, 'salidas' );
    const consulta = query( salidasCol,
      where('planta', '==', obraRef),
      where('prefalbaran', '==', prefijo),
      orderBy('albaran', 'desc'),
      limit(1)
    );
    return collectionData( consulta, { idField: 'id' } )
      .pipe(
        map( (reg: any) => {
          return reg.map( (a: any) => {
            const albaran = a.albaran;
            return { albaran };
          });
        })
      );

  }

  getUserName( uid: string ) {

    const userRef = doc( this.firestore, 'usuarios', uid )
    return docData( userRef, { idField: 'id' } );

  }

}


