import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl, FormArray, AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, first, catchError } from 'rxjs/operators';

import { FormulasService } from '../../../../services/service.index';
import { FormulariosService } from '../../../../services/service.index';


import Swal from 'sweetalert2';
import { getDoc } from '@angular/fire/firestore';

type Planta = { id: string, codigo: string, nombre: string };
type Articulo = { id: string, nombre: string, nombreProveedor: string };

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.css']
})
export class FormulaComponent implements OnInit, OnDestroy {

  titulo: string = 'Nueva formula';
  titCargando: string = 'Cargando';
  cargando: boolean = true;
  guardando: boolean = false;
  formulaID: string;
  plantas: any = [];
  articulos: any = [];
  unidades: any[] = [];
  sellosce: any[] = [];
  tipoMaterial:  any[] = [];
  totDosiFrio: number = 0;
  totDosiCali: number = 0;

  private subs = new SubSink();

  public formSubmitted = false;

  public formulaForm = this.fb.group({
    codigo: [
      '',
      {
        validators: [
          Validators.required,
        ],
        asyncValidators: [ this.formulaValidator() ],
        updateOn: 'blur'
      }
    ],
    nombre: ['', Validators.required ],
    unidades: [ '', Validators.required ],
    planta: new FormControl( { value: null, disabled: true } ),
    numnavision: [''],
    marcadoce: [false],
    selloce: [ { value: '', disabled: true }, Validators.required ],
    dosificacionfrio: this.fb.array([]),
    dosificacioncaliente: this.fb.array([]),
    comentarios: [null],
  });

  artiformat = (articulo: Articulo) => articulo.nombreProveedor ? articulo.nombre + ' (' + articulo.nombreProveedor.toLocaleUpperCase() + ')' : articulo.nombre ;

  artisearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.articulos.filter( articulo => articulo?.nombre?.toLowerCase().includes( term.toLowerCase() ) ||
                                                   articulo?.nombreProveedor?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  plantaformat = (planta: Planta) => planta.codigo + ' - ' + planta.nombre;

  plantasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.plantas.filter( ( planta: any ) => planta?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                planta?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  constructor( private formulasService: FormulasService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.formulaID = params.id;
          this.cargarFormula( params.id );
        } else {
          this.agregarMaterialFrio();
          this.agregarMaterialCali();
          this.cargando = false;
        }

      });

    this.subs.sink = this.formulariosService.selectData('unidades', true)
      .subscribe( res => {
        this.unidades = res;
      });

    this.subs.sink = this.formulariosService.selectObrasTipo('planta', true)
      .subscribe( res => {
        this.plantas = res;
      });

    this.subs.sink = this.formulariosService.selectArticulos('pb')
      .subscribe( res => {
        this.articulos = res;
      });

    this.subs.sink = this.formulariosService.selectSellosCE()
      .subscribe( res => {
        this.sellosce = res;
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get dosificacionfrio(): FormArray {
    return this.formulaForm.get('dosificacionfrio') as FormArray;
  }

  agregarMaterialFrio( click: boolean = false ): void {
    if (click) {
      this.formulaForm.markAsDirty();
    }
    this.dosificacionfrio.push(
      this.fb.group({
        material: [null],
        cantidad: [0]
      })
    );
  }

  eliminarMaterialFrio( i: number ): void {
    this.formulaForm.markAsDirty();
    this.dosificacionfrio.removeAt(i);
    this.recalcularTotFrio();
  }

  get dosificacioncaliente(): FormArray {
    return this.formulaForm.get('dosificacioncaliente') as FormArray;
  }

  agregarMaterialCali( click: boolean = false ): void {
    if (click) {
      this.formulaForm.markAsDirty();
    }
    this.dosificacioncaliente.push(
      this.fb.group({
        tipo: [''],
        material: new FormControl( {value: null, disabled: true} ),
        cantidad: new FormControl( {value: 0, disabled: true} )
      })
    );
  }

  verificarTipo(i: number ): void {
    const form: any = this.formulaForm.get('dosificacioncaliente');
    const tipo = this.formulaForm.get('dosificacioncaliente').value;
    this.tipoMaterial[i] = tipo[i].tipo;
    if ( tipo[i].tipo !== '' ) {
      form.controls[i].controls.material.enable();
      form.controls[i].controls.cantidad.enable();
    } else {
      form.controls[i].controls.material.disable();
      form.controls[i].controls.cantidad.disable();
    }
  }

  eliminarMaterialCali( i: number ): void {
    this.formulaForm.markAsDirty();
    this.dosificacioncaliente.removeAt(i);
    this.recalcularTotCali();
  }

  verificarSello() {
    const estado = this.formulaForm.controls.marcadoce.value;
    if (estado) {
      this.formulaForm.controls.selloce.enable();
    } else {
      this.formulaForm.controls.selloce.disable();
    }
  }

  cargarFormula( id: string ): void {

    this.formulaID = id;

    this.subs.sink = this.formulasService.obtenerFormulaPorId( id )
      .pipe(
        first()
      )
      .subscribe( async (formula: any) => {

        if ( !formula ) {
          Swal.fire('Error', 'Error al cargar los datos de la formula', 'error');
          return this.router.navigateByUrl('/laboratorio/formulas');
        }
        this.formulaForm.controls.codigo.clearAsyncValidators();
        this.formulaForm.controls.codigo.updateValueAndValidity();
        this.formulaForm.reset({
            codigo: formula.codigo,
            nombre: formula.nombre,
            unidades: formula.unidades.id,
            numnavision: formula.numnavision,
            marcadoce: formula.marcadoce,
            selloce: formula?.selloce?.id ?? '',
            comentarios: formula.comentarios,
          });
        if ( formula.marcadoce ) {
          this.formulaForm.controls.selloce.enable();
        }
        if ( formula.dosificacionfrio ) {
          if ( formula.dosificacionfrio.length <= 0 ) {
            this.agregarMaterialFrio();
          }
          this.dosificacionfrio.clear();
          await Promise.all(
            formula.dosificacionfrio.map( async (doc: any) => {
              this.totDosiFrio += doc.cantidad;
              if (doc.material) {
                doc.material = ( doc.material ) ? (await getDoc( doc.material )).data() : null;
                this.dosificacionfrio.push(
                  this.fb.group({
                    material: [doc.material],
                    cantidad: [doc.cantidad]
                  })
                );
              } else {
                this.agregarMaterialFrio();
              }
            })
          );
        } else {
          this.agregarMaterialFrio();
        }
        if ( formula.dosificacioncaliente ) {
          if ( formula.dosificacioncaliente.length <= 0 ) {
            this.agregarMaterialCali();
          }
          let i = 0;
          this.dosificacioncaliente.clear();
          await Promise.all(
            formula.dosificacioncaliente.map( async (doc: any) => {
              this.totDosiCali += doc.cantidad;
              if ( doc.tipo !== 'criba' ) {
                if (doc.material) {
                  doc.material = ( doc.material ) ? (await getDoc( doc.material )).data() : null;
                  this.dosificacioncaliente.push(
                    this.fb.group({
                      tipo: [doc.tipo],
                      material: [doc.material],
                      cantidad: [doc.cantidad]
                    })
                  );
                  this.verificarTipo(i);
                  i++;
                } else {
                  this.agregarMaterialCali();
                }
              } else {
                this.dosificacioncaliente.push(
                  this.fb.group({
                    tipo: [doc.tipo],
                    material: [doc.material],
                    cantidad: [doc.cantidad]
                  })
                );
                this.verificarTipo(i);
                i++;
              }
            })
          );

        } else {
          this.agregarMaterialCali();
        }
        if ( formula.planta ) {
          if ( !this.guardando ) {
            formula.planta = ( formula.planta ) ? (await getDoc( formula.planta )).data() : null;
            this.formulaForm.get('planta').setValue({
              id: formula.planta.id,
              codigo: formula.planta.codigo,
              nombre: formula.planta.nombre,
              codform: formula.planta.codigoformulas
            });
            this.cargando = false;
          }
        } else {
          if ( !this.guardando ) {
            this.cargando = false;
            this.formulaForm.get('planta').enable();
          }
        }

      });

  }

  crearFormula(): void {

    this.formSubmitted = true;

    if ( this.formulaForm.invalid ) {
      return;
    }

    this.titCargando = 'Guardando';
    this.cargando = true;
    this.guardando = true;

    this.formulasService.crearFormula( this.formulaForm.getRawValue() )
      .then( () => this.router.navigateByUrl('/laboratorio/formulas') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', `Error: ${err}`, 'error');
      });

  }

  actualizarFormula(): void {

    this.formSubmitted = true;

    if ( this.formulaForm.invalid ) {
      console.log(this.formulaForm);
      return;
    }

    if ( !this.formulaForm.pristine ) {

      this.titCargando = 'Guardando';
      this.cargando = true;
      this.guardando = true;

      this.formulasService.actualizarFormula( this.formulaID, this.formulaForm.getRawValue() )
        .then( () => this.router.navigateByUrl('/laboratorio/formulas') )
        .catch( err => {
          console.log( err );
          Swal.fire('Error', `Error: ${err}`, 'error');
        });

    } else {
      this.cancelarFormFormula();
    }

  }

  cancelarFormFormula(): void {

    if ( this.formulaForm.pristine ) {
      this.router.navigate(['/laboratorio/formulas']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/laboratorio/formulas']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.formulaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( (this.formulaForm.get(campo).value === null || this.formulaForm.get(campo).value === '') && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  formulaValidator(): AsyncValidatorFn {
    return ( control: AbstractControl ): Observable<ValidationErrors | null> => {
      return this.formulasService.buscarFormula(control.value).pipe(
        first(),
        map( (res: any) => {
          if ( res.length ) {
            if (!res[0].estado) {
              Swal.fire({
                title: '¿Volver a activar?',
                text: 'La formula ya existe pero esta dada de baja.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si',
                cancelButtonText: 'No'
              })
              .then( (result) => {

                if (result.value) {

                  this.cargando = true;
                  this.formulasService.activarFormula( res[0].id )
                    .then( () => this.router.navigate(['/laboratorio/formula/editar/', res[0].id]));

                }

              });
              return { formulaBaja: true };
            } else {
              return { formulaExists: true };
            }
          } else {
            return null;
          }
        }),
        catchError( () => of(null))
      );
    };
  }

  cambioCodigo(): void {

    if ( this.formulaForm.get('codigo') ) {
      const codigoFormula = this.formulaForm.get('codigo').value;
      const codigoPlanta = codigoFormula.charAt(0);
      switch ( codigoPlanta ) {
        case '1': // Planta de Galdakao
          this.plantas.map( res => {
            if (res.codform === '1') {
              this.formulaForm.get('planta').setValue(res);
            }
          });
          break;
        case '2': // Planta de Alsasua
          this.plantas.map( res => {
            if (res.codform === '2') {
              this.formulaForm.get('planta').setValue(res);
            }
          });
          break;
        default:
          this.formulaForm.get('planta').setValue(null);
          if ( this.formulaForm.get('codigo').value.length > 0 ) {
            this.formulaForm.get('planta').enable();
          } else {
            this.formulaForm.get('planta').disable();
          }
          break;
      }
    }

  }

  recalcularTotFrio(): void {

    const array = this.formulaForm.get('dosificacionfrio').value;
    this.totDosiFrio = 0;

    array.forEach( mat => {
      this.totDosiFrio += mat.cantidad;
    });

  }

  recalcularTotCali(): void {

    const array = this.formulaForm.get('dosificacioncaliente').value;
    this.totDosiCali = 0;

    array.forEach( mat => {
      this.totDosiCali += mat.cantidad;
    });

  }

}
