import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { addDoc, collection, collectionData, doc, docData, DocumentData, Firestore, orderBy, query, Query, updateDoc, where } from '@angular/fire/firestore';

import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class CategoriasDocumentosService {

  categoriasDocumentosCol = collection( this.firestore, 'documentacion_categorias_documentos' );
  tiposDocumentosCol = collection( this.firestore, 'documentacion_tipos_documentos' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
    private auth: Auth,
    private usuarioService: UsuarioService,
   ) { }

  cargarTiposDocumentos( activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.tiposDocumentosCol, where('estado', '==', true), orderBy('nombre', 'asc') );
    } else {
      consulta = query( this.tiposDocumentosCol, orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarCategoriasDocumentos( activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.categoriasDocumentosCol, where('estado', '==', true), orderBy('tipo', 'asc') );
    } else {
      consulta = query( this.categoriasDocumentosCol, orderBy('tipo', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarCategoriasDocumentosPorTipo( tipo: string, activos: boolean = true ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.categoriasDocumentosCol, where('tipo', '==', tipo), where('estado', '==', true), orderBy('tipo', 'asc') );
    } else {
      consulta = query( this.categoriasDocumentosCol, where('tipo', '==', tipo), orderBy('tipo', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarCategoriaDocumentoPorId( categoriaDocumentoID: string ) {

    const categoriaDocumentoDoc = doc( this.firestore, 'documentacion_categorias_documentos', categoriaDocumentoID );
    return docData( categoriaDocumentoDoc, { idField: 'id' } );

  }

  crearCategoriaDocumento( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.tipo = doc( this.tiposDocumentosCol, formData.tipo );
    formData.estado = true;

    return addDoc( this.categoriasDocumentosCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
      });

  }

  actualizarCategoriaDocumento( categoriaDocumentoID: string, formData: any ) {

    formData.tipo = doc( this.tiposDocumentosCol, formData.tipo );

    const categoriaDocumentoDoc = doc( this.firestore, 'documentacion_categorias_documentos', categoriaDocumentoID );
    return updateDoc( categoriaDocumentoDoc, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarCategoriaDocumento( categoriaDocumentoID: string ) {

    return updateDoc( doc( this.firestore, 'documentacion_categorias_documentos', categoriaDocumentoID ), {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
