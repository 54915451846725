import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { FormulariosService, PersonalSaldosService } from 'src/app/services/service.index';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-saldo-trabajador',
  templateUrl: './saldo-trabajador.component.html',
  styleUrls: ['./saldo-trabajador.component.css']
})
export class SaldoTrabajadorComponent implements OnInit {

  cargando: boolean = true;
  trabajadorID: string;
  modoLectura: boolean = false;

  private subs = new SubSink();

  public formSubmitted = false;

  public saldosTrabajadorForm = this.fb.group({
    nombre: [ { value: '', disabled: true } ],
    saldo_horas: this.fb.group({
      anio: [ '', Validators.required ],
      fecha_ultima_actualizacion: [ null, Validators.required ],
      horas_anios_anteriores: [ 0, Validators.required ],
      horas_extra: [ 0, Validators.required ],
      horas_lluvia: [ 0, Validators.required ],
      horas_pagadas: [ 0, Validators.required ],
      horas_traslados: [ 0, Validators.required ],
      saldo: [ 0, Validators.required ],
    }),
    saldo_vacaciones: this.fb.group({
      anio: [ '', Validators.required ],
      fecha_ultima_actualizacion: [ null, Validators.required ],
      dias_anios_anteriores: [ 0, Validators.required ],
      dias_anios_anteriores_disfrutados: [ 0, Validators.required ],
      dias_asuntos_propios: [ 0, Validators.required ],
      dias_asuntos_propios_disfrutados: [ 0, Validators.required ],
      dias_empresa: [ 0, Validators.required ],
      dias_empresa_disfrutados: [ 0, Validators.required ],
      dias_fiesta_local: [ 0, Validators.required ],
      dias_fiesta_local_disfrutados: [ 0, Validators.required ],
      dias_trabajador: [ 0, Validators.required ],
      dias_trabajador_disfrutados: [ 0, Validators.required ],
      saldo: [ 0, Validators.required ],
    }),
  });

  constructor( private personalSaldosService: PersonalSaldosService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

  this.activatedRoute.params
    .subscribe( params => {

      if ( params.id ) {
        this.trabajadorID = params.id;
        if (params.accion === 'editar') {
          this.modoLectura = false;
          this.cargarTrabajador( params.id );
        } else if (params.accion === 'ver') {
          this.modoLectura = true;
          this.cargarTrabajador( params.id );
        } else {
          this.router.navigate(['/rrhh/saldos-personal']);
        }
      } else {
        this.cargando = false;
      }

    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarTrabajador( id: string ) {

    this.subs.sink = this.personalSaldosService.cargarOperarioConSaldos( id )
      .pipe(
        first()
      )
      .subscribe( operarioBD => {

        const fechaHorasBD = operarioBD?.saldo_horas?.fecha_ultima_actualizacion ? formatDate( operarioBD?.saldo_horas?.fecha_ultima_actualizacion?.toDate(), 'yyyy-MM-dd', 'en' ) : null;
        const fechaVacacionesBD = operarioBD?.saldo_vacaciones?.fecha_ultima_actualizacion ? formatDate( operarioBD?.saldo_vacaciones?.fecha_ultima_actualizacion?.toDate(), 'yyyy-MM-dd', 'en' ) : null;

        this.saldosTrabajadorForm.reset({
          nombre: operarioBD.apellido1 + ' ' + operarioBD.apellido2 + ', ' + operarioBD.nombre,
          saldo_horas: {
            anio: operarioBD?.saldo_horas?.anio ?? '',
            fecha_ultima_actualizacion: fechaHorasBD,
            horas_anios_anteriores: operarioBD?.saldo_horas?.horas_anios_anteriores ?? '',
            horas_extra: operarioBD?.saldo_horas?.horas_extra ?? '',
            horas_lluvia: operarioBD?.saldo_horas?.horas_lluvia ?? '',
            horas_pagadas: operarioBD?.saldo_horas?.horas_pagadas ?? '',
            horas_traslados: operarioBD?.saldo_horas?.horas_traslados ?? '',
            saldo: operarioBD?.saldo_horas?.saldo ?? '',
          },
          saldo_vacaciones: {
            anio: operarioBD?.saldo_vacaciones?.anio ?? '',
            fecha_ultima_actualizacion: fechaVacacionesBD,
            dias_anios_anteriores: operarioBD?.saldo_vacaciones?.dias_anios_anteriores ?? '',
            dias_anios_anteriores_disfrutados: operarioBD?.saldo_vacaciones?.dias_anios_anteriores_disfrutados ?? '',
            dias_asuntos_propios: operarioBD?.saldo_vacaciones?.dias_asuntos_propios ?? '',
            dias_asuntos_propios_disfrutados: operarioBD?.saldo_vacaciones?.dias_asuntos_propios_disfrutados ?? '',
            dias_empresa: operarioBD?.saldo_vacaciones?.dias_empresa ?? '',
            dias_empresa_disfrutados: operarioBD?.saldo_vacaciones?.dias_empresa_disfrutados ?? '',
            dias_fiesta_local: operarioBD?.saldo_vacaciones?.dias_fiesta_local ?? '',
            dias_fiesta_local_disfrutados: operarioBD?.saldo_vacaciones?.dias_fiesta_local_disfrutados ?? '',
            dias_trabajador: operarioBD?.saldo_vacaciones?.dias_trabajador ?? '',
            dias_trabajador_disfrutados: operarioBD?.saldo_vacaciones?.dias_trabajador_disfrutados ?? '',
            saldo: operarioBD?.saldo_vacaciones?.saldo ?? '',
          }
        });
        this.calcularSaldoHoras();
        this.calcularSaldoVacaciones();
        this.cargando = false;
      });


  }

  actualizarSaldosTrabajador() {

    this.formSubmitted = true;

    if ( this.saldosTrabajadorForm.invalid ) {
      return;
    }

    if ( this.saldosTrabajadorForm.pristine ) {
      this.cancelarFormTrabajador();
      return;
    }

    this.personalSaldosService.actualizarSaldosTrabajador( this.trabajadorID, this.saldosTrabajadorForm.value )
      .then( () => this.router.navigateByUrl('/rrhh/saldos-personal') )
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  cancelarFormTrabajador() {

    if ( this.saldosTrabajadorForm.pristine ) {
      this.router.navigate(['/rrhh/saldos-personal']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/rrhh/saldos-personal']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.saldosTrabajadorForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  calcularSaldoHoras() {

    const horasAniosAnteriores = this.saldosTrabajadorForm.get('saldo_horas.horas_anios_anteriores').value;
    const horasExtra = this.saldosTrabajadorForm.get('saldo_horas.horas_extra').value;
    const horasLluvia = this.saldosTrabajadorForm.get('saldo_horas.horas_lluvia').value;
    const horasPagadas = this.saldosTrabajadorForm.get('saldo_horas.horas_pagadas').value;

    let saldoHoras = horasAniosAnteriores + horasExtra + horasLluvia - horasPagadas;
    saldoHoras = Math.round( (saldoHoras + Number.EPSILON) * 100 ) / 100;

    this.saldosTrabajadorForm.get('saldo_horas').get('saldo').setValue(saldoHoras);

  }

  calcularSaldoVacaciones() {

    const diasAniosAnteriores = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_anios_anteriores').value;
    const diasAniosAnterioresDisfrutados = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_anios_anteriores_disfrutados').value;
    const diasAsuntosPropios = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_asuntos_propios').value;
    const diasAsuntosPropiosDisfrutados = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_asuntos_propios_disfrutados').value;
    const diasEmpresa = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_empresa').value;
    const diasEmpresaDisfrutados = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_empresa_disfrutados').value;
    const diasFiestaLocal = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_fiesta_local').value;
    const diasFiestaLocalDisfrutados = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_fiesta_local_disfrutados').value;
    const diasTrabajador = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_trabajador').value;
    const diasTrabajadorDisfrutados = this.saldosTrabajadorForm.get('saldo_vacaciones.dias_trabajador_disfrutados').value;

    let saldoVacaciones = (diasAniosAnteriores - diasAniosAnterioresDisfrutados) + (diasAsuntosPropios - diasAsuntosPropiosDisfrutados) + (diasEmpresa - diasEmpresaDisfrutados) + (diasFiestaLocal - diasFiestaLocalDisfrutados) + (diasTrabajador - diasTrabajadorDisfrutados);
    saldoVacaciones = Math.round( (saldoVacaciones + Number.EPSILON) * 100 ) / 100;

    this.saldosTrabajadorForm.get('saldo_vacaciones').get('saldo').setValue(saldoVacaciones);
  }

  fechaHoyHoras() {
    const fechaHoy = formatDate( new Date(), 'yyyy-MM-dd', 'en' );
    this.saldosTrabajadorForm.get('saldo_horas.fecha_ultima_actualizacion').setValue(fechaHoy);
    this.saldosTrabajadorForm.get('saldo_horas.fecha_ultima_actualizacion').markAsDirty();
  }

  fechaHoyVacaciones() {
    const fechaHoy = formatDate( new Date(), 'yyyy-MM-dd', 'en' );
    this.saldosTrabajadorForm.get('saldo_vacaciones.fecha_ultima_actualizacion').setValue(fechaHoy);
    this.saldosTrabajadorForm.get('saldo_vacaciones.fecha_ultima_actualizacion').markAsDirty();
  }

}
