<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center" [ngClass]="{'alert-warning': !guardando, 'alert-info': guardando}">
            <strong>{{ titCargando }}</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Fórmula</h4>
            </div>
            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="formulaForm" (ngSubmit)="crearFormula()">
                <div class="card-body">

                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Codigo*</label>
                                <input type="text" class="form-control" placeholder="Código" required [ngClass]="{ 'is-invalid': campoNoValido('codigo') || formulaForm.controls['codigo'].hasError('formulaExists') || formulaForm.controls['codigo'].hasError('formulaBaja') }" formControlName="codigo"
                                    (ngModelChange)="cambioCodigo()">
                                <div class="invalid-feedback" *ngIf="formulaForm.controls['codigo'].hasError('required')">
                                    El código es obligatorio
                                </div>
                                <div class="invalid-feedback" *ngIf="formulaForm.controls['codigo'].hasError('formulaExists')">
                                    El código ya existe
                                </div>
                                <div class="invalid-feedback" *ngIf="formulaForm.controls['codigo'].hasError('formulaBaja')">
                                    El código ya existe pero esta dada de baja
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Planta</label>
                                <input type="search" class="form-control" placeholder="Escribe el código o nombre..." formControlName="planta" [ngbTypeahead]="plantasearch" [inputFormatter]="plantaformat" [resultFormatter]="plantaformat" [editable]="false">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-9">
                            <div class="form-group">
                                <label>Nombre*</label>
                                <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                                <div class="invalid-feedback">
                                    El nombre es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-3">
                            <label class="">Unidades*</label>
                            <div class="input-group">
                                <select class="custom-select" required [ngClass]="{ 'is-invalid': selectNoValido('unidades') }" formControlName="unidades">
                                    <option value="" selected>Elige una opción...</option>
                                    <option *ngFor="let unidad of unidades" [ngValue]="unidad.id">{{ unidad.codigo | uppercase }}</option>
                                </select>
                                <!-- <div class="input-group-append">
                                    <button class="btn btn-outline-success" type="button">
                                        <i class="mdi mdi-plus"></i>
                                    </button>
                                </div> -->
                                <div class="invalid-feedback">
                                    La unidad es obligatoria. Elige una.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Nº de Navision</label>
                                <input type="text" class="form-control" placeholder="Nº de Navision" formControlName="numnavision">
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-sm-12 col-lg-3 mt-auto">
                            <div class="form-group mb-0">
                                <div class="d-flex">
                                    <div class="checkbox checkbox-info pt-0">
                                        <input name="marcadoce" id="check-CE" type="checkbox" class="material-inputs chk-col-indigo" formControlName="marcadoce"  (change)="verificarSello()">
                                        <label for="check-CE"> Marcado CE </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="formulaForm.controls.marcadoce.value">
                            <label class="">Sello marcado CE*</label>
                            <div class="input-group">
                                <select class="custom-select" required [ngClass]="{ 'is-invalid': selectNoValido('selloce') }" formControlName="selloce">
                                    <option value="" selected>Elige una opción...</option>
                                    <option *ngFor="let sello of sellosce" [ngValue]="sello.id">{{ sello.nombre | uppercase }}</option>
                                </select>
                                <div class="invalid-feedback">
                                    El sello es obligatorio. Elige uno.
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="form-row mt-4">

                        <div class="col-sm-12 col-lg-6">

                            <table>
                                <thead>
                                    <tr>
                                        <th colspan="3">Dosificación en frío</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="dosificacionfrio">
                                    <tr>
                                        <td class="font-weight-normal" style="width: 75%">Material</td>
                                        <td class="font-weight-normal">Cantidad</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let control of dosificacionfrio.controls; let i = index">
                                        <td style="width: 75%" [formGroupName]="i">
                                            <input type="search" class="form-control" placeholder="Escribe el nombre..." formControlName="material" [ngbTypeahead]="artisearch" [inputFormatter]="artiformat" [resultFormatter]="artiformat" [editable]="false">
                                        </td>
                                        <td [formGroupName]="i">
                                            <input type="number" class="form-control" formControlName="cantidad" (ngModelChange)="recalcularTotFrio()">
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger" (click)="eliminarMaterialFrio(i)">
                                                <i class="mdi mdi-delete"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <input type="number" class="form-control" disabled [value]="totDosiFrio">
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="pb-3" colspan="3">
                                            <button type="button" class="btn btn-secondary" (click)="agregarMaterialFrio(true)">Añadir</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>

                        <div class="col-sm-12 col-lg-6">

                            <table>
                                <thead>
                                    <tr>
                                        <th colspan="4">Dosificación en caliente</th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="dosificacioncaliente">
                                    <tr>
                                        <td class="font-weight-normal" style="width: 25%">Tipo</td>
                                        <td class="font-weight-normal" style="width: 50%">Material</td>
                                        <td class="font-weight-normal">Cantidad</td>
                                        <td></td>
                                    </tr>
                                    <tr *ngFor="let control of dosificacioncaliente.controls; let i = index">
                                        <td style="width: 25%" [formGroupName]="i">
                                            <select class="custom-select" formControlName="tipo" (ngModelChange)="verificarTipo(i)">
                                                <option value="">Elige una opción...</option>
                                                <option value="criba">Criba</option>
                                                <option value="filler">Polvo mineral</option>
                                                <option value="aditivo">Aditivo</option>
                                                <option value="betun">Betún</option>
                                            </select>
                                        </td>
                                        <td style="width: 50%" [formGroupName]="i" [ngSwitch]="tipoMaterial[i]">
                                            <input type="text" placeholder="Criba" class="form-control" formControlName="material" *ngSwitchCase="'criba'">
                                            <input type="search" placeholder="Escribe el nombre..." class="form-control" formControlName="material" [ngbTypeahead]="artisearch" [inputFormatter]="artiformat" [resultFormatter]="artiformat" [editable]="false" *ngSwitchDefault>
                                        </td>
                                        <td [formGroupName]="i">
                                            <input type="number" class="form-control" formControlName="cantidad" (ngModelChange)="recalcularTotCali()">
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-danger" (click)="eliminarMaterialCali(i)">
                                                <i class="mdi mdi-delete"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td>
                                            <input type="number" class="form-control" disabled [value]="totDosiCali">
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="pb-3" colspan="4">
                                            <button type="button" class="btn btn-secondary" (click)="agregarMaterialCali(true)">Añadir</button>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido( 'codigo') || formulaForm.controls['codigo'].errors?.formulaExists || formulaForm.controls['codigo'].errors?.formulaBaja ||
                            campoNoValido( 'nombre') || selectNoValido( 'unidades') || (this.formulaForm.controls['selloce'].enabled && selectNoValido( 'selloce'))">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="form-group">
                                <label>Comentarios</label>
                                <textarea class="form-control" placeholder="Comentarios" rows="2" formControlName="comentarios"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!formulaID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="formulaID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarFormula()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarFormFormula()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>