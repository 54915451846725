import { Injectable } from '@angular/core';
import { addDoc, collection, collectionData, deleteDoc, doc, docData, Firestore, query, updateDoc } from '@angular/fire/firestore';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, deleteObject } from '@angular/fire/storage';
import { Observable, firstValueFrom } from 'rxjs';

import { UsuarioService } from '../../services/usuario/usuario.service';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService )  { }

  subirDocObra( archivo: File[], obraID: string, codObra: string, docsForm: any ) {

    const codObraUrl = codObra?.replace('/', '-');
    const file = archivo[0];
    const filePath = `docsObra/${codObraUrl}/` + archivo[0].name;
    const storage = getStorage();
    const storageRef = ref(storage, filePath);
    const task = uploadBytesResumable(storageRef, file);

    docsForm.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    docsForm.estado = true;
    docsForm.url = filePath;
    docsForm.tipo = file.name.split('.').pop();
    task.on('state_changed',
      // Durante la subida
      (snapshot) => {
        // console.log(snapshot);
      },
      // Errores
      (error) => {
        console.error(error);
      },
      // Cuando termina de subir se añade el registro en firestore
      () => {
        const docsObraCol = collection(this.firestore, 'obras', obraID, 'documentos');
        addDoc(docsObraCol, docsForm)
          .then( docRef => {
            const obraDocRef = doc(this.firestore, 'obras', obraID, 'documentos', docRef.id );
            updateDoc(obraDocRef, { id: docRef.id });
          }
        );
      }
    );
    return task;

  }

  async existeDocObra( archivo: File[], codObra: string ): Promise<boolean> {
    const codObraUrl = codObra?.replace('/', '-');
    const filePath = `docsObra/${codObraUrl}/` + archivo[0].name;
    const storage = getStorage();
    return getDownloadURL( ref(storage, filePath) )
      .then( () => true )
      .catch( () => false );
  }

  cargarDocsObra( obraID: string ): Observable<any[]> {

    const docsObraCol = collection(this.firestore, 'obras', obraID, 'documentos');
    const consulta = query(docsObraCol);
    return collectionData( consulta, { idField: 'id'} ) as Observable<any[]>;

  }

  verDocumento(url: string): Promise<string> {

    const storage = getStorage();
    return getDownloadURL( ref(storage, url) );

  }

  borrarDocObra( obraID: string, docID: string ) {

    const storage = getStorage();
    const docObraRef = doc(this.firestore, 'obras', obraID, 'documentos', docID);
    firstValueFrom( docData( docObraRef, { idField: 'id' } ))
      .then( (docBD: any) => {
        const docuRef = ref(storage, docBD?.url );
        deleteObject(docuRef).then(() => {
          deleteDoc(docObraRef)
            .then( () => {
              Swal.fire(
                '¡Eliminado!',
                'El documento ha sido eliminada.',
                'success'
              );
            });
        }).catch((error) => {
          console.error(error);
        });
      });

  }


  // Documentos de maquinaria y averias

  subirDocAveria( archivo: File[], averiaID: string, codMaquina: string, docsForm: any ) {

    const codMaquinaUrl = codMaquina?.replace('/', '-');
    const file = archivo[0];
    const filePath = `docsMaquinaria/${codMaquinaUrl}/averias/` + archivo[0].name;
    const storage = getStorage();
    const storageRef = ref(storage, filePath);
    const task = uploadBytesResumable(storageRef, file);

    docsForm.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    docsForm.estado = true;
    docsForm.url = filePath;
    docsForm.tipo = file.name.split('.').pop();
    task.on('state_changed',
      // Durante la subida
      (snapshot) => {
        // console.log(snapshot);
      },
      // Errores
      (error) => {
        console.error(error);
      },
      // Cuando termina de subir se añade el registro en firestore
      () => {
        const docsAveriaCol = collection(this.firestore, 'taller_averias', averiaID, 'documentos');
        addDoc(docsAveriaCol, docsForm)
          .then( docRef => {
            const averiaDocRef = doc(this.firestore, 'taller_averias', averiaID, 'documentos', docRef.id );
            updateDoc(averiaDocRef, { id: docRef.id });
          }
        );
      }
    );
    return task;

  }

  async existeDocAveria( archivo: File[], codMaquina: string ): Promise<boolean> {
    const codMaquinaUrl = codMaquina?.replace('/', '-');
    const filePath = `docsMaquinaria/${codMaquinaUrl}/averias/` + archivo[0].name;
    const storage = getStorage();
    return getDownloadURL( ref(storage, filePath) )
      .then( () => true )
      .catch( () => false );
  }

  borrarDocAveria( averiaID: string, docID: string ) {

    const storage = getStorage();
    const docAveriaRef = doc(this.firestore, 'taller_averias', averiaID, 'documentos', docID);
    firstValueFrom( docData( docAveriaRef, { idField: 'id' } ) )
      .then( (docBD: any) => {
        const docuRef = ref(storage, docBD?.url );
        deleteObject(docuRef)
          .then(() => {
            deleteDoc(docAveriaRef)
              .then( () => {
                Swal.fire(
                  '¡Eliminado!',
                  'El documento ha sido eliminada.',
                  'success'
                );
              });
          }).catch((error) => {
            console.error(error);
          });
      });

  }

}
