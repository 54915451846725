<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center" [ngClass]="cargandoClass">
            <strong>{{ txtCargando }}</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Calendario</h4>
            </div>

            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="calendarioForm" (ngSubmit)="crearCalendario()">

                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>

                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Año*</label>
                                <input type="number" class="form-control" placeholder="Año" required [ngClass]="{ 'is-invalid': campoNoValido('anio') || calendarioForm.get('anio').hasError('calendarioExists') || calendarioForm.get('convenio').hasError('calendarioExists') }" formControlName="anio" [readonly]="modoLectura">
                                <div class="invalid-feedback" *ngIf="calendarioForm.controls['anio'].hasError('required')">
                                    El año es obligatorio
                                </div>
                                <div class="invalid-feedback" *ngIf="calendarioForm.get('anio').hasError('calendarioExists') || calendarioForm.get('convenio').hasError('calendarioExists')">
                                    Ya existe un calendario para este año y convenio
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Convenio*</label>
                                <div class="input-group" *ngIf="!modoLectura">
                                    <select id="convenio" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('convenio') || calendarioForm.get('anio').hasError('calendarioExists') || calendarioForm.get('convenio').hasError('calendarioExists') }" formControlName="convenio">
                                        <option value="">Elige un convenio...</option>
                                        <option value="gipuzkoa">Gipuzkoa</option>
                                        <option value="bizkaia">Bizkaia</option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="calendarioForm.controls['convenio'].hasError('required')">
                                        El convenio es obligatorio. Elige uno.
                                    </div>
                                    <div class="invalid-feedback" *ngIf="calendarioForm.get('anio').hasError('calendarioExists') || calendarioForm.get('convenio').hasError('calendarioExists')">
                                        Ya existe un calendario para este año y convenio
                                    </div>
                                </div>
                                <input type="text" class="form-control" [value]="convenio" [readonly]="modoLectura" *ngIf="modoLectura">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Horas</label>
                                <input type="number" min="0" class="form-control" placeholder="p. ej. 1686" formControlName="horas" [readonly]="modoLectura">
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-2">
                            <div class="form-group">
                                <label>Vacaciones Empresa</label>
                                <input type="number" class="form-control" placeholder="p. ej. 11 días" formControlName="vacacionesEmpresa" [readonly]="modoLectura">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-2">
                            <div class="form-group">
                                <label>Vacaciones Trabajador</label>
                                <input type="number" class="form-control" placeholder="p. ej. 11 días" formControlName="vacacionesTrabajador" [readonly]="modoLectura">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-2">
                            <div class="form-group">
                                <label>Vacaciones total</label>
                                <input type="number" class="form-control" placeholder="p. ej. 22 días" formControlName="vacacionesTotal" [readonly]="modoLectura">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-2">
                            <div class="form-group">
                                <label>Fiesta local</label>
                                <input type="number" class="form-control" placeholder="p. ej. 1 día" formControlName="fiestaLocal" [readonly]="modoLectura">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-2">
                            <div class="form-group">
                                <label>Libre disposición</label>
                                <input type="number" class="form-control" placeholder="p. ej. 0 días" formControlName="libreDisposicion" [readonly]="modoLectura">
                            </div>
                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="col-12">
                            <h4>Fechas destacadas</h4>
                        </div>
                    </div>
                    <div class="form-row mt-3">
                        <div class="col-sm-12 col-lg-2">
                            <label>Fecha/Desde*</label>
                        </div>
                        <div class="col-sm-12 col-lg-2">
                            <label>Hasta</label>
                        </div>
                        <div class="col-sm-12 col-lg-3">
                            <label>Tipo fecha*</label>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <label>Comentario</label>
                        </div>
                    </div>
                    <ng-container formArrayName="events">
                        <div class="form-row mb-2" *ngFor="let control of eventsControlsArray; let i = index">
                            <ng-container [formGroup]="control">
                                <div class="col-sm-12 col-lg-2">
                                    <input type="date" class="form-control" [min]="calendarioForm.get('anio').value + '-01-01'" [max]="calendarioForm.get('anio').value + '-12-31'" required [ngClass]="{ 'is-invalid': campoNoValido('events.'+ i + '.start') }" [readonly]="modoLectura" formControlName="start">
                                    <div class="invalid-feedback">
                                        La fecha desde es obligatoria
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-2">
                                    <input type="date" class="form-control" [min]="calendarioForm.get('anio').value + '-01-01'" [max]="calendarioForm.get('anio').value + '-12-31'" [readonly]="modoLectura" formControlName="end">
                                </div>
                                <div class="col-sm-12 col-lg-3">
                                    <div class="input-group" *ngIf="!modoLectura">
                                        <select class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('events.'+ i + '.backgroundColor') }" formControlName="backgroundColor">
                                            <option value="">Elige un tipo...</option>
                                            <option *ngFor="let descanso of tiposDescanso" [value]="descanso.color">{{ descanso.nombre }}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            El tipo de fecha es obligatorio. Elige uno.
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" [value]="control.get('tipo').value" [readonly]="modoLectura" *ngIf="modoLectura">
                                </div>
                                <div class="col-sm-12 col-lg-4">
                                    <input type="text" class="form-control" [readonly]="modoLectura" formControlName="comentario">
                                </div>
                                <div class="col-sm-12 col-lg-1" *ngIf="!modoLectura">
                                    <button type="button" class="btn btn-danger ml-2" (click)="eliminarEvento( control.value, i )">
                                        <i class="mdi mdi-delete"></i>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div class="form-row" *ngIf="!modoLectura">
                            <div class="col-sm-12 col-lg-2">
                                <button type="button" class="btn btn-secondary mt-3" (click)="agregarEvento(true)">Añadir</button>
                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('anio') || selectNoValido('convenio') ||
                                      calendarioForm.get('anio').hasError('calendarioExists') || calendarioForm.get('convenio').hasError('calendarioExists')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!calendarioID && !modoLectura" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="calendarioID && !modoLectura" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarCalendario()">Actualizar</button>
                        <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarCalendario()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>
