<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Informes</h4>
            </div>
            <form class="form-horizontal" id="infoform" autocomplete="off" [formGroup]="informeForm" (ngSubmit)="crearInforme()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <div class="input-group">
                                    <select id="seccion" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('seccion') }" formControlName="seccion" (ngModelChange)="cambioSeccion()">
                                        <option value="">Elige una opción...</option>
                                        <option value="maquinaria">Maquinaria</option>
                                        <option value="gondola">Góndola</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        La sección es obligatoria. Elige una.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="informeForm.get('seccion').value !== ''">
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.get('seccion').value === 'maquinaria'">
                            <div class="form-group">
                                <label>Maquina*</label>
                                <input type="search" class="form-control" required [ngClass]="{ 'is-invalid': campoNoValido('maquina') }" placeholder="Escribe el nombre o código..." formControlName="maquina" [ngbTypeahead]="maquinasearch" [inputFormatter]="maquinaformat" [resultFormatter]="maquinaformat" [editable]="false" (ngModelChange)="verificarMaquina()">
                                <div class="invalid-feedback">
                                    La maquina es obligatoria. Elige una.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.get('seccion').value === 'gondola'">
                            <div class="form-group">
                                <label>Góndola*</label>

                                <div class="input-group">
                                    <select id="gondola" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('gondola') }" formControlName="gondola">
                                        <option value="">Elige una góndola...</option>
                                        <option value="3118LBY">V-4 - 3118LBY</option>
                                        <option value="9186DTV">V-3 - 9186DTV</option>
                                        <option value="3229GBH">CR-1 - 3229GBH</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        La góndola es obligatoria. Elige una.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Tipo de informe*</label>

                                <div class="input-group">
                                    <select id="tipo" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('tipo') }" formControlName="tipo" (ngModelChange)="cambioTipo()">
                                        <option value="">Elige un tipo...</option>
                                        <option *ngIf="informeForm.get('seccion').value === 'maquinaria'" value="averias">Averías</option>
                                        <option *ngIf="informeForm.get('seccion').value === 'maquinaria'" value="mantenimientos">Mantenimientos</option>
                                        <option *ngIf="informeForm.get('seccion').value === 'maquinaria'" value="horometros">Horometros</option>
                                        <option *ngIf="informeForm.get('seccion').value === 'gondola'" value="resumen">Resumen mensual</option>
                                        <option *ngIf="informeForm.get('seccion').value === 'gondola'" value="detalle">Busqueda</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        El tipo de informe es obligatorio. Elige uno.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.get('seccion').value !== 'gondola' && informeForm.get('tipo').value !== '' && informeForm.get('tipo').value !== 'horometros'">
                            <div class="form-group">
                                <label>Estado*</label>

                                <div class="input-group">
                                    <select id="tipo" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('estado') }" formControlName="estado">
                                        <option value="">Elige un estado...</option>
                                        <option value="todos">Todos</option>
                                        <option *ngIf="informeForm.controls.tipo.value === 'mantenimientos'" value="aviso">Aviso</option>
                                        <option value="pendiente">Pendiente</option>
                                        <option value="en_curso">En curso</option>
                                        <option *ngIf="informeForm.controls.tipo.value === 'mantenimientos'" value="realizado">Realizados</option>
                                        <option *ngIf="informeForm.controls.tipo.value === 'averias'" value="reparado">Reparados</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        El estado es obligatorio. Elige uno.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="informeForm.get('seccion').value !== ''">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Fechas*</label>

                                <div class="input-group">
                                    <select id="rango" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('rango') }" formControlName="rango" (ngModelChange)="cambioRango()">
                                        <option value="">Elige un rango...</option>
                                        <option *ngIf="informeForm.get('tipo').value !== 'resumen'" value="todo">A origen</option>
                                        <option value="mes">Mes</option>
                                        <option *ngIf="informeForm.get('tipo').value !== 'resumen'" value="dh">Desde-Hasta</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        El rango de fechas son obligatorias. Elige una.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'mes' || inputFechas">
                            <div class="form-group">
                                <label>Mes</label>
                                <input type="month" class="form-control" formControlName="mes" (ngModelChange)="cambioMes()" [readonly]="inputFechas">
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'dh'">
                            <div class="form-group">
                                <label>Desde</label>
                                <input type="date" class="form-control" formControlName="desde" [readonly]="inputFechas">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'dh'">
                            <div class="form-group">
                                <label>Hasta</label>
                                <input type="date" class="form-control" formControlName="hasta" [readonly]="inputFechas">
                            </div>
                        </div>

                    </div>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('maquina') || selectNoValido('seccion') || selectNoValido('tipo') || selectNoValido('estado') || selectNoValido('rango')">
                                Hay errores en el formulario. Corrígelos para poder buscar.
                            </p>

                        </div>
                    </div>
                    <div class="form-group mb-0 text-right">
                        <button type="submit" class="btn btn-primary waves-effect waves-light mr-1" [disabled]="disableSubmit">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="disableSubmit"></span>
                            {{ txtBtnSubmit }}
                        </button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="restablecer()">Restablecer</button>
                    </div>
                </div>
            </form>

        </div>

        <div class="card">
            <div class="card-body" *ngIf="buscando">

                <div class="row animated fadeIn fast" *ngIf="cargando">

                    <div class="col-sm-12">

                        <div class="info alert-info text-center">
                            <strong>Buscando</strong>
                            <br>
                            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                            <br>
                            <span>Espere por favor</span>
                        </div>

                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'averias'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">

                                    <div class="col-lg-6 col-sm-12">
                                        <h4 class="card-title">Averias ({{ totalRegistros }})</h4>
                                    </div>

                                    <div class="col-lg-6 col-sm-12">
                                      <div class="text-right">
                                        <button type="button" class="btn btn-primary" (click)="CrearInformePDF('a')">
                                            <i class="mdi mdi-file-pdf" aria-hidden="true"></i>
                                            Descargar PDF
                                        </button>
                                      </div>
                                    </div>

                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                </select>
                                        <small> registros</small>
                                    </div>
                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='averias'>
                                    <ngx-datatable-column name="Fecha" prop="fechaAviso" [comparator]="fechaComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fechaAviso'].toDate() | date: 'dd/MM/yyyy' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Maquina" prop="maquina" [comparator]="maquinaComparator" [flexGrow]="4">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['maquina']['codnavision'] }} - {{ row['maquina']['nombre'] }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Descripción" prop="descripcion" [sortable]="false" [flexGrow]="3"></ngx-datatable-column>
                                    <ngx-datatable-column name="Horometro/Km" prop="horometro" [sortable]="false" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['horometro'] }} {{ (row['horometro']) ? row['udHorometro'] ?? 'h' : '' }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Prioridad" prop="prioridad" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['prioridad'] | titlecase }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Estado" prop="estado" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['estado'] | estadoTxt }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                                        <ng-template let-value="value" ngx-datatable-header-template>
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('a')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-excel"></i></button> -->
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="CrearInformePDF('a')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-pdf"></i></button> -->
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                                <!-- <button type="button" class="btn btn-secondary" (click)="descargarSalida( value )"><i class="fas fa-download"></i></button>
                                                <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="fas fa-print"></i></button> -->
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'mantenimientos'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">

                                    <div class="col-lg-6 col-sm-12">
                                        <h4 class="card-title">Mantenimientos ({{ totalRegistros }})</h4>
                                    </div>

                                    <div class="col-lg-6 col-sm-12">
                                      <div class="text-right">
                                        <button type="button" class="btn btn-primary" (click)="CrearInformePDF('m')">
                                            <i class="mdi mdi-file-pdf" aria-hidden="true"></i>
                                            Descargar PDF
                                        </button>
                                      </div>
                                    </div>

                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                </select>
                                        <small> registros</small>
                                    </div>
                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='mantenimientos'>
                                    <ngx-datatable-column name="Fecha" prop="fecha" [comparator]="fechaComparator" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ ((row['fechaAviso']) ? row['fechaAviso'].toDate() : row['fecha'].toDate()) | date: 'dd/MM/yyyy' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Maquina" prop="maquina" [comparator]="maquinaComparator" [flexGrow]="4">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['maquina']['codnavision'] }} - {{ row['maquina']['nombre'] }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="H/Km" prop="horometro" [sortable]="false" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ (row['horometroAviso']) ? row['horometroAviso'] : row['horometro'] ?? '' }} {{ (row['horometroAviso']) ? row['udHorometroAviso'] ?? 'h' : (row['horometro']) ? row['udHorometro'] ?? 'h' : '' }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Descripción" prop="descripcion" [sortable]="false" [flexGrow]="3">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ (row['descripcion']) }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Estado" prop="estado" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['estado'] | estadoTxt }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Ace." prop="aceite" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipAceite>
                                                <div class="d-flex flex-column">
                                                    <span>Aceite</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipAceite" tooltipClass="tooltip-custom" container="body" *ngIf="row['aceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipAceite" tooltipClass="tooltip-custom" container="body" *ngIf="!row['aceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Hid." prop="hidraulico" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipHidraulico>
                                                <div class="d-flex flex-column">
                                                    <span>Hidráulico</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="row['hidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="!row['hidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Lu.Ot." prop="otros" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipAceiteOtros>
                                                <div class="d-flex flex-column">
                                                    <span>Lubricantes Otros</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipAceiteOtros" tooltipClass="tooltip-custom" container="body" *ngIf="row['otros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipAceiteOtros" tooltipClass="tooltip-custom" container="body" *ngIf="!row['otros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Ac." prop="filtroaceite" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroAceite>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Aceite</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroAceite" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroAceite" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Ga." prop="filtrogasoil" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroGasoil>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Gasoil</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroGasoil" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrogasoil'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroGasoil" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrogasoil'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Ai." prop="filtroaire" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroAire>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Aire</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroAire" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaire'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroAire" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaire'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Hi." prop="filtrohidraulico" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroHidraulico>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Hidráulico</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrohidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrohidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Agu." prop="filtroagua" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroAgua>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Agua</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroAgua" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroagua'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroAgua" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroagua'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.A.C." prop="filtroaceiteconvertidor" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroAceiteConvertidor>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Aceite Convertidor</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroAceiteConvertidor" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaceiteconvertidor'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroAceiteConvertidor" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaceiteconvertidor'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="F.Ot." prop="filtrootros" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipFiltroOtros>
                                                <div class="d-flex flex-column">
                                                    <span>Filtro Otros</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipFiltroOtros" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrootros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                                            <span [ngbTooltip]="tipFiltroOtros" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrootros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                                        <ng-template let-value="value" ngx-datatable-header-template>
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('m')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-excel"></i></button> -->
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="CrearInformePDF('m')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-pdf"></i></button> -->
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                                <!-- <button type="button" class="btn btn-secondary" (click)="descargarSalida( value )"><i class="fas fa-download"></i></button>
                                                <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="fas fa-print"></i></button> -->
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'horometros'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">

                                    <div class="col-lg-6 col-sm-12">
                                        <h4 class="card-title">Horometros ({{ totalRegistros }})</h4>
                                    </div>

                                    <div class="col-lg-6 col-sm-12">
                                      <div class="text-right">
                                        <button type="button" class="btn btn-primary" (click)="CrearInformePDF('h')">
                                            <i class="mdi mdi-file-pdf" aria-hidden="true"></i>
                                            Descargar PDF
                                        </button>
                                      </div>
                                    </div>

                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                </select>
                                        <small> registros</small>
                                    </div>
                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='horometros'>
                                    <ngx-datatable-column name="Fecha" prop="fecha" [comparator]="fechaComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fecha'].toDate() | date: 'dd/MM/yyyy' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Horometro" prop="horometro" [comparator]="horometroComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['horometro'] }} {{ row['udHorometro'] ?? 'h' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                                        <ng-template ngx-datatable-header-template>
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('h')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-excel"></i></button> -->
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="CrearInformePDF('h')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-pdf"></i></button> -->
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                                <!-- <button type="button" class="btn btn-secondary" (click)="descargarSalida( value )"><i class="fas fa-download"></i></button>
                                                <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="fas fa-print"></i></button> -->
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'detalle'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">

                                    <div class="col-lg-6 col-sm-12">
                                        <h4 class="card-title">Portes ({{ totalRegistros }})</h4>
                                    </div>

                                    <div class="col-lg-6 col-sm-12">
                                      <div class="text-right">
                                        <button type="button" class="btn btn-primary" (click)="CrearInformePDF('g')">
                                            <i class="mdi mdi-file-pdf" aria-hidden="true"></i>
                                            Descargar PDF
                                        </button>
                                      </div>
                                    </div>

                                </div>

                                <div class="row mb-1">

                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                </select>
                                        <small> registros</small>
                                    </div>

                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'flex'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='portes'>

                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [flexGrow]="0.5">
                                      <!-- <ng-template let-value="value" ngx-datatable-header-template>
                                          <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                                      </ng-template> -->

                                      <ng-template let-row="row" ngx-datatable-cell-template>
                                          <div class="d-flex justify-content-center">
                                              <ng-template #tipRevisadoVerificado>
                                                <div class="d-flex flex-column">
                                                    <span>Revisado por: {{ row['revisadoPor'] }}</span>
                                                </div>
                                              </ng-template>
                                              <i [ngbTooltip]="tipRevisadoVerificado" tooltipClass="tooltip-custom" container="body" class="mdi mdi-checkbox-marked-circle-outline" *ngIf="row['revisado']" style="color: green;"></i>
                                              <i class="mdi mdi-checkbox-blank-circle-outline" *ngIf="!row['revisado']" style="color: blue;"></i>
                                          </div>
                                      </ng-template>

                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Fecha carga" prop="fechaCarga" [comparator]="fechaComparator" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['fechaCarga']?.toDate() | date: 'dd/MM/yyyy - H:mm' }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Cargado en" prop="cargadoEn" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div *ngIf="row['cargadoObra']; else elseBlock">
                                                <ng-template #tipObraCarga>
                                                    <div class="d-flex flex-column">
                                                        <span>{{ row['cargadoObra']['nombre'] | uppercase }}</span>
                                                        <span>({{ row['cargadoObra']['cliente']['nombre'] | uppercase }})</span>
                                                    </div>
                                                </ng-template>
                                                <span [ngbTooltip]="tipObraCarga" tooltipClass="tooltip-custom" container="body">{{ row['cargadoObra']['codigo'] }}</span>
                                            </div>
                                            <ng-template #elseBlock><span>{{ row['cargadoEn'] | uppercase }}</span></ng-template>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Fecha descarga" prop="fechaDescarga" [comparator]="fechaComparator" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span>{{ row['fechaDescarga']?.toDate() | date: 'dd/MM/yyyy - H:mm' }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Descargado en" prop="descargadoEn" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div *ngIf="row['descargadoObra']; else elseBlock">
                                                <ng-template #tipObraDescarga>
                                                    <div class="d-flex flex-column">
                                                        <span>{{ row['descargadoObra']['nombre'] | uppercase }}</span>
                                                        <span>({{ row['descargadoObra']['cliente']['nombre'] | uppercase }})</span>
                                                    </div>
                                                </ng-template>
                                                <span [ngbTooltip]="tipObraDescarga" tooltipClass="tooltip-custom" container="body">{{ row['descargadoObra']['codigo'] }}</span>
                                            </div>
                                            <ng-template #elseBlock><span>{{ row['descargadoEn'] | uppercase }}</span></ng-template>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Nº maquinas" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                          <div [ngSwitch]="row['tipoCarga']">
                                            <span *ngSwitchCase="'vacio'">Porte vacío</span>
                                            <span *ngSwitchCase="'externa'">Maquinaria externa</span>
                                            <ng-container *ngSwitchDefault>
                                              <div *ngIf="row['maquinas'].length > 0; else elseBlock">
                                                <ng-template #tipMaquinas>
                                                    <div class="d-flex flex-column">
                                                        <span *ngFor="let maquina of row['maquinas']">{{ maquina['nombre'] | uppercase }}</span>
                                                    </div>
                                                </ng-template>
                                                <span [ngbTooltip]="tipMaquinas" tooltipClass="tooltip-custom" container="body">{{ row['maquinas'].length }}</span>
                                              </div>
                                              <ng-template #elseBlock><span>{{ row['maquinas'].length }}</span></ng-template>
                                            </ng-container>
                                          </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Horas" prop="horas" [comparator]="fechaComparator" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                          <span>{{ row['horas'] | number: '1.2-2' }} h</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                                        <ng-template ngx-datatable-header-template>
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('h')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-excel"></i></button> -->
                                            <!-- <button type="button" class="btn btn-sm btn-outline-secondary" (click)="CrearInformePDF('g')" *ngIf="totalRegistros > 0"><i class="mdi mdi-file-pdf"></i></button> -->
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                                <!-- <button type="button" class="btn btn-secondary" (click)="descargarSalida( value )"><i class="fas fa-download"></i></button>
                                                <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="fas fa-print"></i></button> -->
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</div>
