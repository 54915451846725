<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">{{ titulo }}</h4>
            </div>
            <form class="form-horizontal" id="loginform" autocomplete="off" [formGroup]="usuarioForm" (ngSubmit)="crearUsuario()">
                <div class="card-body">
                    <div class="form-group row">
                        <label for="fname" class="col-sm-3 text-right control-label col-form-label">Nombre*</label>
                        <div class="col-sm-9">
                            <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                            <div class="invalid-feedback">
                                El nombre es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="lname" class="col-sm-3 text-right control-label col-form-label">Email*</label>
                        <div class="col-sm-9">
                            <input type="email" class="form-control" placeholder="Email" required [ngClass]="{ 'is-invalid': campoNoValido('email') }" formControlName="email">
                            <div class="invalid-feedback">
                                El email es obligatorio y tiene que ser válido
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="!userID">
                        <label for="email1" class="col-sm-3 text-right control-label col-form-label">Contraseña*</label>
                        <div class="col-sm-9">
                            <input type="password" class="form-control" placeholder="Contraseña" required [ngClass]="{ 'is-invalid': contrasenasDebiles() }" formControlName="password">
                            <div class="invalid-feedback">
                                Las contraseñas son obligatorias y deben tener al menos 6 caracteres
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="!userID">
                        <label for="cono1" class="col-sm-3 text-right control-label col-form-label">Confirmar contraseña*</label>
                        <div class="col-sm-9">
                            <input type="password" class="form-control" placeholder="Confirmar contraseña" required [ngClass]="{ 'is-invalid': contrasenasNoValidas() }" formControlName="password2">
                            <div class="invalid-feedback">
                                Las contraseñas deben de ser iguales
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="role" class="col-sm-3 text-right control-label col-form-label">Rol*</label>
                        <div class="col-sm-9">
                          <ng-select
                            [items]="roles"
                            bindValue="nombre"
                            bindLabel="nombre"
                            labelForId="role"
                            placeholder="Elige una opción..."
                            notFoundText="No hay roles disponibles"
                            clearAllText="Limpiar"
                            formControlName="role"
                            [clearable]="true"
                            [loadingText]="'Cargando...'"
                            required
                          >
                          </ng-select>
                          <div class="invalid-ngselect" *ngIf="formSubmitted && usuarioForm.controls['role'].hasError('required')">
                            El rol es obligatorio. Elige uno.
                          </div>
                        </div>
                    </div>

                    <!-- Operario -->
                    <div class="form-group row">
                      <label for="operario" class="col-sm-3 text-right control-label col-form-label">Operario{{ usuarioForm.get('parte').value !== 'no' ? '*' : '' }}</label>
                      <div class="col-sm-9">
                        <ng-select
                          [items]="personal"
                          bindValue="id"
                          bindLabel="nombreCompleto"
                          labelForId="operario"
                          placeholder="Elige un operario..."
                          notFoundText="No hay operarios disponibles"
                          clearAllText="Limpiar"
                          formControlName="operario"
                          [clearable]="true"
                          [loadingText]="'Cargando...'"
                        >
                        </ng-select>
                        <div class="invalid-ngselect" *ngIf="formSubmitted && usuarioForm.controls['operario'].hasError('required')">
                          El operario es obligatorio. Elige uno.
                        </div>
                      </div>
                    </div>

                    <!-- Obra -->
                    <div class="form-group row">
                        <label for="obra" class="col-sm-3 text-right control-label col-form-label">Obra</label>
                        <div class="col-sm-9">

                          <ng-select
                            [items]="obras"
                            bindValue="id"
                            bindLabel="nombreCompleto"
                            labelForId="obra"
                            placeholder="Elige una obra..."
                            notFoundText="No hay obras disponibles"
                            clearAllText="Limpiar"
                            [searchFn]="buscarObra"
                            formControlName="obra"
                            [clearable]="true"
                            [loadingText]="'Cargando...'"
                          >
                          </ng-select>

                        </div>
                    </div>

                    <hr>

                    <h6>Parte de trabajo</h6>


                    <div class="form-group row">
                      <label for="parte" class="col-sm-3 text-right control-label col-form-label">Parte de trabajo</label>
                      <div class="col-sm-9">
                        <ng-select [searchable]="false" [clearable]="false" labelForId="parte" formControlName="parte" (change)="cambiarParte($event)">
                          <ng-option value="no">No</ng-option>
                          <ng-option value="individual">Individual</ng-option>
                          <ng-option value="equipo">Equipo</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="responsables" class="col-sm-3 text-right control-label col-form-label">Responsables*</label>
                      <div class="col-sm-9">
                        <ng-select
                          [items]="responsables"
                          [multiple]="true"
                          bindValue="id"
                          bindLabel="nombreCompleto"
                          labelForId="responsables"
                          placeholder="Elige los responsables..."
                          notFoundText="No hay responsables disponibles"
                          clearAllText="Limpiar"
                          formControlName="responsables"
                          [clearable]="true"
                          [loadingText]="'Cargando...'"
                          required
                        >
                        </ng-select>
                        <div class="invalid-ngselect" *ngIf="formSubmitted && usuarioForm.controls['responsables'].hasError('required')">
                          Los responsables son obligatorios. Elige al menos uno.
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label for="firmar" class="col-sm-3 text-right control-label col-form-label">Firmar</label>
                      <div class="col-sm-9">
                        <ng-select [searchable]="false" [clearable]="false" labelForId="firmar" formControlName="firmar">
                          <ng-option [value]="false">No</ng-option>
                          <ng-option [value]="true">Si</ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="row mt-4">

                      <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                              <ng-toggle onColor="primary" onText="Activo" offColor="secondary" offText="Inactivo" [size]="" formControlName="estado">
                              </ng-toggle>
                          </div>
                      </div>

                  </div>

                  </div>
                  <hr>
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-danger">

                          <p *ngIf="campoNoValido('nombre') || campoNoValido('email') ||
                                    campoNoValido('role') || campoNoValido('responsables') ||
                                    (contrasenasNoValidas() && !userID) || (contrasenasDebiles() && !userID)">
                              Hay errores en el formulario. Corrígelos para poder guardar la información.
                          </p>

                      </div>
                    </div>
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!userID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="userID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarUsuario()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarFormUsuario()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
