import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { delay } from 'rxjs/operators';

import { ProveedoresService } from '../../../../services/service.index';

import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-proveedor',
  templateUrl: './proveedor.component.html',
  styles: [
  ]
})
export class ProveedorComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Nuevo proveedor';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  proveedorID: string;
  modoLectura: boolean = false;

  private subs = new SubSink();

  public formSubmitted = false;

  public proveedorForm = this.fb.group({
    nombre: ['', Validators.required ],
    alias: [ '' ],
    cif: [ '' ],
    numnavision: [ '' ],
    direccion: [ '' ],
    codpostal: [ '' ],
    localidad: [ '' ],
    telefono: [ '' ],
    email: [ '' ]
  });

  constructor( private proveedoresService: ProveedoresService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

    this.activatedRoute.params
    .subscribe( params => {

      if ( params.id ) {
        this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Proveedor';
        this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
        this.proveedorID = params.id;
        if (params.accion === 'editar') {
          this.modoLectura = false;
          this.cargarProveedor( params.id );
        } else if (params.accion === 'ver') {
          this.modoLectura = true;
          this.cargarProveedor( params.id );
        } else {
          this.router.navigate(['/maestros/proveedores']);
        }
      } else {
        this.cargando = false;
      }

    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarProveedor( id: string ) {

    this.proveedorID = id;

    this.subs.sink = this.proveedoresService.obtenerProveedorPorId( id )
      .pipe(
        delay(100)
      )
      .subscribe( (proveedor: any) => {

        if ( !proveedor ) {
          Swal.fire('Error', 'Error al cargar los datos del proveedor', 'error');
          return this.router.navigateByUrl('/maestros/proveedores');
        }
        this.proveedorForm.reset(
          {
            nombre: proveedor.nombre,
            alias: proveedor.alias,
            cif: proveedor.cif,
            numnavision: proveedor.numnavision,
            direccion: proveedor.direccion,
            codpostal: proveedor.codpostal,
            localidad: proveedor.localidad,
            telefono: proveedor.telefono,
            email: proveedor.email
          });
        this.cargando = false;
      });

  }

  crearProveedor() {

    this.formSubmitted = true;

    if ( this.proveedorForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.proveedoresService.crearProveedor( this.proveedorForm.value )
      .then( () => this.router.navigateByUrl('/maestros/proveedores') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarProveedor() {

    this.formSubmitted = true;

    if ( this.proveedorForm.invalid ) {
      return;
    }

    if ( this.proveedorForm.pristine ) {
      this.cancelarProveedor();
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    // Realizar el posteo
    this.proveedoresService.actualizarProveedor( this.proveedorID, this.proveedorForm.value )
      .then( () => this.router.navigateByUrl('/maestros/proveedores') )
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  // Cancelar el formulario y regresar al listado
  cancelarProveedor() {

    if ( this.proveedorForm.pristine ) {
      this.router.navigateByUrl('/maestros/proveedores');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/maestros/proveedores']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.proveedorForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
