import { RouterModule, Routes } from '@angular/router';

// GUARDS

import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import {
    AdminGuard,
    AdministracionGuard,
    MaestrosGuard,
    LabotarioGuard,
    PlantaGuard,
    TallerGuard,
    PersonalGuard,
} from '../guards/guards.index';

import { PagesComponent } from './pages.component';

// INICIO
import { InicioComponent } from './inicio/inicio.component';
import { PerfilComponent } from './usuario/perfil/perfil.component';

// ADMINISTRACION
import { ClientesComponent } from './administracion/clientes/clientes.component';
import { ClienteComponent } from './administracion/clientes/cliente/cliente.component';
import { ObrasComponent } from './administracion/obras/obras.component';
import { ObraComponent } from './administracion/obras/obra/obra.component';
import { CategoriasDocumentosComponent } from './administracion/categorias-documentos/categorias-documentos.component';
import { CategoriaDocumentoComponent } from './administracion/categorias-documentos/categoria-documento/categoria-documento.component';

// MAESTROS
import { CamionesComponent } from './maestros/camiones/camiones.component';
import { CamionComponent } from './maestros/camiones/camion/camion.component';
import { ProveedoresComponent } from './maestros/proveedores/proveedores.component';
import { ProveedorComponent } from './maestros/proveedores/proveedor/proveedor.component';
import { MateriasPrimasComponent } from './maestros/materias-primas/materias-primas.component';
import { ArticuloComponent } from './maestros/materias-primas/articulo/articulo.component';

// PLANTA
import { ListaObrasComponent } from './planta/obras/obras.component';
import { EntradasPlantaComponent } from './planta/entradas/entradas.component';
import { EntradaPlantaComponent } from './planta/entradas/entrada/entrada.component';
import { SalidasPlantaComponent } from './planta/salidas/salidas.component';
import { SalidaPlantaComponent } from './planta/salidas/salida/salida.component';
import { InformesPlantaComponent } from './planta/informes/informes.component';

// PERSONAL
import { ConveniosComponent } from './personal/convenios/convenios.component';
import { ConvenioComponent } from './personal/convenios/convenio/convenio.component';
import { CalendarioComponent } from './personal/calendarios/calendario.component';
import { ListaCalendariosComponent } from './personal/calendarios/lista/lista-calendarios.component';
import { CalendarioFormComponent } from './personal/calendarios/calendario-form/calendario-form.component';
import { PersonalComponent } from './administracion/personal/personal.component';
import { TrabajadorComponent } from './administracion/personal/trabajador/trabajador.component';
import { EquiposComponent } from './personal/equipos/equipos.component';
import { EquipoComponent } from './personal/equipos/equipo/equipo.component';
import { PartesTrabajoComponent } from './personal/partes-trabajo/partes-trabajo.component';
import { ParteTrabajoComponent } from './personal/partes-trabajo/parte-trabajo/parte-trabajo.component';
import { ParteTrabajoMensualComponent } from './personal/partes-trabajo/parte-trabajo-mensual/parte-trabajo-mensual.component';
import { SaldosComponent } from './personal/saldos/saldos.component';
import { SaldoTrabajadorComponent } from './personal/saldos/saldo-trabajador/saldo-trabajador.component';
import { CierreHorasComponent } from './personal/saldos/cierre-horas/cierre-horas.component';
import { SaldoVacacionesComponent } from './personal/saldos/saldo-vacaciones/saldo-vacaciones.component';
import { VacacionesComponent } from './personal/vacaciones/vacaciones.component';

// LABORATORIO
import { FormulasComponent } from './laboratorio/formulas/formulas.component';
import { FormulaComponent } from './laboratorio/formulas/formula/formula.component';
import { SellosceComponent } from './laboratorio/sellosce/sellosce.component';
import { SelloceComponent } from './laboratorio/sellosce/selloce/selloce.component';

// TALLER
import { MaquinariaComponent } from './administracion/maquinaria/maquinaria.component';
import { MaquinaComponent } from './administracion/maquinaria/maquina/maquina.component';
import { AveriasComponent } from './taller/averias/averias.component';
import { AveriaComponent } from './taller/averias/averia/averia.component';
import { MantenimientosComponent } from './taller/mantenimientos/mantenimientos.component';
import { MantenimientoComponent } from './taller/mantenimientos/mantenimiento/mantenimiento.component';
import { PortesGondolaComponent } from './taller/portes-gondola/portes-gondola.component';
import { PorteGondolaComponent } from './taller/portes-gondola/porte-gondola/porte-gondola.component';
import { InformesTallerComponent } from './taller/informes/informes.component';

// ADMIN
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { EstadisticasComponent } from './admin/estadisticas/estadisticas.component';

// Redirects to /login if user is not authenticated
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const pagesRoutes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [ AuthGuard ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
        { path: 'inicio', component: InicioComponent, data: { titulo: 'Inicio' } },
        // ADMINISTRACION
        { path: 'admin/clientes', canActivate: [ AdministracionGuard ], component: ClientesComponent, data: { titulo: 'Clientes' } },
        { path: 'admin/cliente/nuevo', canActivate: [ AdministracionGuard ], component: ClienteComponent, data: { titulo: 'Crear Cliente' } },
        { path: 'admin/cliente/editar/:id', canActivate: [ AdministracionGuard ], component: ClienteComponent, data: { titulo: 'Editar Cliente' } },
        { path: 'admin/obras', canActivate: [ AdministracionGuard ], component: ObrasComponent, data: { titulo: 'Obras' } },
        { path: 'admin/obra/nueva', canActivate: [ AdministracionGuard ], component: ObraComponent, data: { titulo: 'Crear Obra' } },
        { path: 'admin/obra/:id/:accion', canActivate: [ AdministracionGuard ], component: ObraComponent, data: { titulo: '# Obra' } },
        { path: 'admin/categorias-documentos', canActivate: [ AdministracionGuard ], component: CategoriasDocumentosComponent, data: { titulo: 'Categorías Documentos' } },
        { path: 'admin/categorias-documentos/nueva', canActivate: [ AdministracionGuard ], component: CategoriaDocumentoComponent, data: { titulo: 'Crear Categoría Documento' } },
        { path: 'admin/categorias-documentos/:id/:accion', canActivate: [ AdministracionGuard ], component: CategoriaDocumentoComponent, data: { titulo: '# Categoría Documento' } },
        // MAESTROS
        { path: 'maestros/camiones', canActivate: [ MaestrosGuard ], component: CamionesComponent, data: { titulo: 'Camiones' } },
        { path: 'maestros/camion/nuevo', canActivate: [ MaestrosGuard ], component: CamionComponent, data: { titulo: 'Crear Camión' } },
        { path: 'maestros/camion/editar/:id', canActivate: [ MaestrosGuard ], component: CamionComponent, data: { titulo: 'Editar Camión' } },
        { path: 'maestros/proveedores', canActivate: [ MaestrosGuard ], component: ProveedoresComponent, data: { titulo: 'Proveedores' } },
        { path: 'maestros/proveedor/nuevo', canActivate: [ MaestrosGuard ], component: ProveedorComponent, data: { titulo: 'Crear Proveedor' } },
        { path: 'maestros/proveedor/:id/:accion', canActivate: [MaestrosGuard ], component: ProveedorComponent, data: { titulo: '# Proveedor' } },
        { path: 'maestros/materias-primas', canActivate: [ MaestrosGuard ], component: MateriasPrimasComponent, data: { titulo: 'Materias primas' } },
        { path: 'maestros/articulo/nuevo', canActivate: [ MaestrosGuard ], component: ArticuloComponent, data: { titulo: 'Crear articulo' } },
        { path: 'maestros/articulo/editar/:id', canActivate: [ MaestrosGuard ], component: ArticuloComponent, data: { titulo: 'Editar articulo' } },
        // PLANTA
        { path: 'planta/obras', canActivate: [ PlantaGuard ], component: ListaObrasComponent, data: { titulo: 'Obras' } },
        { path: 'planta/entradas', canActivate: [ PlantaGuard ], component: EntradasPlantaComponent, data: { titulo: 'Entradas' } },
        { path: 'planta/entrada/nueva', canActivate: [ PlantaGuard ], component: EntradaPlantaComponent, data: { titulo: 'Crear Entrada' } },
        { path: 'planta/entrada/editar/:id', canActivate: [ PlantaGuard ], component: EntradaPlantaComponent, data: { titulo: 'Editar Entrada' } },
        { path: 'planta/salidas', canActivate: [ PlantaGuard ], component: SalidasPlantaComponent, data: { titulo: 'Salidas' } },
        { path: 'planta/salida/nueva', canActivate: [ PlantaGuard ], component: SalidaPlantaComponent, data: { titulo: 'Crear Salida' } },
        { path: 'planta/salida/editar/:id', canActivate: [ PlantaGuard ], component: SalidaPlantaComponent, data: { titulo: 'Editar Salida' } },
        { path: 'planta/informes', canActivate: [ PlantaGuard ], component: InformesPlantaComponent, data: { titulo: 'Informes' } },
        // PRODUCCION

        // PERSONAL
        { path: 'rrhh/convenios', canActivate: [ PersonalGuard ], component: ConveniosComponent, data: { titulo: 'Convenios' } },
        { path: 'rrhh/convenios/nuevo', canActivate: [ PersonalGuard ], component: ConvenioComponent, data: { titulo: 'Crear Convenio' } },
        { path: 'rrhh/convenios/:id/:accion', canActivate: [ PersonalGuard ], component: ConvenioComponent, data: { titulo: '# Convenio' } },
        { path: 'rrhh/calendario', canActivate: [ PersonalGuard ], component: CalendarioComponent, data: { titulo: 'Calendario' } },
        { path: 'rrhh/calendario/nuevo', canActivate: [ PersonalGuard ], component: CalendarioFormComponent, data: { titulo: 'Crear Calendario' } },
        { path: 'rrhh/calendario/:id/:accion', canActivate: [ PersonalGuard ], component: CalendarioFormComponent, data: { titulo: '# Calendario' } },
        { path: 'rrhh/calendarios/lista', canActivate: [ PersonalGuard ], component: ListaCalendariosComponent, data: { titulo: 'Lista Calendarios' } },
        { path: 'rrhh/trabajadores', canActivate: [ PersonalGuard ], component: PersonalComponent, data: { titulo: 'Trabajadores' } },
        { path: 'rrhh/trabajadores/nuevo', canActivate: [ PersonalGuard ], component: TrabajadorComponent, data: { titulo: 'Crear Trabajador' } },
        { path: 'rrhh/trabajadores/:id/:accion', canActivate: [ PersonalGuard ], component: TrabajadorComponent, data: { titulo: '# Trabajador' } },
        { path: 'rrhh/equipos', canActivate: [ PersonalGuard ], component: EquiposComponent, data: { titulo: 'Equipos' } },
        { path: 'rrhh/equipos/nuevo', canActivate: [ PersonalGuard ], component: EquipoComponent, data: { titulo: 'Crear Equipo' } },
        { path: 'rrhh/equipos/:id/:accion', canActivate: [ PersonalGuard ], component: EquipoComponent, data: { titulo: '# Equipo' } },
        { path: 'rrhh/partes-trabajo', canActivate: [ PersonalGuard ], component: PartesTrabajoComponent, data: { titulo: 'Partes Trabajo' } },
        { path: 'rrhh/partes-trabajo/diario/nuevo', canActivate: [ PersonalGuard ], component: ParteTrabajoComponent, data: { titulo: 'Crear Parte Trabajo' } },
        { path: 'rrhh/partes-trabajo/mensual/nuevo', canActivate: [ PersonalGuard ], component: ParteTrabajoMensualComponent, data: { titulo: 'Crear Parte Trabajo Mensual' } },
        { path: 'rrhh/partes-trabajo/:id/:accion', canActivate: [ PersonalGuard ], component: ParteTrabajoComponent, data: { titulo: '# Parte Trabajo' } },
        { path: 'rrhh/saldos-personal', canActivate: [ PersonalGuard ], component: SaldosComponent, data: { titulo: 'Saldos Personal' } },
        { path: 'rrhh/saldos-personal/:id/:accion', canActivate: [ PersonalGuard ], component: SaldoTrabajadorComponent, data: { titulo: 'Editar Saldo Trabajador' } },
        { path: 'rrhh/saldos-personal/vacaciones', canActivate: [ PersonalGuard ], component: SaldoVacacionesComponent, data: { titulo: 'Saldos Vacaciones' } },
        { path: 'rrhh/saldos-personal/cierre-horas', canActivate: [ PersonalGuard ], component: CierreHorasComponent, data: { titulo: 'Cierre Horas' } },
        { path: 'rrhh/vacaciones', canActivate: [ PersonalGuard ], component: VacacionesComponent, data: { titulo: 'Vacaciones' } },
        // LABORATORIO
        { path: 'laboratorio/formulas', canActivate: [ LabotarioGuard ], component: FormulasComponent, data: { titulo: 'Formulas' } },
        { path: 'laboratorio/formula/nueva', canActivate: [ LabotarioGuard ], component: FormulaComponent, data: { titulo: 'Crear Formula' } },
        { path: 'laboratorio/formula/editar/:id', canActivate: [ LabotarioGuard ], component: FormulaComponent, data: { titulo: 'Editar Formula' } },
        { path: 'laboratorio/sellosce', canActivate: [ LabotarioGuard ], component: SellosceComponent, data: { titulo: 'Sellos Marcado CE' } },
        { path: 'laboratorio/selloce/nuevo', canActivate: [ LabotarioGuard ], component: SelloceComponent, data: { titulo: 'Crear Sello' } },
        { path: 'laboratorio/selloce/editar/:id', canActivate: [ LabotarioGuard ], component: SelloceComponent, data: { titulo: 'Editar Sello' } },
        // MAQUINARIA
        { path: 'maquinaria/listado', canActivate: [ AdministracionGuard ], component: MaquinariaComponent, data: { titulo: 'Maquinaria' } },
        { path: 'maquinaria/maquina/nueva', canActivate: [ AdministracionGuard ], component: MaquinaComponent, data: { titulo: 'Crear Maquina' } },
        { path: 'maquinaria/maquina/:id/:accion', canActivate: [ AdministracionGuard ], component: MaquinaComponent, data: { titulo: '# Maquina' } },
        { path: 'maquinaria/averias', canActivate: [ TallerGuard ], component: AveriasComponent, data: { titulo: 'Averías' } },
        { path: 'maquinaria/averia/nueva', canActivate: [ TallerGuard ], component: AveriaComponent, data: { titulo: 'Crear Avería' } },
        { path: 'maquinaria/averia/:id/:accion', canActivate: [ TallerGuard ], component: AveriaComponent, data: { titulo: '# Avería' } },
        { path: 'maquinaria/mantenimientos', canActivate: [ TallerGuard ], component: MantenimientosComponent, data: { titulo: 'Mantenimientos' } },
        { path: 'maquinaria/mantenimiento/:accion', canActivate: [ TallerGuard ], component: MantenimientoComponent, data: { titulo: 'Crear Mantenimiento' } },
        { path: 'maquinaria/mantenimiento/:id/:accion', canActivate: [ TallerGuard ], component: MantenimientoComponent, data: { titulo: '# Mantenimiento' } },
        { path: 'maquinaria/portes-gondola', canActivate: [ TallerGuard ], component: PortesGondolaComponent, data: { titulo: 'Portes góndola' } },
        { path: 'maquinaria/porte-gondola/:accion', canActivate: [ TallerGuard ], component: PorteGondolaComponent, data: { titulo: 'Crear porte' } },
        { path: 'maquinaria/porte-gondola/:id/:accion', canActivate: [ TallerGuard ], component: PorteGondolaComponent, data: { titulo: '# porte' } },
        { path: 'maquinaria/informes', canActivate: [ TallerGuard ], component: InformesTallerComponent, data: { titulo: 'Informes' } },
        // USUARIO
        { path: 'usuario/perfil', canActivate: [], component: PerfilComponent, data: { titulo: 'Perfil' } },
        // ADMIN
        { path: 'admin/usuarios', canActivate: [ AdminGuard ], component: UsuariosComponent, data: { titulo: 'Mantenimiento de usuarios' } },
        { path: 'admin/usuario/nuevo', canActivate: [ AdminGuard ], component: UsuarioComponent, data: { titulo: 'Crear usuario' } },
        { path: 'admin/usuario/editar/:id', canActivate: [ AdminGuard ], component: UsuarioComponent, data: { titulo: 'Editar usuario' } },
        { path: 'admin/estadisticas', canActivate: [ AdminGuard ], component: EstadisticasComponent, data: { titulo: 'Estadisticas' } },

        { path: '', redirectTo: 'inicio', pathMatch: 'full'}
    ]
  }

];


export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
