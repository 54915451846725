import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { getDoc } from '@angular/fire/firestore';
import { Observable, firstValueFrom, debounceTime, distinctUntilChanged, map, filter, first } from 'rxjs';

import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { UsuarioService, SalidasService, FormulariosService, ObrasService, JspdfService } from '../../../../services/service.index';
import { CamionModalComponent } from 'src/app/shared/modals/camion/camion-modal.component';

import Swal from 'sweetalert2';
import { SortType } from '@swimlane/ngx-datatable';

type Planta = {id: string, codigo: string, nombre: string};
type Obra = {id: string, codigo: string, nombre: string};
type Cliente = {id: string, nombre: string};
type Formula = {id: string, codigo: string, nombre: string};
type Articulo = {id: string, nombre: string, nombreProveedor: string};
type Matricula = {id: string, matricula: string};

@Component({
  selector: 'app-salida',
  templateUrl: './salida.component.html',
  styles: [
  ]
})
export class SalidaPlantaComponent implements OnInit, OnDestroy {

  salidaID: string;
  titulo: string = 'Nueva salida';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  usuarioRole: string = this.usuarioService.usuario.role;
  verDatosAdicionales: boolean = false;
  tieneTimeline: boolean = false;
  timeline: any = [];
  tieneVersiones: boolean = false;
  versiones: any = [];
  registrosPagina: number;
  sorts: any = [];
  sortType: SortType = SortType.multi;
  offset: number = 0;
  txtBtnImprimir = 'Imprimir';
  disableImprimir: boolean = false;
  actualizarNumAlbaran: boolean = true;
  txtFormuArti: string = 'Formula*';
  formuArti: string = 'pt';
  bascula: boolean = false;
  verNeto: boolean = false;
  verTemperatura: boolean = false;
  unidad: string;
  codigoUnidad: string;
  editarCamion: boolean = false;
  obras: any = [];
  clientes: any = [];
  obra: any = [];
  plantas: any = [];
  formulas: any = [];
  articulos: any = [];
  matriculas: any = [];

  private subs = new SubSink();

  public formSubmitted = false;

  hoy: Date = new Date( Date.now() );
  ahora: Date = new Date( Date.now() );

  public salidaForm = this.fb.group({

      planta: new FormControl( { value: null, disabled: true }, Validators.required ),

      obra: [ null, Validators.required ],
      cliente: new FormControl( { value: null, disabled: true }, Validators.required ),

      fecha: [ formatDate( this.hoy, 'yyyy-MM-dd', 'en' ), Validators.required ],
      hora: [ formatDate( this.ahora, 'HH:mm', 'en' ), Validators.required ],
      prefalbaran: new FormControl( {value: null, disabled: true} ),
      albaran: new FormControl( {value: null, disabled: true},  Validators.required ),

      tipo: new FormControl( {value: '', disabled: true},  Validators.required ),
      formula: new FormControl( {value: null, disabled: true},  Validators.required ),
      articulo: new FormControl( {value: null, disabled: true},  Validators.required ),

      matricula: [null],
      remolque: new FormControl( {value: null, disabled: true} ),
      enviado: [true],

      transportista: new FormControl( {value: null, disabled: true} ),
      agencia: new FormControl( {value: null, disabled: true} ),

      chofer: new FormControl( {value: null, disabled: true} ),
      dnichofer: new FormControl( {value: null, disabled: true} ),
      telefonochofer: new FormControl( {value: null, disabled: true} ),

      bruto: [null],
      tara: [null],
      neto: [null, Validators.required ],
      unidad: [null],

      temperatura: [null, Validators.required ],

      comentarios: [null],
      notas: [null]

  });

  plantaformat = (planta: Planta) => planta.codigo + ' - ' + planta.nombre;

  plantasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.plantas.filter( ( planta: any ) => planta?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                planta?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  obraformat = (obra: Obra) => obra.codigo + ' - ' + obra.nombre;

  obrasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.obras.filter( ( obra: any ) => obra?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                              obra?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  clieformat = (cliente: Cliente) => cliente.nombre;

  cliesearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.clientes.filter( ( cliente: any ) => cliente?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  formformat = (formula: Formula) => formula.codigo + ' - ' + formula.nombre;

  formsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.formulas.filter( ( formula: any ) => formula?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                 formula?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  artiformat = (articulo: Articulo) => articulo?.nombreProveedor ? articulo.nombre + ' (' + articulo?.nombreProveedor + ')' : articulo.nombre;

  artisearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.articulos.filter( ( articulo: any ) => articulo?.nombre?.toLowerCase().includes( term.toLowerCase() ) ||
                                                    articulo?.nombreProveedor?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  resiformat = (articulo: Articulo) => articulo.nombre;

  resisearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.articulos.filter( ( articulo: any ) => articulo.nombre.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  matformat = (matricula: Matricula) => matricula.matricula;

  matsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.matriculas.filter( ( matricula: any ) => new RegExp(term, 'mi').test(matricula.matricula)).slice(0, 10) )
  )

  constructor( private salidasService: SalidasService,
               private jspfd: JspdfService,
               private usuarioService: UsuarioService,
               private formulariosService: FormulariosService,
               private obraService: ObrasService,
               private modalService: NgbModal,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.salidaID = params.id;
          this.cargarSalida( params.id );
        } else {
          this.cargando = false;
          if ( this.salidasService.fechaListadoDesde !== formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' ) ) {
            this.salidaForm.get('fecha').setValue( this.salidasService.fechaListadoDesde );
            this.salidaForm.get('hora').setValue( null );
          }
        }

        this.subs.sink = this.formulariosService.selectObrasTipo('planta', true)
          .subscribe( res => {
            this.plantas = res;
          });

        if (this.usuarioService.usuario.obra === '') {
          this.salidaForm.get('planta').enable();
          this.salidaForm.get('planta').setValue(null);
        } else {
          this.subs.sink = this.obraService.obtenerObraPorId( this.usuarioService.usuario.obra )
            .subscribe( (obra: any) => {
              this.obra = {
                id: obra.id,
                codigo: obra.codigo,
                nombre: obra.nombre
              };
              this.salidaForm.get('planta').disable();
              this.salidaForm.get('planta').setValue( this.obra );
              this.salidaForm.get('tipo').setValue('pt');
              this.salidaForm.get('tipo').enable();
            });
        }

        if ( this.usuarioRole === 'admin' || this.usuarioRole === 'oficina' ) {
          this.verDatosAdicionales = true;
        }

        this.subs.sink = this.formulariosService.selectObrasClientes()
          .subscribe( async (obrasBD) => {

            if ( obrasBD.length > 0 ) {

              await Promise.all( obrasBD.map( async ( obra: any ) => {
                obra.cliente = ( obra.cliente ) ? (await getDoc( obra.cliente )).data() : null;
              }));
              this.obras = obrasBD;
            }
          });

        this.subs.sink = this.formulariosService.selectMatriculas()
          .subscribe( res => {
            this.matriculas = res;
          });

      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarSalida( id: string ): void {

    this.salidaID = id;
    this.actualizarNumAlbaran = false;

    this.subs.sink = this.salidasService.obtenerSalidaPorId( id )
      .pipe(
        first()
      )
      .subscribe( async (salida: any) => {

        if ( !salida ) {
          Swal.fire('Error', 'Error al cargar los datos de la salida', 'error');
          return this.router.navigateByUrl('/planta/salidas');
        }

        if ( this.usuarioRole === 'admin' || this.usuarioRole === 'oficina' ) {
          const versionesSnap = await this.salidasService.obtenerVersionesSalida( id );
          await Promise.all(
            versionesSnap.docs.map( async (version: any) => {
              const id = version.id;
              version = version.data();
              version.parentId = version.id;
              version.id = id;
              version.articulo = ( version.articulo ) ? (await getDoc(version['articulo'])).data() : null;
              version.cliente = ( version.cliente ) ? (await getDoc(version['cliente'])).data() : null;
              version.formula = ( version.formula ) ? (await getDoc(version['formula'])).data() : null;
              version.matricula = ( version.matricula ) ? (await getDoc(version['matricula'])).data() : null;
              version.obra = ( version.obra ) ? (await getDoc(version['obra'])).data() : null;
              version.planta = ( version.planta ) ? (await getDoc(version['planta'])).data() : null;
              version.unidad = ( version.unidad ) ? (await getDoc(version['unidad'])).data() : null;
              version.controls = Object.entries( version.controls )
                .filter( control => control[1] )
                .map( ([key, value]) => {
                  switch (key) {
                    case 'articulo':
                      value = version.articulo.nombre;
                      break;
                    case 'cliente':
                      value = version.cliente.nombre;
                      break;
                    case 'formula':
                      value = version.formula?.codigo ? version.formula.codigo + ' - ' + version.formula.nombre : version.formula.nombre;
                      break;
                    case 'matricula':
                      value = version.matricula.matricula;
                      break
                    case 'obra':
                      value = version.obra?.codigo ? version.obra.codigo + ' - ' + version.obra.nombre : version.obra.nombre;
                      break
                    case 'planta':
                      value = version.planta?.codigo ? version.planta.codigo + ' - ' + version.planta.nombre : version.planta.nombre;
                      break;
                    case 'unidad':
                      value = version.unidad.nombre;
                      break;
                    default:
                      value = version[key] ?? '';
                      break;
                  }
                  return { campo: key, valor: value }
                });
              this.versiones.push( version );
            })
          );
          this.tieneVersiones = this.versiones.length > 0;
          this.timeline = await firstValueFrom( this.salidasService.obtenerTimelineSalida( id ) );
          this.tieneTimeline = this.timeline.length > 1;
        }
        const fechaBD = formatDate(  salida.fecha.toDate(), 'yyyy-MM-dd', 'en' );
        const horaBD = formatDate( salida.fecha.toDate(), 'HH:mm', 'en' );
        salida.planta = ( salida.planta ) ? (await getDoc( salida.planta )).data() : null;
        salida.obra = ( salida.obra ) ? (await getDoc( salida.obra )).data() : null;
        salida.cliente = ( salida.cliente ) ? (await getDoc( salida.cliente )).data() : null;
        let FormArti: any;
        if ( salida.tipo === 'pt') {
          FormArti = ( salida.formula ) ? (await getDoc( salida.formula )).data() : null;
          FormArti.unidades = ( FormArti.unidades ) ? (await getDoc( FormArti.unidades )).data() : null;
          this.cargarFormulas( salida.planta.id );
          this.cargarUnidades( FormArti.unidades.codigo );
        } else {
          FormArti = ( salida.articulo ) ? (await getDoc( salida.articulo )).data() : null;
          FormArti.unidades = ( FormArti.unidades ) ? (await getDoc( FormArti.unidades )).data() : null;
          this.cargarArticulos( salida.tipo );
          this.cargarUnidades( FormArti.unidades.codigo );
        }
        salida.unidad = ( salida.unidad ) ? (await getDoc( salida.unidad )).data() : null;
        salida.matricula = ( salida.matricula ) ? (await getDoc( salida.matricula )).data() : null;
        this.salidaForm.get('remolque').enable();
        this.salidaForm.get('transportista').enable();
        this.salidaForm.get('agencia').enable();
        this.salidaForm.get('chofer').enable();
        this.salidaForm.get('dnichofer').enable();
        this.salidaForm.get('telefonochofer').enable();
        this.salidaForm.get('albaran').enable();
        this.salidaForm.get('planta').setValue( salida.planta );
        this.salidaForm.get('tipo').setValue( salida.tipo );
        this.salidaForm.get('tipo').enable();
        this.verificarTipo( null, false );
        this.salidaForm.reset({
          planta: salida.planta,
          obra: salida.obra,
          cliente: salida.cliente,
          fecha: fechaBD,
          hora: horaBD,
          prefalbaran: salida.prefalbaran,
          albaran: salida.albaran,
          tipo: salida.tipo,
          formula: FormArti,
          articulo: FormArti,
          matricula: salida.matricula,
          remolque: salida.remolque,
          enviado: salida.enviado,
          transportista: salida.transportista,
          agencia: salida.agencia,
          chofer: salida.chofer,
          dnichofer: salida.dnichofer,
          telefonochofer: salida.telefonochofer,
          bruto: salida.bruto,
          tara: salida.tara,
          neto: salida.neto,
          unidad: salida.unidad.id,
          temperatura: salida.temperatura,
          comentarios: salida.comentarios,
          notas: salida.notas
        });
        this.cargando = false;
      });

  }

  imprimirSalida(): void {

    this.formSubmitted = true;
    this.txtBtnImprimir = 'Imprimiendo...';
    this.disableImprimir = true;

    if ( this.salidaForm.invalid ) {
      this.txtBtnImprimir = 'Imprimir';
      this.disableImprimir = false;
      return;
    }

    if ( this.salidaForm.pristine ) {
      this.jspfd.imprimirAlbaranPlanta( this.salidaID );
      this.cancelarSalida();
      return;
    }

    this.salidasService.imprimirSalida( this.salidaID, this.salidaForm.getRawValue(), this.salidaForm.controls )
      .then( (res) => {
          this.tomarMuestra(res);
      })
      .catch( (err) => {
        console.log(err);
        this.txtBtnImprimir = 'Imprimir';
        this.disableImprimir = false;
      });

  }

  crearSalida(): void {

    this.formSubmitted = true;

    if ( this.salidaForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.salidasService.crearSalida( this.salidaForm.getRawValue() )
      .then( (res) => {
        this.tomarMuestra(res);
      })
      .catch( err => {
        Swal.fire('Error', `Error: ${err}`, 'error');
        this.cargando = false;
        // TODO: Crear un log de errores
      });

  }

  actualizarSalida(): void {

    this.formSubmitted = true;

    if ( this.salidaForm.invalid ) {
      return;
    }

    if ( this.salidaForm.pristine ) {
      this.cancelarSalida();
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.salidasService.actualizarSalida( this.salidaID, this.salidaForm.getRawValue(), this.salidaForm.controls )
      .then( (res) => {
        this.tomarMuestra(res);
      })
      .catch( err => {
        Swal.fire('Error', `Error: ${err}`, 'error');
        this.cargando = false;
        // TODO: Crear un log de errores
      });

  }

  tomarMuestra( data: any ) {

    // TODO: Activar toma de muestras
    this.router.navigate(['/planta/salidas']);
    return;

    // if (data?.proximosMuestreos?.length) {
    //   // proximosMuestreos.forEach(muestreo => {
    //   //   console.log(muestreo);
    //   // });
    //   Swal.fire({
    //     title: 'Muestreo pendiente',
    //     text: 'Hay que tomar un muestreo de la formula',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Tomar muestra',
    //     cancelButtonText: 'Ignorar'
    //   })
    //   .then( (result) => {
    //     if (result.value) {
    //       this.salidasService.crearMuestreo( this.salidaForm.getRawValue(), data )
    //         .then( () => this.router.navigate(['/planta/salidas']) );
    //     } else {
    //       this.router.navigate(['/planta/salidas']);
    //     }
    //   });
    // } else {
    //   this.router.navigate(['/planta/salidas']);
    // }

  }

  cancelarSalida(): void {

    if ( this.salidaForm.pristine ) {
      this.router.navigate(['/planta/salidas']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {
        this.router.navigate(['/planta/salidas']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.salidaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.salidaForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  cargarFormulas( plantaId: string ): void {

    if ( plantaId ) {

      this.salidaForm.get('formula').setValue( null );
      this.salidaForm.get('formula').enable();
      this.subs.sink = this.formulariosService.selectFormulasPlanta( plantaId )
        .pipe(
          first()
        )
        .subscribe( async (formulas) => {

          if ( formulas.length > 0 ) {
            await Promise.all(
              formulas.map( async ( formula: any ) => {
                formula.ud = ( formula.ud ) ? (await getDoc( formula.ud )).data() : null;
              })

            );
            this.formulas = formulas;
          }

        });

    }

  }

  cargarArticulos( tipo: string ): void {

    this.salidaForm.get('articulo').setValue( null );
    this.salidaForm.get('articulo').enable();
    this.subs.sink = this.formulariosService.selectArticulos( tipo )
      .subscribe( async articulos => {
        this.articulos = [];
        if ( articulos.length > 0 ) {
          await Promise.all(
            articulos.map( async ( articulo: any ) => {
              articulo.ud = ( articulo.ud ) ? (await getDoc( articulo.ud )).data() : null;
            })
          );
          this.articulos = articulos;
        }
      });

  }

  cargarNumAlbaran( id: string ): void {

    if ( id !== null && this.actualizarNumAlbaran ) {

      let prefijo = '';
      const tipo = this.salidaForm.get('tipo').value;
      const anio = this.salidaForm.get('fecha').value.slice(2, 4);
      switch (tipo) {
        case 'pb':
          prefijo = 'SM' + anio;
          break;
        case 'pt':
          prefijo = 'G' + anio;
          break;
        case 're':
          prefijo = 'R' + anio;
          break;
      }

      // TODO: enviar tambien el prefijo para buscar por planta y prefijo
      this.subs.sink = this.formulariosService.ultimoAlbaranSalida( id, prefijo )
        .subscribe( res => {
          let proximoNumero = 1;
          if ( res.length > 0 ) {
            proximoNumero = res[0].albaran + 1;
          }
          this.salidaForm.get('prefalbaran').setValue( prefijo );
          this.salidaForm.get('albaran').setValue( proximoNumero );
          this.salidaForm.get('albaran').enable();
        });

    }

  }

  plantaSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    this.salidaForm.get('tipo').setValue('pt');
    this.salidaForm.get('tipo').enable();
    this.verificarTipo( event.item?.id );

  }

  verificarPlanta(): void {

    if ( !this.salidaForm.get('planta').value ) {

      this.salidaForm.get('prefalbaran').setValue(null);
      this.salidaForm.get('albaran').setValue(null);
      this.salidaForm.get('albaran').disable();
      this.salidaForm.get('tipo').setValue('');
      this.salidaForm.get('tipo').disable();
      this.salidaForm.get('formula').setValue(null);
      this.salidaForm.get('formula').disable();
      this.salidaForm.get('articulo').setValue(null);
      this.salidaForm.get('articulo').disable();

    }

  }

  obraSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    this.salidaForm.get('cliente').setValue({
      id: event.item.cliente.id,
      nombre: event.item.cliente.nombre
    });

  }

  verificarObra(): void {

    if ( !this.salidaForm.get('obra').value ) {

      this.salidaForm.get('cliente').setValue( null );

    }

  }

  verificarFecha(): void {

    if ( this.salidaForm.get('fecha').value ) {
      const planta = this.salidaForm.get('planta').value;
      if ( planta ) {
        this.cargarNumAlbaran( planta.id );
      }
    }

  }

  // Mostrar modal para crear o editar camion
  camionModal(): void {

    const modalRef = this.modalService.open(CamionModalComponent, { size: 'xl' });

    modalRef.componentInstance.camionId = this.salidaForm.get('matricula').value?.id;

    modalRef.result.then( camionData => {
      if ( camionData ) {
        const camionCreado = {
          id: camionData.id,
          matricula: camionData.matricula,
        };
        this.salidaForm.controls.matricula.setValue(camionCreado);
        this.cargarCamion(camionData);
      }
    })
    .catch( err => {
      if (err) {
        console.error('error ', err);
      }
    });

  }

  // Cargar informacion del camion en el formulario
  cargarCamion( data: any ): void {

    let camionData: any;

    if ( data.item ) {
      camionData = data.item;
    } else {
      camionData = data;
    }
    this.salidaForm.get('remolque').setValue( camionData?.remolque || null );
    this.salidaForm.get('transportista').setValue( camionData?.transportista || null );
    this.salidaForm.get('agencia').setValue( camionData?.agencia || null );
    this.salidaForm.get('chofer').setValue( camionData?.chofer || null );
    this.salidaForm.get('dnichofer').setValue( camionData?.dnichofer || camionData?.dni || null );
    this.salidaForm.get('telefonochofer').setValue( camionData?.telefonochofer || camionData?.telefono || null );
    this.salidaForm.get('tara').setValue( camionData?.tara ?? null );
    this.salidaForm.get('remolque').enable();
    this.salidaForm.get('transportista').enable();
    this.salidaForm.get('agencia').enable();
    this.salidaForm.get('chofer').enable();
    this.salidaForm.get('dnichofer').enable();
    this.salidaForm.get('telefonochofer').enable();
    this.calcularPesos('t');
    this.editarCamion = true;

  }

  verificarMatricula(): void {

    if ( !this.salidaForm.get('matricula').value ) {

      this.salidaForm.get('remolque').setValue( null );
      this.salidaForm.get('transportista').setValue( null );
      this.salidaForm.get('agencia').setValue( null );
      this.salidaForm.get('chofer').setValue( null );
      this.salidaForm.get('dnichofer').setValue( null );
      this.salidaForm.get('telefonochofer').setValue( null );
      this.salidaForm.get('tara').setValue( null );
      this.salidaForm.get('remolque').disable();
      this.salidaForm.get('transportista').disable();
      this.salidaForm.get('agencia').disable();
      this.salidaForm.get('chofer').disable();
      this.salidaForm.get('dnichofer').disable();
      this.salidaForm.get('telefonochofer').disable();
      this.calcularPesos('t');
      this.editarCamion = false;

    }

  }

  verificarTipo( plantaId?: string, cambio: boolean = true ): void {

    if ( !plantaId ) {
      plantaId = this.salidaForm.get('planta').value?.id;
    }

    if ( cambio && (this.salidaID === null) ) {
      this.actualizarNumAlbaran = true;
    }

    if ( !this.salidaForm.get('tipo').pristine ) {
      this.bascula = false;
      this.verNeto = false;
    }

    if ( this.salidaForm.get('tipo').value !== '' && plantaId ) {

      const tipoSalida = this.salidaForm.get('tipo').value;
      this.salidaForm.get('temperatura').setValue(null);
      this.cargarNumAlbaran( plantaId );
      switch (tipoSalida) {
        case 'pt':
          this.txtFormuArti = 'Formula*';
          this.formuArti = 'pt';
          this.verTemperatura = true;
          this.cargarFormulas( plantaId );
          this.salidaForm.get('articulo').setValue(null);
          this.salidaForm.get('articulo').disable();
          this.salidaForm.get('articulo').clearValidators();
          this.salidaForm.get('articulo').updateValueAndValidity();
          this.salidaForm.get('formula').setValidators(Validators.required);
          this.salidaForm.get('formula').updateValueAndValidity();
          this.salidaForm.get('temperatura').setValidators(Validators.required);
          this.salidaForm.get('temperatura').updateValueAndValidity();
          break;
        case 'pb':
          this.txtFormuArti = 'Articulo*';
          this.formuArti = 'pb';
          this.verTemperatura = false;
          this.cargarArticulos(tipoSalida);
          this.salidaForm.get('formula').setValue(null);
          this.salidaForm.get('formula').disable();
          this.salidaForm.get('formula').clearValidators();
          this.salidaForm.get('formula').updateValueAndValidity();
          this.salidaForm.get('articulo').setValidators(Validators.required);
          this.salidaForm.get('articulo').updateValueAndValidity();
          this.salidaForm.get('temperatura').clearValidators();
          this.salidaForm.get('temperatura').updateValueAndValidity();
          break;
        case 're':
          this.txtFormuArti = 'Articulo*';
          this.formuArti = 're';
          this.verTemperatura = false;
          this.cargarArticulos(tipoSalida);
          this.salidaForm.get('formula').setValue(null);
          this.salidaForm.get('formula').disable();
          this.salidaForm.get('formula').clearValidators();
          this.salidaForm.get('formula').updateValueAndValidity();
          this.salidaForm.get('articulo').setValidators(Validators.required);
          this.salidaForm.get('articulo').updateValueAndValidity();
          this.salidaForm.get('temperatura').clearValidators();
          this.salidaForm.get('temperatura').updateValueAndValidity();
          break;
      }

    } else {
      this.txtFormuArti = 'Formula*';
      this.formuArti = 'pt';
      this.verTemperatura = false;
      this.salidaForm.get('prefalbaran').setValue(null);
      this.salidaForm.get('albaran').setValue(null);
      this.salidaForm.get('albaran').disable();
      this.salidaForm.get('formula').setValue(null);
      this.salidaForm.get('formula').disable();
      this.salidaForm.get('articulo').setValue(null);
      this.salidaForm.get('articulo').disable();
      this.salidaForm.get('temperatura').setValue(null);
    }

  }

  cargarUnidades( codigo: string ): void {

    switch ( codigo ) {
      case 'tn':
        this.bascula = true;
        this.verNeto = true;
        this.unidad = 'Neto';
        this.codigoUnidad = 'TN';
        break;
      case 'kg':
        this.bascula = true;
        this.verNeto = true;
        this.unidad = 'Neto';
        this.codigoUnidad = 'Kg';
        break;
      case 'l':
        this.bascula = false;
        this.verNeto = true;
        this.unidad = 'Litros';
        this.codigoUnidad = 'L';
        break;
      case 'ud':
        this.bascula = false;
        this.verNeto = true;
        this.unidad = 'Unidades';
        this.codigoUnidad = 'Uds.';
        break;
    }

  }

  formulaSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    this.cargarUnidades( event.item.ud.codigo );
    this.salidaForm.get('unidad').setValue( event.item.ud.id );

  }

  verificarForm(): void {

    // TODO: Comprobar si ya tiene datos en bruto, tara y neto y mantener si hay datos
    // TODO: Revisar si es conveniente mantener los datos y ocultar solamente en caso de cambio de unidad cargarUnidades()
    if ( !this.salidaForm.get('formula').value && !this.salidaForm.get('formula').pristine ) {
      this.bascula = false;
      this.verNeto = false;
      this.unidad = '';
      this.salidaForm.get('bruto').setValue(null);
      this.salidaForm.get('tara').setValue(null);
      this.salidaForm.get('neto').setValue(null);
      this.salidaForm.get('unidad').setValue(null);

    }

  }

  articuloSeleccionado( event: NgbTypeaheadSelectItemEvent ): void {

    this.cargarUnidades( event.item.ud.codigo );
    this.salidaForm.get('unidad').setValue( event.item.ud.id );

  }

  verificarArti(): void {

    return;

    // TODO: Comprobar si ya tiene datos en bruto, tara y neto y mantener si hay datos
    // TODO: Revisar si es conveniente mantener los datos y ocultar solamente en caso de cambio de unidad cargarUnidades()
    if ( !this.salidaForm.get('articulo').value && !this.salidaForm.get('articulo').pristine ) {
      this.bascula = false;
      this.verNeto = false;
      this.unidad = '';
      this.salidaForm.get('bruto').setValue(null);
      this.salidaForm.get('tara').setValue(null);
      this.salidaForm.get('neto').setValue(null);
      this.salidaForm.get('unidad').setValue(null);

    }

  }

  // b = bruto, t = tara, n = neto
  // ba = bruto albaran, ta = tara albaran, na = neto albaran
  calcularPesos( campo: string ): void {

    if(this.bascula) {

      let bruto = 0;
      let tara = 0;
      let neto = 0;
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.salidaForm.get('bruto').value ) {
        bruto = this.salidaForm.get('bruto').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.salidaForm.get('brutoalbaran').value ) {
        bruto = this.salidaForm.get('brutoalbaran').value;
      }
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.salidaForm.get('tara').value ) {
        tara = this.salidaForm.get('tara').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.salidaForm.get('brutoalbaran').value ) {
        tara = this.salidaForm.get('taraalbaran').value;
      }
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.salidaForm.get('neto').value ) {
        neto = this.salidaForm.get('neto').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.salidaForm.get('netoalbaran').value ) {
        neto = this.salidaForm.get('netoalbaran').value;
      }
      if ( campo === 'b' || campo === 'ba' ) {
        neto = Math.round( ((bruto - tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 'b' ) {
          this.salidaForm.get('neto').setValue( neto );
        } else {
          this.salidaForm.get('netoalbaran').setValue( neto );
        }
      }
      if ( campo === 't' || campo === 'ta' ) {
        neto = Math.round( ((bruto - tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 't' ) {
          this.salidaForm.get('neto').setValue( neto );
        } else {
          this.salidaForm.get('netoalbaran').setValue( neto );
        }
      }
      if ( campo === 'n' || campo === 'na' ) {
        bruto = Math.round( ((neto + tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 'n' ) {
          this.salidaForm.get('bruto').setValue( bruto );
        } else {
          this.salidaForm.get('brutoalbaran').setValue( bruto );
        }
      }

    }

  }

  tabClic( tab: string ) {

    if (tab === 'versiones') {

    }

  }

  verVersionSalida( salida: any ): void {}

  imprimirVersionSalida( salida: any ): void {

  }

  onPage(event) {
    this.salidasService.setPage(event.offset);
  }

  onSort(event) {
    this.salidasService.setSorts(event.sorts);
  }

  getBtnClass({ row, column, value }): any {
    return ' botones-tabla';
  }


}

