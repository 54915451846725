<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Informes</h4>
            </div>
            <form class="form-horizontal" id="infoform" autocomplete="off" [formGroup]="informeForm" (ngSubmit)="crearInforme()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Tipo de informe*</label>

                                <div class="input-group">
                                    <select id="tipo" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('tipo') }" formControlName="tipo" (ngModelChange)="cambioTipo()">
                                        <option value="">Elige un tipo...</option>
                                        <option value="entradas">Entradas</option>
                                        <option value="salidas">Salidas</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        El tipo de informe es obligatoria. Elige uno.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.tipo !== ''">
                            <div class="form-group">
                                <label>{{ txtPlanta | titlecase }}*</label>

                                <div class="input-group">
                                    <select id="planta" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('planta') }" formControlName="planta" (ngModelChange)="cambioPlanta()">
                                        <option value="">Elige un {{ txtPlanta }}...</option>
                                        <option value="todo">Todas las plantas</option>
                                        <option *ngFor="let planta of plantas" [value]="planta.id">{{ planta.nombre | titlecase }}</option>
                                        <option *ngIf="informeForm.value.tipo === 'entradas'" value="obra">Obra</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        La planta es obligatoria. Elige una.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="informeForm.value.tipo !== ''">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Fechas*</label>

                                <div class="input-group">
                                    <select id="rango" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('rango') }" formControlName="rango" (ngModelChange)="cambioRango()">
                                        <option value="">Elige un rango...</option>
                                        <option value="todo">A origen</option>
                                        <option value="mes">Mes</option>
                                        <option value="dh">Desde-Hasta</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Las fechas son obligatorias. Elige una.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'mes'">
                            <div class="form-group">
                                <label>Mes</label>
                                <input type="month" class="form-control" formControlName="mes" (ngModelChange)="cambioMes()">
                            </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'dh'">
                            <div class="form-group">
                                <label>Desde</label>
                                <input type="date" class="form-control" formControlName="desde">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4" *ngIf="informeForm.value.rango === 'dh'">
                            <div class="form-group">
                                <label>Hasta</label>
                                <input type="date" class="form-control" formControlName="hasta">
                            </div>
                        </div>

                    </div>

                    <div class="form-row" *ngIf="informeForm.value.tipo === 'salidas' || informeForm.value.planta === 'obra'">
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Obra</label>
                                <input type="search" class="form-control" placeholder="Escribe el código o nombre..." formControlName="obra" [ngbTypeahead]="obrasearch" [inputFormatter]="obraformat" [resultFormatter]="obraformat" [editable]="false" (selectItem)="obraSeleccionada($event)"
                                    (ngModelChange)="verificarObra()">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Cliente</label>
                                <input type="search" class="form-control" placeholder="Cliente" formControlName="cliente" [ngbTypeahead]="cliesearch" [inputFormatter]="clieformat" [resultFormatter]="clieformat" [editable]="false" (selectItem)="clienteSeleccionado($event)" (change)="verificarCliente()">
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="informeForm.value.tipo === 'entradas'">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Proveedor</label>
                                <input type="search" class="form-control" placeholder="Escribe el nombre..." formControlName="proveedor" [ngbTypeahead]="provsearch" [inputFormatter]="provformat" [resultFormatter]="provformat" [editable]="false" (selectItem)="proveedorSeleccionado($event)"
                                    (ngModelChange)="verificarProv()">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Articulo</label>
                                <input type="search" class="form-control" placeholder="Escribe el nombre..." formControlName="articulo" [ngbTypeahead]="artsearch" [inputFormatter]="artformat" [resultFormatter]="artformat" [editable]="false">
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="informeForm.value.tipo === 'salidas'">
                        <div class="col-sm-12 col-lg-8">
                            <div class="form-group">
                                <label>Fórmula</label>
                                <input type="search" class="form-control" placeholder="Escribe el código o nombre..." formControlName="formula" [ngbTypeahead]="formsearch" [inputFormatter]="formformat" [resultFormatter]="formformat" [editable]="false">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Matrícula</label>
                                <input type="search" class="form-control" placeholder="Escribe la matrícula..." formControlName="matricula" [ngbTypeahead]="matsearch" [inputFormatter]="matformat" [resultFormatter]="matformat" [editable]="false">
                            </div>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-danger">

                            <p *ngIf="selectNoValido('tipo') || selectNoValido('planta') ||
                                      selectNoValido('rango')">
                                Hay errores en el formulario. Corrígelos para poder buscar.
                            </p>

                        </div>
                    </div>
                    <div class="form-group mb-0 text-right">
                        <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">Buscar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="restablecer()">Restablecer</button>
                    </div>
                </div>
            </form>

        </div>

        <div class="card">
            <div class="card-body" *ngIf="buscando">

                <div class="row animated fadeIn fast" *ngIf="cargando">

                    <div class="col-sm-12">

                        <div class="info alert-info text-center">
                            <strong>Buscando</strong>
                            <br>
                            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                            <br>
                            <span>Espere por favor</span>
                        </div>

                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'salidas'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <h4 class="card-title">Salidas ({{ totalRegistros }})</h4>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                                    <option [ngValue]="10">10</option>
                                                    <option [ngValue]="25">25</option>
                                                    <option [ngValue]="50">50</option>
                                                </select>
                                        <small> registros</small>
                                    </div>
                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='salidas'>
                                    <ngx-datatable-column name="Fecha" prop="fecha" [comparator]="fechaComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fecha'].toDate() | date: 'dd/MM/yyyy' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Hora" prop="fecha" [comparator]="fechaComparator" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fecha'].toDate() | date: 'HH:mm' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Albarán" prop="" [comparator]="albaranComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['prefalbaran'] | uppercase }}-{{ row['albaran'] }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Planta" prop="planta" [comparator]="obraComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipPlanta>
                                                <div class="d-flex flex-column">
                                                    <span>{{ row['planta']?.['nombre'] | uppercase }}</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipPlanta" tooltipClass="tooltip-custom" container="body">{{ row['planta']?.['codigo'] }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Obra" prop="obra" [comparator]="obraComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <ng-template #tipObra>
                                                <div class="d-flex flex-column">
                                                    <span>{{ row['obra']?.['nombre'] | uppercase }}</span>
                                                    <span>({{ row['cliente']?.['nombre'] | uppercase }})</span>
                                                </div>
                                            </ng-template>
                                            <span [ngbTooltip]="tipObra" tooltipClass="tooltip-custom" container="body">{{ row['obra']['codigo'] }}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Formula" prop="" [comparator]="formulaComparator" [flexGrow]="4">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div [ngSwitch]="row['tipo']">
                                                <ng-template #tipFormula>
                                                    <div class="d-flex flex-column">
                                                        <span>{{ row['formula']['codigo'] + ' - ' + row['formula']['nombre'] }}</span>
                                                    </div>
                                                </ng-template>
                                                <span *ngSwitchCase="'pt'" [ngbTooltip]="tipFormula" tooltipClass="tooltip-custom" container="body">{{ row['formula']['nombre'] }}</span>
                                                <span *ngSwitchCase="'pb'">{{ row['articulo']?.['nombre'] + (row['articulo']?.['nombreProveedor'] ? ' (' + row['articulo']?.['nombreProveedor'] + ')' : '') | uppercase }}</span>
                                                <span *ngSwitchCase="'re'">{{ row['articulo']?.['nombre'] }}</span>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Matricula" prop="matricula" [comparator]="matriculaComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['matricula']?.['nombre'] | uppercase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Neto" prop="neto" [comparator]="netoComparator" [flexGrow]="1.5">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['neto'] | number:'1.2-2' }} {{ row['unidad']?.['codigo'] | titlecase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="1.5">
                                        <ng-template let-value="value" ngx-datatable-header-template>
                                            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('s')"><i class="mdi mdi-file-excel"></i></button>
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                                <button type="button" class="btn btn-secondary" (click)="descargarSalida( value )"><i class="fas fa-download"></i></button>
                                                <button type="button" class="btn btn-secondary" (click)="imprimirSalida( value )"><i class="fas fa-print"></i></button>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)"
                                                (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row animated fadeIn fast" *ngIf="!cargando && informeForm.value.tipo === 'entradas'">

                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <h4 class="card-title">Entradas ({{ totalRegistros }})</h4>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-lg-12 col-sm-12">
                                        <small>Mostrar </small>
                                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina">
                                            <option [ngValue]="10">10</option>
                                            <option [ngValue]="25">25</option>
                                            <option [ngValue]="50">50</option>
                                        </select>
                                        <small> registros</small>
                                    </div>
                                </div>

                                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{emptyMessage: 'No hay registros que mostrar'}" [limit]="registrosPagina" [rows]='entradas'>
                                    <ngx-datatable-column name="Fecha" prop="fecha" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fecha'].toDate() | date: 'dd/MM/yyyy' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Hora" prop="fecha" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['fecha'].toDate() | date: 'HH:mm' }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Albarán" prop="numalbaran" [flexGrow]="1"></ngx-datatable-column>
                                    <ngx-datatable-column name="Obra" prop="obra" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['obra']['codigo'] | uppercase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Proveedor" prop="proveedor" [flexGrow]="2">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['proveedor']['nombre'] | uppercase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Articulo" prop="articulo" [flexGrow]="3">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['articulo']['nombre'] | uppercase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Matricula" prop="matricula.nombre" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['matricula']['nombre'] | uppercase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Neto" prop="netoalbaran" [flexGrow]="1">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{ row['netoalbaran'] | number:'1.2-2' }} {{ row['unidad'].codigo | titlecase }}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="0.5">
                                        <ng-template let-value="value" ngx-datatable-header-template>
                                            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel('e')"><i class="mdi mdi-file-excel"></i></button>
                                        </ng-template>
                                        <ng-template let-value="value" ngx-datatable-cell-template>
                                            <div class="btn-group" role="group">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-footer>
                                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                                            <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                                            </div>
                                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage
                                       " [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)" (change)="table.onFooterPage($event)">
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>

                                </ngx-datatable>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
