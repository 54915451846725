import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { delay, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { NgbModal, NgbActiveModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { UsuarioService, ObrasService, ClientesService, FormulariosService, FirebaseStorageService } from '../../../../services/service.index';

import Swal from 'sweetalert2';
import { getDoc } from '@angular/fire/firestore';

type Provincia = {id: string, nombre: string};
type Localidad = {id: string, nombre: string; codigo_postal: string};


@Component({
  selector: 'app-cliente-modal',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Crear cliente</h4>
    </div>
    <div class="modal-body">
      <div class="form-row">
        <div class="col-sm-12 col-lg-12">
            <div class="form-group">

              <label>Nombre*</label>
              <input name="nombre" class="form-control" placeholder="Nombre" autocomplete="off" [(ngModel)]="nombre">

            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(nombre)">Guardar</button>
      <button type="button" class="btn btn-danger" (click)="activeModal.dismiss(false)">Cancelar</button>

    </div>
  `
})
export class ClienteModalComponent {
  @Input() nombre;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-obra',
  templateUrl: './obra.component.html',
  styles: [
  ]
})
export class ObraComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Obra';
  modoLectura: boolean = false;
  cargando: boolean = true;
  cargandoDocs: boolean = true;
  obraID: string;
  datosPlanta: boolean = false;
  clientes: any = [];
  provincias: any = [];
  localidades: any = [];

  files: File[] = [];
  uploadPercent: number;
  documentos: any[];

  private subs = new SubSink();

  public formSubmitted = false;
  public formDocsSubmitted = false;

  public obraForm = this.fb.group({

    codigo: [ '', Validators.required ],
    nombre: [ '', Validators.required ],

    cliente: [ null, Validators.required ],
    nombrecliente: [ '' ],

    tipo: [ null, Validators.required ],
    situacion: [ null, Validators.required ],

    direccion: [ '' ],

    provincia: [ '' ],
    localidad: new FormControl( {value: null, disabled: true} ),
    cp: new FormControl( {value: null, disabled: true} ),

    lat: [ '' ],
    lng: [ '' ],

    numeroce: [ '' ],
    codigoformulas: [ '' ],

  });

  public docsForm = this.fb.group({
    nombredoc: [ '', Validators.required ],
    categoriadoc: [ null, Validators.required ],
  });

  provformat = (provincia: Provincia) => provincia.nombre;

  provsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.provincias.filter( provincia => new RegExp( term, 'mi' ).test( provincia.nombre )).slice(0, 10) )
  )

  locaformat = (localidad: Localidad) => localidad.nombre;

  locasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.nombre )).slice(0, 10) )
  )

  cpformat = (localidad: Localidad) => localidad.codigo_postal;

  cpsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.localidades.filter( localidad => new RegExp( term, 'mi' ).test( localidad.codigo_postal )).slice(0, 10) )
  )

  constructor( private usuarioService: UsuarioService,
               private obrasService: ObrasService,
               private clientesService: ClientesService,
               private formulariosService: FormulariosService,
               private firebaseStorageService: FirebaseStorageService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private location: Location,
               private title: Title,
               private modalService: NgbModal ) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Obra';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.obraForm.controls.tipo.enable();
            this.obraForm.controls.situacion.enable();
            this.obraID = params.id;
            this.cargarObra( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.obraForm.controls.tipo.disable();
            this.obraForm.controls.situacion.disable();
            this.cargarObra( params.id );
          } else {
            this.router.navigate(['/admin/obras']);
          }
        } else {
          this.cargando = false;
        }

      });

    this.subs.sink = this.formulariosService.selectData('clientes')
      .subscribe( res => {
        this.clientes = res;
      });

    this.subs.sink = this.formulariosService.selectProvincias()
      .subscribe( res => {
        this.provincias = res;
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarObra( id: string ) {

      this.obraID = id;

      this.subs.sink = this.obrasService.obtenerObraPorId( id )
        .pipe(
          delay(100)
        )
        .subscribe( async (obra: any) => {

          if ( !obra ) {
            Swal.fire('Error', 'Error al cargar los datos de la obra', 'error');
            return this.router.navigateByUrl('/admin/obras');
          }
          // Traer data del cliente para poder rellenar el nombre
          const clienteData: any = (await getDoc( obra.cliente )).data();
          obra.nombrecliente = clienteData.nombre;
          if ( !obra.localizacion ) {
            obra.localizacion = {
              latitude: null,
              longitude: null
            };
          }
          if ( obra.provincia ) {
            this.cargarLocalidades( obra.provincia.id );
          }
          if ( obra.localidad ) {
            this.obraForm.get('localidad').enable();
            this.obraForm.get('cp').enable();
          }
          this.obraForm.reset(
            {
              codigo: obra.codigo,
              nombre: obra.nombre,
              cliente: obra.cliente.id,
              nombrecliente: obra.nombrecliente ?? '',
              tipo: obra.tipo,
              situacion: obra.situacion,
              direccion: obra.direccion,
              provincia: obra.provincia,
              localidad: obra.localidad,
              cp: obra.localidad,
              lat: obra.localizacion.latitude,
              lng: obra.localizacion.longitude,
              numeroce: obra.numeroce,
              codigoformulas: obra.codigoformulas,
            });
          this.cambioTipo();
          this.cargando = false;
        });

  }

  crearObra() {

      this.formSubmitted = true;

      if ( this.obraForm.invalid ) {
        return;
      }

      this.obrasService.crearObra( this.obraForm.value )
        .then( () => this.router.navigateByUrl('/admin/obras') )
        .catch( err => {
          console.log( err );
          Swal.fire('Error', 'Error', 'error');
        });

  }

  actualizarObra() {

      this.formSubmitted = true;

      if ( this.obraForm.invalid ) {
        return;
      }

      if ( !this.obraForm.pristine ) {
        // Realizar el posteo
        this.obrasService.actualizarObra( this.obraID, this.obraForm.value )
          .then( () => this.router.navigateByUrl('/admin/obras') )
          .catch( err => {
            Swal.fire('Error', 'error', 'error' );
          });
      } else {
        this.cancelarObra();
      }

  }

  changeCliente( event: any ) {
    this.obraForm.get('nombrecliente').setValue( event?.nombre || '' );
  }

  // Cancelar el formulario y regresar al listado
  cancelarObra(): void {

      if ( !this.obraForm.pristine || !this.docsForm.pristine ) {
        Swal.fire({
          title: '¿Está seguro?',
          text: 'Hay cambios sin guardar, si cancela se perderán.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        })
        .then((result) => {

          if (result.value) {

            if (this.modoLectura) {
              this.location.back();
            } else {
              this.router.navigateByUrl('/admin/obras');
            }

          }

        });
      } else {

        if (this.modoLectura) {
          this.location.back();
        } else {
          this.router.navigateByUrl('/admin/obras');
        }

      }

  }

  campoNoValido( campo: string ): boolean {

      if ( this.obraForm.get(campo).invalid && this.formSubmitted ) {
        return true;
      } else {
        return false;
      }

  }

  campoDocsNoValido( campo: string ): boolean {

    if ( this.docsForm.get(campo).invalid && this.formDocsSubmitted ) {
      return true;
    } else {
      return false;
    }

}

  selectNoValido( campo: string ): boolean {

    if ( this.obraForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectDocsNoValido( campo: string ): boolean {

    if ( this.docsForm.get(campo).value === '' && this.formDocsSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  clienteModal(): void {

    const modalRef = this.modalService.open(ClienteModalComponent);

    modalRef.result.then( nombreForm => {
      if ( nombreForm ) {
        const nombre = { nombre: nombreForm };
        this.clientesService.crearCliente( nombre )
          .then( (idCliente) => {
            this.obraForm.controls.cliente.setValue(idCliente);
            this.obraForm.get('nombrecliente').setValue( nombreForm );
          });
      }
    })
    .catch( err => {
      if (err) {
        console.log('error ', err);
      }
    });

  }

  cambioTipo() {

    if ( this.obraForm.get('tipo').value === 'planta' ) {
      this.datosPlanta = true;
    } else {
      this.datosPlanta = false;
      this.obraForm.get('numeroce').setValue(null);
      this.obraForm.get('codigoformulas').setValue(null);
    }

  }

  cargarLocalidades( id: any ) {

    this.subs.sink = this.formulariosService.selectLocalidades( id )
      .subscribe( res => {
        this.localidades = res;
        this.obraForm.get('localidad').enable();
        this.obraForm.get('cp').enable();
      });

  }

  selecProvincia( event: NgbTypeaheadSelectItemEvent ) {

    this.cargarLocalidades( event.item.id );

  }

  verificarProvincia() {

    if ( !this.obraForm.get('provincia').value ) {
      this.obraForm.get('localidad').disable();
      this.obraForm.get('cp').disable();
      this.obraForm.get('localidad').setValue( null );
      this.obraForm.get('cp').setValue( null );
    }

  }

  selecLocalidad( event: NgbTypeaheadSelectItemEvent ) {

    this.obraForm.get('cp').setValue( event.item );

  }

  selecCP( event: NgbTypeaheadSelectItemEvent ) {

    this.obraForm.get('localidad').setValue( event.item );

  }

  //////////////////
  // Documentos
  //////////////////

  tabClic( tab ) {

    if (tab === 'documentos') {
      this.cargarDocs();
    }

  }

  onChangeDropzone(event) {
    this.files.push(...event.addedFiles);
  }

  onRemoveDoc(file) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  subirDoc( archivos: File[] ) {

    this.formDocsSubmitted = true;
    this.uploadPercent = null;

    if ( this.docsForm.invalid ) {
      return;
    }

    if (archivos.length > 0) {

      const codObra = this.obraForm.controls.codigo.value;
      if (this.obraID) {
        const existeDoc = this.firebaseStorageService.existeDocObra(archivos, codObra);
        existeDoc.then( res => {
          if (!res) {
            const task = this.firebaseStorageService.subirDocObra(archivos, this.obraID, codObra, this.docsForm.value);
            task.on('state_changed',
              (snapshot) => {
                this.uploadPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.limpiarDocs();
              }
            );
          } else {
            Swal.fire('Error', 'El documento ya existe.', 'error');
          }
        });
      } else {
        Swal.fire('Error', 'La obra no existe.', 'error');
      }
    }
  }

  cargarDocs(): void {

    this.cargandoDocs = true;
    this.firebaseStorageService.cargarDocsObra( this.obraID )
      .subscribe( docs => {
        this.documentos = docs;
        this.cargandoDocs = false;
      });
  }

  verDocumento( urlDoc: string ): void {

    this.firebaseStorageService.verDocumento(urlDoc)
      .then( url => window.open(url, '_blank') );

  }

  borrarDocumento( docID: string) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.firebaseStorageService.borrarDocObra(this.obraID, docID);
      }
    });

  }

  limpiarDocs() {
    this.files = [];
    this.uploadPercent = null;
    this.formDocsSubmitted = false;
    this.docsForm.reset();
    this.docsForm.controls.categoriadoc.setValue('');
  }

}
