import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, docData, getDoc, getDocs, limit, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { first, firstValueFrom, map } from 'rxjs';

import Swal from 'sweetalert2';

import { UsuarioService, EstadisticasService } from '../../services/service.index';
import { JspdfService } from '../shared/jspdf.service';

@Injectable({
  providedIn: 'root'
})
export class SalidasService {

  salidasCol = collection( this.firestore, 'salidas' );
  fechaListadoDesde: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  fechaListadoHasta: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  verHasta: boolean = false;
  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  registrosPagina: number = 10;

  constructor( private jspdfService: JspdfService,
               private usuarioService: UsuarioService,
               private estadisticasService: EstadisticasService,
               private router: Router,
               private firestore: Firestore ) { }


  imprimirSalida( salidaID: string, formData: any, controls: any ) {

    if ( salidaID ) {
      return this.actualizarSalida( salidaID, formData, controls, true );
    } else {
      return this.crearSalida( formData )
        .then( (res) => {
          this.jspdfService.crearAlbaranPlanta( formData );
          this.router.navigateByUrl('/planta/salidas');
          return res;
        });
    }

  }

  cargarSalidas( obra: string = '', desde: Date, hasta: Date ) {

    let consulta: Query<DocumentData>;
    if (obra != '') {
      const obraRef = doc( this.firestore, 'obras', obra );
      consulta = query( this.salidasCol, where('estado', '==', true), where('fecha', '>=', desde), where('fecha', '<', hasta), where('planta', '==', obraRef), orderBy('fecha', 'desc') );
    } else {
      consulta = query( this.salidasCol, where('estado', '==', true), where('fecha', '>=', desde), where('fecha', '<', hasta), orderBy('fecha', 'desc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerSalidaPorId( salidaID: string ) {

    const salidaRef = doc( this.firestore, 'salidas', salidaID );
    return docData( salidaRef, { idField: 'id' } );

  }

  obtenerVersionesSalida( salidaID: string ) {

    const versionesSalidaCol = collection( this.firestore, 'salidas', salidaID, 'versiones' );
    const consulta = query( versionesSalidaCol, orderBy('modificado.modificadoel', 'desc') );
    return getDocs( consulta );

  }

  obtenerTimelineSalida( salidaID: string ) {

    const salidaRef = doc( this.firestore, 'salidas', salidaID );
    return docData( salidaRef, { idField: 'id' } )
      .pipe(
        map( (salida: any) => {

          const timeline = [];

          timeline.push({
            id: 'creado',
            title: 'Salida creada',
            content: ``,
            meta: `Salida creada a las ${ formatDate( salida.meta.creado?.creadoel?.toDate(), 'HH:mm', 'es' ) } por ${salida.meta.creado?.creadopor?.nombre}`,
            date: formatDate( salida.fecha.toDate(), 'dd/MM/yyyy', 'es' ),
            hour: formatDate( salida.fecha.toDate(), 'HH:mm', 'es' ),
            clima: null,
            localizacion: null,
          });

          if ( salida?.situacion?.horaEntrada ) {
            timeline.push({
              id: 'llegada',
              title: 'Llegada a obra',
              content: ``,
              meta: `Llegada a obra a las ${formatDate( salida.situacion?.horaEntrada?.toDate(), 'HH:mm', 'es' )}`,
              date: formatDate( salida?.situacion?.horaEntrada?.toDate(), 'dd/MM/yyyy', 'es' ),
              hour: formatDate( salida?.situacion?.horaEntrada?.toDate(), 'HH:mm', 'es' ),
              clima: null,
              localizacion: null,
            });
          }

          if ( salida?.situacion?.horaDescargando ) {
            timeline.push({
              id: 'descargando',
              title: 'Inicio de descarga',
              content: ``,
              meta: `Inicio de la descarga a las ${formatDate( salida.situacion?.horaDescargando?.toDate(), 'HH:mm', 'es' )}`,
              date: formatDate( salida.situacion?.horaDescargando?.toDate(), 'dd/MM/yyyy', 'es' ),
              hour: formatDate( salida.situacion?.horaDescargando?.toDate(), 'HH:mm', 'es' ),
              clima: salida?.geoclima?.inicio_extendido?.clima ?? null,
              localizacion: salida?.geoclima?.inicio_extendido?.localizacion ? [ salida?.geoclima?.inicio_extendido?.localizacion?.longitude, salida?.geoclima?.inicio_extendido?.localizacion?.latitude ] : null,
            });
          }

          if ( salida?.situacion?.horaDescargado ) {
            timeline.push({
              id: 'descargado',
              title: 'Fin de descarga',
              content: ``,
              meta: `Fin de la descarga a las ${formatDate( salida.situacion?.horaDescargado?.toDate(), 'HH:mm', 'es' )}`,
              date: formatDate( salida.situacion?.horaDescargado?.toDate(), 'dd/MM/yyyy', 'es' ),
              hour: formatDate( salida.situacion?.horaDescargado?.toDate(), 'HH:mm', 'es' ),
              clima: salida?.geoclima?.fin_extendido?.clima ?? null,
              localizacion: salida?.geoclima?.fin_extendido?.localizacion ? [ salida?.geoclima?.fin_extendido?.localizacion?.longitude, salida?.geoclima?.fin_extendido?.localizacion?.latitude ] : null,
            });
          }

          if ( salida?.situacion?.horaSalida ) {
            timeline.push({
              id: 'salida',
              title: 'Salida de obra',
              content: ``,
              meta: `Salida de obra a las ${formatDate( salida.situacion?.horaSalida?.toDate(), 'HH:mm', 'es' )}`,
              date: formatDate( salida.situacion?.horaSalida?.toDate(), 'dd/MM/yyyy', 'es' ),
              hour: formatDate( salida.situacion?.horaSalida?.toDate(), 'HH:mm', 'es' ),
              clima: null,
              localizacion: null,
            });
          }

          return timeline;
        })
      );

  }

  crearSalida( formData: any ): Promise<any> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };

    formData.fecha = new Date(formData.fecha + ' ' + formData.hora);
    if ( !formData.fecha ) {
      return Promise.reject('No se ha seleccionado una fecha');
    }
    delete formData.hora;
    if ( formData?.planta?.id ) {
      formData.planta = doc( this.firestore, 'obras', formData.planta.id );
    } else {
      return Promise.reject('No se ha seleccionado una planta');
    }
    if ( formData?.obra?.id ) {
      formData.obra = doc( this.firestore, 'obras', formData.obra.id );
    } else {
      return Promise.reject('No se ha seleccionado una obra');
    }
    if ( formData?.cliente?.id ) {
      formData.cliente = doc( this.firestore, 'clientes', formData.cliente.id );
    } else {
      return Promise.reject('No se ha seleccionado un cliente');
    }
    const tipoSalida = formData.tipo;
    if ( tipoSalida === 'pt' ) {
      formData.formula = doc( this.firestore, 'formulas', formData.formula.id );
      formData.articulo = null;
    } else if ( tipoSalida === 'pb' ) {
      formData.articulo = doc( this.firestore, 'materiasprimas', formData.articulo.id );
      formData.formula = null;
    } else if ( tipoSalida === 're' ) {
      formData.articulo = doc( this.firestore, 'residuos', formData.articulo.id );
      formData.formula = null;
    } else {
      return Promise.reject('No se ha seleccionado un tipo de salida');
    }
    if ( formData?.unidad ) {
      formData.unidad = doc( this.firestore, 'unidades', formData.unidad );
    } else {
      return Promise.reject('No se ha seleccionado una unidad');
    }
    if ( formData?.matricula?.id ) {
      formData.matricula = doc( this.firestore, 'camiones', formData.matricula.id );
    } else {
      return Promise.reject('No se ha seleccionado un camión');
    }
    formData.estado = true;
    formData.situacion = {
      estado: 'enRuta',
      horaETA: null,
      horaDescargando: null,
      horaDescargado: null,
    };

    return addDoc( this.salidasCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );

        // Añadir a estadisticas
        return this.estadisticasService.estadisticas( 'salida', 'add', formData )
          .then( async (acumulado: number) => {
            // Si es del tipo pt comprobar proximo muestreo
            if ( tipoSalida === 'pt' && acumulado ) {
              const idFormula = formData.formula.id;
              let formulaData: any;
              formulaData = (formData.formula) ? (await getDoc( formData.formula )).data() : null;
              const codigoFormula = formulaData.codigo;
              return this.proximoMuestreo(acumulado, idFormula, codigoFormula)
                  .then( (proximosMuestreos) => {
                    //console.log(proximosMuestreos);
                    return {
                      salidaID: docRef.id,
                      proximosMuestreos
                    };
                  });
            } else {
              return null;
            }
          });
      });

  }

  async actualizarSalida( salidaID: string, formData: any, controls: any, imprimir: boolean = false ): Promise<any> {

    const dirtyControls: any = {};
    for (const [key, value] of Object.entries(controls)) {
      dirtyControls[key] = value['dirty'];
    }

    formData.fecha = new Date(formData.fecha + ' ' + formData.hora);
    if ( !formData.fecha ) {
      return Promise.reject('No se ha seleccionado una fecha');
    }
    delete formData.hora;
    if ( formData?.planta?.id ) {
      formData.planta = doc( this.firestore, 'obras', formData.planta.id );
    } else {
      return Promise.reject('No se ha seleccionado una planta');
    }
    if ( formData?.obra?.id ) {
      formData.obra = doc( this.firestore, 'obras', formData.obra.id );
    } else {
      return Promise.reject('No se ha seleccionado una obra');
    }
    if ( formData?.cliente?.id ) {
      formData.cliente = doc( this.firestore, 'clientes', formData.cliente.id );
    } else {
      return Promise.reject('No se ha seleccionado un cliente');
    }
    const tipoSalida = formData.tipo;
    let formulaData: any;
    let idFormula: string;
    let codigoFormula: string;
    if ( tipoSalida === 'pt' ) {
      formData.formula = doc( this.firestore, 'formulas', formData.formula.id );
      formulaData = (formData.formula) ? (await getDoc( formData.formula )).data() : null;
      idFormula = formulaData?.id ?? null;
      codigoFormula = formulaData?.codigo ?? null;
      formData.articulo = null;
    } else if ( tipoSalida === 'pb' ) {
      formData.articulo = doc( this.firestore, 'materiasprimas', formData.articulo.id );
      formData.formula = null;
    } else if ( tipoSalida === 're' ) {
      formData.articulo = doc( this.firestore, 'residuos', formData.articulo.id );
      formData.formula = null;
    } else {
      return Promise.reject('No se ha seleccionado un tipo de salida');
    }
    if ( formData?.unidad ) {
      formData.unidad = doc( this.firestore, 'unidades', formData.unidad );
    } else {
      return Promise.reject('No se ha seleccionado una unidad');
    }
    if ( formData?.matricula?.id ) {
      formData.matricula = doc( this.firestore, 'camiones', formData.matricula.id );
    } else {
      return Promise.reject('No se ha seleccionado un camión');
    }

    // TODO: si se modifica la formula hay que cambiar las estadisticas de ambas formulas
    // Calcular diferencia entre neto anterior y nuevo neto
    const salidaRef = doc( this.firestore, 'salidas', salidaID );
    const versionesSalidaCol = collection( this.firestore, 'salidas', salidaID, 'versiones' );
    return await firstValueFrom( docData( salidaRef, { idField: 'id' } ) )
      .then( async (ref) => {
        ref.controls = dirtyControls;
        ref.modificado = {
          modificadoel: new Date(),
          modificadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          }
        };
        await addDoc( versionesSalidaCol, ref );
        const tipoSalidaAnt = ref.tipo;
        const diferencia = formData.neto - ref.neto;
        let formulaDataAnt: any;
        if (tipoSalida === 'pt') {
          formulaDataAnt = (ref?.formula) ? (await getDoc( ref.formula )).data() : null;
        }
        return await updateDoc( salidaRef, {
            ...formData,
            'meta.modificado': {
              modificadoel: new Date(),
              modificadopor: {
                nombre: this.usuarioService.usuario.nombre,
                uid: this.usuarioService.usuario.uid
              }
            }
          })
          .then( async () => {
            if (imprimir) {
              this.jspdfService.crearAlbaranPlanta( formData );
            }
            let acumulado: number | void;
            // Cambio de tipo de salida
            if ( tipoSalida !== tipoSalidaAnt ) {
              this.estadisticasService.estadisticas( 'salida', 'del', ref );
              acumulado = await this.estadisticasService.estadisticas( 'salida', 'add', formData );
              if (tipoSalida === 'pt' && acumulado) {
                return await this.proximoMuestreo(acumulado, idFormula, codigoFormula)
                  .then( (proximosMuestreos) => {
                    return {
                      salidaID,
                      proximosMuestreos
                    };
                  });
              } else {
                return null;
              }
            // Sin cambio de tipo de salida
            } else {
              // Si es tipo pt
              if (tipoSalida === 'pt') {
                // Cambio de formula
                if (formulaData?.id !== formulaDataAnt?.id) {
                  this.estadisticasService.estadisticas( 'salida', 'del', ref );
                  acumulado = await this.estadisticasService.estadisticas( 'salida', 'add', formData );
                // Sin cambio de formula
                } else {
                  if (diferencia !== 0) {
                    acumulado = await this.estadisticasService.estadisticas( 'salida', 'mod', ref, diferencia );
                  }
                }
                if (acumulado) {
                  return await this.proximoMuestreo(acumulado, idFormula, codigoFormula)
                    .then( (proximosMuestreos) => {
                      return {
                        salidaID,
                        proximosMuestreos
                      };
                    });
                } else {
                  return null;
                }
              // Tipo diferente a pt
              } else {
                if (diferencia !== 0) {
                  this.estadisticasService.estadisticas( 'salida', 'mod', ref, diferencia );
                }
                return null;
              }
            }
          })
          .catch( err => {
            Swal.fire('Error', `Error: ${err}`, 'error' );
          });
      });

  }

  borrarSalida( salidaID: string ): void {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        const salidaDoc = doc( this.firestore, 'salidas', salidaID );
        docData( salidaDoc, { idField: 'id' } )
          .pipe( first() )
          .subscribe( (ref: any) => {
            deleteDoc( salidaDoc )
              .then( () => {
                this.estadisticasService.estadisticas( 'salida', 'del', ref );
              })
              .finally( () => {
                Swal.fire(
                  '¡Eliminado!',
                  'La salida ha sido eliminada.',
                  'success'
                );
              });
          });
      }

    });


  }

  async proximoMuestreo( acumulado: number, idFormula: string, codigoFormula: string ): Promise<any[]> {

    return this.estadisticasService.obtenerProximoMuestreo( idFormula, codigoFormula )
      .then( async muestreos => {
        const arrayMuestreos = [];
        const arrayProximosMuestreos: any[] = [];
        muestreos.map( async (muestreo) => {
          if (muestreo[0]?.id) {
            const muestreoRef = doc( this.firestore, 'gema_registroformulas', muestreo[0].id );
            arrayMuestreos.push( (await getDoc( muestreoRef )).data() );
          }
        })
        for ( const muestreo of arrayMuestreos ) {
          if ( (acumulado - muestreo.ultimomuestreoz) >= (muestreo.frecuenciaz - muestreo.avisarz) ) {
            let grupoEnsayo = await muestreo?.grupoensayosz?.get() ?? null;
            grupoEnsayo = grupoEnsayo?.data() ?? null;
            arrayProximosMuestreos.push({
              tipo: 'z',
              registroFormulaID: muestreo.id,
              grupoEnsayoID: grupoEnsayo?.id ?? null,
              grupoensayo: grupoEnsayo?.nombre ?? null,
              acumulado: acumulado,
              proximoMuestreo: (muestreo.frecuenciaz - (acumulado - muestreo.ultimomuestreoz)).toFixed(2)
            });
          }
          if (muestreo.chkg) {
            if ( (acumulado - muestreo.ultimomuestreog) >= (muestreo.frecuenciag - muestreo.avisarg) ) {
              let grupoEnsayo = await muestreo?.grupoensayosg?.get() ?? null;
              grupoEnsayo = grupoEnsayo?.data() ?? null;
              arrayProximosMuestreos.push({
                tipo: 'g',
                registroFormulaID: muestreo.id,
                grupoEnsayoID: grupoEnsayo?.id ?? null,
                grupoensayo: grupoEnsayo?.nombre ?? null,
                acumulado: acumulado,
                proximoMuestreo: (muestreo.frecuenciag - (acumulado - muestreo.ultimomuestreog)).toFixed(2)
              });
            }
          }
          if (muestreo.chkx) {
            if ( (acumulado - muestreo.ultimomuestreox) >= (muestreo.frecuenciax - muestreo.avisarx) ) {
              let grupoEnsayo = await muestreo?.grupoensayosx?.get() ?? null;
              grupoEnsayo = grupoEnsayo?.data() ?? null;
              arrayProximosMuestreos.push({
                tipo: 'x',
                registroFormulaID: muestreo.id,
                grupoEnsayoID: grupoEnsayo?.id ?? null,
                grupoensayo: grupoEnsayo?.nombre ?? null,
                acumulado: acumulado,
                proximoMuestreo: (muestreo.frecuenciax - (acumulado - muestreo.ultimomuestreox)).toFixed(2)
              });
            }
          }
          if (muestreo.chky) {
            if ( (acumulado - muestreo.ultimomuestreoy) >= (muestreo.frecuenciay - muestreo.avisary) ) {
              let grupoEnsayo = await muestreo?.grupoensayosy?.get() ?? null;
              grupoEnsayo = grupoEnsayo?.data() ?? null;
              arrayProximosMuestreos.push({
                tipo: 'y',
                registroFormulaID: muestreo.id,
                grupoEnsayoID: grupoEnsayo?.id ?? null,
                grupoensayo: grupoEnsayo?.nombre ?? null,
                acumulado: acumulado,
                proximoMuestreo: (muestreo.frecuenciay - (acumulado - muestreo.ultimomuestreoy)).toFixed(2)
              });
            }
          }
        }
        return arrayProximosMuestreos;
      })
      .catch( err => {
        console.log(err);
        return [];
      });

  }

  async crearMuestreo( formData: any, acumuladoData: any ) {
    // TODO: hay que mostrar en la alerta los muestreo es Z, Y, X, G

    const muestreoData: any = {};
    const proximosMuestreos = acumuladoData.proximosMuestreos;
    const proximoMuestreoData = proximosMuestreos[0];
    const registroFormulaID = proximoMuestreoData.registroFormulaID;
    const registroFormulaRef = doc( this.firestore, 'gema_registroformulas', registroFormulaID );
    const muestreosCol = collection( this.firestore, 'gema_registromuestreos' );
    const muestrasCol = collection( this.firestore, 'gema_registromuestras' );
    let ultimomuestreo: any;
    let acumuladoUltimoMuestreo = proximoMuestreoData.acumulado;
    if ( parseFloat(proximoMuestreoData.proximoMuestreo) < 0 ) {
      acumuladoUltimoMuestreo = Math.round( ((proximoMuestreoData.acumulado + parseFloat(proximoMuestreoData.proximoMuestreo)) + Number.EPSILON) * 100 ) / 100;
    }
    switch (proximoMuestreoData.tipo) {
      case 'z':
        ultimomuestreo = { ultimomuestreoz: acumuladoUltimoMuestreo };
        break;
      case 'y':
        ultimomuestreo = { ultimomuestreoy: acumuladoUltimoMuestreo };
        break;
      case 'x':
        ultimomuestreo = { ultimomuestreox: acumuladoUltimoMuestreo };
        break;
      case 'g':
        ultimomuestreo = { ultimomuestreog: acumuladoUltimoMuestreo };
        break;
    }

    muestreoData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };

    const fecha = new Date();
    muestreoData.fecharegistro = fecha;
    muestreoData.prefijomuestreo = this.cargarPrefijoMuestreo( formData?.fecha ); // MU-22
    muestreoData.nummuestreo = await this.cargarNumeroAlbaranMuestreo( formData?.fecha ); // 0007
    muestreoData.albaran = muestreoData.prefijomuestreo + '/' + this.rellenarConCeros( muestreoData.nummuestreo, 4 ); // MU-22/0001
    // asignar obra de control de produccion de planta o dejar vacio
    muestreoData.obra = null;
    muestreoData.peticionario = '';
    muestreoData.numpedido = '';
    muestreoData.tipomuestra = 'mb'; // TODO: Verificar el tipo de producto que es
    muestreoData.prefijomuestra = this.cargarPrefijoMuestra( formData?.fecha ); // MB-22
    muestreoData.nummuestra = await this.cargarNumeroAlbaranMuestra( formData?.fecha ); // 0008;
    muestreoData.albaranmuestra = muestreoData.prefijomuestra + '/' + this.rellenarConCeros( muestreoData.nummuestra, 4 ); // MB-22/0001
    muestreoData.tipoenvases = '';
    muestreoData.descripcionenvase = null;
    muestreoData.numenvases = 1;
    muestreoData.albaranseleccionado = true;
    muestreoData.acumuladoProducto = proximoMuestreoData.acumulado; // Guardar el acumulado de la familia/formula en el momento del muestreo
    muestreoData.albaranencontrado = acumuladoData.salidaID; // FHB1ynuFXTwHJoJjzK3M
    muestreoData.prefalbarancliente = formData?.prefalbaran; // G22
    muestreoData.albarancliente = this.rellenarConCeros(formData?.albaran, 4 ); // 1264
    muestreoData.designacionmuestra = formData?.formula?.codigo + ' - ' + formData?.formula?.nombre; // 1421111 - AC16 SURF 50/70 (D) CALIZO.
    muestreoData.procedencia = formData?.planta?.nombre ?? null; // PLANTA DE GALDAKAO
    muestreoData.obracliente = formData?.obra?.codigo + ' - ' + formData?.obra?.nombre; // ASF22/092 - VARIAS CALLES Y CAMINOS DE MUNGIA
    muestreoData.camion = formData.matricula.matricula;
    muestreoData.temperaturasalida = formData.temperatura;
    muestreoData.localizacion = ''; // Definir
    muestreoData.temperaturaambiente = 0;
    muestreoData.temperaturamuestra = 0;
    muestreoData.metodomuestreo = 'camion'; // Definir
    muestreoData.grupoensayos = doc( this.firestore, 'obras', proximoMuestreoData.grupoEnsayoID );
    muestreoData.listaensayos = null; // Definir al cargar el muestreo en LaboApp
    muestreoData.observaciones = '';
    muestreoData.recogidopor = 'recogidolaboratorio'; // Definir
    muestreoData.muestratomadapor = ''; // Definir
    muestreoData.horallegadaobra = fecha; // Definir
    muestreoData.horasalidaobra = fecha;
    muestreoData.firmadocliente = null;
    muestreoData.firmadodnicliente = null;
    muestreoData.firmaclienteimg = null;
    muestreoData.firmadolaboratek = ''; // Definir
    muestreoData.firmadodnilaboratek = null;
    muestreoData.firmalaboratekimg = null;
    muestreoData.isPendiente = true; // Establece que tiene que aparecer como pendiente de revision
    muestreoData.isAutoGenerado = true; // Establece que el albaran se ha autogenerado
    muestreoData.estado = true;

    const muestraData = {
      albaran: muestreoData.albaranmuestra,
      prefijo: muestreoData.prefijomuestra,
      nummuestra: muestreoData.nummuestra,
      fecha,
      meta: muestreoData.meta,
      situacion: 0,
      estado: true
    };

    // console.log(muestreoData);
    return addDoc( muestrasCol, muestraData )
      .then( muestraDoc => {

        updateDoc( muestraDoc, { id: muestraDoc.id } )
          .then( () => {

            muestreoData.idmuestra = muestraDoc.id;
            addDoc( muestreosCol, muestreoData )
              .then( muestreoDoc => {

                updateDoc( muestreoDoc, { id: muestreoDoc.id } )
                  .then( () => {

                    updateDoc( muestraDoc, { idmuestreo: muestreoDoc.id } )
                      .then( () => {

                        updateDoc( registroFormulaRef, ultimomuestreo );

                      })

                  });
              });
          });
      });

  }

  cargarPrefijoMuestreo( fecha: string ): string {
    const anio = fecha.slice(2, 4);
    return ( 'MU-' + anio ).toLocaleUpperCase();
  }

  async cargarNumeroAlbaranMuestreo( fecha: string ): Promise<number> {

    const anio = fecha.slice(2, 4);
    const prefijo = 'MU-' + anio;
    let proximoNumero = 1;
    let consulta: Query<DocumentData>;
    const registroMuestreosCol = collection( this.firestore, 'gema_registromuestreos' );
    consulta = query( registroMuestreosCol, where('prefijomuestreo', '==', prefijo), orderBy('nummuestreo', 'desc'), limit(1) );
    const numero = await firstValueFrom(
      collectionData( consulta, { idField: 'id' } )
        .pipe(
          map( (reg: any) => {
            return reg.docs.map( (a: any) => {
              a = a.data();
              return a.nummuestreo;
            });
          })
        )
    ).then(r => r);

    if ( numero.length > 0 ) {
      proximoNumero = Number(numero[0]) + 1;
    }
    return proximoNumero;

  }

  cargarPrefijoMuestra( fecha: string ): string {
    const anio = fecha.slice(2, 4);
    return ( 'MB-' + anio ).toLocaleUpperCase();
  }

  async cargarNumeroAlbaranMuestra( fecha: string ): Promise<number> {

    const anio = fecha.slice(2, 4);
    const prefijo = 'MB-' + anio;
    let proximoNumero = 1;
    let consulta: Query<DocumentData>;
    const registroMuestrasCol = collection( this.firestore, 'gema_registromuestras' );
    consulta = query( registroMuestrasCol, where('prefijo', '==', prefijo), orderBy('nummuestra', 'desc'), limit(1) );
    const numero = await firstValueFrom(
      collectionData( consulta, { idField: 'id' } )
        .pipe(
          map( (reg: any) => {
            return reg.docs.map( (a: any) => {
              a = a.data();
              return a.nummuestra;
            });
          })
        )
    ).then(r => r);

    if ( numero.length > 0 ) {
      proximoNumero = Number(numero[0]) + 1;
    }
    return proximoNumero;

  }

  rellenarConCeros( numero: string, length: number ): string {

    let myString = '' + numero;
    while (myString.length < length) {
      myString = '0' + myString;
    }

    return myString;

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

}
