import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public menu = [];

  // TODO llevarlo al backend
  cargaMenu() {
    this.menu = JSON.parse( localStorage.getItem('menu') ) || [];

    if ( this.menu.length === 0) {
     this.router.navigateByUrl('/login');
    }
  }

  constructor( private router: Router ) {}

}
