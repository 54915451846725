<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="searchForm">

                    <input type="search" class="form-control" placeholder="Buscar mantenimiento" #input formControlName="termino" (keyup)="buscarMantenimiento( input.value )" (search)="verificarBusqueda( input.value )">

                </form>

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="row mb-3">
                    <div class="col-lg-8 col-sm-12">
                        <h4 class="card-title">Mantenimientos ({{ totalRegistros }})</h4>
                    </div>

                    <div class="col-lg-4 col-sm-12">
                        <div class="text-right">
                            <button type="button" class="btn btn-primary" routerLink="/maquinaria/mantenimiento/nuevo">
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                Crear mantenimiento
                            </button>
                        </div>
                    </div>

                </div>

                <div class="row mb-1">
                    <div class="col-lg-6 col-sm-12">
                        <small>Mostrar </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="registrosPagina" (change)="actualizarRegistros($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                        <small> registros</small>
                    </div>
                    <div class="col-lg-6 col-sm-12 text-right">
                        <small>Estado </small>
                        <select class="custom-select-sm border-light" [(ngModel)]="estadoMantenimientos" (change)="actualizarEstadoMantenimientos($event)">
                            <option value="activos">Activos</option>
                            <option value="avisos">Avisos</option>
                            <option value="pendientes">Pendientes</option>
                            <option value="en_curso">En curso</option>
                            <option value="realizados">Realizados</option>
                            <option value="todo">Todos</option>
                        </select>
                    </div>
                </div>

                <ngx-datatable #table class='bootstrap' [columnMode]="'force'" [scrollbarH]="true" [headerHeight]="40" [footerHeight]="50" [rowHeight]="'auto'" [sortType]="sortType.multi" [messages]="{ emptyMessage: 'No hay registros que mostrar' }" [limit]="registrosPagina" [rows]="mantenimientos" [sorts]="sorts" (sort)="onSort($event)" [offset]="offset" (page)="onPage($event)">
                    <ngx-datatable-column name="Fecha" [comparator]="fechaComparator" [flexGrow]="3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ ((row['fechaAviso']) ? row['fechaAviso'].toDate() : row['fecha'].toDate()) | date: 'dd/MM/yyyy' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Maquina" prop="maquina" [comparator]="maquinaComparator" [flexGrow]="4">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['maquina']['codnavision'] }} - {{ row['maquina']['nombre'] }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="H/Km" prop="horometro" [sortable]="false" [flexGrow]="2">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ (row['horometroAviso']) ? row['horometroAviso'] : row['horometro'] ?? '' }} {{ (row['horometroAviso']) ? row['udHorometroAviso'] ?? 'h' : (row['horometro']) ? row['udHorometro'] ?? 'h' : '' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Descripción" prop="descripcion" [sortable]="false" [flexGrow]="3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ (row['descripcion']) }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Estado" prop="estado" [flexGrow]="3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['estado'] | estadoTxt }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Ace." prop="aceite" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipAceite>
                                <div class="d-flex flex-column">
                                    <span>Aceite</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipAceite" tooltipClass="tooltip-custom" container="body" *ngIf="row['aceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipAceite" tooltipClass="tooltip-custom" container="body" *ngIf="!row['aceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Hid." prop="hidraulico" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipHidraulico>
                                <div class="d-flex flex-column">
                                    <span>Hidráulico</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="row['hidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="!row['hidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Lu.Ot." prop="otros" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipAceiteOtros>
                                <div class="d-flex flex-column">
                                    <span>Lubricantes Otros</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipAceiteOtros" tooltipClass="tooltip-custom" container="body" *ngIf="row['otros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipAceiteOtros" tooltipClass="tooltip-custom" container="body" *ngIf="!row['otros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Ac." prop="filtroaceite" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroAceite>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aceite</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroAceite" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroAceite" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaceite'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Ga." prop="filtrogasoil" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroGasoil>
                                <div class="d-flex flex-column">
                                    <span>Filtro Gasoil</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroGasoil" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrogasoil'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroGasoil" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrogasoil'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Ai." prop="filtroaire" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroAire>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aire</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroAire" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaire'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroAire" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaire'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Hi." prop="filtrohidraulico" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroHidraulico>
                                <div class="d-flex flex-column">
                                    <span>Filtro Hidráulico</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrohidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroHidraulico" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrohidraulico'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Agu." prop="filtroagua" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroAgua>
                                <div class="d-flex flex-column">
                                    <span>Filtro Agua</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroAgua" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroagua'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroAgua" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroagua'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.A.C." prop="filtroaceiteconvertidor" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroAceiteConvertidor>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aceite Convertidor</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroAceiteConvertidor" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtroaceiteconvertidor'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroAceiteConvertidor" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtroaceiteconvertidor'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="F.Ot." prop="filtrootros" [flexGrow]="0.5">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <ng-template #tipFiltroOtros>
                                <div class="d-flex flex-column">
                                    <span>Filtro Otros</span>
                                </div>
                            </ng-template>
                            <span [ngbTooltip]="tipFiltroOtros" tooltipClass="tooltip-custom" container="body" *ngIf="row['filtrootros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-success">Si</span>
                            <!-- <span [ngbTooltip]="tipFiltroOtros" tooltipClass="tooltip-custom" container="body" *ngIf="!row['filtrootros'] && (row['estado'] === 'realizado')" class="badge badge-pill badge-danger">No</span> -->
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="" prop="id" [sortable]="false" [cellClass]="getBtnClass" [flexGrow]="3">
                        <!-- <ng-template let-value="value" ngx-datatable-header-template>
                            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="ExportarExcel()"><i class="mdi mdi-file-excel"></i></button>
                        </ng-template> -->
                        <ng-template let-value="value" ngx-datatable-cell-template>
                             <div class="btn-group text-right" role="group">
                                <button type="button" class="btn btn-secondary" (click)="verMantenimiento( value )"><i class="mdi mdi-eye"></i></button>
                                <button type="button" class="btn btn-info" (click)="editarMantenimiento( value )"><i class="mdi mdi-pencil"></i></button>
                                <button type="button" class="btn btn-danger" (click)="borrarMantenimiento( value )"><i class="mdi mdi-delete"></i></button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px">
                                <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                            </div>
                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                            [page]="curPage" [size]="pageSize" [count]="rowCount" [hidden]="!((rowCount / pageSize)> 1)" (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>

                </ngx-datatable>

            </div>
        </div>
    </div>

</div>
