import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, NgForm } from '@angular/forms';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { GondolaService } from 'src/app/services/service.index';
import { formatDate } from '@angular/common';
import { SortType } from '@swimlane/ngx-datatable';
import { getDoc } from '@angular/fire/firestore';

@Component({
  selector: 'app-portes-gondola',
  templateUrl: './portes-gondola.component.html',
  styleUrls: ['./portes-gondola.component.css']
})
export class PortesGondolaComponent implements OnInit, OnDestroy {

  portes: any = [];
  portesTodas: any = [];

  fecha: string;

  sortType = SortType;
  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private fb: FormBuilder,
               private router: Router,
               private gondolaService: GondolaService, ) { }

  ngOnInit(): void {
    this.fecha = this.gondolaService.fechaListado;
    this.registrosPagina = this.gondolaService.getRegistrosPagina();
    this.sorts = this.gondolaService.getSorts();
    this.offset = this.gondolaService.getPage();
    this.cargarPortes( this.fecha );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarPortes( fecha: string ) {

    this.cargando = true;
    const desdeDate = new Date( fecha + ' 00:00' );
    const hastaDate = new Date( fecha + ' 00:00' );
    hastaDate.setDate( hastaDate.getDate() + 1 );
    this.subs.sink = this.gondolaService.listaPortes( desdeDate, hastaDate )
      .subscribe( async (portesDB: any) => {
        this.totalRegistros = 0;
        this.portes = [];
        if ( portesDB.length > 0 ) {
          await Promise.all(
            portesDB.map( async (porte: any) => {
              if ( porte.cargadoObra ) {
                porte.cargadoObra = (porte.cargadoObra) ? (await getDoc( porte.cargadoObra )).data() : null;
                porte.cargadoObra.cliente = (porte.cargadoObra.cliente) ? (await getDoc( porte.cargadoObra.cliente )).data() : null;
              }
              if ( porte.descargadoObra ) {
                porte.descargadoObra = (porte.descargadoObra) ? (await getDoc( porte.descargadoObra )).data() : null;
                porte.descargadoObra.cliente = (porte.descargadoObra.cliente) ? (await getDoc( porte.descargadoObra.cliente )).data() : null;
              }
            })
          );
          this.totalRegistros = portesDB.length; // TODO: Obtener total usuarios de la BD
          this.portes = portesDB;
          this.portesTodas = portesDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;
      });

  }

  buscarPorte( termino: string ) {

    if (termino.length === 0) {
      this.totalRegistros = this.portesTodas.length;
      this.portes = this.portesTodas;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      // TODO
      this.portes = this.portesTodas.filter( ( porte: any ) =>
        porte?.descripcion?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.prioridad?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.maquina?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.maquina?.numserie?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.maquina?.codnavision?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.maquina?.codcontable?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        porte?.maquina?.matricula?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.portes.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.portesTodas.length;
      this.portes = this.portesTodas;
    }
  }

  verPorte( id: string ): void {

    this.router.navigate( ['/maquinaria/porte-gondola', id, 'ver'] );

  }

  editarPorte( id: string ) {

    this.router.navigate( ['/maquinaria/porte-gondola', id, 'editar'] );

  }

  borrarPorte( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.gondolaService.borrarPorte( id )
          .then( res => {
            Swal.fire(
              '¡Eliminado!',
              'El porte ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  cambiarFecha( form: NgForm ): void {

    const fechaDesde = new Date( form.value.fechaDesde );
    if ( fechaDesde instanceof Date && !isNaN( fechaDesde.getTime() ) ) {
      this.gondolaService.fechaListado = formatDate( fechaDesde, 'yyyy-MM-dd', 'en' );
      this.fecha = this.gondolaService.fechaListado;
      this.subs.unsubscribe();
      this.cargarPortes( this.fecha );
    }

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.gondolaService.setRegistrosPagina( Number(event.target.value) );
  }

  onPage(event) {
    this.gondolaService.setPage( event.offset );
  }

  onSort(event) {
    this.gondolaService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  fechaComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA < propB) {
      return -1;
    } else if (propA > propB) {
      return 1;
    } else {
      return 0;
    }
  }

}
