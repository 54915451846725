import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { collection, collectionData, doc, docData, DocumentData, Firestore, orderBy, Query, query, updateDoc, where } from '@angular/fire/firestore';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class PersonalSaldosService {

  personalCol = collection( this.firestore, 'personal' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 100;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
             ) { }

  cargarOperariosConSaldos() {

    let consulta: Query<DocumentData>;
    consulta = query( this.personalCol, where('bolsaHoras', '==', true), where('estado', '==', true), orderBy('apellido1', 'asc') );

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarOperarioConSaldos( id: string ) {

    const operarioRef = doc( this.firestore, 'personal', id );

    return docData( operarioRef, { idField: 'id' } );

  }

  actualizarSaldosTrabajador( trabajadorID: string, formData: any ) {

    const operarioRef = doc( this.firestore, 'personal', trabajadorID );

    formData.saldo_horas.fecha_ultima_actualizacion = new Date( formData.saldo_horas.fecha_ultima_actualizacion );
    formData.saldo_vacaciones.fecha_ultima_actualizacion = new Date( formData.saldo_vacaciones.fecha_ultima_actualizacion );

    return updateDoc( operarioRef, {
      saldo_horas: formData.saldo_horas,
      saldo_vacaciones: formData.saldo_vacaciones,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }
}
