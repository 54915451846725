import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, debounceTime, distinctUntilChanged, filter, first, map } from 'rxjs';
import { FormulariosService, GondolaService } from 'src/app/services/service.index';

import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

type Obra = { id: string, codigo: string, nombre: string };
type Cliente = { id: string, nombre: string };
type Maquina = { id: string, codigo: string, nombre: string, codnom: string };

@Component({
  selector: 'app-porte-gondola',
  templateUrl: './porte-gondola.component.html',
  styleUrls: ['./porte-gondola.component.css']
})
export class PorteGondolaComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Nuevo porte';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  porteID: string;
  modoLectura: boolean = false;
  gondola = '3118LBY';
  obras: any = [];
  clientes: any = [];
  verPoblacionCarga: boolean = false;
  verObraCarga: boolean = true;
  verPoblacionDescarga: boolean = false;
  verObraDescarga: boolean = true;
  verDescarga: boolean = false;
  verMaquinas: boolean = true;
  maquinaria: any[];

  private subs = new SubSink();

  public formSubmitted = false;

  hoy: Date = new Date( Date.now() );
  ahora: Date = new Date( Date.now() );

  public porteForm = this.fb.group({

    gondola: [ this.gondola, Validators.required ],

    fechaCarga: [ formatDate( this.hoy, 'yyyy-MM-dd', 'en' ), Validators.required ],
    horaCarga: [ formatDate( this.ahora, 'HH:mm', 'en' ), Validators.required ],
    radioObraCarga: [ 'obra' ],
    cargadoEn: [ null, Validators.required ],
    cargadoObra: [ null ],
    cargadoCliente: [ null ],

    descargado: [ false ],
    fechaDescarga: [ { value: formatDate( this.hoy, 'yyyy-MM-dd', 'en' ), disabled: true }, Validators.required ],
    horaDescarga: [ { value: formatDate( this.ahora, 'HH:mm', 'en' ), disabled: true }, Validators.required ],
    radioObraDescarga: [ 'obra' ],
    descargadoEn: [ { value: null, disabled: true }, Validators.required ],
    descargadoObra: [ null ],
    descargadoCliente: [ null ],

    horas: [ 0 ],
    tipoCarga: [ 'propia' ],
    maquinas: this.fb.array([
      this.fb.group({
        maquina: [ null ],
      })
    ]),

  });

  obraformat = (obra: Obra) => obra.codigo + ' - ' + obra.nombre;

  obrasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.obras.filter( ( obra: any ) => obra?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                              obra?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  clieformat = (cliente: Cliente) => cliente.nombre;

  cliesearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.clientes.filter( ( cliente: any ) => cliente?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  maquinaformat = (maquina: Maquina) => (maquina?.codnom) ? maquina.codnom : (maquina?.codigo) ? maquina.codigo + ' - ' + maquina.nombre : maquina.nombre;

  maquinasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map( term => this.maquinaria.filter( ( maquina: any ) => maquina?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                            maquina?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  constructor( private gondolaService: GondolaService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Porte';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          this.porteID = params.id;
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.cargarPorte( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.cargarPorte( params.id );
          } else {
            this.router.navigate(['/maquinaria/portes-gondola']);
          }
        } else {
          this.porteForm.get('fechaCarga').setValue( this.gondolaService.fechaListado );
          this.porteForm.get('fechaDescarga').setValue( this.gondolaService.fechaListado );
          this.porteForm.get('horaCarga').setValue( null );
          this.porteForm.get('horaDescarga').setValue( null );
          this.cargando = false;
        }

      });

    this.cargarObras();
    this.cargarMaquinas();

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarObras(): void {
    this.subs.sink = this.formulariosService.selectObrasClientes()
    .subscribe( async (obrasDB) => {
      if ( obrasDB.length > 0 ) {
        await Promise.all(
          obrasDB.map( async (obra) => {
            obra.cliente = (obra?.cliente) ? (await getDoc( obra.cliente )).data() : null;
            obra.cliente = {
              id: obra.cliente.id,
              nombre: obra.cliente.nombre,
            };
          })
        );
        this.obras = obrasDB;
      }
    });
  }

  cargarMaquinas() {
    this.subs.sink = this.formulariosService.selectMaquinas()
      .subscribe( maquinasBD => {
        if ( maquinasBD.length > 0 ) {
          this.maquinaria = maquinasBD.sort( (a, b) => a.codigo.localeCompare(b.codigo, 'es', { numeric: true } ) );
        }
      });
  }

  cargarPorte( id: string ): void {

    this.subs.sink = this.gondolaService.obtenerPortePorId( id )
      .pipe(
        first()
      )
      .subscribe( async (porteData: any) => {

        if ( !porteData ) {
          Swal.fire('Error', 'Error al cargar los datos del porte', 'error');
          return this.router.navigateByUrl('/maquinaria/portes-gondola');
        }
        const fechaCargaBD = formatDate(  porteData.fechaCarga.toDate(), 'yyyy-MM-dd', 'en' );
        const horaCargaBD = formatDate(  porteData.fechaCarga.toDate(), 'HH:mm', 'en' );
        if ( porteData?.cargadoObra ) {
          porteData.cargadoObra = (porteData.cargadoObra) ? (await getDoc( porteData.cargadoObra )).data() : null;
          porteData.obraCarga = {
            id: porteData.cargadoObra.id,
            codigo: porteData.cargadoObra.codigo,
            nombre: porteData.cargadoObra.nombre,
          };
          porteData.obraCarga.cliente = (porteData.cargadoObra.cliente) ? (await getDoc( porteData.cargadoObra.cliente )).data() : null;
          porteData.clienteCarga = {
            id: porteData.obraCarga.cliente.id,
            nombre: porteData.obraCarga.cliente.nombre,
          };
        }
        let radioObraCarga = 'obra';
        this.verObraCarga = true;
        this.verPoblacionCarga = false;
        if ( porteData?.radioObraCarga ) {
          radioObraCarga = porteData.radioObraCarga;
          if ( radioObraCarga === 'poblacion' ) {
            this.verObraCarga = false;
            this.verPoblacionCarga = true;
          }
        } else {
          if ( porteData?.obraCarga ) {
            radioObraCarga = 'obra';
          } else {
            radioObraCarga = 'poblacion';
            this.verObraCarga = false;
            this.verPoblacionCarga = true;
          }
        }

        let fechaDescargaBD = null, horaDescargaBD = null;
        if ( porteData?.fechaDescarga ) {
          fechaDescargaBD = formatDate(  porteData.fechaDescarga.toDate(), 'yyyy-MM-dd', 'en' );
          horaDescargaBD = formatDate(  porteData.fechaDescarga.toDate(), 'HH:mm', 'en' );
        }
        if ( porteData?.descargadoObra ) {
          porteData.descargadoObra = (porteData.descargadoObra) ? (await getDoc( porteData.descargadoObra )).data() : null;
          if ( porteData.descargadoObra ) {
            porteData.obraDescarga = {
              id: porteData.descargadoObra.id,
              codigo: porteData.descargadoObra.codigo,
              nombre: porteData.descargadoObra.nombre,
            };
            porteData.descargadoObra.cliente = (porteData.descargadoObra.cliente) ? (await getDoc( porteData.descargadoObra.cliente )).data() : null;
            porteData.clienteDescarga = {
              id: porteData.descargadoObra.cliente.id,
              nombre: porteData.descargadoObra.cliente.nombre,
            };
          }
        }
        let radioObraDescarga = 'obra';
        this.verObraDescarga = true;
        this.verPoblacionDescarga = false;
        if ( porteData?.radioObraDescarga ) {
          radioObraDescarga = porteData.radioObraDescarga;
          if ( radioObraDescarga === 'poblacion' ) {
            this.verObraDescarga = false;
            this.verPoblacionDescarga = true;
          }
        } else {
          if ( porteData?.obraDescarga ) {
            radioObraDescarga = 'obra';
          } else {
            radioObraDescarga = 'poblacion';
            this.verObraDescarga = false;
            this.verPoblacionDescarga = true;
          }
        }
        // Mostrar parte del formulario sobre descarga y habilitar campos
        porteData.descargado = porteData?.descargado ?? porteData?.descargadoEn ? true : false;
        if ( porteData.descargado ) {
          this.verDescarga = true;
          this.porteForm.get('fechaDescarga').enable();
          this.porteForm.get('horaDescarga').enable();
          this.porteForm.get('descargadoEn').enable();
        }
        porteData.tipoCarga = porteData?.tipoCarga ?? 'propia';
        if (porteData.tipoCarga === 'propia') {
          this.porteForm.get('maquinas').enable();
          this.verMaquinas = true;
        } else {
          this.porteForm.get('maquinas').disable();
          this.verMaquinas = false;
        }
        this.porteForm.reset({
          gondola: porteData?.gondola ?? this.gondola,
          fechaCarga: fechaCargaBD,
          horaCarga: horaCargaBD,
          radioObraCarga: radioObraCarga,
          cargadoEn: porteData.cargadoEn,
          cargadoObra: porteData.obraCarga,
          cargadoCliente: porteData.clienteCarga,
          descargado: porteData.descargado,
          fechaDescarga: fechaDescargaBD,
          horaDescarga: horaDescargaBD,
          radioObraDescarga: radioObraDescarga,
          descargadoEn: porteData?.descargadoEn,
          descargadoObra: porteData?.obraDescarga,
          descargadoCliente: porteData?.clienteDescarga,
          tipoCarga: porteData.tipoCarga,
        });
        this.maquinas.clear();
        porteData.maquinas.forEach( maquina => {
          maquina.codnom = maquina.nombre;
          this.maquinas.push(
            this.fb.group({
              maquina: maquina,
            })
          );

        });
        this.cargando = false;

      });

  }

  crearPorte() {

    this.formSubmitted = true;

    if ( this.porteForm.invalid ) {
      console.log( this.porteForm );
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.gondolaService.crearPorte( this.porteForm.value )
      .then( () => this.router.navigateByUrl('/maquinaria/portes-gondola') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarPorte() {

    this.formSubmitted = true;

    if ( this.porteForm.invalid ) {
      return;
    }

    if ( this.porteForm.pristine ) {
      this.cancelarPorte();
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    // Realizar el posteo
    this.gondolaService.actualizarPorte( this.porteID, this.porteForm.value )
      .then( () => this.router.navigateByUrl('/maquinaria/portes-gondola') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  // Cancelar el formulario y regresar al listado
  cancelarPorte(): void {

    if ( this.porteForm.pristine ) {
      this.router.navigateByUrl('/maquinaria/portes-gondola');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigateByUrl('/maquinaria/portes-gondola');

      }

    });


  }

  campoNoValido( campo: string ): boolean {

    if ( this.porteForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.porteForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  verificarRadioObraCarga( tipo: any ) {
    this.verObraCarga = !this.verObraCarga;
    this.verPoblacionCarga = !this.verPoblacionCarga;
    this.porteForm.get('cargadoObra').setValue( null );
    this.porteForm.get('cargadoCliente').setValue( null );
    this.porteForm.get('cargadoEn').setValue( null );
    if ( this.verObraCarga ) {
      this.porteForm.get('cargadoObra').enable();
      this.porteForm.get('cargadoCliente').enable();
    } else {
      this.porteForm.get('cargadoObra').disable();
      this.porteForm.get('cargadoCliente').disable();
    }
  }

  obraCargaSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    const nombreObra = event.item.codigo + ' - ' + event.item.nombre;
    this.porteForm.get('cargadoEn').setValue( nombreObra );
    this.porteForm.get('cargadoCliente').setValue({
      id: event.item.cliente.id,
      nombre: event.item.cliente.nombre
    });

  }

  verificarObraCarga(): void {

    if ( !this.porteForm.get('cargadoObra').value ) {

      this.porteForm.get('cargadoEn').setValue( null );
      this.porteForm.get('cargadoCliente').setValue( null );

    }

  }

  verificarDescarga() {

    this.verDescarga = !this.verDescarga
    if (this.verDescarga) {
      this.porteForm.get('fechaDescarga').enable();
      this.porteForm.get('horaDescarga').enable();
      this.porteForm.get('descargadoEn').enable();
    } else {
      this.porteForm.get('fechaDescarga').disable();
      this.porteForm.get('horaDescarga').disable();
      this.porteForm.get('descargadoEn').disable();
    }

  }

  verificarRadioObraDescarga( tipo: any ) {
    this.verObraDescarga = !this.verObraDescarga;
    this.verPoblacionDescarga = !this.verPoblacionDescarga;
    this.porteForm.get('descargadoObra').setValue( null );
    this.porteForm.get('descargadoCliente').setValue( null );
    this.porteForm.get('descargadoEn').setValue( null );
    if ( this.verObraDescarga ) {
      this.porteForm.get('descargadoObra').enable();
      this.porteForm.get('descargadoCliente').enable();
    } else {
      this.porteForm.get('descargadoObra').disable();
      this.porteForm.get('descargadoCliente').disable();
    }
  }

  obraDescargaSeleccionada( event: NgbTypeaheadSelectItemEvent ): void {

    const nombreObra = event.item.codigo + ' - ' + event.item.nombre;
    this.porteForm.get('descargadoEn').setValue( nombreObra );
    this.porteForm.get('descargadoCliente').setValue({
      id: event.item.cliente.id,
      nombre: event.item.cliente.nombre
    });

  }

  verificarObraDescarga(): void {

    if ( !this.porteForm.get('descargadoObra').value ) {

      this.porteForm.get('descargadoEn').setValue( null );
      this.porteForm.get('descargadoCliente').setValue( null );

    }

  }

  verificarTipoCarga() {

    if ( this.porteForm.get('tipoCarga').value === 'propia' ) {
      if (this.maquinas.length === 0) {
        this.agregarMaquina();
      }
      this.porteForm.get('maquinas').enable();
      this.verMaquinas = true;
    } else {
      this.porteForm.get('maquinas').disable();
      this.verMaquinas = false;
    }

  }

  get maquinas(): FormArray {
    return this.porteForm.get('maquinas') as FormArray;
  }

  get eventsControlsArray(): FormGroup[] {
    return this.maquinas.controls as FormGroup[];
  }

  agregarMaquina( click: boolean = false ): void {
    if (click) {
      this.porteForm.markAsDirty();
    }
    this.maquinas.push(
      this.fb.group({
        maquina: [ null ],
      })
    );
  }

  eliminarMaquina( value: any, i: number ): void {

    if ( !value.id ) {
      this.porteForm.markAsDirty();
      this.maquinas.removeAt(i);
      return;
    }
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {

      if ( result.value ) {

        // this.calendariosService.borrarEventoCalendario( this.calendarioID, value.id );

      }

    });

  }

}
