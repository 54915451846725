import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';

import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class GondolaService {

  maquinariaMovimientosCol = collection( this.firestore, 'maquinaria_movimientos' );

  fechaListado: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
              ) { }


  listaPortes( desde: Date, hasta: Date ) {

    const consulta = query( this.maquinariaMovimientosCol, where('fechaCarga', '>=', desde), where('fechaCarga', '<', hasta), orderBy('fechaCarga', 'desc') );
    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerPortePorId( porteID: string ) {

    const porteRef = doc( this.firestore, 'maquinaria_movimientos', porteID );
    return docData( porteRef, { idField: 'id' } );

  }

  crearPorte( formData: any ): Promise<string> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.fechaCarga = new Date( formData.fechaCarga + ' ' + formData.horaCarga );
    delete formData.horaCarga;
    if ( formData.cargadoObra ) {
      formData.cargadoObra = doc( this.firestore, 'obras', formData.cargadoObra.id );
    }
    delete formData.cargadoCliente;
    formData.horas = 0;
    if ( formData.fechaDescarga ) {
      formData.fechaDescarga = new Date( formData.fechaDescarga + ' ' + formData.horaDescarga );
      const horas = ( formData.fechaDescarga - formData.fechaCarga ) / ( 1000 * 60 * 60 );
      formData.horas = horas;
    }
    delete formData.horaDescarga;
    if ( formData.descargadoObra ) {
      formData.descargadoObra = doc( this.firestore, 'obras', formData.descargadoObra.id );
    }
    delete formData.descargadoCliente;
    const maquinaria = [];
    formData.maquinas?.forEach( maquina => {
      maquinaria.push({ id: maquina.maquina.id, nombre: maquina.maquina.codigo + ' - ' + maquina.maquina.nombre });
    });
    formData.maquinas = maquinaria;

    return addDoc( this.maquinariaMovimientosCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id } )
          .then(() => {
            return docRef.id;
          });
      });
  }

  actualizarPorte( porteID: string, formData: any ) {

    formData.fechaCarga = new Date( formData.fechaCarga + ' ' + formData.horaCarga );
    delete formData.horaCarga;
    if ( formData.cargadoObra ) {
      formData.cargadoObra = doc( this.firestore, 'obras', formData.cargadoObra.id );
    }
    delete formData.cargadoCliente;
    formData.horas = 0;
    if ( formData.fechaDescarga ) {
      formData.fechaDescarga = new Date( formData.fechaDescarga + ' ' + formData.horaDescarga );
      const horas = ( formData.fechaDescarga - formData.fechaCarga ) / ( 1000 * 60 * 60 );
      formData.horas = horas;
    }
    delete formData.horaDescarga;
    if ( formData.descargadoObra ) {
      formData.descargadoObra = doc( this.firestore, 'obras', formData.descargadoObra.id );
    }
    delete formData.descargadoCliente;
    const maquinaria = [];
    formData.maquinas?.forEach( maquina => {
      maquinaria.push({ id: maquina.maquina.id, nombre: (maquina.maquina?.codigo) ? maquina.maquina.codigo + ' - ' + maquina.maquina.nombre : maquina.maquina.nombre });
    });
    formData.maquinas = maquinaria

    const porteRef = doc( this.firestore, 'maquinaria_movimientos', porteID );
    return updateDoc( porteRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarPorte( porteID: string ) {

    // TODO: verificar entradas y salidas de maquinaria en obra
    const porteRef = doc( this.firestore, 'maquinaria_movimientos', porteID );
    return deleteDoc( porteRef );

  }

  // Estado de ngx-datatable de porte gondola

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
