import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-mapa-descargado',
  templateUrl: './mapa-descargado.component.html',
  styleUrls: ['./mapa-descargado.component.scss']
})
export class MapaDescargadoComponent implements OnInit, OnDestroy {

  @Input() mapaSelector: string;
  @Input() ubicacion: mapboxgl.LngLatLike;
  @Input() mostrarPlanta: boolean = false;
  @Output() ubicacionSeleccionada = new EventEmitter();

  map: mapboxgl.Map;
  maker: mapboxgl.Marker;
  styleStreet = 'mapbox://styles/mapbox/streets-v12';
  styleSat = 'mapbox://styles/mapbox/satellite-streets-v12';
  styleNavLight = 'mapbox://styles/mapbox/navigation-day-v1';
  styleNavDark = 'mapbox://styles/mapbox/navigation-night-v1';
  ubicacionPlantaAlsasua: mapboxgl.LngLatLike = [ -1.9255191, 43.2812532 ];
  ubicacionPlantaGaldakao: mapboxgl.LngLatLike = [ -1.9255191, 43.2812532 ];

  constructor() {}

  ngOnInit(): void {

    this.maker = new mapboxgl.Marker();

    mapboxgl as typeof mapboxgl;
    this.map = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoiYmVnaW5vIiwiYSI6ImNrcjNjcHY1dTJoc2YybnFoNnF3YXB3ZDEifQ.dLab_q-x0sbWmwuFn9K4dw', // TODO: Move to environment
      container: "mapaDescargado",
      style: this.styleStreet,
      zoom: this.ubicacion ? 17 : 10,
      center: this.ubicacion ? this.ubicacion : this.ubicacionPlantaAlsasua,
      interactive: false,
    });

    if ( this.mostrarPlanta ) {
      // TODO Hacer un custom marker
      const planta = new mapboxgl.Marker()
        .setLngLat( this.ubicacionPlantaAlsasua )
        .addTo( this.map );
    }

    if ( this.ubicacion ) {
      this.maker
        .setLngLat( this.ubicacion )
        .addTo( this.map );
    }

  }

  ngOnDestroy(): void {
    this.map.remove();
    this.map = null;
  }

}
