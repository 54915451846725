import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { EstadoTxtPipe } from './estado-txt.pipe';


@NgModule({
  declarations: [
    ImagenPipe,
    EstadoTxtPipe,
  ],
  imports: [],
  exports: [
    ImagenPipe,
    EstadoTxtPipe,
  ]
})
export class PipesModule { }
