import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class AveriasService {

  averiasCol = collection( this.firestore, 'taller_averias' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;
  private _estado: string = 'activos';

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
              ) { }

  listaAverias( estado: string = 'todo' ) {

    let consulta = query( this.averiasCol );
    switch (estado) {
      case 'pendientes':
        consulta = query( consulta, where('estado', '==', 'pendiente') );
        break;
      case 'en_curso':
        consulta = query( consulta, where('estado', '==', 'en_curso') );
        break;
      case 'reparados':
        consulta = query( consulta, where('estado', '==', 'reparado') );
        break;
      case 'activos':
        consulta = query( consulta, where('estado', '!=', 'reparado'), orderBy('estado', 'desc') );
        break;
    }

    consulta = query( consulta, orderBy('fechaAviso', 'desc') );
    return collectionData( consulta, { idField: 'id' } );

  }

  listaAveriasMaquina( idMaquina: string, estado: string = 'todo' ) {

    const maquinaDocRef = doc( this.firestore, 'maquinaria', idMaquina );
    let consulta = query( this.averiasCol, where('maquina', '==', maquinaDocRef) );
    switch (estado) {
      case 'pendientes':
        consulta = query( consulta, where('estado', '==', 'pendiente') );
        break;
      case 'en_curso':
        consulta = query( consulta, where('estado', '==', 'en_curso') );
        break;
      case 'reparados':
        consulta = query( consulta, where('estado', '==', 'reparado') );
        break;
      case 'activos':
        consulta = query( consulta, where('estado', '!=', 'reparado'), orderBy('estado', 'desc') );
        break;
    }

    consulta = query( consulta, orderBy('fechaAviso', 'desc') );
    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerAveriaPorId( averiaID: string ) {

    const averiaRef = doc( this.firestore, 'taller_averias', averiaID );
    return docData( averiaRef, { idField: 'id' } );

  }

  obtenerAveriaDocs( averiaID: string ) {

    const documentosAveriaCol = collection( this.firestore, 'taller_averias', averiaID, 'documentos' );
    return collectionData( documentosAveriaCol, { idField: 'id' } );

  }

  obtenerAveriaDocPorId( averiaID: string, docID: string ) {

    const documentoAveriaRef = doc( this.firestore, 'taller_averias', averiaID, 'documentos', docID );
    return docData( documentoAveriaRef, { idField: 'id' } );

  }

  crearAveria( formData: any ): Promise<string> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.fechaAviso = new Date( formData.fechaAviso );
    formData.maquina = doc( this.firestore, 'maquinaria', formData.maquina.id );

    return addDoc( this.averiasCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id } )
          .then(() => {
            return docRef.id;
          });
      });
  }

  actualizarAveria( averiaID: string, formData: any ) {

    formData.fechaAviso = new Date( formData.fechaAviso );
    formData.maquina = doc( this.firestore, 'maquinaria', formData.maquina.id );

    const averiaRef = doc( this.firestore, 'taller_averias', averiaID );
    return updateDoc( averiaRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarAveria( averiaID: string ) {

    // TODO Borrar documentos asociados
    const averiaRef = doc( this.firestore, 'taller_averias', averiaID );
    return deleteDoc( averiaRef );

  }

  // Estado de ngx-datatable de averias

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

  public setEstadoAverias(estado: string): void {
    this._estado = estado;
  }

  public getEstadoAverias(): string {
    return this._estado;
  }

}
