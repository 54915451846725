<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Camión</h4>
            </div>
            <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="camionForm" (ngSubmit)="crearCamion()">
                <div class="card-body">
                    <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                    <div class="row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Matricula*</label>
                                <input type="text" class="form-control" placeholder="Matricula" required [ngClass]="{ 'is-invalid': campoNoValido('matricula') || camionForm.controls['matricula'].hasError('matriculaExists') || camionForm.controls['matricula'].hasError('matriculaBaja') }"
                                    formControlName="matricula">
                                <div class="invalid-feedback" *ngIf="camionForm.controls['matricula'].hasError('required')">
                                    La matricula es obligatoria
                                </div>
                                <div class="invalid-feedback" *ngIf="camionForm.controls['matricula'].hasError('matriculaExists')">
                                    La matricula ya existe
                                </div>
                                <div class="invalid-feedback" *ngIf="camionForm.controls['matricula'].hasError('matriculaBaja')">
                                    La matricula ya existe pero esta dada de baja
                                </div>
                                <div class="invalid-feedback" *ngIf="camionForm.controls['matricula'].hasError('pattern')">
                                    La matricula no puede contener espacios en blanco ni otros caracteres especiales.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Remolque</label>
                                <input type="text" class="form-control" placeholder="Remolque" [ngClass]="{ 'is-invalid': campoNoValido('remolque') }" formControlName="remolque">
                                <div class="invalid-feedback">
                                    El remolque no puede contener espacios en blanco ni otros caracteres especiales.
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Tara</label>
                                <input type="number" class="form-control" placeholder="Tara" formControlName="tara">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Chofer</label>
                                <input type="text" class="form-control" placeholder="Chofer" formControlName="chofer">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>DNI</label>
                                <input type="text" class="form-control" placeholder="12345678X" [ngClass]="{ 'is-invalid': campoNoValido('dni') }" formControlName="dni">
                                <div class="invalid-feedback">
                                    El DNI no puede contener espacios en blanco ni otros caracteres especiales.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Teléfono</label>
                                <input type="text" class="form-control" placeholder="Teléfono de contacto" formControlName="telefono">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Transportista</label>
                                <input type="text" class="form-control" placeholder="Transportista" formControlName="transportista">
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <div class="form-group">
                                <label>Agencia</label>
                                <input type="text" class="form-control" placeholder="Agencia" formControlName="agencia">
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('matricula') || camionForm.controls['matricula'].errors?.matriculaExists ||
                            camionForm.controls['matricula'].errors?.matriculaBaja || campoNoValido('remolque') || campoNoValido('dni')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>

                    </div>


                </div>
                <hr>
                <div class="card-body">
                    <div class="form-group mb-0 text-right">
                        <button *ngIf="!camionID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                        <button *ngIf="camionID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarCamion()">Actualizar</button>
                        <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarCamion()">Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>