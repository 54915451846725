import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';

import { Usuario } from '../../models/usuario.model';
import { UsuarioService } from '../../services/service.index';
import Swal from 'sweetalert2';

// TODO: Borrar
import { ImportadorService } from 'src/app/services/util/importador.service';
import { getDoc } from '@angular/fire/firestore';
import { SortType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: [
  ]
})
export class UsuariosComponent implements OnInit, OnDestroy {

  usuarios: Usuario[] = [];
  usuariosTodos: Usuario[] = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = true;

  private subs = new SubSink();

  constructor( public usuarioService: UsuarioService,
               private importadorService: ImportadorService,
               public router: Router) { }

  ngOnInit(): void {
    this.registrosPagina = this.usuarioService.getRegistrosPagina();
    this.sorts = this.usuarioService.getSorts();
    this.offset = this.usuarioService.getPage();
    this.cargarUsuarios();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // TODO: funcion para actualizar cosas en el backend
  UpdateMeta(): void {
    //this.importadorService.UpdateMeta();
  }

  cargarUsuarios(): void {

    this.cargando = true;

    this.subs.sink = this.usuarioService.cargarUsuarios()
      .subscribe( async (usuariosDB: any) => {
        this.totalRegistros = 0;
        this.usuarios = [];
        if ( usuariosDB.length > 0 ) {
          await Promise.all(
            usuariosDB.map( async (usuario: any) => {
              usuario.obra = ( usuario.obra ) ? (await getDoc( usuario.obra )).data() : null;
              usuario.operario = ( usuario.operario ) ? (await getDoc( usuario.operario )).data() : null;
              usuario.cliente = ( usuario.obra ) ? (await getDoc( usuario.obra.cliente )).data() : null;
            })
          );
          this.totalRegistros = usuariosDB.length; // TODO: Obtener total usuarios de la BD
          this.usuarios = usuariosDB;
          this.usuariosTodos = usuariosDB;
        }
        this.cargando = false;
      });

  }

  buscarUsuarios( termino: string ) {

    if ( termino.length <= 0 ) {
      this.totalRegistros = this.usuariosTodos.length;
      this.usuarios = this.usuariosTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.usuarios = this.usuariosTodos.filter( ( usuario: any ) =>
        usuario?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        usuario?.email?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        usuario?.role?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.usuarios.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.usuariosTodos.length;
      this.usuarios = this.usuariosTodos;    }
  }

  editarUsuario( id: string ) {

    this.router.navigate( ['/admin/usuario/editar', id ] );

  }

  borrarUsuario( usuario: Usuario ) {

    // TODO: borrar correctamente el usuario de la aplicacion en el backend

    if ( usuario.uid === this.usuarioService.usuario.uid ) {
      Swal.fire('No puede borrar usuario', 'No se puede borrar a si mismo', 'error');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.usuarioService.borrarUsuario( usuario.uid )
          .then( res => {
            this.cargarUsuarios();
            Swal.fire(
              '¡Eliminado!',
              'El usuario ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.usuarioService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.usuarioService.setPage( event.offset );
  }

  onSort(event) {
    this.usuarioService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
