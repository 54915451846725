import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PAGES_ROUTES } from './pages.routes';

import { SharedModule } from '../shared/shared.module';

// MODULOS
import { ChartsModule } from 'ng2-charts';
import { NgbModalModule, NgbTypeaheadModule, NgbTooltipModule, NgbTimepickerModule, NgbDropdownModule, NgbButtonsModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';

/////////////////////////////
// COMPONENTES
////////////////////////////

import { PagesComponent } from './pages.component';

// Usuarios
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';

// Administracion
import { ClientesComponent } from './administracion/clientes/clientes.component';
import { ClienteComponent } from './administracion/clientes/cliente/cliente.component';
import { ObrasComponent } from './administracion/obras/obras.component';
import { ObraComponent, ClienteModalComponent } from './administracion/obras/obra/obra.component';
import { CategoriasDocumentosComponent } from './administracion/categorias-documentos/categorias-documentos.component';
import { CategoriaDocumentoComponent } from './administracion/categorias-documentos/categoria-documento/categoria-documento.component';

// Personal
import { ConveniosComponent } from './personal/convenios/convenios.component';
import { ConvenioComponent } from './personal/convenios/convenio/convenio.component';
import { PersonalComponent } from './administracion/personal/personal.component';
import { TrabajadorComponent } from './administracion/personal/trabajador/trabajador.component';
import { CalendarioComponent } from './personal/calendarios/calendario.component';
import { ListaCalendariosComponent } from './personal/calendarios/lista/lista-calendarios.component';
import { CalendarioFormComponent } from './personal/calendarios/calendario-form/calendario-form.component';
import { EquiposComponent } from './personal/equipos/equipos.component';
import { EquipoComponent } from './personal/equipos/equipo/equipo.component';
import { SaldosComponent } from './personal/saldos/saldos.component';
import { CierreHorasComponent } from './personal/saldos/cierre-horas/cierre-horas.component';
import { SaldoTrabajadorComponent } from './personal/saldos/saldo-trabajador/saldo-trabajador.component';
import { SaldoVacacionesComponent } from './personal/saldos/saldo-vacaciones/saldo-vacaciones.component';
import { VacacionesComponent } from './personal/vacaciones/vacaciones.component';

// Maestros
import { CamionesComponent } from './maestros/camiones/camiones.component';
import { CamionComponent } from './maestros/camiones/camion/camion.component';
import { ProveedoresComponent } from './maestros/proveedores/proveedores.component';
import { ProveedorComponent } from './maestros/proveedores/proveedor/proveedor.component';
import { MateriasPrimasComponent } from './maestros/materias-primas/materias-primas.component';
import { ArticuloComponent, ProveedorModalComponent } from './maestros/materias-primas/articulo/articulo.component';

// Planta
import { ListaObrasComponent } from './planta/obras/obras.component';
import { EntradasPlantaComponent } from './planta/entradas/entradas.component';
import { EntradaPlantaComponent } from './planta/entradas/entrada/entrada.component';
import { SalidasPlantaComponent } from './planta/salidas/salidas.component';
import { SalidaPlantaComponent } from './planta/salidas/salida/salida.component';
import { EstadisticasPlantaComponent } from './planta/estadisticas/estadisticas.component';
import { InformesPlantaComponent } from './planta/informes/informes.component';
import { EstadisticasComponent } from './admin/estadisticas/estadisticas.component';

// Laboratorio
import { FormulasComponent } from './laboratorio/formulas/formulas.component';
import { FormulaComponent } from './laboratorio/formulas/formula/formula.component';
import { SellosceComponent } from './laboratorio/sellosce/sellosce.component';
import { SelloceComponent } from './laboratorio/sellosce/selloce/selloce.component';

// Taller
import { MaquinariaComponent } from './administracion/maquinaria/maquinaria.component';
import { MaquinaComponent } from './administracion/maquinaria/maquina/maquina.component';
import { AveriasComponent } from './taller/averias/averias.component';
import { AveriaComponent } from './taller/averias/averia/averia.component';
import { MantenimientosComponent } from './taller/mantenimientos/mantenimientos.component';
import { MantenimientoComponent } from './taller/mantenimientos/mantenimiento/mantenimiento.component';
import { PortesGondolaComponent } from './taller/portes-gondola/portes-gondola.component';
import { PorteGondolaComponent } from './taller/portes-gondola/porte-gondola/porte-gondola.component';
import { InformesTallerComponent } from './taller/informes/informes.component';

// Perfil Usuario
import { PerfilComponent } from './usuario/perfil/perfil.component';

// Inicio
import { InicioComponent } from './inicio/inicio.component';

// Pipe Module
import { PipesModule } from '../pipes/pipes.module';
import { PartesTrabajoComponent } from './personal/partes-trabajo/partes-trabajo.component';
import { ParteTrabajoComponent } from './personal/partes-trabajo/parte-trabajo/parte-trabajo.component';
import { ParteTrabajoMensualComponent } from './personal/partes-trabajo/parte-trabajo-mensual/parte-trabajo-mensual.component';


@NgModule({
    declarations: [
        PagesComponent,
        UsuariosComponent,
        UsuarioComponent,
        ListaObrasComponent,
        EntradasPlantaComponent,
        EntradaPlantaComponent,
        SalidasPlantaComponent,
        SalidaPlantaComponent,
        EstadisticasPlantaComponent,
        InformesPlantaComponent,
        ProveedoresComponent,
        ProveedorComponent,
        ProveedorModalComponent,
        MateriasPrimasComponent,
        ArticuloComponent,
        FormulasComponent,
        FormulaComponent,
        InicioComponent,
        ClientesComponent,
        ClienteComponent,
        ClienteModalComponent,
        ObrasComponent,
        PersonalComponent,
        MaquinariaComponent,
        CamionesComponent,
        CamionComponent,
        ObraComponent,
        PerfilComponent,
        TrabajadorComponent,
        MaquinaComponent,
        EstadisticasComponent,
        AveriasComponent,
        AveriaComponent,
        MantenimientoComponent,
        MantenimientosComponent,
        InformesTallerComponent,
        SellosceComponent,
        SelloceComponent,
        VacacionesComponent,
        CalendarioComponent,
        ListaCalendariosComponent,
        CalendarioFormComponent,
        PortesGondolaComponent,
        PorteGondolaComponent,
        CategoriasDocumentosComponent,
        CategoriaDocumentoComponent,
        EquiposComponent,
        EquipoComponent,
        SaldosComponent,
        SaldoVacacionesComponent,
        CierreHorasComponent,
        ConveniosComponent,
        ConvenioComponent,
        SaldoTrabajadorComponent,
        PartesTrabajoComponent,
        ParteTrabajoComponent,
        ParteTrabajoMensualComponent,
      ],
      exports: [
        PagesComponent,
      ],
      imports: [
        SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        ChartsModule,
        NgSelectModule,
        NgbModalModule,
        NgbTypeaheadModule,
        NgbTooltipModule,
        NgbTimepickerModule,
        NgbDropdownModule,
        NgbButtonsModule,
        NgToggleModule,
        NgbCarouselModule,
        NgxDropzoneModule,
        NgxDatatableModule,
        FullCalendarModule,
        TimelineModule,
        CardModule,
        ButtonModule,
        OverlayPanelModule,
        PAGES_ROUTES
    ]
})
export class PagesModule { }

