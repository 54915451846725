import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';

import { UsuarioService } from '../../services/service.index';


@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  materiasPrimasCol = collection( this.firestore, 'materiasprimas' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
              ) { }

  cargarArticulos( activos: boolean = true, desde: number = 0 ) {

    let consulta: Query<DocumentData>;
    if (activos) {
      consulta = query( this.materiasPrimasCol, where( 'estado', '==', true ), orderBy('nombreProveedor', 'asc'), orderBy('nombre', 'asc') );
    } else {
      consulta = query( this.materiasPrimasCol, orderBy('nombreProveedor', 'asc'), orderBy('nombre', 'asc') );
    }

    return collectionData( consulta, { idField: 'id' } );

  }

  obtenerArticuloPorId( articuloID: string ) {

    const articuloRef = doc( this.firestore, 'materiasprimas', articuloID );
    return docData( articuloRef, { idField: 'id' } );

  }

  crearArticulo( formData: any ): Promise<void | string> {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };
    formData.estado = true;
    formData.nombreProveedor = formData.proveedor.nombre;
    if (formData?.proveedor?.id) {
      formData.proveedor = doc( this.firestore, 'proveedores', formData.proveedor.id );
    } else {
      return Promise.reject('No se ha seleccionado un proveedor');
    }
    if (formData?.familia) {
      formData.familia = doc( this.firestore, 'familias_pb', formData.familia );
    } else {
      return Promise.reject('No se ha seleccionado una familia de producto');
    }
    if (formData?.unidades) {
      formData.unidades = doc( this.firestore, 'unidades', formData.unidades );
    } else {
      return Promise.reject('No se ha seleccionado una unidad de medida');
    }

    // TODO: Comprobar si existe y en caso positivo poner en activo
    return addDoc( this.materiasPrimasCol, formData )
      .then( docRef => {
        return updateDoc( docRef, { id: docRef.id } );
      });

}

  actualizarArticulo( articuloID: string, formData: any ) {

    formData.nombreProveedor = formData.proveedor.nombre;
    if (formData?.proveedor?.id) {
      formData.proveedor = doc( this.firestore, 'proveedores', formData.proveedor.id );
    } else {
      return Promise.reject('No se ha seleccionado un proveedor');
    }
    if (formData?.familia) {
      formData.familia = doc( this.firestore, 'familias_pb', formData.familia );
    } else {
      return Promise.reject('No se ha seleccionado una familia de producto');
    }
    if (formData?.unidades) {
      formData.unidades = doc( this.firestore, 'unidades', formData.unidades );
    } else {
      return Promise.reject('No se ha seleccionado una unidad de medida');
    }

    const articuloRef = doc( this.firestore, 'materiasprimas', articuloID );
    return updateDoc( articuloRef, {
        ...formData,
        'meta.modificado': {
          modificadoel: new Date(),
          modificadopor: {
            nombre: this.usuarioService.usuario.nombre,
            uid: this.usuarioService.usuario.uid
          }
        }
      });

  }

  borrarArticulo( articuloID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    const articuloRef = doc( this.firestore, 'materiasprimas', articuloID );
    return updateDoc( articuloRef, {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
