import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SortType } from '@swimlane/ngx-datatable';
import { PartesTrabajoService } from 'src/app/services/service.index';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-partes-trabajo',
  templateUrl: './partes-trabajo.component.html',
  styleUrls: ['./partes-trabajo.component.css']
})
export class PartesTrabajoComponent implements OnInit, OnDestroy {

  partesTrabajo: any = [];
  partesTrabajoTodos: any = [];
  autores: any = [];
  responsables: any = [];

  registrosPagina: number = 10;
  hoy: Date = new Date();
  primerDiaMes: Date = new Date( this.hoy.getFullYear(), this.hoy.getMonth(), 1, 0, 0, 0, 0 );
  ultimoDiaMes: Date = new Date( this.hoy.getFullYear(), this.hoy.getMonth() + 1, 0, 23, 59, 59, 999 );
  meses: any = [{ id: 1, nombre: 'Enero' }, { id: 2, nombre: 'Febrero' }, { id: 3, nombre: 'Marzo' }, { id: 4, nombre: 'Abril' }, { id: 5, nombre: 'Mayo' }, { id: 6, nombre: 'Junio' }, { id: 7, nombre: 'Julio' }, { id: 8, nombre: 'Agosto' }, { id: 9, nombre: 'Septiembre' }, { id: 10, nombre: 'Octubre' }, { id: 11, nombre: 'Noviembre' }, { id: 12, nombre: 'Diciembre' }];
  anios: any = [2024, 2025];
  fechaInicio: Date = this.primerDiaMes;
  fechaFin: Date = this.ultimoDiaMes;
  estado: string = 'pendientes';
  autor: string;
  responsable: string;
  delayCargaPartes: any;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = true;
  cargandoTabla: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  public filtrarForm = this.fb.group({
    rango: [ 'mes' ],
    mes: [ this.hoy.getMonth() + 1 ],
    anio: [ this.hoy.getFullYear() ],
    fechaInicio: [ formatDate( this.primerDiaMes, 'yyyy-MM-dd', 'es' ) ],
    fechaFin: [ formatDate( this.ultimoDiaMes, 'yyyy-MM-dd', 'es' ) ],
    autor: [ '' ],
    responsable: [ '' ],
  });

  constructor( private partesTrabajoService: PartesTrabajoService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.partesTrabajoService.getRegistrosPagina();
    this.sorts = this.partesTrabajoService.getSorts();
    this.offset = this.partesTrabajoService.getPage();
    this.cargarAutores();
    this.cargarResponsables();
    this.cargarPartesTrabajo();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarAutores() {
    this.subs.sink = this.partesTrabajoService.cargarAutores()
      .subscribe( ( autores: any ) => {
        this.autores = autores;
      });
  }

  cargarResponsables() {
    this.subs.sink = this.partesTrabajoService.cargarResponsables()
      .subscribe( ( responsables: any ) => {
        this.responsables = responsables;
      });
  }

  cargarPartesTrabajo(): void {

    this.cargandoTabla = true;

    this.subs.sink = this.partesTrabajoService.cargarPartesTrabajo(this.fechaInicio, this.fechaFin, this.estado, this.autor, this.responsable)
      .subscribe( async ( partesTrabajoDB: any ) => {
        this.totalRegistros = 0;
        this.partesTrabajo = [];
        if ( partesTrabajoDB.length > 0 ) {
          await Promise.all( partesTrabajoDB.map( async ( parteTrabajo: any ) => {
            parteTrabajo.autor = ( await getDoc( parteTrabajo.autor ) ).data();
            parteTrabajo.horarioOperarios = await Promise.all( parteTrabajo.horarioOperarios.map( async ( horarioOperario: any ) => {
              horarioOperario.operario = ( await getDoc( horarioOperario.operario ) ).data();
              horarioOperario.horario = '';
              if( parteTrabajo.individual ) {
                const jornada = horarioOperario?.jornada;
                const horaInicio = horarioOperario?.horaInicio ? formatDate( horarioOperario.horaInicio?.toDate(), 'HH:mm', 'es' ) : '';
                const horaFin = horarioOperario?.horaFin ? formatDate( horarioOperario.horaFin?.toDate(), 'HH:mm', 'es' ) : '';
                const horaInicioDescanso = horarioOperario?.horaInicioDescanso ? formatDate( horarioOperario.horaInicioDescanso?.toDate(), 'HH:mm', 'es' ) : null;
                const horaFinDescanso = horarioOperario?.horaFinDescanso ? formatDate( horarioOperario.horaFinDescanso?.toDate(), 'HH:mm', 'es' ) : null;
                const traslado = horarioOperario?.traslado ? ' + 1:00' : '';
                const horasTotales = parteTrabajo?.horasTotales ? ` => ${parteTrabajo.horasTotales} h.` : '';
                switch( jornada ) {
                  case 'diurna':
                  case 'nocturna':
                    horarioOperario.horario = horaInicioDescanso ? horaInicio + ' a ' + horaInicioDescanso + ' y de ' + horaFinDescanso + ' a ' + horaFin + traslado + horasTotales : horaInicio + ' a ' + horaFin + traslado + horasTotales;
                    break;
                  case 'temperil':
                    horarioOperario.horario = 'Temperil';
                    break;
                  case 'regulacion':
                    horarioOperario.horario = 'Regulación de horas';
                    break;
                  case 'vacaciones':
                    horarioOperario.horario = 'Vacaciones';
                    break;
                  case 'baja':
                    horarioOperario.horario = 'Baja';
                    break;
                }
              }
              return horarioOperario;
            }));
            parteTrabajo.responsables = await Promise.all( parteTrabajo.responsables.map( async ( responsable: any ) => ( await getDoc( responsable ) ).data() ) );
            parteTrabajo.responsables = parteTrabajo.responsables.map( ( responsable: any ) => responsable.nombre );
            switch( parteTrabajo.estado ) {
              case 'pendiente':
                parteTrabajo.estado = 'Pendiente';
                break;
              case 'aprobado':
              case 'aprobadoConCambios':
                parteTrabajo.estado = 'Aprobado';
                break;
              case 'rechazado':
                parteTrabajo.estado = 'Rechazado';
                break;
              case 'modificado':
                parteTrabajo.estado = 'Modificado';
                break;
            }
            return parteTrabajo;
          }));
          this.totalRegistros = partesTrabajoDB.length;
          this.partesTrabajo = partesTrabajoDB;
          this.partesTrabajoTodos = partesTrabajoDB;
          this.searchForm.controls.termino.enable();
        }
        this.cargando = false;
        this.cargandoTabla = false;
      });

  }

  buscarParteTrabajo( termino: string ) {

    // TODO

    if (termino.length <= 0) {
      this.totalRegistros = this.partesTrabajoTodos.length;
      this.partesTrabajo = this.partesTrabajoTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      this.partesTrabajo = this.partesTrabajoTodos.filter( ( parteTrabajo: any ) =>
        parteTrabajo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        parteTrabajo?.apellido1?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        parteTrabajo?.apellido2?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        parteTrabajo?.dni?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        parteTrabajo?.telefono?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        parteTrabajo?.email?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.partesTrabajo.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.partesTrabajoTodos.length;
      this.partesTrabajo = this.partesTrabajoTodos;
    }
  }

  editarParteTrabajo( id: string ) {
    this.router.navigate([ '/rrhh/partes-trabajo', id, 'editar' ]);
  }

  borrarParteTrabajo( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción eliminará el parte de trabajo.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.partesTrabajoService.eliminarParteTrabajo( id )
          .then( res => {
            Swal.fire(
              '¡Eliminado!',
              'El parte de trabajo ha sido eliminado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  actualizarEstadoPartesTrabajo( event: any ) {
    this.estado = event.target.value;
    this.cargarPartesTrabajo();
  }

  actualizarRangoFechas( event: any ) {
    switch( event ) {
      case 'dia':
      case 'periodo':
        this.filtrarForm.controls.fechaInicio.setValue( formatDate( this.hoy, 'yyyy-MM-dd', 'es' ) );
        this.filtrarForm.controls.fechaFin.setValue( formatDate( this.hoy, 'yyyy-MM-dd', 'es' ) );
        this.fechaInicio = this.hoy;
        this.fechaFin = this.hoy;
        this.cargarPartesTrabajo();
        break;
      case 'mes':
        this.filtrarForm.controls.fechaInicio.setValue( formatDate( this.primerDiaMes, 'yyyy-MM-dd', 'es' ) );
        this.filtrarForm.controls.fechaFin.setValue( formatDate( this.ultimoDiaMes, 'yyyy-MM-dd', 'es' ) );
        this.fechaInicio = this.primerDiaMes;
        this.fechaFin = this.ultimoDiaMes;
        this.cargarPartesTrabajo();
        break;
    }
  }

  actualizarFechas( event: any ) {

    clearTimeout( this.delayCargaPartes );
    this.delayCargaPartes = setTimeout(() => {

      if ( this.filtrarForm.controls.rango.value === 'periodo' ) {
        const inicio = new Date( this.filtrarForm.controls.fechaInicio.value );
        inicio.setHours( 0, 0, 0, 0 );
        const fin = new Date( this.filtrarForm.controls.fechaFin.value );
        fin.setHours( 23, 59, 59, 999 );
        this.fechaInicio = inicio;
        this.fechaFin = fin;
      } else {
        const inicio = new Date( this.filtrarForm.controls.fechaInicio.value );
        inicio.setHours( 0, 0, 0, 0 );
        const fin = new Date( this.filtrarForm.controls.fechaInicio.value );
        fin.setHours( 23, 59, 59, 999 );
        this.fechaInicio = inicio;
        this.fechaFin = fin;
      }

      this.cargarPartesTrabajo();

    }, 500);

  }

  actualizarMesAnio( event: any ) {
    const mes = this.filtrarForm.controls.mes.value;
    const anio = this.filtrarForm.controls.anio.value;
    this.fechaInicio = new Date( anio, mes - 1, 1, 0, 0, 0, 0 );
    this.fechaFin = new Date( anio, mes, 0, 23, 59, 59, 999 );
    this.cargarPartesTrabajo();
  }

  actualizarAutor( event: any ) {
    const autor = this.filtrarForm.controls.autor.value;
    this.autor = autor;
    if ( autor === '' ) {
      this.autor = null;
    }
    this.cargarPartesTrabajo();
  }

  actualizarResponsable( event: any ) {
    const responsable = this.filtrarForm.controls.responsable.value;
    this.responsable = responsable;
    if ( responsable === '' ) {
      this.responsable = null;
    }
    this.cargarPartesTrabajo();
  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.partesTrabajoService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.partesTrabajoService.setPage( event.offset );
  }

  onSort(event) {
    this.partesTrabajoService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
