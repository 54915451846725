import { Component, OnInit } from '@angular/core';

import { EstadisticasService } from 'src/app/services/planta/estadisticas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-estadisticas',
  templateUrl: './estadisticas.component.html',
  styles: [
  ]
})
export class EstadisticasComponent implements OnInit {

  constructor( private estadisticasservice: EstadisticasService ) { }

  ngOnInit(): void {
  }

  // Reiniciar las estadísticas de la planta
  reiniciarEstadisticas(): void {

    this.estadisticasservice.reiniciarEstadisticas();

  }

  // Rehacer las partes de trabajo para añadir subcolleccion de horarioOperarios
  rehacerPartesTrabajo(): void {

    Swal.fire({
      title: '¿Estás seguro de querer rehacer las partes de trabajo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Rehacer',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.estadisticasservice.rehacerPartesTrabajo();
      }
    });

  }

  // Actualizar el nombre de los clientes en las obras
  actualizarNombreClientesObras(): void {

    Swal.fire({
      title: '¿Estás seguro de querer actualizar el nombre de los clientes en las obras?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Actualizando...',
          icon: 'info',
          showCancelButton: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
            this.estadisticasservice.actualizarNombreClientesObras()
              .then( () => {
                Swal.fire('Nombre de los clientes actualizado', '', 'success');
              })
              .catch( error => {
                Swal.fire('Error al actualizar el nombre de los clientes en las obras', error, 'error');
              })
              .finally( () => {
                Swal.close();
              });
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    });

  }

}
