<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

  <div class="col-sm-12">

      <div class="alert text-center" [ngClass]="cargandoClass">
          <strong>{{ txtCargando }}</strong>
          <br>
          <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
          <br>
          <span>Espere por favor</span>
      </div>

  </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
  <div class="col-sm-12">
      <div class="card">
          <div class="card-header bg-info">
              <h4 class="card-title text-white">Equipo</h4>
          </div>
          <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="equipoForm" (ngSubmit)="crearEquipo()">
              <div class="card-body">
                  <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                  <div class="row">

                      <div class="col-sm-12 col-lg-3">
                        <div class="form-group">
                          <label>Nombre*</label>
                          <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                          <div class="invalid-feedback">
                            El nombre es obligatorio
                          </div>
                        </div>

                      </div>

                      <div class="col-sm-12 col-lg-3">
                        <label for="encargado">Encargado</label>
                        <ng-select
                          [items]="encargados"
                          bindValue="id"
                          bindLabel="nombre"
                          labelForId="encargado"
                          placeholder="Elige un encargado..."
                          notFoundText="No hay encargados disponibles"
                          clearAllText="Limpiar"
                          formControlName="encargado"
                          [clearable]="false"
                          [loadingText]="'Cargando...'"
                        >
                        </ng-select>
                      </div>

                  </div>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-lg-5">
                      <ngx-datatable #table class='bootstrap'
                        [columnMode]="'flex'"
                        [headerHeight]="40"
                        [footerHeight]="50"
                        [rowHeight]="'auto'"
                        [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                        [limit]="20"
                        [rows]='operarios'
                      >
                        <ngx-datatable-column name="Código" [flexGrow]="2">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['numnavision'] }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Apellidos" [flexGrow]="4">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['apellido1'] | uppercase }} {{ row['apellido2'] | uppercase }}</span>
                          </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Nombre" prop="nombre" [flexGrow]="4">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                            <span>{{ row['nombre'] | uppercase }}</span>
                          </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                            <div style="padding: 5px 10px">
                              <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                            </div>
                            <datatable-pager
                              [pagerLeftArrowIcon]="'datatable-icon-left'"
                              [pagerRightArrowIcon]="'datatable-icon-right'"
                              [pagerPreviousIcon]="'datatable-icon-prev'"
                              [pagerNextIcon]="'datatable-icon-skip'"
                              [page]="curPage"
                              [size]="pageSize"
                              [count]="rowCount"
                              [hidden]="!((rowCount / pageSize)> 1)"
                              (change)="table.onFooterPage($event)"
                            >
                            </datatable-pager>
                          </ng-template>
                        </ngx-datatable-footer>
                      </ngx-datatable>
                    </div>
                  </div>
                  <div class="row">
                      <div class="col text-danger">

                          <p *ngIf="campoNoValido('nombre')">
                              Hay errores en el formulario. Corrígelos para poder guardar la información.
                          </p>

                      </div>
                  </div>

              </div>
              <hr>
              <div class="card-body">
                  <div class="form-group mb-0 text-right">
                      <button *ngIf="!equipoID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                      <button *ngIf="equipoID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarEquipo()">Actualizar</button>
                      <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarEquipo()">Cancelar</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</div>
