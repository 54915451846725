import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  partesTrabajoCol = collection( this.firestore, 'produccion_parte_trabajo' );

  constructor( private firestore: Firestore ) { }

  reiniciarEstadisticas(): void {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción eliminara todos los datos de estadísticas y los volvera a crear.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, reiniciar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        const estadisCol = collection( this.firestore, 'estadisticasplantas');
        firstValueFrom( collectionData( estadisCol, { idField: 'id' } ) )
          .then( async estadisColData => {
            for await ( const docData of estadisColData ) {
              const docRef = doc( this.firestore, 'estadisticasplantas', docData.id );
              await deleteDoc( docRef );
            }
            Swal.fire('¡Eliminados!', 'Los datos han sido eliminados.', 'success');
          })
          .catch( err => console.error(err) )
          .finally(() => {
            const salidasCol = collection( this.firestore, 'salidas');
            firstValueFrom( collectionData( salidasCol, { idField: 'id' } ) )
              .then( async salidasColData => {
                for await ( const docData of salidasColData ) {
                  await this.estadisticas( 'salida', 'add', docData );
                }
                Swal.fire('Creados!', 'Los datos de salidas han sido creados nuevamente.', 'success');
              })
              .catch( err => console.error(err) );

            const entradasCol = collection( this.firestore, 'entradas');
            firstValueFrom( collectionData( entradasCol, { idField: 'id' } ) )
              .then( async entradasColData => {
                for await ( const docData of entradasColData ) {
                  await this.estadisticas( 'entrada', 'add', docData );
                }
                Swal.fire('Creados!', 'Los datos de entradas han sido creados nuevamente.', 'success');
              })
              .catch( err => console.error(err) );

          });

      }

    });

  }

  async estadisticas( tipo: string, operacion: string, data: any, diferencia: number = 0 ) {

    let familiaForm = '';
    if ( data.formula && data.tipo === 'pt' ) {
      const formulaData: any = (await getDoc( data.formula )).data();
      familiaForm = formulaData.codigo.slice( 0, 3 );
    }
    let conta = 1;
    if ( operacion === 'del' ) {
      data.neto = -Math.round((data.neto + Number.EPSILON) * 100) / 100;
      conta = -1;
    } else if ( operacion === 'mod' ) {
      data.neto = Math.round((diferencia + Number.EPSILON) * 100) / 100;
      conta = 0;
    } else {
      data.neto = Math.round((data.neto + Number.EPSILON) * 100) / 100;
    }
    if ( !(data.fecha instanceof Date && !isNaN(data.fecha)) ) {
      data.fecha = data.fecha.toDate();
    }
    const dia = new Date( data.fecha.getFullYear(), data.fecha.getMonth(), data.fecha.getDate() );
    const mes = new Date( data.fecha.getFullYear(), data.fecha.getMonth(), 1 );
    const anio = new Date( data.fecha.getFullYear(), 0, 1 );
    const estadisticas = {
      planta: null,
      tipo,
      subtipo: data?.tipo ?? '',
      periodo: null,
      fecha: null,
      material: data.articulo || data.formula,
      familia: familiaForm,
      neto: data.neto,
      contador: conta,
      proveedor: null,
      cliente: null,
      obra: data.obra,
      seguimiento: true
    };
    if ( tipo === 'salida' ) {
      estadisticas.planta = data.planta;
      estadisticas.cliente = data.cliente;
    } else {
      estadisticas.proveedor = data.proveedor;
    }
    const diaEstadisticas = { ...estadisticas };
    diaEstadisticas.periodo = 'dia';
    diaEstadisticas.fecha = dia;
    const diaPromise = await this.actualizarEstadisticas( tipo, 'dia', diaEstadisticas );
    const mesEstadisticas = { ...estadisticas };
    mesEstadisticas.periodo = 'mes';
    mesEstadisticas.fecha = mes;
    const mesPromise = await this.actualizarEstadisticas( tipo, 'mes', mesEstadisticas );
    const anioEstadisticas = { ...estadisticas };
    anioEstadisticas.periodo = 'anio';
    anioEstadisticas.fecha = anio;
    const anioPromise = await this.actualizarEstadisticas( tipo, 'anio', anioEstadisticas );
    const origenEstadisticas = { ...estadisticas };
    origenEstadisticas.periodo = 'origen';
    origenEstadisticas.fecha = null;
    origenEstadisticas.cliente = null;
    origenEstadisticas.obra = null;
    const origenPromise = await this.actualizarEstadisticas( tipo, 'origen', origenEstadisticas );

    return Promise.all([diaPromise, mesPromise, anioPromise, origenPromise])
      .then( values => {
        return values[3]; // Devolver el acumulado de la formula
      });
  }

  actualizarEstadisticas( tipo: string, periodo: string, data: any ): Promise<void|number> {

    const estCol = collection( this.firestore, 'estadisticasplantas');
    let consulta: Query<DocumentData>;
    if ( tipo === 'salida' ) {
      consulta = query( estCol, where('planta', '==', data.planta), where('tipo', '==', tipo), where('material', '==', data.material), where('periodo', '==', periodo) );
      if ( periodo !== 'origen' ) {
        consulta = query( estCol, where('planta', '==', data.planta), where('tipo', '==', tipo), where('material', '==', data.material), where('periodo', '==', periodo), where('obra', '==', data.obra), where('fecha', '==', data.fecha) );
      }
    } else {
      consulta = query( estCol, where('tipo', '==', tipo), where('obra', '==', data.obra), where('material', '==', data.material), where('proveedor', '==', data.proveedor), where('periodo', '==', periodo) );
      if ( periodo !== 'origen' ) {
        consulta = query( estCol, where('tipo', '==', tipo), where('obra', '==', data.obra), where('material', '==', data.material), where('proveedor', '==', data.proveedor), where('periodo', '==', periodo), where('fecha', '==', data.fecha) );
      }
    }
    return firstValueFrom( collectionData( consulta, { idField: 'id' } ) )
      .then( async (docToUpd) => {
        // Si ya hay un registro anterior actualizar con el nuevo registro
        if ( docToUpd.length ) {
          const netoAnterior = Math.round((docToUpd[0]['neto'] + Number.EPSILON) * 100) / 100;
          const contadorAnterior = docToUpd[0]['contador'];
          const familia = docToUpd[0]['familia'];
          const docRef = doc( this.firestore, 'estadisticasplantas', docToUpd[0].id );
          const netoRedondeado = Math.round((netoAnterior + data.neto + Number.EPSILON) * 100) / 100;
          return updateDoc( docRef, {
              neto: netoRedondeado,
              contador: contadorAnterior + data.contador
            }).then( () => {
              // Si el registro es de salida y tiene una familia asignada añadir al origenagrupado el valor de origen
              if ( periodo === 'origen' && tipo === 'salida' && familia !== '' ) {
                let consulta2: Query<DocumentData>;
                consulta2 = query( estCol, where('familia', '==', familia), where('periodo', '==', 'origenagrupado') );
                return firstValueFrom( collectionData( consulta2, { idField: 'id' } ) )
                    .then( docAcumToUpd => {
                      data.periodo = 'origenagrupado';
                      // Si ya hay un registro anterior actualizar con el nuevo registro acumulado a origen
                      if ( docAcumToUpd.length > 0 ) {
                        const netoAnteriorAgrupado = Math.round((docAcumToUpd[0]['neto'] + Number.EPSILON) * 100) / 100;
                        const contadorAnteriorAgrupado = docAcumToUpd[0]['contador'];
                        const docAcumRef = doc( this.firestore, 'estadisticasplantas', docAcumToUpd[0].id );
                        updateDoc( docAcumRef, {
                            neto: netoAnteriorAgrupado + data.neto,
                            contador: contadorAnteriorAgrupado + data.contador
                          }).catch( err => console.error( 'Error al actualizar acumulado a origenagrupado (linea 180): ' + err ));
                        return netoAnteriorAgrupado + data.neto;
                      // Si no hay un registro anterior añadir el nuevo registro acumulado a origen
                      } else {
                        data.material = null;
                        data.neto = netoAnterior + data.neto;
                        addDoc( estCol, data )
                          .then( docAcumRef => {
                            updateDoc( docAcumRef, {
                              id: docAcumRef.id
                            }).catch( err => console.error( 'Error al actualizar el id del registro acumulado a origenagrupado (linea 190): ' + err ));
                          });
                        return data.neto;
                      }
                    });
              }
            })
            .catch( err => console.error( 'Error al actualizar el registro (linea 197): ' + err ));
        // Si no hay un registro anterior añadir el nuevo registro
        } else {
          return addDoc( estCol, data )
            .then( docRef => {
              updateDoc( docRef, {
                  id: docRef.id
                }).then( async () => {
                  // Si el registro es de salida añadir al origenagrupado el valor de origen
                  if ( periodo === 'origen' && tipo === 'salida' ) {
                    const docSnap = await getDoc( docRef );
                    const familia = await docSnap.get('familia');
                    if (familia !== '') {
                      let consulta3: Query<DocumentData>;
                      consulta3 = query( estCol, where('familia', '==', familia), where('periodo', '==', 'origenagrupado') );
                      return firstValueFrom( collectionData( consulta3, { idField: 'id' } ) )
                          .then( docAcumToUpd => {
                            data.periodo = 'origenagrupado';
                            // Si ya hay un registro anterior actualizar con el nuevo registro acumulado a origen
                            if ( docAcumToUpd.length > 0 ) {
                              const netoAnteriorAgrupado = Math.round((docAcumToUpd[0]['neto'] + Number.EPSILON) * 100) / 100;
                              const contadorAnteriorAgrupado = docAcumToUpd[0]['contador'];
                              const docAcumRef = doc( this.firestore, 'estadisticasplantas', docAcumToUpd[0].id );
                              updateDoc( docAcumRef, {
                                  neto: netoAnteriorAgrupado + data.neto,
                                  contador: contadorAnteriorAgrupado + data.contador
                                }).catch( err => console.error( 'Error al actualizar acumulado a origenagrupado (linea 223): ' + err ));
                              return netoAnteriorAgrupado + data.neto;
                            // Si no hay un registro anterior añadir el nuevo registro acumulado a origen
                            } else {
                              data.material = null;
                              addDoc( estCol, data )
                                .then( docAcumRef => {
                                  updateDoc( docAcumRef, {
                                    id: docAcumRef.id
                                  }).catch( err => console.error( 'Error al actualizar el id del registro acumulado a origen (linea 232): ' + err ));
                                });
                              return data.neto;
                            }
                          });
                    }
                  }
                });
            })
            .catch( err => console.error( 'Error al añadir el registro (linea 241): ' + err ));
        }
      });
  }

  obtenerProximoMuestreo( idFormula: string, codigo: string ) {

    const registroFormulasCol = collection( this.firestore, 'gema_registroformulas');
    const formulaRef = doc( this.firestore, 'formulas', idFormula );
    const codigoFamilia = codigo.substring(0, 3);

    let consulta: Query<DocumentData>;
    consulta = query( registroFormulasCol, where('selFamForm', '==', 'formula'), where('formula', '==', formulaRef) );
    const muestreoFormula = collectionData( consulta, { idField: 'id' } );

    let consulta2: Query<DocumentData>;
    consulta2 = query( registroFormulasCol, where('selFamForm', '==', 'familia'), where('codfamilia', '==', codigoFamilia) );
    const muestreoFamilia = collectionData( consulta2, { idField: 'id' } );

    const muestreos = [ firstValueFrom( muestreoFormula ), firstValueFrom( muestreoFamilia ) ];

    return Promise.all( muestreos );
  }

  // Rehacer las partes de trabajo para añadir subcolleccion de horarioOperarios
  async rehacerPartesTrabajo(): Promise<void> {

    const partesTrabajo = await getDocs( this.partesTrabajoCol );

    for await ( const parte of partesTrabajo.docs ) {

      const parteRef = parte.ref;
      const horariosCol = collection( this.firestore, parteRef.path, 'horarioOperarios' );

      const parteData = parte.data();

      const horarios = parteData.horarioOperarios;

      for await ( const horario of horarios ) {

        const horarioRef = await addDoc( horariosCol, horario );

        await updateDoc( horarioRef, { id: horarioRef.id });

      }

    };

  }

  // Actualizar el nombre de los clientes en las obras
  async actualizarNombreClientesObras(): Promise<void> {

    const clientesCol = collection( this.firestore, 'clientes' );
    const obrasCol = collection( this.firestore, 'obras' );

    const clientes = await getDocs( clientesCol );

    for await ( const cliente of clientes.docs ) {

      const obras = await getDocs( query( obrasCol, where('cliente', '==', cliente.ref) ) );

      for await ( const obra of obras.docs ) {
        updateDoc( obra.ref, { 'nombrecliente': cliente.data().nombre } );
      };

    }

    return Promise.resolve();
  }

}

