import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Workbook } from 'exceljs';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportEntradas( entradas: any ): void {

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Entradas');
    worksheet.columns = [
      { header: 'Fecha', key: 'fecha', width: 11 },
      { header: 'Hora', key: 'hora', width: 11 },
      { header: 'Fecha Albaran', key: 'fechaAlbaran', width: 15 },
      { header: 'Hora Albaran', key: 'horaAlbaran', width: 15 },
      { header: 'Albaran', key: 'albaran', width: 11 },
      { header: 'Planta', key: 'planta', width: 11 },
      { header: 'Proveedor', key: 'proveedor', width: 25 },
      { header: 'Articulo', key: 'articulo', width: 46 },
      { header: 'Neto', key: 'neto', width: 9 },
      { header: 'Ud', key: 'ud', width: 9 },
      { header: 'Matricula', key: 'matricula', width: 9 },
    ];

    const titleRow = worksheet.getRow(1);

    titleRow.eachCell( (cell, rownumber) => {
      cell.font = {
        bold: true
      };
    });

    entradas.forEach( entrada =>  {

      let auxMatricula = '';
      if ( entrada?.matricula?.matricula ) {
        auxMatricula = entrada.matricula.matricula?.toUpperCase() ?? '';
      }

      const fecha = formatDate( entrada.fecha.toDate(), 'dd/MM/yyyy', 'es' );
      const fechaAlbaran = formatDate( entrada.fechaalbaran.toDate(), 'dd/MM/yyyy', 'es' );

      worksheet.addRow({
        fecha: fecha,
        hora: formatDate( entrada.fecha.toDate(), 'HH:mm', 'es' ),
        fechaAlbaran: fechaAlbaran,
        horaAlbaran: ( entrada.horaalbaran !== null ) ? entrada.horaalbaran : '',
        albaran: entrada.numalbaran,
        planta: entrada.obra?.codigo?.toUpperCase() ?? '',
        proveedor: entrada.proveedor?.nombre?.toUpperCase() ?? '',
        articulo: entrada.articulo?.nombre?.toUpperCase() ?? '',
        neto: entrada.netoalbaran,
        ud: entrada.unidad?.codigo?.toUpperCase() ?? '',
        matricula: auxMatricula,
      });

      const row = worksheet.lastRow;

      if ( fecha !== fechaAlbaran ) {
        row.getCell( 'fechaAlbaran' ).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FCD5B4' }
        };
      }

    });

    // worksheet.autoFilter = 'A1:I1'; TODO: revisar como solucionar el error al ordenar

    workbook.xlsx.writeBuffer()
      .then((dataBuffer) => {
        this.saveExcelFile(dataBuffer, 'Entradas');
      });
  }

  public exportSalidas( salidas: any ): void {

    const workbook = new Workbook();

    // TODO: Añadir hoja de resumen
    // const worksheetRes = workbook.addWorksheet('Resumen');
    // const salidasResumen = [...salidas];
    // salidasResumen.sort(( a, b ) => {
    //     if ( a.obra.codigo > b.obra.codigo ) {
    //       return 1;
    //     } else if ( a.obra.codigo === b.obra.codigo ) {
    //       if ( a.articulo > b.articulo ) {
    //         return 1;
    //       } else if ( a.articulo === b.articulo ) {
    //         if ( a.fecha > b.fecha ) {
    //           return 1;
    //         } else if ( a.fecha === b.fecha ) {
    //           return 0;
    //         } else {
    //           return -1;
    //         }
    //       } else {
    //         return -1;
    //       }
    //     } else {
    //       return -1;
    //     }
    //   }
    // );
    // salidas = salidasResumen;
    // console.log(salidasResumen);


    const worksheetSal = workbook.addWorksheet('Salidas');
    worksheetSal.columns = [
      { header: 'Fecha', key: 'fecha', width: 11 },
      { header: 'Hora Salida Planta', key: 'hora', width: 11 },
      { header: 'Hora Entrada Obra', key: 'horaEntrada', width: 11 },
      { header: 'Hora Inicio Descarga', key: 'horaInicioDescarga', width: 11 },
      { header: 'Hora Fin Descarga', key: 'horaFinDescarga', width: 11 },
      { header: 'Hora Salida Obra', key: 'horaSalida', width: 11 },
      { header: 'Albaran', key: 'albaran', width: 11 },
      { header: 'Planta', key: 'planta', width: 11 },
      { header: 'Cliente', key: 'cliente', width: 35 },
      { header: 'Obra', key: 'obra', width: 63 },
      { header: 'Formula', key: 'formula', width: 50 },
      { header: 'Tara', key: 'tara', width: 9 },
      { header: 'Neto', key: 'neto', width: 9 },
      { header: 'Temp.', key: 'temperatura', width: 9 },
      { header: 'Matricula', key: 'matricula', width: 12 },
      { header: 'Chofer', key: 'chofer', width: 12 },
      { header: 'Portes', key: 'portes', width: 12 },
      { header: 'Comentarios', key: 'comentarios', width: 50 }
    ];

    const titleRow = worksheetSal.getRow(1);

    titleRow.eachCell( (cell, rownumber) => {
      cell.font = {
        bold: true
      };
    });

    salidas.forEach( salida =>  {

      let articulo = '';
      switch ( salida.tipo ) {
        case 'pt':
          articulo = salida.formula?.codigo + ' - ' + salida.formula?.nombre?.toUpperCase();
          break;
        case 'pb':
          articulo = salida.articulo?.nombre?.toUpperCase() + ' (' + salida.articulo?.nombreProveedor?.toUpperCase() + ')';
          break;
        case 're':
          articulo = salida.articulo?.nombre?.toUpperCase();
          break;
      }
      let portesSiNo = 'No';
      if (salida.enviado) {
        portesSiNo = 'Si';
      }
      worksheetSal.addRow({
        fecha: formatDate( salida.fecha.toDate(), 'dd/MM/yyyy', 'es' ),
        hora: formatDate( salida.fecha.toDate(), 'HH:mm', 'es' ),
        horaEntrada: salida?.situacion?.horaEntrada ? formatDate( salida.situacion.horaEntrada.toDate(), 'HH:mm', 'es' ) : '',
        horaInicioDescarga: salida?.situacion?.horaDescargando ? formatDate( salida.situacion.horaDescargando.toDate(), 'HH:mm', 'es' ) : '',
        horaFinDescarga: salida?.situacion?.horaDescargado ? formatDate( salida.situacion.horaDescargado.toDate(), 'HH:mm', 'es' ) : '',
        horaSalida: salida?.situacion?.horaSalida ? formatDate( salida.situacion.horaSalida.toDate(), 'HH:mm', 'es' ) : '',
        albaran: salida.prefalbaran + '-' + salida.albaran,
        planta: salida.planta.codigo?.toUpperCase() ?? '',
        cliente: salida.cliente?.nombre.toUpperCase() ?? '',
        obra: salida.obra?.codigo.toUpperCase() + ' - ' + salida.obra?.nombre.toUpperCase(),
        formula: articulo,
        tara: salida.tara,
        neto: salida.neto,
        temperatura: salida.temperatura,
        matricula: salida?.matricula?.matricula?.toUpperCase() ?? '',
        chofer: salida?.matricula?.chofer?.toUpperCase() ?? '',
        portes: portesSiNo,
        comentarios: salida.comentarios,
      });
    });

    // worksheet.autoFilter = 'A1:K1'; TODO: revisar como solucionar el error al ordenar

    worksheetSal.getColumn('tara').numFmt = '0.00';
    worksheetSal.getColumn('neto').numFmt = '0.00';

    workbook.xlsx.writeBuffer()
      .then((dataBuffer) => {
        this.saveExcelFile(dataBuffer, 'Salidas');
      });

  }

  public exportAverias( salidas: any ): void {}

  public exportMantenimientos( salidas: any ): void {}

  public exportHorometros( salidas: any ): void {}

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

}
