<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">

    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Mantenimiento</h4>
            </div>

            <div class="card-body">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="mantenimientoForm" (ngSubmit)="crearMantenimiento()">
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label *ngIf="!modoAviso">Fecha*</label>
                                <label *ngIf="modoAviso">Fecha Aviso*</label>
                                <input type="date" class="form-control" placeholder="Fecha" required [ngClass]="{ 'is-invalid': campoNoValido('fecha') }" formControlName="fecha" [readonly]="modoLectura" *ngIf="!modoAviso">
                                <input type="date" class="form-control" placeholder="Fecha" required [ngClass]="{ 'is-invalid': campoNoValido('fechaAviso') }" formControlName="fechaAviso" [readonly]="modoLectura" *ngIf="modoAviso">
                                <div class="invalid-feedback">
                                    La fecha es obligatoria
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            <div class="form-group">
                                <label>Maquina*</label>
                                <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Maquina'" required [ngClass]="{ 'is-invalid': campoNoValido('maquina') }" formControlName="maquina" [ngbTypeahead]="maquinasearch" [inputFormatter]="maquinaformat" [resultFormatter]="maquinaformat" [editable]="false" (selectItem)="selecMaquina($event)"
                                    (ngModelChange)="verificarMaquina()" [readonly]="modoLectura">
                                <div class="invalid-feedback">
                                    La maquina es obligatoria
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row mb-3">
                        <div class="col-sm-12 col-lg-4">
                            <label *ngIf="!modoAviso">Horometro/Km</label>
                            <label *ngIf="modoAviso">Horometro/Km Aviso</label>
                            <div class="input-group">
                                <input type="text" class="form-control" [value]="(txtHorometro) ? txtHorometro + ' ' + txtUdHorometro : ''" [readonly]="modoLectura" *ngIf="modoLectura && !modoAviso">
                                <input type="text" class="form-control" [value]="(txtHorometroAviso) ? txtHorometroAviso + ' ' + txtUdHorometroAviso : ''" [readonly]="modoLectura" *ngIf="modoLectura && modoAviso">
                                <input type="number" class="form-control" formControlName="horometro" *ngIf="!modoLectura && !modoAviso">
                                <input type="number" class="form-control" formControlName="horometroAviso" *ngIf="!modoLectura && modoAviso">
                                <select class="custom-select col-2" formControlName="udHorometro" *ngIf="!modoLectura && !modoAviso">
                                    <option value="h">h.</option>
                                    <option value="km">Km.</option>
                                </select>
                                <select class="custom-select col-2" formControlName="udHorometroAviso" *ngIf="!modoLectura && modoAviso">
                                    <option value="h">h.</option>
                                    <option value="km">Km.</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-row" *ngIf="!modoLectura || (modoLectura && mantenimientoForm.controls?.estado?.value === 'realizado')">
                        <div class="col-sm-4 col-lg-1 overflow-hidden">
                            <ng-template #tipAceite>
                                <div class="d-flex flex-column">
                                    <span>Aceite</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">Aceite</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="aceite">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden">
                            <ng-template #tipHidráulico>
                                <div class="d-flex flex-column">
                                    <span>Hidráulico</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">Hidráulico</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="hidraulico">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden">
                            <ng-template #tipOtros>
                                <div class="d-flex flex-column">
                                    <span>Otros</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">Otros</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="otros">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroAceite" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroAceite>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aceite</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Aceite</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtroaceite">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroGasoil" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroGasoil>
                                <div class="d-flex flex-column">
                                    <span>Filtro Gasoil</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Gasoil</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtrogasoil">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroAire" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroAire>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aire</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Aire</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtroaire">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroHidraulico" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroHidraulico>
                                <div class="d-flex flex-column">
                                    <span>Filtro Hidráulico</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Hidráulico</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtrohidraulico">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroAgua" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroAgua>
                                <div class="d-flex flex-column">
                                    <span>Filtro Agua</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Agua</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtroagua">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroAceiteConvertidor" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroAceiteConvertidor>
                                <div class="d-flex flex-column">
                                    <span>Filtro Aceite Convertidor</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Aceite Conv.</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtroaceiteconvertidor">
                                </ng-toggle>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-1 overflow-hidden" [ngbTooltip]="tipFiltroOtros" tooltipClass="tooltip-custom" container="body">
                            <ng-template #tipFiltroOtros>
                                <div class="d-flex flex-column">
                                    <span>Filtro Otros</span>
                                </div>
                            </ng-template>
                            <label class="text-nowrap">F. Otros</label>
                            <div class="form-group">
                                <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="filtrootros">
                                </ng-toggle>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-4 col-lg-8">
                            <div class="form-group">
                                <label>Descripción</label>
                                <textarea class="form-control" [placeholder]="(modoLectura) ? '' : 'Descripción del mantenimiento...'" rows="2" formControlName="descripcion" [readonly]="modoLectura"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-sm-12 col-lg-4">
                            <label>Estado*</label>
                            <div class="input-group" *ngIf="!modoLectura">
                                <select id="situacion" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('estado') }" formControlName="estado">
                                    <option value="">Elige un estado...</option>
                                    <option value="aviso" *ngIf="modoEditar && mantenimientoForm.get('estado')?.value === 'aviso'">Aviso</option>
                                    <option value="pendiente">Pendiente</option>
                                    <option value="en_curso">En curso</option>
                                    <option value="realizado">Realizado</option>
                                </select>
                                <div class="invalid-feedback">
                                    El estado es obligatorio. Elige uno.
                                </div>
                            </div>
                            <input type="text" class="form-control" [value]="estado" [readonly]="modoLectura" *ngIf="modoLectura">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-danger">

                            <p *ngIf="campoNoValido('fecha') || campoNoValido('fechaAviso') || campoNoValido('maquina') || selectNoValido('estado')">
                                Hay errores en el formulario. Corrígelos para poder guardar la información.
                            </p>

                        </div>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="form-group mb-0 text-right">
                            <button *ngIf="!mantenimientoID && !modoLectura" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                            <button *ngIf="mantenimientoID && !modoLectura" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarMantenimiento()">Actualizar</button>
                            <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarMantenimiento()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                        </div>
                    </div>
                </form>

            </div>

        </div>

    </div>

</div>
