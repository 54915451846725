import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { DocumentData, Firestore, QuerySnapshot, collection, getDoc, getDocs, orderBy, query, where } from '@angular/fire/firestore';

import { Observable, switchMap } from 'rxjs';
import jspdf from 'jspdf';
import { font } from '../../../../assets/fonts/fa-solid-900-normal';

@Injectable({
  providedIn: 'root'
})
export class TallerJsPdfService {

  imgLogo: string;
  imgLogoNuevo: string;
  imgFirma: string;

  colorOrsa: string = '#ff4700';

  constructor( private http: HttpClient,
               private firestore: Firestore,
              ) {

    // Logo Orsa
    this.http.get('../../assets/images/logo-albaran.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgLogo = base64ImageUrl;
      });

    // Logo Orsa nuevo
    this.http.get('../../assets/images/logo-albaran-nuevo.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgLogoNuevo = base64ImageUrl;
      });

    // Firma Marga
    this.http.get('../../assets/images/firma-marga.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgFirma = base64ImageUrl;
      });

  }

  anadirMarcaAgua( jspdf: any, x: number, y: number, size: number, texto: any[] ) {

    jspdf.setTextColor('#f5f5f5');
    jspdf.setFontSize(size);
    jspdf.setFont('helvetica', 'bold');
    jspdf.text(texto, x, y, {angle: 45});
    jspdf.setTextColor('#000000');

  }

  /////////////////////////////////////////////////
  // INFORME AVERIAS                             //
  /////////////////////////////////////////////////

  cabeceraAverias( doc: jspdf, maquina: any, formData: any ) {

    // Añadir logo
    if ( Date.now() >= new Date('2024-07-01').getTime() ) {
      if ( this.imgLogoNuevo ) {
        doc.addImage(this.imgLogoNuevo, 'PNG', 7, 5, 55, 23);
      }
    } else {
      if ( this.imgLogo ) {
        doc.addImage(this.imgLogo, 'PNG', 7, 5, 55, 23);
      }
    }

    // Añadir textos fijos
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    const matricula = (maquina?.matricula) ? ' (' + maquina.matricula + ')' : '';
    const numserie = (maquina?.numserie) ? ' (Nº SERIE: ' + maquina.numserie + ')' : '';
    const identificador = matricula.length > 0 ? matricula : numserie;
    const nombreMaquina = maquina.codigo + ' - ' + maquina.nombre + identificador;
    let txtRango = 'A ORIGEN';
    if (formData.rango === 'mes') {
      const mes = formatDate( formData.mes, 'MMMM yyyy', 'es' );
      txtRango = 'DEL MES DE ' + mes?.toLocaleUpperCase();
    } else if (formData.rango === 'dh') {
      const desde = formatDate( formData.desde, 'dd/MM/yyyy', 'es' );
      const hasta = formatDate( formData.hasta, 'dd/MM/yyyy', 'es' );
      txtRango = 'DESDE EL ' + desde + ' HASTA EL ' + hasta;
    }
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    doc.text(nombreMaquina?.toLocaleUpperCase(), 7, 37);
    doc.setFontSize(11);
    doc.text('HISTÓRICO DE AVERÍAS ' + txtRango, 7, 44);

    // Dibujar cuadro del historico
    doc.setFillColor('#ff7f28');
    doc.rect(7, 46, 281, 6, 'F');
    doc.line(7, 46, 288, 46); //lh
    doc.line(7, 52, 288, 52); //lh

    // Datos cabecera del cuadro
    doc.setFontSize(11);
    doc.text('Fecha', 9, 50);
    doc.text('Descripción', 35, 50);
    doc.text('Horometro/Km', 200, 50);
    doc.text('Prioridad', 240, 50);
    doc.text('Estado', 266, 50);

  }

  pieAverias( doc: jspdf ) {

    // Firma y sello
    if ( this.imgFirma ) {
      // TODO: modificar aleatoriamente la posición y rotación
      doc.addImage(this.imgFirma, 'PNG', 218, 164, 70, 40);
    }

    // Pie de pagina
    doc.setFontSize(9);
    doc.line(7, 200, 288, 200);
    const fechaLarga = formatDate( new Date(), 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm:ss', 'es'  );
    doc.text(fechaLarga, 7, 203);

  }

  crearInformeAverias( formData: any, registros: any ) {

    const maquina = formData.maquina;
    let doc = new jspdf('landscape');

    // Marca de agua
    this.anadirMarcaAgua( doc, 80, 170, 95, ['HISTÓRICO', 'AVERÍAS']);

    this.cabeceraAverias( doc, maquina, formData );

    // Datos del historico
    doc.setFont('helvetica', 'normal');
    let despRegistros = 0;
    // TODO: verificar si se sale de la página
    registros.forEach( registro => {
      if (despRegistros >= 110) {
        // Pintar lineas del cuadro segun su alto total
        doc.line(7, 46, 7, 52 + despRegistros); // lv
        doc.line(33, 46, 33, 52 + despRegistros); // lv
        doc.line(198, 46, 198, 52 + despRegistros); // lv
        doc.line(238, 46, 238, 52 + despRegistros); // lv
        doc.line(264, 46, 264, 52 + despRegistros); // lv
        doc.line(288, 46, 288, 52 + despRegistros); // lv
        this.pieAverias( doc );
        doc.addPage();
        despRegistros = 0;
        this.anadirMarcaAgua( doc, 80, 170, 95, ['HISTÓRICO', 'AVERÍAS']);
        this.cabeceraAverias( doc, maquina, formData );
      }
      doc.setFont('helvetica', 'normal');
      const fecha = formatDate( registro?.fechaAviso?.toDate(), 'dd/MM/yyyy', 'es' );
      doc.text(fecha, 9, 57 + despRegistros);
      const desc = (registro?.descripcion) ? registro.descripcion : '' ;
      const splitDescripcion = doc.splitTextToSize(desc, 160);
      let lineasDesc = splitDescripcion.length;
      doc.text(splitDescripcion, 35, 57 + despRegistros, {lineHeightFactor: 1.6});
      const numeroHorometro = (registro?.horometro)
        ? new Intl.NumberFormat('es-ES', {
            style: 'decimal',
            maximumFractionDigits: 2,
            useGrouping: true
          }).format(registro?.horometro)
        : null;
      const horometroTxt = (numeroHorometro) ? numeroHorometro + ' ' + (registro?.udHorometro ?? 'h') : '';
      doc.text(horometroTxt, 200, 57 + despRegistros);
      const prio = (registro?.prioridad) ? this.capitalizarString(registro.prioridad) : '' ;
      doc.text(prio, 240, 57 + despRegistros);
      let est: string;
      switch(registro.estado) {
        case 'pendiente':
          est = 'Pendiente';
          break;
        case 'en_curso':
          est = 'En curso';
          break;
        case 'reparado':
          est = 'Reparado';
          break;
        default:
          est = '';
          break;
      }
      doc.text(est, 266, 57 + despRegistros);

      doc.line(7, 52 + despRegistros + (7*lineasDesc), 288, 52 + despRegistros + (7*lineasDesc)); //lh
      despRegistros+= 7 * lineasDesc;
    });
    // Pintar lineas del cuadro segun su alto total
    doc.line(7, 46, 7, 52 + despRegistros); // lv
    doc.line(33, 46, 33, 52 + despRegistros); // lv
    doc.line(198, 46, 198, 52 + despRegistros); // lv
    doc.line(238, 46, 238, 52 + despRegistros); // lv
    doc.line(264, 46, 264, 52 + despRegistros); // lv
    doc.line(288, 46, 288, 52 + despRegistros); // lv

    this.pieAverias( doc );

    const pageCount = (doc as any).internal.getCurrentPageInfo();
    for (let i = 1; i <= pageCount.pageNumber; i++) {
      doc.setFontSize(9);
      // Go to page i
      doc.setPage(i);
      doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 268, 203);
    }

    //Imprimir
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }

  /////////////////////////////////////////////////
  // INFORME MANTENIMIENTOS                      //
  /////////////////////////////////////////////////

  cabeceraMantenientos( doc: jspdf, maquina: any, formData: any ) {

    // Añadir logo
    if ( Date.now() >= new Date('2024-07-01').getTime() ) {
      if ( this.imgLogoNuevo ) {
        doc.addImage(this.imgLogoNuevo, 'PNG', 7, 5, 55, 23);
      }
    } else {
      if ( this.imgLogo ) {
        doc.addImage(this.imgLogo, 'PNG', 7, 5, 55, 23);
      }
    }

    // Añadir textos fijos
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    const matricula = (maquina?.matricula) ? ' (' + maquina.matricula + ')' : '';
    const numserie = (maquina?.numserie) ? ' (Nº SERIE: ' + maquina.numserie + ')' : '';
    const identificador = matricula.length > 0 ? matricula : numserie;
    const nombreMaquina = maquina.codigo + ' - ' + maquina.nombre + identificador;
    let txtRango = 'A ORIGEN';
    if (formData.rango === 'mes') {
      const mes = formatDate( formData.mes, 'MMMM yyyy', 'es' );
      txtRango = 'DEL MES DE ' + mes?.toLocaleUpperCase();
    } else if (formData.rango === 'dh') {
      const desde = formatDate( formData.desde, 'dd/MM/yyyy', 'es' );
      const hasta = formatDate( formData.hasta, 'dd/MM/yyyy', 'es' );
      txtRango = 'DESDE EL ' + desde + ' HASTA EL ' + hasta;
    }
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    doc.text(nombreMaquina?.toLocaleUpperCase(), 7, 37);
    doc.setFontSize(11);
    doc.text('HISTÓRICO DE MANTENIMIENTOS ' + txtRango, 7, 44);

    // Dibujar lineas cabecera cuadro
    doc.setFillColor('#ff7f28');
    doc.rect(7, 46, 281, 11, 'F');
    doc.line(7, 46, 288, 46); //lh
    doc.line(7, 57, 288, 57); //lh

    // Datos cabecera del cuadro
    doc.setFontSize(11);
    doc.text('Fecha', 9, 55);
    doc.text('Descripción', 35, 55);
    doc.text('Horometro/Km', 140, 55);
    doc.text('Estado', 175, 55);
    doc.text('Mantenimientos', 228, 50);
    doc.setFontSize(8);
    doc.text('Ace.', 199, 55);
    doc.text('Hid.', 208, 55);
    doc.text('L.Ot.', 217, 55);
    doc.text('F.Ac.', 226, 55);
    doc.text('F.Ga.', 235, 55);
    doc.text('F.Ai.', 244, 55);
    doc.text('F.Hi.', 253, 55);
    doc.text('F.Ag.', 262, 55);
    doc.text('F.A.C.', 271, 55);
    doc.text('F.Ot.', 280, 55);

  }

  pieMantenimientos( doc: jspdf ) {

    // Firma y sello
    if ( this.imgFirma ) {
      // TODO: modificar aleatoriamente la posición y rotación
      doc.addImage(this.imgFirma, 'PNG', 218, 164, 70, 40);
    }

    // Pie de pagina
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
    doc.text('EL VEHÍCULO HA PASADO LAS REVISIONES CONFORME A LAS INSTRUCCIONES DEL FABRICANTE', 7, 193);
    doc.setFontSize(8);
    doc.text('*Ace.: Aceite | Hid.: Hidráulico | L.Ot.: Lubcricante Otros | F.Ac.: Filtro Aceite | F.Ga.: Filtro Gasoil | F.Ai.: Filtro Aire | F.Hi.: Filtro Hidráulico | F.Ag.: Filtro Agua | F.A.C.: Filtro Aceite Convertidor | F.Ot.: Filtro Otros', 7, 198);
    doc.setFontSize(9);
    doc.line(7, 200, 288, 200);
    const fechaLarga = formatDate( new Date(), 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm:ss', 'es'  );
    doc.text(fechaLarga, 7, 203);

  }

  crearInformeMantenimientos( formData: any, registros: any ) {

    const maquina = formData.maquina;
    const doc = new jspdf('landscape');

    // Marca de agua
    this.anadirMarcaAgua( doc, 35, 192, 95, ['HISTÓRICO', 'MANTENIMIENTOS']);

    this.cabeceraMantenientos( doc, maquina, formData );

    // Datos del historico
    let despRegistros = 0;
    // TODO: verificar si se sale de la página
    registros.forEach( registro => {
      if (despRegistros >= 110) {
        // doc.line(7, 57 + despRegistros, 288, 57 + despRegistros); //lh
        // Pintar lineas del cuadro segun su alto total
        doc.line(7, 46, 7, 57 + despRegistros); // lv
        doc.line(288, 46, 288, 57 + despRegistros); // lv
        doc.line(33, 46, 33, 57 + despRegistros); // lv
        doc.line(138, 46, 138, 57 + despRegistros); // lv
        doc.line(173, 46, 173, 57 + despRegistros); // lv
        // Lineas del cuadro de lubricantes y filtros
        doc.line(198, 46, 198, 57 + despRegistros); // lv
        doc.line(207, 52, 207, 57 + despRegistros); // lv
        doc.line(216, 52, 216, 57 + despRegistros); // lv
        doc.line(225, 52, 225, 57 + despRegistros); // lv
        doc.line(234, 52, 234, 57 + despRegistros); // lv
        doc.line(243, 52, 243, 57 + despRegistros); // lv
        doc.line(252, 52, 252, 57 + despRegistros); // lv
        doc.line(261, 52, 261, 57 + despRegistros); // lv
        doc.line(270, 52, 270, 57 + despRegistros); // lv
        doc.line(279, 52, 279, 57 + despRegistros); // lv
        this.pieMantenimientos( doc );
        doc.addPage();
        despRegistros = 0;
        this.anadirMarcaAgua( doc, 35, 192, 95, ['HISTÓRICO', 'MANTENIMIENTOS']);
        this.cabeceraMantenientos( doc, maquina, formData );
      }
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      let fecha: string;
      let numeroHorometro: string;
      if (registro.estado === 'aviso') {
        fecha = formatDate( registro.fechaAviso?.toDate(), 'dd/MM/yyyy', 'es' );
        numeroHorometro = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(registro.horometroAviso);
      } else {
        fecha = formatDate( registro?.fecha?.toDate(), 'dd/MM/yyyy', 'es' );
        numeroHorometro = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(registro.horometro);
      }
      doc.text(fecha, 9, 62 + despRegistros);
      const desc = (registro?.descripcion) ? registro.descripcion : '' ;
      const splitDescripcion = doc.splitTextToSize(desc, 102);
      let lineasDesc = splitDescripcion.length;
      doc.text(splitDescripcion, 35, 62 + despRegistros, {lineHeightFactor: 1.6});
      const horometroTxt = numeroHorometro + ' ' + (registro?.udHorometro ?? 'h');
      doc.text(horometroTxt, 140, 62 + despRegistros);
      let est: string;
      switch(registro.estado) {
        case 'aviso':
          est = 'Aviso';
          break;
        case 'pendiente':
          est = 'Pendiente';
          break;
        case 'en_curso':
          est = 'En curso';
          break;
        case 'realizado':
          est = 'Realizado';
          break;
        default:
          est = '';
          break;
      }
      doc.text(est, 175, 62 + despRegistros);
      if (registro.aceite) {
        doc.setFillColor(39, 174, 96);
        doc.circle(202.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.aceite) {
        doc.setFillColor(39, 174, 96);
        doc.circle(202.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.hidraulico) {
        doc.setFillColor(39, 174, 96);
        doc.circle(211.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.otros) {
        doc.setFillColor(39, 174, 96);
        doc.circle(220.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtroaceite) {
        doc.setFillColor(39, 174, 96);
        doc.circle(229.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtrogasoil) {
        doc.setFillColor(39, 174, 96);
        doc.circle(238.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtroaire) {
        doc.setFillColor(39, 174, 96);
        doc.circle(247.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtrohidraulico) {
        doc.setFillColor(39, 174, 96);
        doc.circle(256.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtroagua) {
        doc.setFillColor(39, 174, 96);
        doc.circle(265.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtroaceiteconvertidor) {
        doc.setFillColor(39, 174, 96);
        doc.circle(274.5, 60.5 + despRegistros, 3, 'F');
      }
      if (registro.filtrootros) {
        doc.setFillColor(39, 174, 96);
        doc.circle(283.5, 60.5 + despRegistros, 3, 'F');
      }

      doc.line(7, 57 + despRegistros + (7*lineasDesc), 288, 57 + despRegistros + (7*lineasDesc)); //lh
      despRegistros+= 7 * lineasDesc;
    });
    // Pintar lineas del cuadro segun su alto total
    doc.line(7, 46, 7, 57 + despRegistros); // lv
    doc.line(288, 46, 288, 57 + despRegistros); // lv
    doc.line(33, 46, 33, 57 + despRegistros); // lv
    doc.line(138, 46, 138, 57 + despRegistros); // lv
    doc.line(173, 46, 173, 57 + despRegistros); // lv
    // Lineas del cuadro de lubricantes y filtros
    doc.line(198, 46, 198, 57 + despRegistros); // lv
    doc.line(207, 52, 207, 57 + despRegistros); // lv
    doc.line(216, 52, 216, 57 + despRegistros); // lv
    doc.line(225, 52, 225, 57 + despRegistros); // lv
    doc.line(234, 52, 234, 57 + despRegistros); // lv
    doc.line(243, 52, 243, 57 + despRegistros); // lv
    doc.line(252, 52, 252, 57 + despRegistros); // lv
    doc.line(261, 52, 261, 57 + despRegistros); // lv
    doc.line(270, 52, 270, 57 + despRegistros); // lv
    doc.line(279, 52, 279, 57 + despRegistros); // lv

    this.pieMantenimientos( doc );

    const pageCount = (doc as any).internal.getCurrentPageInfo();
    for (let i = 1; i <= pageCount.pageNumber; i++) {
      doc.setFontSize(9);
      // Go to page i
      doc.setPage(i);
      doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 268, 203);
    }

    //Imprimir
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }

  /////////////////////////////////////////////////
  // INFORME HOROMETROS                          //
  /////////////////////////////////////////////////

  cabeceraHorometros( doc: jspdf, maquina: any, formData: any ) {

    // Añadir logo
    if ( Date.now() >= new Date('2024-07-01').getTime() ) {
      if ( this.imgLogoNuevo ) {
        doc.addImage(this.imgLogoNuevo, 'PNG', 7, 5, 55, 23);
      }
    } else {
      if ( this.imgLogo ) {
        doc.addImage(this.imgLogo, 'PNG', 7, 5, 55, 23);
      }
    }

    // Añadir textos fijos
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    const matricula = (maquina?.matricula) ? ' (' + maquina.matricula + ')' : '';
    const numserie = (maquina?.numserie) ? ' (Nº SERIE: ' + maquina.numserie + ')' : '';
    const identificador = matricula.length > 0 ? matricula : numserie;
    const nombreMaquina = maquina.codigo + ' - ' + maquina.nombre + identificador;
    let txtRango = 'A ORIGEN';
    if (formData.rango === 'mes') {
      const mes = formatDate( formData.mes, 'MMMM yyyy', 'es' );
      txtRango = 'DEL MES DE ' + mes?.toLocaleUpperCase();
    } else if (formData.rango === 'dh') {
      const desde = formatDate( formData.desde, 'dd/MM/yyyy', 'es' );
      const hasta = formatDate( formData.hasta, 'dd/MM/yyyy', 'es' );
      txtRango = 'DESDE EL ' + desde + ' HASTA EL ' + hasta;
    }
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    doc.text(nombreMaquina?.toLocaleUpperCase(), 7, 37);
    doc.setFontSize(11);
    doc.text('HISTÓRICO DE HORÓMETROS ' + txtRango, 7, 44);

    // Dibujar cuadro del historico
    doc.setFillColor('#ff7f28');
    doc.rect(7, 46, 196, 6, 'F');
    doc.line(7, 46, 203, 46); //lh
    doc.line(7, 52, 203, 52); //lh

    // Datos cabecera del cuadro
    doc.setFontSize(11);
    doc.text('Fecha', 9, 50);
    doc.text('Horometro/Km', 35, 50);

  }

  pieHorometros( doc: jspdf ) {

    // Firma y sello
    if ( this.imgFirma ) {
      // modificar aleatoriamente la posición y rotación
      const x = Math.floor( Math.random() * (140 - 150 + 1) ) + 140;
      const y = Math.floor( Math.random() * (250 - 260 + 1) ) + 250;
      const rotation = Math.floor( Math.random() * (-10 - 10 + 1) ) + 10;
      doc.addImage(this.imgFirma, 'PNG', x, y, 70, 40, undefined, undefined, rotation);
    }

    // Pie de pagina
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    doc.line(7, 287, 203, 287);
    const fechaLarga = formatDate( new Date(), 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm:ss', 'es'  );
    doc.text(fechaLarga, 7, 290);

  }

  crearInformeHorometros( formData: any, registros: any ) {

    const maquina = formData.maquina;

    let doc = new jspdf();

    // Marca de agua
    this.anadirMarcaAgua( doc, 15, 210, 95, ['HISTÓRICO', 'HORÓMETROS']);

    this.cabeceraHorometros( doc, maquina, formData);

    // Datos del historico
    let despRegistros = 0;
    registros.forEach( registro => {
      if (despRegistros >= 200) {
        doc.line(7, 52 + despRegistros, 203, 52 + despRegistros); //lh
        doc.line(7, 46, 7, 52 + despRegistros); // lv
        doc.line(33, 46, 33, 52 + despRegistros); // lv
        doc.line(203, 46, 203, 52 + despRegistros); // lv
        this.pieHorometros( doc );
        doc.addPage();
        despRegistros = 0;
        this.anadirMarcaAgua( doc, 15, 210, 95, ['HISTÓRICO', 'HORÓMETROS']);
        this.cabeceraHorometros( doc, maquina, formData);
      }
      doc.setFontSize(11);
      doc.setFont('helvetica', 'normal');
      const fecha = formatDate( registro?.fecha?.toDate(), 'dd/MM/yyyy', 'es' );
      doc.text(fecha, 9, 57 + despRegistros);
      const numeroHorometro = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        maximumFractionDigits: 2,
        useGrouping: true
      }).format(registro.horometro);
      const horometroTxt = numeroHorometro + ' ' + (registro?.udHorometro ?? 'h');
      doc.text(horometroTxt, 35, 57 + despRegistros);
      doc.line(7, 52 + despRegistros, 203, 52 + despRegistros); //lh
      despRegistros+= 7;
    });
    doc.line(7, 52 + despRegistros, 203, 52 + despRegistros); //lh
    doc.line(7, 46, 7, 52 + despRegistros); // lv
    doc.line(33, 46, 33, 52 + despRegistros); // lv
    doc.line(203, 46, 203, 52 + despRegistros); // lv

    this.pieHorometros( doc );

    const pageCount = (doc as any).internal.getCurrentPageInfo();
    for (let i = 1; i <= pageCount.pageNumber; i++) {
      doc.setFontSize(9);
      // Go to page i
      doc.setPage(i);
      doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 183, 290);
    }

    //Imprimir
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }

  /////////////////////////////////////////////////
  // RESUMEN MENSUAL GONDOLA                     //
  /////////////////////////////////////////////////

  cabeceraGondola( doc: jspdf, gondola: any, formData: any ) {

    // Añadir logo
    if ( Date.now() >= new Date('2024-07-01').getTime() ) {
      if ( this.imgLogoNuevo ) {
        doc.addImage(this.imgLogoNuevo, 'PNG', 7, 5, 55, 23);
      }
    } else {
      if ( this.imgLogo ) {
        doc.addImage(this.imgLogo, 'PNG', 7, 5, 55, 23);
      }
    }

    // Añadir textos fijos
    doc.setFontSize(11);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(15);
    doc.setFont('helvetica', 'bold');
    let txtRango = 'A ORIGEN';
    if (formData.rango === 'mes') {
      const mes = formatDate( formData.mes, 'MMMM yyyy', 'es' );
      txtRango = 'DEL MES DE ' + mes?.toLocaleUpperCase();
    } else if (formData.rango === 'dh') {
      const desde = formatDate( formData.desde, 'dd/MM/yyyy', 'es' );
      const hasta = formatDate( formData.hasta, 'dd/MM/yyyy', 'es' );
      txtRango = 'DESDE EL ' + desde + ' HASTA EL ' + hasta;
    }
    doc.text(gondola?.toLocaleUpperCase(), 7, 37);
    doc.setFontSize(11);
    doc.text('RESUMEN DE PORTES DE MAQUINARIA ' + txtRango, 7, 44);

  }

  pieGondola( doc: jspdf ) {

    // Pie de pagina
    doc.setFontSize(9);
    doc.setFont('helvetica', 'normal');
    doc.line(7, 287, 203, 287);
    const fechaLarga = formatDate( new Date(), 'EEEE, d \'de\' MMMM \'de\' yyyy \'a las\' HH:mm:ss', 'es'  );
    doc.text(fechaLarga, 7, 290);

  }

  crearInformeMensualGondola( formData: any, registros: any ): Promise<jspdf> {

    let desdeDate: Date;
    let hastaDate: Date;

    if (formData.rango === 'dh') {
      desdeDate = new Date( formData.desde + ' 00:00' );
      hastaDate = new Date( formData.hasta + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    } else if (formData.rango === 'mes') {
      const anioMes = formData.mes.split('-');
      const diasMes = new Date( anioMes[0], anioMes[1], 0 ).getDate();
      desdeDate = new Date( formData.mes + '-01' + ' 00:00' );
      hastaDate = new Date( formData.mes + '-' + diasMes + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    }

    const gondola = formData.gondola;

    let doc = new jspdf();

    // Importar FontAwesome
    doc.addFileToVFS('fa-solid-900-normal.ttf', font);
    doc.addFont('fa-solid-900-normal.ttf', 'fa-solid-900', 'normal');

    // Marca de agua
    if ( registros.length === 0 ) {
      this.anadirMarcaAgua( doc, 40, 210, 95, ['RESUMEN', 'MENSUAL']);
    } else {
      this.anadirMarcaAgua( doc, 40, 210, 95, ['PORTES', 'GÓNDOLA']);
    }

    this.cabeceraGondola( doc, gondola, formData);

    // Traer todos los registros del mes y matricula
    // Despues recorrer el array e ir extrayendo a otro array
    // segun la obra de destino o origen (si el destino es almacen)
    let portesPorObra = {};
    let portes: Promise<QuerySnapshot<DocumentData>>;
    if ( registros.length === 0 ) {

      const maquinariaMovimientosCol = collection( this.firestore, 'maquinaria_movimientos' );
      let consulta = query( maquinariaMovimientosCol );
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fechaCarga', '>=', desdeDate), where('fechaCarga', '<', hastaDate) );
      }
      consulta = query( consulta, orderBy('fechaCarga', 'desc') );
      portes = getDocs( consulta );

    }

    return new Promise( resolve => {
      // Si hay registros, leerlos y agruparlos por obra
      if ( registros.length > 0 ) {

        registros.forEach( porte => {
          const cargadoObraTipo = porte['cargadoObra']?.['tipo'];
          const descargadoObraTipo = porte['descargadoObra']?.['tipo'];
            let codigoObraDestino = '';
            let obraDestino = '';
            if ( porte['descargadoEn'] === 'CMP-HERN - CAMPA HERNANI' || descargadoObraTipo === 'maquinaria' ) {
              obraDestino = porte['cargadoEn'];
              codigoObraDestino = obraDestino.split(' - ')[0];
              this.agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porte );
            } else {
              if ( porte['descargadoEn'] ) {
                obraDestino = porte['descargadoEn'];
                codigoObraDestino = obraDestino.split(' - ')[0];
                this.agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porte );
              }
            }
        });
        const obras = Object.entries( portesPorObra );
        let despRegistros = 0;
        obras.forEach( obra => {
          if (despRegistros >= 200) {
            this.pieGondola( doc );
            doc.addPage();
            despRegistros = 0;
            this.anadirMarcaAgua( doc, 40, 210, 95, ['PORTES', 'GÓNDOLA']);
            this.cabeceraGondola( doc, gondola, formData);
          }
          doc.setFontSize(11);
          doc.setFont('helvetica', 'bold');
          const nombreObra = obra[1]['portes'][0]['obraDestino'].toLocaleUpperCase();
          doc.text(nombreObra, 9, 57 + despRegistros);
          obra[1]['portes'].forEach( porte => {
            if (despRegistros >= 200) {
              doc.setFontSize(9);
              doc.setFont('helvetica', 'normal');
              doc.text('Continua en próxima página...', 12, 63 + despRegistros);
              this.pieGondola( doc );
              doc.addPage();
              despRegistros = 0;
              this.anadirMarcaAgua( doc, 40, 210, 95, ['PORTES', 'GÓNDOLA']);
              this.cabeceraGondola( doc, gondola, formData);
              doc.text('...' + nombreObra, 9, 57 + despRegistros);
            }
            doc.setFontSize(9);
            doc.setFont('helvetica', 'normal');
            const fechaCarga = formatDate( porte['fechaCarga'], 'dd/MM/yyyy HH:mm', 'es' );
            const fechaDescarga = formatDate( porte['fechaDescarga'], 'dd/MM/yyyy HH:mm', 'es' );
            doc.text( fechaCarga, 16, 63 + despRegistros);
            doc.text( fechaDescarga + ' (' + porte['horas'] + ' horas)', 46, 63 + despRegistros);
            doc.text( porte['cargadoEn'], 22, 68 + despRegistros);
            doc.text( porte['descargadoEn'], 22, 73 + despRegistros);
            porte['tipoCarga'] = porte?.['tipoCarga'] ?? 'propia';
              if (porte['tipoCarga'] === 'propia') {
                let maquinas = '';
                porte['listaMaquinas'].map( maquina => {
                  maquinas+= maquina.nombre?.split(' - ')[0] + ', ';
                });
                maquinas = maquinas.slice(0, -2);
                doc.text( maquinas, 22, 78 + despRegistros);
              } else {
                if (porte['tipoCarga'] === 'externa') {
                  doc.text( 'MAQUINARIA EXTERNA', 22, 78 + despRegistros);
                } else {
                  doc.text( 'PORTE VACÍO', 22, 78 + despRegistros);
                }
              }
            // Añadir simbolos FontAwesome
            doc.setFont('fa-solid-900', 'normal');
            doc.setFontSize(9);
            if ( porte['revisado'] ) {
              doc.text('\uf058', 12, 63 + despRegistros ); // flecha antes de fechas
            } else {
              doc.text('\uf06a', 12, 63 + despRegistros ); // flecha antes de fechas
            }
            doc.text('\uf061', 42, 63 + despRegistros ); // flecha entre fechas
            doc.text('\uf2f5', 15, 68 + despRegistros ); // flecha desde
            doc.text('\uf2f6', 15, 73 + despRegistros ); // flecha hasta
            doc.text('\uf7d2', 15, 78 + despRegistros ); // maquinas
            despRegistros+= 20;
          });
          doc.setFontSize(10);
          doc.setFont('helvetica', 'bold');
          const totalHoras = obra[1]['horas'];
          doc.text( 'TOTAL HORAS ' + obra[0].toLocaleUpperCase() + ': ' + totalHoras + ' h.', 9, 64 + despRegistros);
          despRegistros+= 16;
        });

        this.pieGondola( doc );

        const pageCount = (doc as any).internal.getCurrentPageInfo();
        for (let i = 1; i <= pageCount.pageNumber; i++) {
          doc.setFontSize(9);
          // Go to page i
          doc.setPage(i);
          doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 183, 290);
        }

        resolve( doc );
      // Si no hay registros, leerlos de la base de datos y agruparlos por obra
      } else {

        portes.then( async (portesSnap) => {
          const portesData = portesSnap.docs.map( doc => doc.data() );
          for (let porteData of portesData) {
            if ( porteData['cargadoObra'] ) {
              porteData['cargadoObra'] = await getDoc(porteData['cargadoObra']);
            }
            if ( porteData['descargadoObra'] ) {
              porteData['descargadoObra'] = await getDoc(porteData['descargadoObra']);
            }
            const cargadoObraTipo = porteData['cargadoObra']?.['tipo'];
            const descargadoObraTipo = porteData['descargadoObra']?.['tipo'];
            let codigoObraDestino = '';
            let obraDestino = '';
            if ( porteData['cargadoEn'] === 'CMP-HERN - CAMPA HERNANI' || cargadoObraTipo === 'maquinaria' ) {
              if ( porteData['descargadoEn'] ) {
                obraDestino = porteData['descargadoEn'];
                codigoObraDestino = obraDestino.split(' - ')[0];
                this.agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porteData );
              }
            } else if ( porteData['descargadoEn'] === 'CMP-HERN - CAMPA HERNANI' || descargadoObraTipo === 'maquinaria' ) {
              obraDestino = porteData['cargadoEn'];
              codigoObraDestino = obraDestino.split(' - ')[0];
              this.agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porteData );
            } else {
              if ( porteData['descargadoEn'] ) {
                obraDestino = porteData['descargadoEn'];
                codigoObraDestino = obraDestino.split(' - ')[0];
                this.agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porteData );
              }
            }
          }
          const obras = Object.entries( portesPorObra );
          let despRegistros = 0;
          obras.forEach( obra => {
            if (despRegistros >= 200) {
              this.pieGondola( doc );
              doc.addPage();
              despRegistros = 0;
              this.anadirMarcaAgua( doc, 40, 210, 95, ['RESUMEN', 'MENSUAL']);
              this.cabeceraGondola( doc, gondola, formData);
            }
            doc.setFontSize(11);
            doc.setFont('helvetica', 'bold');
            const nombreObra = obra[1]['portes'][0]['obraDestino'].toLocaleUpperCase();
            doc.text(nombreObra, 9, 57 + despRegistros);
            obra[1]['portes'].forEach( porte => {
              if (despRegistros >= 200) {
                doc.setFontSize(9);
                doc.setFont('helvetica', 'normal');
                doc.text('Continua en próxima página...', 12, 63 + despRegistros);
                this.pieGondola( doc );
                doc.addPage();
                despRegistros = 0;
                this.anadirMarcaAgua( doc, 40, 210, 95, ['RESUMEN', 'MENSUAL']);
                this.cabeceraGondola( doc, gondola, formData);
                doc.text('...' + nombreObra, 9, 57 + despRegistros);
              }
              doc.setFontSize(9);
              doc.setFont('helvetica', 'normal');
              const fechaCarga = formatDate( porte['fechaCarga'], 'dd/MM/yyyy HH:mm', 'es' );
              const fechaDescarga = formatDate( porte['fechaDescarga'], 'dd/MM/yyyy HH:mm', 'es' );
              doc.text( fechaCarga, 16, 63 + despRegistros);
              doc.text( fechaDescarga + ' (' + porte['horas'] + ' horas)', 46, 63 + despRegistros);
              doc.text( porte['cargadoEn'], 22, 68 + despRegistros);
              doc.text( porte['descargadoEn'], 22, 73 + despRegistros);
              porte['tipoCarga'] = porte?.['tipoCarga'] ?? 'propia';
              if (porte['tipoCarga'] === 'propia') {
                let maquinas = '';
                porte['listaMaquinas'].map( maquina => {
                  maquinas+= maquina.nombre?.split(' - ')[0] + ', ';
                });
                maquinas = maquinas.slice(0, -2);
                doc.text( maquinas, 22, 78 + despRegistros);
              } else {
                if (porte['tipoCarga'] === 'externa') {
                  doc.text( 'MAQUINARIA EXTERNA', 22, 78 + despRegistros);
                } else {
                  doc.text( 'PORTE VACÍO', 22, 78 + despRegistros);
                }
              }
              // Añadir simbolos FontAwesome
              doc.setFont('fa-solid-900', 'normal');
              doc.setFontSize(9);
              if ( porte['revisado'] ) {
                doc.text('\uf058', 12, 63 + despRegistros ); // flecha antes de fechas
              } else {
                doc.text('\uf06a', 12, 63 + despRegistros ); // flecha antes de fechas
              }
              doc.text('\uf061', 42, 63 + despRegistros ); // flecha entre fechas
              doc.text('\uf2f5', 15, 68 + despRegistros ); // flecha desde
              doc.text('\uf2f6', 15, 73 + despRegistros ); // flecha hasta
              doc.text('\uf7d2', 15, 78 + despRegistros ); // maquinas
              despRegistros+= 20;
            });
            doc.setFontSize(10);
            doc.setFont('helvetica', 'bold');
            const totalHoras = obra[1]['horas'];
            doc.text( 'TOTAL HORAS ' + obra[0].toLocaleUpperCase() + ': ' + totalHoras + ' h.', 9, 64 + despRegistros);
            despRegistros+= 16;
          });

          this.pieGondola( doc );

          const pageCount = (doc as any).internal.getCurrentPageInfo();
          for (let i = 1; i <= pageCount.pageNumber; i++) {
            doc.setFontSize(9);
            // Go to page i
            doc.setPage(i);
            doc.text('Página ' + String(i) + ' de ' + String(pageCount.pageNumber), 183, 290);
          }

          resolve( doc );

        });

      }

    });

  }


  /////////////////////////////////////////////////
  // IMAGEN LOCAL A BASE64                       //
  /////////////////////////////////////////////////

  convertBlobToBase64(blob: Blob) {
    return new Observable(observer => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

  /////////////////////////////////////////////////
  // UTILIDADES                                  //
  /////////////////////////////////////////////////

  capitalizarString( txt: string ): string {
    return txt.charAt(0).toUpperCase() + txt.slice(1);;
  }

  agruparPortesPorObras( portesPorObra, codigoObraDestino, obraDestino, porteData ) {
    if ( portesPorObra[codigoObraDestino] ) {
      portesPorObra[codigoObraDestino]['portes'].push({
        obraDestino: obraDestino,
        fechaCarga: porteData['fechaCarga'].toDate(),
        cargadoEn: porteData['cargadoEn'],
        fechaDescarga: porteData['fechaDescarga'].toDate(),
        descargadoEn: porteData['descargadoEn'],
        horas: porteData['horas'],
        tipoCarga: porteData['tipoCarga'],
        maquinas: porteData['maquinas'].length,
        listaMaquinas: porteData['maquinas'],
        revisado: porteData['revisado'],
      });
      portesPorObra[codigoObraDestino]['horas'] += porteData['horas'];
    } else {
      portesPorObra[codigoObraDestino]= {
        portes: [{
          obraDestino: obraDestino,
          fechaCarga: porteData['fechaCarga'].toDate(),
          cargadoEn: porteData['cargadoEn'],
          fechaDescarga: porteData['fechaDescarga'].toDate(),
          descargadoEn: porteData['descargadoEn'],
          horas: porteData['horas'],
          tipoCarga: porteData['tipoCarga'],
          maquinas: porteData['maquinas'].length,
          listaMaquinas: porteData['maquinas'],
          revisado: porteData['revisado'],
        }],
        horas: porteData['horas'],
      };
    }
  }

}
