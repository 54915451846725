import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../services/service.index';
import { Usuario } from '../models/usuario.model';

declare function app();
declare function app_init();
declare function app_init_dark();
declare function feather_init();
declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  recordar: boolean = false;
  claseBack: string = 'login-back1';

  constructor( public router: Router,
               public usuarioService: UsuarioService ) {

                const back = Math.floor( Math.random() * 4 ) + 1;
                this.claseBack = 'login-back' + back.toString();
               }


  ngOnInit(): void {
    app();
    app_init();
    feather_init();
    init_plugins();

    if ( localStorage.getItem( 'email-orsaapp' ) ) {
      this.email = localStorage.getItem( 'email-orsaapp' );
      this.recordar = true;
    }

    if ( this.usuarioService.estaLogueado() ) {
      this.router.navigate(['/inicio']);
    }

  }

  loginFB( form: NgForm ) {

    if (form.invalid) {
      return;
    }

    const usuario = new Usuario( null, form.value.email, form.value.password );
    this.recordar = form.value.recordar;

    this.usuarioService.loginFB( usuario, form.value.recordar );

  }

}
