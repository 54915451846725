import { Injectable } from '@angular/core';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, deleteDoc, doc, docData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';

import { UsuarioService } from '../../services/usuario/usuario.service';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FormulasService {

  formulasCol = collection( this.firestore, 'formulas' );

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private usuarioService: UsuarioService,
              ) { }

  cargarFormulas( activos: boolean = true, desde: number = 0 ) {

      let consulta: Query<DocumentData>;
      if (activos) {
        consulta = query( this.formulasCol, where('estado', '==', true), orderBy('codigo', 'asc') );
      } else {
        consulta = query( this.formulasCol, orderBy('codigo', 'asc') );
      }

      return collectionData( consulta, { idField: 'id' } );

  }

  obtenerFormulaPorId( formulaID: string ) {

      const formulaRef = doc( this.firestore, 'formulas', formulaID );
      return docData( formulaRef, { idField: 'id' } );

  }

  crearFormula( formData: any ) {

    formData.meta = {
      creado: {
        creadoel : new Date(),
        creadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        },
      },
      modificado: {
        modificadoel: null,
        modificadopor: {
          nombre: null,
          uid: null
        },
      },
      eliminado: {
        eliminadoel: null,
        eliminadopor: {
          nombre: null,
          uid: null
        },
      }
    };

    if ( formData?.planta?.id ) {
      formData.planta = doc( this.firestore, 'obras', formData.planta.id );
    }
    if ( formData?.unidades ) {
      formData.unidades = doc( this.firestore, 'unidades', formData.unidades );
    } else {
      return Promise.reject('No se ha seleccionado la unidad de medida');
    }
    if ( formData?.marcadoce ) {
      formData.selloce = doc( this.firestore, 'sellos_ce', formData.selloce );
    }
    formData.dosificacionfrio.map( dosificacionfrio => {
      if ( dosificacionfrio?.material?.id ) {
        dosificacionfrio.material = doc( this.firestore, 'materiasprimas', dosificacionfrio.material.id );
      }
    });
    formData.dosificacioncaliente.map( dosificacioncaliente => {
      if ( dosificacioncaliente.tipo !== 'criba' ) {
        if ( dosificacioncaliente?.material?.id ) {
          dosificacioncaliente.material = doc( this.firestore, 'materiasprimas', dosificacioncaliente.material.id );
        }
      }
    });
    formData.estado = true;

    return addDoc( this.formulasCol, formData )
      .then( docRef => {
        updateDoc( docRef, { id: docRef.id } );
      });

}

  actualizarFormula( formulaID: string, formData: any ) {

    if ( formData?.planta ) {
      formData.planta = doc( this.firestore, 'obras', formData.planta.id );
    }
    if ( formData?.unidades ) {
      formData.unidades = doc( this.firestore, 'unidades', formData.unidades );
    } else {
      return Promise.reject('No se ha seleccionado la unidad de medida');
    }
    if ( formData?.marcadoce ) {
      formData.selloce = doc( this.firestore, 'sellos_ce', formData.selloce );
    }
    formData.dosificacionfrio.map( dosificacionfrio => {
      if ( dosificacionfrio.material ) {
        dosificacionfrio.material = doc( this.firestore, 'materiasprimas', dosificacionfrio.material.id );
      }
    });
    formData.dosificacioncaliente.map( dosificacioncaliente => {
      if ( dosificacioncaliente.tipo !== 'criba' ) {
        if ( dosificacioncaliente.material ) {
          dosificacioncaliente.material = doc( this.firestore, 'materiasprimas', dosificacioncaliente.material.id );
        }
      }
    });

    const formulaRef = doc( this.firestore, 'formulas', formulaID );
    return updateDoc( formulaRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  activarFormula( formulaID: string ) {

    const formulaRef = doc( this.firestore, 'formulas', formulaID );
    return updateDoc( formulaRef, {
      estado: true,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  borrarFormula( formulaID: string ) {

    const formulaRef = doc( this.firestore, 'formulas', formulaID );
    const consulta = query( collection( this.firestore, 'salidas' ), where('formula', '==', formulaRef) );
    firstValueFrom( collectionData( consulta, { idField: 'id' } ) )
      .then( salidas => {

        if (salidas.length) {

          Swal.fire({
            title: '¡Hay registros con esta fórmula!',
            text: 'Si se elimina la fórmula podría haber inconsistencias en las salidas.',
            icon: 'warning',
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            denyButtonColor: '#6e7881',
            cancelButtonColor: '#dd3333',
            confirmButtonText: 'Dar de baja',
            denyButtonText: 'Sustituir',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {

              updateDoc( formulaRef, {
                estado: false,
                'meta.modificado': {
                  eliminadoel: new Date(),
                  eliminadopor: {
                    nombre: this.usuarioService.usuario.nombre,
                    uid: this.usuarioService.usuario.uid
                  }
                }
              }).then( () => {
                Swal.fire('Dado de baja!', '', 'success');
              });

              } else if (result.isDenied) {
              // TODO: dar opción de asignar otra matricula
              Swal.fire('Seleccione la fórmula', 'En desarrollo', 'info');
            }

          });

        } else {

          deleteDoc( formulaRef )
            .then( () => {
              Swal.fire(
                '¡Eliminado!',
                'La fórmula ha sido eliminado.',
                'success'
              );
            }).catch( err => {
              console.log(err);
            });

        }

      });

  }

  buscarFormula( formula: string ): Observable<unknown> {

    formula = formula.toLocaleUpperCase();
    const consulta = query( this.formulasCol, where('codigo', '==', formula) );
    return collectionData( consulta, { idField: 'id' } );

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }
}
