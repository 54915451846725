import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

import { PersonalService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styles: [
  ]
})
export class PersonalComponent implements OnInit, OnDestroy {

  trabajadores: any = [];
  trabajadoresTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private personalService: PersonalService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.personalService.getRegistrosPagina();
    this.sorts = this.personalService.getSorts();
    this.offset = this.personalService.getPage();
    this.cargarTrabajadores();
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  cargarTrabajadores(): void {

    this.cargando = true;

    this.subs.sink = this.personalService.cargarTrabajadores()
      .subscribe( async (trabajadoresDB: any) => {
        this.totalRegistros = 0;
        this.trabajadores = [];
        if ( trabajadoresDB.length > 0 ) {
          await Promise.all( trabajadoresDB.map( async (trabajador: any) => {
            trabajador.equipo = (trabajador.equipo) ? (await getDoc( trabajador.equipo )).data() : null;
          }) );
          this.totalRegistros = trabajadoresDB.length; // TODO: Obtener total usuarios de la BD
          this.trabajadores = trabajadoresDB;
          this.trabajadoresTodos = trabajadoresDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarTrabajador( termino: string ) {

    if (termino.length <= 0) {
      this.totalRegistros = this.trabajadoresTodos.length;
      this.trabajadores = this.trabajadoresTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      this.trabajadores = this.trabajadoresTodos.filter( ( trabajador: any ) =>
        trabajador?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.apellido1?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.apellido2?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.dni?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.telefono?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.email?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.trabajadores.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.trabajadoresTodos.length;
      this.trabajadores = this.trabajadoresTodos;
    }
  }

  editarTrabajador( id: string ) {

    this.router.navigate( ['/rrhh/trabajadores', id, 'editar' ] );

  }

  borrarTrabajador( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción desactivara el trabajador en este y todos los listados e informes.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, desactivar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.personalService.borrarTrabajador( id )
          .then( res => {
            Swal.fire(
              '¡Desactivado!',
              'El trabajador ha sido desactivado.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.personalService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.personalService.setPage( event.offset );
  }

  onSort(event) {
    this.personalService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

  equipoComparator(propA: any, propB: any): number {
    // Just a simple sort function comparisoins
    if (propA.nombre.toLowerCase() < propB.nombre.toLowerCase()) {
      return -1;
    } else if (propA.nombre.toLowerCase() > propB.nombre.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

}
