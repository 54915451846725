export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyB1cZwgZTrsH6SnVPdcLwOAEPQCLYCCINs',
    authDomain: 'orsaapp-40be2.firebaseapp.com',
    databaseURL: 'https://orsaapp-40be2.firebaseio.com',
    projectId: 'orsaapp-40be2',
    storageBucket: 'orsaapp-40be2.appspot.com',
    messagingSenderId: '40456539997',
    appId: '1:40456539997:web:e42341b8c6842e7e11e2b4',
    measurementId: 'G-HJBFWY8ME0'
  }
};

// // OrsaAppDev //
// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyCuGtdEsvfZoKGiDR9v0wgCeIRkEfCWZXE',
//     authDomain: 'orsaappdev.firebaseapp.com',
//     databaseURL: 'https://orsaappdev.firebaseio.com',
//     projectId: 'orsaappdev',
//     storageBucket: 'orsaappdev.appspot.com',
//     messagingSenderId: '498242445693',
//     appId: '1:498242445693:web:9b66d30c7dcc62d5e22c14',
//     measurementId: 'G-8D1H4FS4BW'
//   }
// };
