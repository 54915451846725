import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';

import Swal from 'sweetalert2';

import { CategoriasDocumentosService } from 'src/app/services/service.index';
import { getDoc } from '@angular/fire/firestore';

@Component({
  selector: 'app-categorias-documentos',
  templateUrl: './categorias-documentos.component.html',
  styleUrls: ['./categorias-documentos.component.css']
})
export class CategoriasDocumentosComponent implements OnInit, OnDestroy {

  categoriasDocumentos: any = [];
  categoriasDocumentosTodos: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private categoriasDocumentosService: CategoriasDocumentosService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.categoriasDocumentosService.getRegistrosPagina();
    this.sorts = this.categoriasDocumentosService.getSorts();
    this.offset = this.categoriasDocumentosService.getPage();
    this.cargarCategoriasDocumentos();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarCategoriasDocumentos() {

    this.cargando = true;

    this.subs.sink = this.categoriasDocumentosService.cargarCategoriasDocumentos()
      .subscribe( async ( categoriasDocumentosDB: any ) => {
        this.totalRegistros = 0;
        this.categoriasDocumentos = [];
        if ( categoriasDocumentosDB.length > 0 ) {
          await Promise.all(  categoriasDocumentosDB.map( async ( categoriaDocumento: any ) => {
            categoriaDocumento.tipo = (categoriaDocumento.tipo) ? (await getDoc( categoriaDocumento.tipo )).data() : null;
          }));
          this.totalRegistros = categoriasDocumentosDB.length; // TODO: Obtener total usuarios de la BD
          this.categoriasDocumentos = categoriasDocumentosDB;
          this.categoriasDocumentosTodos = categoriasDocumentosDB;
          this.searchForm.controls.termino.enable();
        }

        this.cargando = false;

      });

  }

  buscarCategoriaDocumento( termino: string ) {

    if ( termino.length <= 0 ) {
      this.totalRegistros = this.categoriasDocumentosTodos.length;
      this.categoriasDocumentos = this.categoriasDocumentosTodos;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.categoriasDocumentos = this.categoriasDocumentosTodos.filter( ( categoriaDocumento: any ) =>
        categoriaDocumento?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        categoriaDocumento?.tipo?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.categoriasDocumentos.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.categoriasDocumentosTodos.length;
      this.categoriasDocumentos = this.categoriasDocumentosTodos;
    }
  }

  editarCategoriaDocumento( id: string ) {

    this.router.navigate( ['/admin/categorias-documentos', id, 'editar' ] );

  }

  borrarCategoriaDocumento( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {

        this.categoriasDocumentosService.borrarCategoriaDocumento( id )
          .then( () => {
            Swal.fire(
              '¡Eliminado!',
              'La categoría de documento ha sido eliminada.',
              'success'
            );
          })
          .catch( err => {
            console.log(err);
          });

      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.categoriasDocumentosService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.categoriasDocumentosService.setPage( event.offset );
  }

  onSort(event) {
    this.categoriasDocumentosService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
