<app-breadcrumbs></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center" [ngClass]="cargandoClass">
            <strong>{{ txtCargando }}</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Salida</h4>
            </div>

            <div [ngClass]="verDatosAdicionales && (tieneTimeline || tieneVersiones) ? 'card-body' : ''">

              <ul class="nav nav-pills justify-content-end mt-2" role="tablist">
                <li class="nav-item" role="presentation" *ngIf="verDatosAdicionales && (tieneTimeline || tieneVersiones)">
                  <button class="btn nav-link active" id="pills-datos-tab" data-toggle="pill" data-target="#datos" type="button" role="tab" aria-controls="pills-datos" aria-selected="true" (click)="tabClic('datos')">
                    Datos básicos
                  </button>
                </li>
                <li class="nav-item"  role="presentation" *ngIf="verDatosAdicionales && tieneTimeline">
                  <button class="btn nav-link" id="pills-adicionales-tab" data-toggle="pill" data-target="#adicionales" type="button" role="tab" aria-controls="pills-adicionales" aria-selected="true" (click)="tabClic('adicionales')">
                    Datos adicionales
                  </button>
                </li>
                <li class="nav-item"  role="presentation" *ngIf="verDatosAdicionales && tieneVersiones">
                  <button class="btn nav-link" id="pills-versiones-tab" data-toggle="pill" data-target="#versiones" type="button" role="tab" aria-controls="pills-versiones" aria-selected="true" (click)="tabClic('versiones')">
                    Versiones
                  </button>
                </li>
              </ul>

              <div [ngClass]="verDatosAdicionales && (tieneTimeline || tieneVersiones) ? 'tab-content border mt-2' : ''">

                <div [ngClass]="verDatosAdicionales && (tieneTimeline || tieneVersiones) ? 'tab-pane active p-3' : ''" id="datos" role="tabpanel">

                  <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="salidaForm" (ngSubmit)="crearSalida()">

                    <div class="card-body">
                        <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>

                        <div class="form-row">
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Planta*</label>
                                    <input type="search" class="form-control" placeholder="Escribe el código o nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('planta') }" formControlName="planta" [ngbTypeahead]="plantasearch" [inputFormatter]="plantaformat" [resultFormatter]="plantaformat"
                                        [editable]="false" (selectItem)="plantaSeleccionada($event)" (ngModelChange)="verificarPlanta()">
                                    <div class="invalid-feedback">
                                        La planta es obligatoria. Elija uno de la lista.
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-8">
                                <div class="form-group">
                                    <label>Obra*</label>
                                    <input type="search" class="form-control" placeholder="Escribe el código o nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('obra') }" formControlName="obra" [ngbTypeahead]="obrasearch" [inputFormatter]="obraformat" [resultFormatter]="obraformat"
                                        [editable]="false" (selectItem)="obraSeleccionada($event)" (ngModelChange)="verificarObra()">
                                    <div class="invalid-feedback">
                                        La obra es obligatoria. Elija uno de la lista.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Cliente*</label>
                                    <input type="search" class="form-control" placeholder="Cliente" formControlName="cliente" [ngbTypeahead]="cliesearch" [inputFormatter]="clieformat" [resultFormatter]="clieformat" [editable]="false">

                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Fecha*</label>
                                    <input type="date" class="form-control" placeholder="Fecha" required [ngClass]="{ 'is-invalid': campoNoValido('fecha') }" formControlName="fecha" (ngModelChange)="verificarFecha()">
                                    <div class="invalid-feedback">
                                        La fecha es obligatoria
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Hora*</label>
                                    <input type="time" class="form-control" placeholder="Hora" required [ngClass]="{ 'is-invalid': campoNoValido('hora') }" formControlName="hora">
                                    <div class="invalid-feedback">
                                        La hora es obligatoria
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <label>Nº Albarán*</label>
                                <div class="input-group">
                                    <input type="text" class="form-control col-2" placeholder="Tipo" formControlName="prefalbaran">
                                    <input type="number" class="form-control col-10" placeholder="Nº albarán" required [ngClass]="{ 'is-invalid': campoNoValido('albaran') }" formControlName="albaran">
                                    <div class="invalid-feedback">
                                        El albarán es obligatorio
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-4">
                                <label>Tipo de salida*</label>
                                <div class="input-group">
                                    <select id="tipo" class="custom-select col-12" required [ngClass]="{ 'is-invalid': selectNoValido('tipo') }" formControlName="tipo" (ngModelChange)="verificarTipo()">
                                        <option value="">Elige un tipo de salida...</option>
                                        <option value="pb">Materias primas</option>
                                        <option value="pt">Producto terminado</option>
                                        <option value="re">Residuos</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        El tipo es obligatorio. Elija uno de la lista.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-8">
                                <div class="form-group" [ngSwitch]="formuArti">
                                    <label>{{ txtFormuArti }}</label>
                                    <input *ngSwitchCase="'pt'" type="search" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('formula') }" formControlName="formula" [ngbTypeahead]="formsearch" [inputFormatter]="formformat" [resultFormatter]="formformat"
                                        [editable]="false" (selectItem)="formulaSeleccionada($event)">
                                    <!-- (ngModelChange)="verificarForm()" -->
                                    <input *ngSwitchCase="'pb'" type="search" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('articulo') }" formControlName="articulo" [ngbTypeahead]="artisearch" [inputFormatter]="artiformat" [resultFormatter]="artiformat"
                                        [editable]="false" (selectItem)="articuloSeleccionado($event)">
                                    <!-- (ngModelChange)="verificarArti()" -->
                                    <input *ngSwitchCase="'re'" type="search" class="form-control" placeholder="Escribe el nombre..." required [ngClass]="{ 'is-invalid': campoNoValido('articulo') }" formControlName="articulo" [ngbTypeahead]="resisearch" [inputFormatter]="resiformat" [resultFormatter]="resiformat"
                                        [editable]="false" (selectItem)="articuloSeleccionado($event)">
                                    <!-- (ngModelChange)="verificarArti()" -->
                                    <div *ngSwitchCase="'pt'" class="invalid-feedback">
                                        La formula es obligatoria. Elija una de la lista.
                                    </div>
                                    <div *ngSwitchCase="formuArti === 'pb' || formuArti === 're'" class="invalid-feedback">
                                        El articulo es obligatorio. Elija uno de la lista.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-4">
                                <label>Matricula*</label>
                                <div class="input-group">
                                    <input type="search" class="form-control" placeholder="Escribe la matricula..." required [ngClass]="{ 'is-invalid': campoNoValido('matricula') }" formControlName="matricula" [ngbTypeahead]="matsearch" [inputFormatter]="matformat" [resultFormatter]="matformat"
                                        [editable]="false" (selectItem)="cargarCamion($event)" (ngModelChange)="verificarMatricula()">
                                    <div class="input-group-append" *ngIf="!editarCamion">
                                        <button class="btn btn-outline-success" type="button" (click)="camionModal()">
                                            <i class="mdi mdi-plus"></i>
                                        </button>
                                    </div>
                                    <div class="input-group-append" *ngIf="editarCamion">
                                      <button class="btn btn-outline-info" type="button" (click)="camionModal()">
                                          <i class="mdi mdi-pencil"></i>
                                      </button>
                                    </div>
                                    <div class="invalid-feedback">
                                        La matricula es obligatoria. Elija uno de la lista.
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Remolque</label>
                                    <input type="text" class="form-control" placeholder="Remolque" formControlName="remolque">
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <div class="checkbox checkbox-info pt-4 mt-2">
                                            <input name="enviado" id="checkEnviado" type="checkbox" class="material-inputs chk-col-indigo" formControlName="enviado">
                                            <label for="checkEnviado"> Portes </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label>Transportista</label>
                                    <input type="text" class="form-control" placeholder="Transportista" formControlName="transportista">
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label>Agencia</label>
                                    <input type="text" class="form-control" placeholder="Agencia" formControlName="agencia">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Chofer</label>
                                    <input type="text" class="form-control" placeholder="Chofer" formControlName="chofer">
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>DNI</label>
                                    <input type="text" class="form-control" placeholder="DNI chofer" formControlName="dnichofer">
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-4">
                                <div class="form-group">
                                    <label>Teléfono</label>
                                    <input type="text" class="form-control" placeholder="Teléfono chofer" formControlName="telefonochofer">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div *ngIf="bascula" class="col-sm-12 col-lg-4">
                                <label>Bruto</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Bruto" step=".01" min="0" formControlName="bruto" (change)="calcularPesos('b')">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ codigoUnidad }}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="bascula" class="col-sm-12 col-lg-4">
                                <label>Tara</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Tara" step=".01" min="0" formControlName="tara" (change)="calcularPesos('t')">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ codigoUnidad }}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="verNeto" class="col-sm-12 col-lg-4">
                                <label>{{ unidad }}*</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Neto" step=".01" min="0" required [ngClass]="{ 'is-invalid': campoNoValido('neto') }" formControlName="neto" (change)="calcularPesos('n')">
                                    <div class="input-group-append">
                                        <span class="input-group-text">{{ codigoUnidad }}</span>
                                    </div>
                                    <div class="invalid-feedback">
                                        {{ unidad }} es obligatorio.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="verTemperatura" class="form-row mt-3">
                            <div class="col-sm-12 col-lg-4">
                                <label>Temperatura*</label>
                                <div class="input-group">
                                    <input type="number" class="form-control" placeholder="Temperatura" step=".5" min="0" required [ngClass]="{ 'is-invalid': campoNoValido('temperatura') }" formControlName="temperatura">
                                    <div class="input-group-append">
                                        <span class="input-group-text">ºC</span>
                                    </div>
                                    <div class="invalid-feedback">
                                        La temperatura es obligatoria.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="card-body">
                        <div class="form-row">

                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label>Observaciones</label>
                                    <textarea class="form-control" placeholder="Observaciones para añadir en el albarán..." rows="2" formControlName="comentarios"></textarea>
                                </div>
                            </div>

                            <div class="col-sm-12 col-lg-6">
                                <div class="form-group">
                                    <label>Notas internas</label>
                                    <textarea class="form-control" placeholder="Notas internas solo visibles en el programa..." rows="2" formControlName="notas"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="row">
                            <div class="col text-danger">

                                <p *ngIf="campoNoValido('planta') || campoNoValido('obra') ||
                                          campoNoValido('fecha') || campoNoValido('hora') ||
                                          campoNoValido('albaran') || selectNoValido('tipo') ||
                                          campoNoValido('formula') || campoNoValido('articulo') ||
                                          campoNoValido('matricula') || campoNoValido('neto') ||
                                          campoNoValido('temperatura')
                                          ">
                                    Hay errores en el formulario. Corrígelos para poder guardar la información.
                                </p>

                            </div>
                        </div>
                        <div class="form-group mb-0 text-right">
                            <button type="button" class="btn btn-secondary waves-effect waves-light mr-1" (click)="imprimirSalida()" [disabled]="disableImprimir">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="disableImprimir"></span>
                                {{ txtBtnImprimir }}
                            </button>
                            <button *ngIf="!salidaID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                            <button *ngIf="salidaID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarSalida()">Actualizar</button>
                            <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarSalida()">Cancelar</button>
                        </div>
                    </div>

                  </form>

                </div>

                <div *ngIf="verDatosAdicionales && tieneTimeline" [ngClass]="verDatosAdicionales && (tieneTimeline || tieneVersiones) ? 'tab-pane p-3' : ''" id="adicionales" class="p-5" style="background-color: #f4f6f9;" role="tabpanel">

                  <div class="row justify-content-md-center">

                    <div class="col-sm-12 col-lg-6">

                      <p-timeline [value]="timeline" align="alternate">
                        <ng-template pTemplate="content" let-event>
                          <p-card [header]="event.title" [subheader]="event?.meta">
                            <ng-template pTemplate="header">
                              <div class="card" [ngStyle]="{ 'min-height': event?.localizacion ? '300px' : '', 'display': event?.localizacion ? 'flex' : 'none' }">
                                <!-- <app-mapa-llegada *ngIf="event?.localizacion && event?.id === 'llegada'" [ubicacion]="event?.localizacion"></app-mapa-llegada> -->
                                <app-mapa-descargando *ngIf="event?.localizacion && event?.id === 'descargando'" [ubicacion]="event?.localizacion"></app-mapa-descargando>
                                <app-mapa-descargado *ngIf="event?.localizacion && event?.id === 'descargado'" [ubicacion]="event?.localizacion"></app-mapa-descargado>
                                <!-- <app-mapa-salida *ngIf="event?.localizacion && event?.id === 'salida'" [ubicacion]="event?.localizacion"></app-mapa-salida> -->
                              </div>
                            </ng-template>
                            <ng-template pTemplate="content" *ngIf="event?.clima">
                              <table class="table table-striped">
                                <tbody>
                                  <tr>
                                    <td>Temperatura</td>
                                    <td>{{ event?.clima?.temperatura }} ºC</td>
                                  </tr>
                                  <tr>
                                    <td>Humedad</td>
                                    <td>{{ event?.clima?.humedad }} %</td>
                                  </tr>
                                  <tr>
                                    <td>Presión</td>
                                    <td>{{ event?.clima?.presion }} hPa</td>
                                  </tr>
                                  <tr>
                                    <td>Viento</td>
                                    <td>{{ event?.clima?.viento }} m/s</td>
                                  </tr>
                                </tbody>
                              </table>
                            </ng-template>
                          </p-card>
                        </ng-template>
                      </p-timeline>

                    </div>

                  </div>

                </div>

                <div *ngIf="verDatosAdicionales && tieneVersiones" [ngClass]="verDatosAdicionales && (tieneTimeline || tieneVersiones) ? 'tab-pane p-3' : ''" id="versiones" role="tabpanel">

                  <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <h4 class="card-title">Versiones ({{ versiones.length }})</h4>
                    </div>
                  </div>

                  <ngx-datatable
                    #table
                    class='bootstrap'
                    [columnMode]="'flex'"
                    [scrollbarH]="true"
                    [headerHeight]="40"
                    [footerHeight]="50"
                    [rowHeight]="'auto'"
                    [sortType]="sortType"
                    [messages]="{emptyMessage: 'No hay registros que mostrar'}"
                    [limit]="versiones.length"
                    [rows]='versiones'
                    [sorts]="sorts"
                    (sort)="onSort($event)"
                    [offset]="offset"
                    (page)="onPage($event)"
                    >

                    <ngx-datatable-column name="Fecha" [flexGrow]="1">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row['modificado']?.['modificadoel'].toDate() | date: 'dd/MM/yyyy' }}
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Hora" [flexGrow]="1">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row['modificado']?.['modificadoel'].toDate() | date: 'HH:mm' }}
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Modificado por" [flexGrow]="2">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                          {{ row['modificado']?.['modificadopor']?.['nombre'] | uppercase }}
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Datos modificados" [flexGrow]="5">
                      <ng-template let-row="row" ngx-datatable-cell-template>
                        <span *ngFor="let item of row['controls']" class="badge bg-info text-white mr-1">{{ item['campo'] }} {{ item['valor'] ? ' - ' + item['valor'] : '' }}</span>
                      </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="" prop="id" [sortable]="false" [headerClass]="getBtnClass" [cellClass]="getBtnClass" [flexGrow]="2">
                      <ng-template let-value="value" ngx-datatable-cell-template>
                          <!-- <div class="btn-group" role="group">
                              <button type="button" class="btn btn-primary" (click)="verVersionSalida( value )"><i class="mdi mdi-eye"></i></button>
                              <button type="button" class="btn btn-secondary" (click)="imprimirVersionSalida( value )"><i class="mdi mdi-printer"></i></button>
                          </div> -->
                      </ng-template>
                  </ngx-datatable-column>


                  <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-curPage="curPage" let-offset="offset">
                        <div style="padding: 5px 10px" *ngIf="rowCount > 0">
                            <div>Mostrando del {{ (curPage * pageSize) - pageSize + 1 }} al {{ ((curPage * pageSize) > rowCount) ? rowCount : curPage * pageSize }} de {{ rowCount }} registros</div>
                        </div>
                        <datatable-pager
                          [pagerLeftArrowIcon]="'datatable-icon-left'"
                          [pagerRightArrowIcon]="'datatable-icon-right'"
                          [pagerPreviousIcon]="'datatable-icon-prev'"
                          [pagerNextIcon]="'datatable-icon-skip'"
                          [page]="curPage"
                          [size]="pageSize"
                          [count]="rowCount"
                          [hidden]="!((rowCount / pageSize)> 1)"
                          (change)="table.onFooterPage($event)"
                        >
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>

                  </ngx-datatable>


                </div>

              </div>

            </div>

        </div>
    </div>
</div>
