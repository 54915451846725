<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-info">
                <h4 class="card-title text-white">Obra</h4>
            </div>

            <div class="card-body">

                <!-- Nav tabs -->
                <ul class="nav nav-pills justify-content-end mt-2" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#datos" role="tab" (click)="tabClic('datos')">
                            <span>Datos básicos</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="obraID">
                        <a class="nav-link" data-toggle="tab" href="#documentos" role="tab" (click)="tabClic('documentos')">
                            <span>Documentos</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content border mt-2">

                    <div class="tab-pane active p-3" id="datos" role="tabpanel">

                        <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="obraForm" (ngSubmit)="crearObra()">
                            <div class="card-body">
                                <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-3">
                                        <div class="form-group">
                                            <label>Código*</label>
                                            <input type="text" class="form-control" [placeholder]="(modoLectura) ? '' : 'Código'" required [ngClass]="{ 'is-invalid': campoNoValido('codigo') }" formControlName="codigo" [readonly]="modoLectura">
                                            <div class="invalid-feedback">
                                                El código es obligatorio.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-9">
                                        <div class="form-group">
                                            <label>Nombre*</label>
                                            <input type="text" class="form-control" [placeholder]="(modoLectura) ? '' : 'Nombre'" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre" [readonly]="modoLectura">
                                            <div class="invalid-feedback">
                                                El nombre es obligatorio.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                  <div class="col-sm-12 col-lg-6">
                                    <label>Cliente*</label>
                                    <div class="input-group">
                                      <ng-select
                                        [items]="clientes"
                                        bindLabel="nombre"
                                        bindValue="id"
                                        placeholder="Elige un cliente..."
                                        notFoundText="No hay clientes disponibles"
                                        clearAllText="Limpiar"
                                        formControlName="cliente"
                                        [clearable]="true"
                                        [loadingText]="'Cargando...'"
                                        (change)="changeCliente($event)"
                                        required
                                      ></ng-select>
                                      <div class="input-group-append" *ngIf="!modoLectura">
                                        <button class="btn btn-outline-success" type="button" [tabIndex]="-1" (click)="clienteModal()">
                                          <i class="mdi mdi-plus"></i>
                                        </button>
                                      </div>
                                      <div class="invalid-ngselect" *ngIf="formSubmitted && obraForm.controls['cliente'].hasError('required')">
                                        El cliente es obligatorio. Elige uno.
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="row mb-3">

                                    <div class="col-sm-12 col-lg-6">
                                        <label>Tipo*</label>
                                        <div class="input-group">
                                            <ng-select
                                              bindLabel="nombre"
                                              bindValue="id"
                                              placeholder="Elige un tipo..."
                                              formControlName="tipo"
                                              [clearable]="false"
                                              required
                                            >
                                              <ng-option value="civil">Civil</ng-option>
                                              <ng-option value="combustible">Combustible</ng-option>
                                              <ng-option value="extendido">Extendido</ng-option>
                                              <ng-option value="planta">Planta</ng-option>
                                              <ng-option value="venta">Venta</ng-option>
                                              <ng-option value="almacen">Almacén</ng-option>
                                            </ng-select>
                                            <div class="invalid-ngselect" *ngIf="formSubmitted && obraForm.controls['tipo'].hasError('required')">
                                              El tipo es obligatorio. Elige uno.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-lg-6">
                                        <label>Estado*</label>
                                        <div class="input-group">
                                            <ng-select
                                              bindLabel="nombre"
                                              bindValue="id"
                                              placeholder="Elige un estado..."
                                              formControlName="situacion"
                                              [clearable]="false"
                                              required
                                            >
                                              <ng-option value="enEstudio">En estudio</ng-option>
                                              <ng-option value="enCurso">En curso</ng-option>
                                              <ng-option value="finalizada">Finalizada</ng-option>
                                            </ng-select>
                                            <div class="invalid-ngselect" *ngIf="formSubmitted && obraForm.controls['situacion'].hasError('required')">
                                              El estado es obligatorio. Elige uno.
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row mb-3">

                                    <div class="col-sm-12 col-lg-12">
                                        <div class="form-group">
                                            <label>Dirección</label>
                                            <input type="text" class="form-control" [placeholder]="(modoLectura) ? '' : 'Dirección'" formControlName="direccion" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                </div>

                                <div class="row mb-3">

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Provincia</label>
                                            <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Provincia'" formControlName="provincia" [ngbTypeahead]="provsearch" [inputFormatter]="provformat" [resultFormatter]="provformat" [editable]="false" (selectItem)="selecProvincia($event)"
                                                (ngModelChange)="verificarProvincia()" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Localidad</label>
                                            <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Localidad'" formControlName="localidad" [ngbTypeahead]="locasearch" [inputFormatter]="locaformat" [resultFormatter]="locaformat" [editable]="false" (selectItem)="selecLocalidad($event)"
                                                [readonly]="modoLectura">
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Código postal</label>
                                            <input type="search" class="form-control" [placeholder]="(modoLectura) ? '' : 'Código postal'" formControlName="cp" [ngbTypeahead]="cpsearch" [inputFormatter]="cpformat" [resultFormatter]="cpformat" [editable]="false" (selectItem)="selecCP($event)" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                </div>

                                <div class="row mb-3">

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Localización</label>
                                            <input type="number" class="form-control" [placeholder]="(modoLectura) ? '' : 'Latitud'" formControlName="lat" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label></label>
                                            <input type="number" class="form-control" [placeholder]="(modoLectura) ? '' : 'Longitud'" formControlName="lng" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                </div>

                                <div class="row mb-3" *ngIf="datosPlanta">

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Número marcado CE</label>
                                            <input type="text" class="form-control" [placeholder]="(modoLectura) ? '' : 'Número marcado CE'" formControlName="numeroce" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-lg-4">
                                        <div class="form-group">
                                            <label>Código formulas</label>
                                            <input type="text" class="form-control" [placeholder]="(modoLectura) ? '' : 'Código formulas'" formControlName="codigoformulas" [readonly]="modoLectura">
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col text-danger">

                                        <p *ngIf="campoNoValido('codigo') || campoNoValido('nombre') ||
                                                selectNoValido('cliente') || selectNoValido('situacion') ||
                                                selectNoValido('tipo')">
                                            Hay errores en el formulario. Corrígelos para poder guardar la información.
                                        </p>

                                    </div>
                                </div>


                            </div>
                            <hr>
                            <div class="card-body">
                                <div class="form-group mb-0 text-right">
                                    <button *ngIf="!obraID && !modoLectura" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                                    <button *ngIf="obraID && !modoLectura" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarObra()">Actualizar</button>
                                    <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarObra()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                                </div>
                            </div>
                        </form>

                    </div>

                    <div class="tab-pane p-3" id="documentos" role="tabpanel" *ngIf="obraID">

                        <div class="card-body">

                            <div class="row">

                                <div class="col-sm-12 col-lg-4 mb-2" *ngIf="!modoLectura">

                                    <form class="form-horizontal" id="docsform" autocomplete="off" [formGroup]="docsForm">
                                        <div class="row">
                                            <div class="col-sm-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Nombre*</label>
                                                    <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoDocsNoValido('nombredoc') }" formControlName="nombredoc">
                                                    <div class="invalid-feedback">
                                                        El nombre es obligatorio.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-sm-12 col-lg-12">
                                            <label>Categoría*</label>
                                            <div class="input-group">
                                              <ng-select
                                                bindLabel="nombre"
                                                bindValue="id"
                                                placeholder="Elige una categoría..."
                                                formControlName="categoriadoc"
                                                [clearable]="false"
                                                required
                                              >
                                                <ng-option value="presupuesto">Presupuesto</ng-option>
                                                <ng-option value="foto">Foto</ng-option>
                                                <ng-option value="otro">Otro</ng-option>
                                              </ng-select>
                                              <div class="invalid-ngselect" *ngIf="formDocsSubmitted && docsForm.controls['categoriadoc'].hasError('required')">
                                                La categoría es obligatoria. Elige una.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </form>

                                    <div class="row mt-3">

                                        <div class="col-sm-12 col-lg-12">

                                            <ngx-dropzone [multiple]="false" (change)="onChangeDropzone($event)">
                                                <ngx-dropzone-label>Suelte los archivos aquí o haga clic para cargarlos</ngx-dropzone-label>
                                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemoveDoc(f)">
                                                    <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                                                </ngx-dropzone-preview>
                                            </ngx-dropzone>

                                        </div>

                                    </div>

                                    <hr>

                                    <div class="row">

                                        <div class="col-sm-12 col-lg-12">

                                            <div class="row">
                                                <div class="col text-danger">

                                                    <p *ngIf="campoDocsNoValido('nombredoc') || selectDocsNoValido('categoriadoc')">
                                                        Hay errores en el formulario. Corrígelos para poder subir el documento.
                                                    </p>

                                                </div>
                                            </div>

                                            <div class="form-group mb-0 text-center">
                                                <button type="button" class="btn btn-success waves-effect waves-light mr-1" *ngIf="!uploadPercent" (click)="subirDoc(files)">Subir</button>
                                                <button class="btn btn-success mr-1" type="button" disabled *ngIf="uploadPercent">
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Subiendo...
                                                    </button>
                                                <button type="button" class="btn btn-secondary waves-effect waves-light" (click)="limpiarDocs()">Limpiar</button>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="row mt-3 mb-3" *ngIf="uploadPercent">
                                        <div class="col-sm-12 col-lg-12">

                                            <div class="progress" style="height: 15px;">
                                                <div class="progress-bar progress-bar-striped bg-info" [style.width.%]="uploadPercent" role="progressbar">{{ uploadPercent }}%</div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div class="col-sm-12" [ngClass]="modoLectura ? 'col-lg-12' : 'col-lg-8'">

                                    <div class="row animated fadeIn fast" *ngIf="cargandoDocs">

                                        <div class="col-sm-12">

                                            <div class="alert alert-warning text-center">
                                                <strong>Cargando</strong>
                                                <br>
                                                <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                                                <br>
                                                <span>Espere por favor</span>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="table-responsive" *ngIf="!cargandoDocs">
                                        <table class="table table-striped search-table v-middle dataTable">
                                            <thead class="header-item">
                                                <th class="text-dark font-weight-bold">Nombre</th>
                                                <th class="text-dark font-weight-bold">Categoría</th>
                                                <th class="text-dark font-weight-bold">Tipo</th>
                                                <th class="text-center"></th>
                                            </thead>
                                            <tbody>
                                                <tr class="search-items" *ngFor="let doc of documentos">
                                                    <td class="text-nowrap">
                                                        <span>{{ doc.nombredoc }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ doc.categoriadoc }}</span>
                                                    </td>
                                                    <td>
                                                        <span>{{ doc.tipo | uppercase }}</span>
                                                    </td>
                                                    <td class="text-right">
                                                        <div class="btn-group" role="group">
                                                            <button type="button" class="btn btn-light-info text-info waves-effect waves-light" (click)="verDocumento( doc.url )"><i class="mdi mdi-eye"></i></button>
                                                            <button *ngIf="!modoLectura" type="button" class="btn btn-light-danger text-danger waves-effect waves-light" (click)="borrarDocumento( doc.id )"><i class="mdi mdi-delete"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="row" *ngIf="documentos && documentos.length === 0">

                                        <div class="col-sm-12">

                                            <div class="alert alert-info text-center">
                                                <strong>No hay documentos</strong> <br>
                                                <i class="fas fa-file fa-3x mt-2 mb-2"></i>
                                                <br>
                                                <span>Por favor agregue un documento para empezar</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <hr>
                        <div class="card-body">
                            <div class="form-group mb-0 text-right">
                                <button type="button" class="btn waves-effect waves-light" [ngClass]="modoLectura ? 'btn-secondary' : 'btn-dark-danger'" (click)="cancelarObra()">{{modoLectura ? 'Salir' : 'Cancelar'}}</button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
