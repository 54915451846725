import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { getDoc } from '@angular/fire/firestore';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

import { debounceTime, distinctUntilChanged, filter, first, map, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { MantenimientosService, FormulariosService, MaquinariaService } from 'src/app/services/service.index';

import Swal from 'sweetalert2';

type Maquina = {id: string, codigo: string, nombre: string, codnom: string};

@Component({
  selector: 'app-mantenimiento',
  templateUrl: './mantenimiento.component.html',
  styleUrls: ['./mantenimiento.component.css']
})
export class MantenimientoComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Avería';
  hoy: string = formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' );
  mantenimientoID: string;
  modoLectura: boolean = false;
  modoEditar: boolean = false;
  modoAviso: boolean = false;
  maquinaria!: any[];
  maquina = '';
  txtHorometro = 0;
  txtHorometroAviso = 0;
  txtUdHorometro = 'h';
  txtUdHorometroAviso = 'h';
  estado = 'Pendiente';
  cargando: boolean = true;

  private subs = new SubSink();

  public formSubmitted = false;

  public mantenimientoForm = this.fb.group({
    fecha: [ { value: null, disabled: true }, Validators.required ],
    fechaAviso: [ { value: null, disabled: true }, Validators.required ],
    maquina: [ null, Validators.required ],
    horometro: null,
    horometroAviso: null,
    udHorometro: 'h',
    udHorometroAviso: 'h',
    descripcion: null,
    aceite: [ {value: false, disabled: true} ],
    hidraulico: [ {value: false, disabled: true} ],
    otros: [ {value: false, disabled: true} ],
    filtroaceite: [ {value: false, disabled: true} ],
    filtrogasoil: [ {value: false, disabled: true} ],
    filtroaire: [ {value: false, disabled: true} ],
    filtrohidraulico: [ {value: false, disabled: true} ],
    filtroagua: [ {value: false, disabled: true} ],
    filtroaceiteconvertidor: [ {value: false, disabled: true} ],
    filtrootros: [ {value: false, disabled: true} ],
    estado: 'pendiente',
  });

  maquinaformat = (maquina: Maquina) => maquina.codigo + ' - ' + maquina.nombre;

  maquinasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter( term => term.length >= 1 ),
    map(term => this.maquinaria.filter( ( maquina: any ) => maquina?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                                            maquina?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
  )

  constructor( private mantenimientosService: MantenimientosService,
               private maquinariaService: MaquinariaService,
               private formulariosService: FormulariosService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private location: Location,
               private title: Title, ) { }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe( params => {
        if ( params.id ) {
          this.mantenimientoID = params.id;
        }
        const accion = params.accion;
        this.tituloBC = accion[0].toUpperCase() + accion.slice(1).toLowerCase() + ' Mantenimiento';
        this.title.setTitle( this.title.getTitle().replace('#', accion[0].toUpperCase() + accion.slice(1).toLowerCase() ) );
        this.cargarMaquinas();
        if (accion === 'editar' || accion === 'ver') {
          this.cargarMantenimiento( params.id, accion );
        } else if ( !this.mantenimientoID && (accion === 'aviso' || accion === 'nuevo') ) {
          if (accion === 'aviso') {
            this.modoAviso = true;
            this.tituloBC = 'Nuevo Aviso Mantenimiento';
            this.mantenimientoForm.controls['fechaAviso'].setValue(this.hoy);
            this.mantenimientoForm.controls['fecha'].disable();
            this.mantenimientoForm.controls['horometro'].disable();
            this.estado = 'Aviso';
            this.mantenimientoForm.controls['estado'].setValue('aviso');
          } else {
            this.mantenimientoForm.controls['fecha'].setValue(this.hoy);
            this.mantenimientoForm.controls['fecha'].enable();
            this.mantenimientoForm.controls['fechaAviso'].disable();
            this.mantenimientoForm.controls['horometroAviso'].disable();
            this.mantenimientoForm.controls['udHorometroAviso'].disable();
          }
          this.activarDisabled();
          this.cargando = false;
        } else {
          this.router.navigate(['/maquinaria/mantenimientos']);
        }
      })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  activarDisabled(): void {
    this.mantenimientoForm.get('aceite').enable();
    this.mantenimientoForm.get('hidraulico').enable();
    this.mantenimientoForm.get('otros').enable();
    this.mantenimientoForm.get('filtroaceite').enable();
    this.mantenimientoForm.get('filtrogasoil').enable();
    this.mantenimientoForm.get('filtroaire').enable();
    this.mantenimientoForm.get('filtrohidraulico').enable();
    this.mantenimientoForm.get('filtroagua').enable();
    this.mantenimientoForm.get('filtroaceiteconvertidor').enable();
    this.mantenimientoForm.get('filtrootros').enable();
  }

  cargarMaquinas(): void {
    this.subs.sink = this.formulariosService.selectMaquinas()
      .subscribe( maquinasBD => {
        if ( maquinasBD.length > 0 ) {
          this.maquinaria = maquinasBD.sort( (a, b) => a.codigo.localeCompare(b.codigo, 'es', { numeric: true } ) );
        }
      });
  }

  cargarMantenimiento( id: string, accion: string ) {
    if (id) {
      this.mantenimientoID = id;
    }
    if (accion === 'ver') {
      this.modoLectura = true;
      this.modoEditar = false;
    } else if(accion === 'editar') {
      this.modoLectura = false;
      this.modoEditar = true;
      this.activarDisabled();
      this.mantenimientoForm.controls['fecha'].enable();
      this.mantenimientoForm.controls['fechaAviso'].disable();
      this.mantenimientoForm.controls['horometroAviso'].disable();
      this.mantenimientoForm.controls['udHorometroAviso'].disable();
    } else {
      return;
    }

    this.subs.sink = this.mantenimientosService.obtenerMantenimientoPorId( id )
        .pipe(
          first()
        )
        .subscribe( async (mantenimientoData: any) => {
          if ( !mantenimientoData ) {
            Swal.fire('Error', 'Error al cargar los datos del mantenimiento', 'error');
            return this.router.navigateByUrl('/maquinaria/mantenimientos');
          }
          const maquinaData: any = (await getDoc(mantenimientoData.maquina)).data();
          mantenimientoData.maquina = maquinaData;
          mantenimientoData.maquina.codnombre = maquinaData.codnavision + ' (' + maquinaData.nombre + ')';
          mantenimientoData.maquina.codigo = maquinaData.codnavision;
          const fechaBD = (mantenimientoData.fecha) ? formatDate(  mantenimientoData.fecha?.toDate(), 'yyyy-MM-dd', 'en' ) : '';
          const fechaAvisoBD = (mantenimientoData.fechaAviso) ? formatDate(  mantenimientoData.fechaAviso?.toDate(), 'yyyy-MM-dd', 'en' ) : '';
          const maquina = {
            id: mantenimientoData.maquina.id,
            codigo: mantenimientoData.maquina.codigo,
            codnom: mantenimientoData.maquina.codigo + ' - ' + mantenimientoData.maquina.nombre,
            nombre: mantenimientoData.maquina.nombre
          };
          this.maquina = maquina.id;
          this.txtHorometro = mantenimientoData.horometro;
          this.txtHorometroAviso = mantenimientoData.horometroAviso;
          this.txtUdHorometro = mantenimientoData?.udHorometro ?? 'h';
          this.txtUdHorometroAviso = mantenimientoData?.udHorometroAviso ?? 'h';
          switch (mantenimientoData.estado) {
            case 'aviso':
              this.modoAviso = true;
              this.mantenimientoForm.controls['fecha'].disable();
              this.mantenimientoForm.controls['fechaAviso'].enable();
              this.mantenimientoForm.controls['horometroAviso'].enable();
              this.mantenimientoForm.controls['udHorometroAviso'].enable();
              this.estado = 'Aviso';
              break;
            case 'pendiente':
              this.estado = 'Pendiente';
              break;
            case 'en_curso':
              this.estado = 'En curso';
              break;
            case 'reparado':
              this.estado = 'Reparado';
              break;
          }
          this.mantenimientoForm.reset({
            fecha: fechaBD,
            fechaAviso: fechaAvisoBD,
            maquina,
            horometro: mantenimientoData.horometro,
            horometroAviso: mantenimientoData.horometroAviso,
            udHorometro: mantenimientoData?.udHorometro ?? 'h',
            udHorometroAviso: mantenimientoData?.udHorometroAviso ?? 'h',
            descripcion: mantenimientoData.descripcion,
            aceite: mantenimientoData.aceite,
            hidraulico: mantenimientoData.hidraulico,
            otros: mantenimientoData.otros,
            filtroaceite: mantenimientoData.filtroaceite,
            filtrogasoil: mantenimientoData.filtrogasoil,
            filtroaire: mantenimientoData.filtroaire,
            filtrohidraulico: mantenimientoData.filtrohidraulico,
            filtroagua: mantenimientoData.filtroagua,
            filtroaceiteconvertidor: mantenimientoData.filtroaceiteconvertidor,
            filtrootros: mantenimientoData.filtrootros,
            estado: mantenimientoData.estado,
          });
          this.cargando = false;          
        });
  }

  crearMantenimiento() {

    this.formSubmitted = true;

    if ( this.mantenimientoForm.invalid ) {
      return;
    }

    this.mantenimientosService.crearMantenimiento( this.mantenimientoForm.value )
      .then( (idMantenimiento) => {
        if ( this.mantenimientoForm.get('horometro').value && (this.mantenimientoForm.get('horometro').value > this.txtHorometro ) ) {
          // Crear horometro si es distinto a un aviso
          if (this.mantenimientoForm.controls['estado'].value !== 'aviso') {
            const horometro = {
              fecha: this.mantenimientoForm.controls['fecha'].value,
              maquina: this.mantenimientoForm.controls['maquina'].value,
              horometro: this.mantenimientoForm.controls['horometro'].value,
              udHorometro: this.mantenimientoForm.controls['udHorometro'].value,
              idMantenimiento
            };
            this.maquinariaService.addHorometro(horometro)
              .then(() => this.router.navigateByUrl('/maquinaria/mantenimientos') )
              .catch( e => console.log(e) );
          }
        } else {
          return this.router.navigateByUrl('/maquinaria/mantenimientos');
        }
      })
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarMantenimiento() {

    this.formSubmitted = true;

    if ( this.mantenimientoForm.invalid ) {
      console.log(this.mantenimientoForm.controls);
      return;
    }

    if ( this.mantenimientoForm.pristine ) {
      this.cancelarMantenimiento();
      return;
    }

    this.mantenimientosService.actualizarMantenimiento( this.mantenimientoID, this.mantenimientoForm.value )
      .then( () => {
        if ( this.mantenimientoForm.get('horometro').value && (this.mantenimientoForm.get('horometro').value !== this.txtHorometro
          || this.mantenimientoForm.get('udHorometro').value !== this?.txtUdHorometro ) ) {
            // Actualizar horometro si es distinto a un aviso
            if (this.mantenimientoForm.controls['estado'].value !== 'aviso') {
              const horometro = {
                fecha: this.mantenimientoForm.controls['fecha'].value,
                maquina: this.mantenimientoForm.controls['maquina'].value,
                horometro: this.mantenimientoForm.controls['horometro'].value,
                udHorometro: this.mantenimientoForm.controls['udHorometro'].value,
                idMantenimiento: this.mantenimientoID
              };
              this.maquinariaService.updateHorometro(horometro)
                .then( () => this.router.navigateByUrl('/maquinaria/mantenimientos') )
                .catch( e => console.log(e) );
            }
        } else {
          return this.router.navigateByUrl('/maquinaria/mantenimientos');
        }
      })
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  // Cancelar el formulario y regresar al listado
  cancelarMantenimiento(): void {

    if ( this.mantenimientoForm.pristine ) {
      this.router.navigateByUrl('/maquinaria/mantenimientos');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigateByUrl('/maquinaria/mantenimientos');

      }

    });


  }

  selecMaquina( event: NgbTypeaheadSelectItemEvent ) {

  }

  verificarMaquina() {

  }

  campoNoValido( campo: string ): boolean {

    if ( this.mantenimientoForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.mantenimientoForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
