import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { DocumentData, DocumentSnapshot, Firestore, doc, docData, getDoc } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import jspdf from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class JspdfService {

  imgLogo: string;
  imgLogoNuevo: string;
  imgSello: string;
  imgSelloCENuevo: string;
  imgSelloNuevo: string;

  constructor( private http: HttpClient,
               private firestore: Firestore,
             ) {

    // Logo Orsa
    this.http.get('../../assets/images/logo-albaran.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgLogo = base64ImageUrl;
      });

    // Logo Orsa nuevo
    this.http.get('../../assets/images/logo-albaran-nuevo.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgLogoNuevo = base64ImageUrl;
      });

    // Sello CE
    this.http.get('../../assets/images/sello-albaran.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgSello = base64ImageUrl;
      });

    // Sello CE nuevo
    this.http.get('../../assets/images/sello-albaran-ce-nuevo.png', { responseType: 'blob' })
      .pipe(
        switchMap(blob => this.convertBlobToBase64(blob))
      )
      .subscribe( (base64ImageUrl: string) => {
        this.imgSelloCENuevo = base64ImageUrl;
      });

    // Sello nuevo
    this.http.get('../../assets/images/sello-albaran-nuevo.png', { responseType: 'blob' })
    .pipe(
      switchMap(blob => this.convertBlobToBase64(blob))
    )
    .subscribe( (base64ImageUrl: string) => {
      this.imgSelloNuevo = base64ImageUrl;
    });

  }

  async crearAlbaranPlanta( formData: any, descargar: boolean = false ) {

    const plantaData: any = (formData?.planta) ? (await getDoc( formData.planta )).data() : null;
    const formulaData: any = (formData?.formula) ? (await getDoc( formData.formula )).data() : null;
    const obraData: any = (formData?.obra) ? (await getDoc( formData.obra )).data() : null;
    const clienteData: any = (formData?.cliente) ? (await getDoc( formData.cliente )).data() : null;
    let formuArtiData: any;
    let formulaSello: any;
    let formulaCE = false;
    if ( formData.tipo === 'pt' ) {
      formuArtiData = formulaData;
      formulaCE = formulaData.marcadoce;
      formulaSello = (formulaData?.selloce) ? (await getDoc( formulaData.selloce )).data() : null;
    } else {
      formuArtiData = (formData?.articulo) ? (await getDoc( formData.articulo )).data() : null;
    }
    const unidadData: any = (formData?.unidad) ? (await getDoc( formData.unidad )).data() : null;
    const matriculaData: any = (formData?.matricula) ? (await getDoc( formData.matricula )).data() : null;

    let desp = 0;
    const txtplanta = plantaData.localidad.nombre?.toUpperCase() ?? '';
    const txtplatit = 'PLANTA ASFALTICA DE ' + txtplanta;
    const txtpladir = plantaData.direccion;
    const txtplaloc =  plantaData.localidad.codigo_postal + ' ' + plantaData.localidad.nombre + ' (' + plantaData.provincia.nombre + ')';

    let doc = new jspdf();

    // Añadir opcion de señalar el numero de copias deseadas
    // Por defecto hacer 4 copias en 2 paginas
    for (let i = 1; i <= 4; i++) {
      if ( i % 2 === 0 ) {
        desp = 145;
      } else if ( i > 1 ) {
        doc.addPage();
        desp = 0;
      }

      if ( !(formData.fecha instanceof Date && !isNaN(formData.fecha)) ) {
        formData.fecha = formData.fecha.toDate();
      }
      const fecha = formatDate( formData.fecha, 'dd/MM/yyyy', 'es' );
      const hora = formatDate( formData.fecha, 'HH:mm:ss', 'es' );
      // Añadir logo
      if ( formData.fecha >= new Date('2024-07-01') ) {
        if ( this.imgLogoNuevo ) {
          doc.addImage(this.imgLogoNuevo, 'PNG', 7, 5 + desp, 55, 23);
        }
      } else {
        if ( this.imgLogo ) {
          doc.addImage(this.imgLogo, 'PNG', 7, 5 + desp, 55, 23);
        }
      }

      // Añadir sello si corresponde
      if ( formData.fecha >= new Date('2024-07-01') ) {
        doc.setFontSize(4);
        if ( this.imgSelloCENuevo && formulaCE ) {
          doc.addImage(this.imgSelloCENuevo, 'PNG', 148, 5 + desp, 55, 20);
          let despSello = 0;
          if (formulaSello?.anio) {
            const txtAnio = formulaSello?.anio?.toString().slice(2);
            doc.text(txtAnio, 157, 20 + despSello + desp);
            despSello+= 2.5;
          }
          if (formulaSello?.certificado) {
            doc.text(formulaSello?.certificado, 150.5, 20 + despSello + desp);
            despSello+= 2.5;
          }
          if (formulaSello?.norma) {
            doc.text(formulaSello?.norma, 149, 20 + despSello + desp);
          }
        } else {
          if ( this.imgSelloNuevo ) {
            doc.addImage(this.imgSelloNuevo, 'PNG', 148, 5 + desp, 55, 20);
          }
        }
        // Nº Certificados
        doc.text( 'ER-1551/2006', 166.5, 20 + desp);
        doc.text( 'GA-0311/2013', 179, 20 + desp);
        doc.text( 'SST-0135/2015', 192, 20 + desp);
      } else {
        if ( this.imgSello && formulaCE ) {
          doc.addImage(this.imgSello, 'PNG', 150, 5 + desp, 55, 20);
          let despSello = 0;
          doc.setFontSize(5);
          if (formulaSello?.anio) {
            const txtAnio = formulaSello?.anio?.toString().slice(2);
            doc.text(txtAnio, 160, 23 + despSello + desp);
            despSello+= 2.5;
          }
          if (formulaSello?.certificado) {
            doc.text(formulaSello?.certificado, 153.5, 23 + despSello + desp);
            despSello+= 2.5;
          }
          if (formulaSello?.norma) {
            doc.text(formulaSello?.norma, 152, 23 + despSello + desp);
          }
        }
      }
      if ( formData.fecha < new Date('2024-07-01') ) {
        // Añadir textos fijos
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text('EXCAVACIONES Y TRANSPORTES ORSA S.L.', 65, 9 + desp);
        doc.setFontSize(9);
        doc.setFont('helvetica', 'normal');
        doc.text('Paseo Errotaburu 1, 5ª Planta - 20018 SAN SEBASTIAN', 67, 13 + desp);
        doc.text('administracion@orsa.eus - Tfno. 943 62 91 44', 74, 17 + desp);
      }
      doc.setFontSize(9);
      doc.setFont('courier', 'normal');
      doc.text('FECHA:', 8, 35 + desp);
      doc.text('HORA SALIDA:', 51, 35 + desp);
      doc.text('Nº ALB.:', 101, 35 + desp);
      doc.text('CLIENTE:', 8, 41 + desp);
      doc.text('TRANSPORTISTA:', 101, 41 + desp);
      doc.text('OBRA:', 8, 64 + desp);
      doc.text('OPERADOR TRANSPORTE:', 101, 64 + desp);
      doc.text('MATRICULA:', 8, 90 + desp);
      doc.text('REMOLQUE:', 58, 90 + desp);
      doc.setFontSize(15);
      doc.setFont('courier', 'bold');
      // TODO: mostrar bruto y tara si el albaran los tiene
      if (unidadData.nombre === 'litro') {
        doc.text('NETO :', 126, 103 + desp);
      } else {
        doc.text('BRUTO:', 126, 91 + desp);
        doc.text('TARA :', 126, 97 + desp);
        doc.text('NETO :', 126, 103 + desp);
      }
      doc.text('Tª', 185, 90 + desp);
      doc.setFontSize(9);
      doc.setFont('courier', 'normal');
      doc.text('MATERIAL:', 8, 96 + desp);
      // TODO: añadir campo gestor de residuos
      // if ( formData.tipo === 're' ) {
      //   doc.text('GESTOR:', 8, 104 + desp);
      // }
      doc.text('OBSERVACIONES:', 8, 110 + desp);
      doc.text('CONDUCTOR:', 8, 119 + desp);
      doc.setFont('helvetica', 'normal');
      doc.text('CONFORME: El Cliente', 10, 124 + desp);
      if ( formData.fecha >= new Date('2024-07-01') ) {
        doc.setFontSize(7);
        doc.text('EXC. Y TRANS. ORSA, S.L. - C.I.F.: B-31065212 Inscrita en el Registro Mercantil de Guipúzcoa, Tomo 372 del libro de sociedades, Folio 180, Hoja nº 1146.', 8, 138 + desp);
      } else {
        doc.text('C.I.F.: B-31065212 Inscrita en el Registro Mercantil de Guipúzcoa, Tomo 372 del libro de sociedades, Folio 180, Hoja nº 1146.', 8, 138 + desp);
        doc.setFontSize(7);
      }
      doc.text('Las partes se someten para la resolución de cualquier controversia relativa a la interpretación y ejecución del contrato de transporte al que se refiere el presente documento a la', 8, 141 + desp);
      doc.text('junta arbitral de transporte que proceda a lo establecido en la ley 16/85 de ordenación de los transportes terrestres y sus normas de desarrollo.', 8, 144 + desp);
      doc.setFontSize(12);
      doc.setFont('courier', 'bold');
      if ( formData.tipo === 'pt' ) {
        doc.text('ALBARAN DE SALIDA', 151, 35 + desp);
      } else if ( formData.tipo === 'pb' ) {
        doc.text('SUMINISTRO', 151, 35 + desp);
      } else if ( formData.tipo === 're' ) {
        doc.text('RESIDUO', 151, 35 + desp);
      }
      // Añadir textos variables
      if ( formData.fecha >= new Date('2024-07-01') ) {
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text( txtplatit, 72, 14 + desp); // PLANTA ASFALTICA
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(9);
        const larguratxt = txtpladir.length;
        const posicion = 107 - (larguratxt / 1.4);
        doc.text( txtpladir, posicion, 18 + desp); // DIRECCION PLANTA
        doc.text( txtplaloc, 90, 22 + desp); // LOCALIDAD PLANTA
      } else {
        doc.setFontSize(8);
        doc.setFont('helvetica', 'bold');
        doc.text( txtplatit, 85, 22 + desp); // PLANTA ASFALTICA
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(10);
        const larguratxt = txtpladir.length;
        const posicion = 107 - (larguratxt / 1.4);
        doc.text( txtpladir, posicion, 26 + desp); // DIRECCION PLANTA
        doc.text( txtplaloc, 90, 29 + desp); // LOCALIDAD PLANTA
      }
      doc.setFont('helvetica', 'bold');
      doc.text( fecha, 20, 35 + desp );
      doc.text( hora, 74, 35 + desp );
      const numAlbaran = this.rellenarConCeros( formData.albaran.toString(), 5 );
      doc.text( formData.prefalbaran + '-' + numAlbaran, 117, 35 + desp);
      let nombreCliente = clienteData.nombre;
      if ( clienteData.cif ) {
        nombreCliente += ' (' + clienteData.cif + ')';
      }
      const splitNombreCliente = doc.splitTextToSize(nombreCliente, 92);
      doc.text( splitNombreCliente , 8, 45 + desp);
      let dirCliente = '';
      if ( clienteData.direccion ) {
        dirCliente = clienteData.direccion?.toUpperCase() ?? '';
      }
      let despCliente: number;
      splitNombreCliente.length > 1 ? despCliente = 4.5 : despCliente = 0;
      doc.text( dirCliente, 10, 49.5 + desp + despCliente);
      let locCliente = '';
      if ( clienteData?.localidad?.codigo_postal ) {
        locCliente = clienteData?.localidad?.codigo_postal ?? clienteData?.codpostal ?? '';
      }
      if ( clienteData?.localidad ) {
        if ( locCliente !== '' ) {
          locCliente += ' - ';
        }
        locCliente += clienteData?.localidad?.nombre?.toUpperCase() ?? clienteData.localidad?.toUpperCase() ?? '';
      }
      doc.text( locCliente, 10, 54 + desp + despCliente);
      const transportista = formData?.transportista?.toUpperCase() ?? '';
      doc.text( transportista , 101, 45 + desp);
      const codigoObra = obraData?.codigo?.toUpperCase() ?? '';
      const nombreObra = obraData?.nombre?.toUpperCase() ?? '';
      const splitNombreObra = doc.splitTextToSize(codigoObra + ' - ' + nombreObra, 91);
      doc.text( splitNombreObra, 8, 68 + desp);
      const agencia = formData?.agencia?.toUpperCase() ?? '';
      doc.text( agencia , 101, 68 + desp);
      doc.text( matriculaData.matricula, 27, 90 + desp);
      const remolque = formData?.remolque ?? '';
      doc.text( remolque, 76, 90 + desp);
      doc.setFontSize(15);
      let bruto: string;
      let tara: string;
      let neto: string;

      // Formato de pesos
      if (unidadData.nombre === 'tonelada') {
        bruto = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
          useGrouping: true
        }).format(formData.bruto);
        tara = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
          useGrouping: true
        }).format(formData.tara);
        neto = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
          useGrouping: true
        }).format(formData.neto);
      } else if(unidadData.nombre === 'litro') {
        bruto =  '';
        tara =  '';
        neto = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(formData.neto);
      } else {
        bruto = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(formData.bruto);
        if (bruto === '0') { bruto =  ''; }
        tara = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(formData.tara);
        if (tara === '0') { tara =  ''; }
        neto = new Intl.NumberFormat('es-ES', {
          style: 'decimal',
          maximumFractionDigits: 2,
          useGrouping: true
        }).format(formData.neto);
      }

      doc.text( bruto, this.alinearPesos(bruto), 91 + desp);
      doc.text( tara, this.alinearPesos(tara), 97 + desp);
      doc.text( neto, this.alinearPesos(neto), 103 + desp);
      doc.setFontSize(12);
      const temperatura = formData.temperatura ? formData.temperatura.toString() + ' ºC' : '';
      doc.text( temperatura, 181, 96 + desp);
      doc.setFontSize(10);
      let nombArticulo = '';
      if ( formData.tipo === 'pt' ) {
        nombArticulo = formuArtiData.codigo + ' - ' + formuArtiData.nombre;
      } else {
        nombArticulo = formuArtiData.nombre;
      }
      // TODO: Añadir gestor de residuos
      const txtNombArticulo = nombArticulo?.toUpperCase() ?? '';
      if ( formData.tipo === 're' ) {
        // TODO: subir nombre articulo al lado de MATERIAL:
        // añadir nombre del gestor al lado de GESTOR:
        doc.text( txtNombArticulo, 8, 100 + desp);
      } else {
        doc.text( txtNombArticulo, 8, 100 + desp);
      }
      if ( formData.comentarios ) {
        const observaciones = formData.comentarios ?? '';
        doc.text( observaciones, 35, 110 + desp);
      }
      const chofer = formData?.chofer?.toUpperCase() ?? '';
      const dniChofer = formData?.dnichofer?.toUpperCase() ?? '';
      doc.text( chofer + ' ' + dniChofer , 28, 119 + desp);

      // Dibujar cuadro
      doc.line(7, 30 + desp, 150, 30 + desp); //lh
      doc.line(7, 30 + desp, 7, 120 + desp); //lv
      doc.line(50, 30 + desp, 50, 37 + desp); //lv
      doc.line(100, 30 + desp, 100, 85 + desp); //lv
      doc.line(7, 37 + desp, 200, 37 + desp); //lh
      doc.line(150, 30 + desp, 150, 37 + desp); // lv
      doc.line(7, 60 + desp, 200, 60 + desp); //lh
      doc.line(200, 37 + desp, 200, 120 + desp); // lv
      doc.line(7, 85 + desp, 200, 85 + desp); //lh
      doc.line(7, 92 + desp, 125, 92 + desp); //lh
      doc.line(57, 85 + desp, 57, 92 + desp); //lv
      doc.line(125, 85 + desp, 125, 106 + desp); //lv
      doc.line(175, 85 + desp, 175, 106 + desp); //lv
      doc.line(7, 106 + desp, 200, 106 + desp); //lh
      doc.line(7, 120 + desp, 200, 120 + desp); //lh

    }

    if (descargar) {
      doc.save('Albaran ' + formData.prefalbaran + '-' + this.rellenarConCeros( formData.albaran.toString(), 5 ) + '.pdf');
    } else {
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    }

  }

  imprimirAlbaranPlanta( salidaID: string, descargar: boolean = false ) {

    const salidaRef = doc( this.firestore, 'salidas', salidaID );
    docData( salidaRef, { idField: 'id' } )
      .pipe(
        first()
      )
      .subscribe({
        next: (salida) => {

          if (salida) {
            this.crearAlbaranPlanta( salida, descargar );
          }

        },
        error: (err) => {
          console.log(err);
        }
      });

  }

  mostrarAlbaranPlanta( salidaID: string ) {
    const salidaRef = doc( this.firestore, 'salidas', salidaID );
    getDoc( salidaRef )
      .then( (salida: DocumentSnapshot<DocumentData>) => {

        if (salida.exists()) {
          this.crearAlbaranPlanta( salida.data(), false );
        }

      });

  }

  /////////////////////////////////////////////////
  // UTILIDADES                                  //
  /////////////////////////////////////////////////

  rellenarConCeros( numero: string, length: number ): string {

    let myString = '' + numero;
    while (myString.length < length) {
      myString = '0' + myString;
    }

    return myString;

  }

  alinearPesos( text: string ): number {
    switch (text.length) {
      case 9:
        return 150;
      case 8:
        return 152;
      case 7:
        return 155;
      case 6:
        return 158;
      case 5:
        return 161;
      case 4:
        return 164;
      case 3:
        return 167;
      case 2:
        return 170;
      case 1:
        return 173;
      default:
        return 158;
    }
  }

   /////////////////////////////////////////////////
  // IMAGEN LOCAL A BASE64                        //
  /////////////////////////////////////////////////

  convertBlobToBase64(blob: Blob) {
    return new Observable((observer) => {
      const reader = new FileReader();
      const binaryString = reader.readAsDataURL(blob);
      reader.onload = (event: any) => {
        observer.next(event.target.result);
        observer.complete();
      };

      reader.onerror = (event: any) => {
        observer.next(event.target.error.code);
        observer.complete();
      };
    });
  }

}

