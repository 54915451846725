<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>

<div class="row animated fadeIn fast" *ngIf="!cargando">
  <div class="col-sm-12">
      <div class="card">
          <div class="card-header bg-info">
              <h4 class="card-title text-white">Parte de Trabajo Mensual</h4>
          </div>

          <form class="form-horizontal" id="filtroForm" autocomplete="off" [formGroup]="filtroForm">

            <div class="card-body">

              <div class="row mt-4">

                <div class="col-sm-12">

                  <div class="alert alert-warning p-3">

                    <div class="row mb-3">

                      <div class="col-xl-2 col-lg-3 col-sm-12 text-nowrap">
                        <label for="mes">Mes</label>
                        <input type="month" class="form-control" required [ngClass]="{ 'is-invalid': campoFiltroNoValido('mes') }" formControlName="mes">
                        <div class="invalid-feedback">
                          El mes es obligatorio. Elige uno.
                        </div>
                      </div>

                      <div class="col-xl-4 col-lg-5 col-sm-12 mt-lg-0 mt-sm-3 text-nowrap">
                        <label for="trabajador">Trabajador</label>
                        <ng-select
                          [items]="trabajadores"
                          bindValue="id"
                          bindLabel="nombreCompleto"
                          placeholder="Elige un trabajador..."
                          notFoundText="No hay trabajadores disponibles"
                          loadingText="Cargando..."
                          [searchable]="true"
                          [clearable]="false"
                          labelForId="trabajador"
                          formControlName="trabajador"
                          required
                        >
                        </ng-select>
                        <div class="invalid-ngselect" *ngIf="formFiltrosSubmitted && filtroForm.controls['trabajador'].hasError('required')">
                          El trabajador es obligatorio. Elige uno.
                        </div>
                      </div>



                    </div>

                    <div class="row">

                      <div class="col-xl-2 col-lg-3 col-sm-12 text-nowrap">
                        <label for="tipoParte">Tipo de parte</label>
                        <ng-select
                          placeholder="Elige un tipo de parte..."
                          [searchable]="false"
                          [clearable]="false"
                          labelForId="tipoParte"
                          formControlName="tipoParte"
                          (change)="actualizarTipoParte($event)"
                          required
                        >
                          <ng-option value="oficina">Oficina</ng-option>
                          <ng-option value="obra">Obra</ng-option>
                        </ng-select>
                        <div class="invalid-ngselect" *ngIf="formFiltrosSubmitted && filtroForm.controls['tipoParte'].hasError('required')">
                          El tipo de parte es obligatorio. Elige uno.
                        </div>
                      </div>

                      <div class="col-xl-2 col-lg-3 col-sm-12 mt-lg-0 mt-sm-3 text-nowrap">
                        <label for="diaCorte">Fecha de corte</label>
                        <div class="form-group">
                          <ng-select placeholder="Elige una fecha de inicio..." [searchable]="false" [clearable]="false" labelForId="diaCorte" formControlName="diaCorte">
                            <ng-option value="25">25</ng-option>
                            <ng-option value="20">20</ng-option>
                            <ng-option value="1">1</ng-option>
                          </ng-select>
                          <div class="invalid-ngselect" *ngIf="formFiltrosSubmitted && filtroForm.controls['diaCorte'].hasError('required')">
                            La fecha de corte es obligatoria. Elige una.
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="row">

                      <div class="col-sm-12">
                        <button type="button" class="btn btn-primary" (click)="actualizarListaFechas()">Crear parte</button>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </form>

          <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="parteTrabajoMensualForm" (ngSubmit)="guardarParteTrabajoMensual()">

            <div class="card-body" *ngIf="cargandoLista && verLista">

              <div class="row animated fadeIn fast">

                <div class="col-sm-12">

                  <div class="alert alert-info text-center">
                    <strong>Cargando fechas...</strong>
                    <br>
                    <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
                    <br>
                    <span>Espere por favor</span>
                  </div>

                </div>

              </div>

            </div>

            <div class="card-body" formArrayName="fechas" *ngIf="!cargandoLista && verLista">

              <div class="row">
                <div class="col-sm-12 col-lg-1">
                  <label>Fecha</label>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <label>Obra</label>
                </div>
                <div class="col-sm-12 col-lg-1">
                  <label>Jornada</label>
                </div>
                <div class="col-sm-12 col-lg-5">
                  <label>Horario (Trasl. / Dieta)</label>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <label>Nota</label>
                </div>
              </div>

              <div class="row pt-3" *ngFor="let fecha of fechas.controls; let i = index" [ngClass]="{ 'bg-light-info': fecha.value.isFestivo, 'bg-light-success': fecha.value.isRecuperado }">

                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-1" [formGroupName]="i">
                  <div class="form-group">
                    <input type="date" class="form-control" formControlName="fecha" readonly>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-2" [formGroupName]="i">
                  <div class="form-group">
                    <ng-select
                      class="custom"
                      [items]="obras"
                      bindValue="id"
                      bindLabel="nombreCompleto"
                      placeholder="Elige una obra..."
                      notFoundText="No hay obras disponibles"
                      [searchable]="true"
                      [clearable]="false"
                      labelForId="obra"
                      formControlName="obra"
                    >
                    </ng-select>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-1" [formGroupName]="i">
                  <div class="form-group">
                    <ng-select placeholder="Elige una jornada..." [searchable]="false" [clearable]="false" labelForId="jornada" (change)="actualizarJornada($event, i)" formControlName="jornada" required>
                      <ng-option value="diurna">Diurna</ng-option>
                      <ng-option value="nocturna">Nocturna</ng-option>
                      <ng-option value="temperil">Temperil</ng-option>
                      <ng-option value="regulacion">Regulación</ng-option>
                      <ng-option value="vacaciones">Vacaciones</ng-option>
                      <ng-option value="baja">Baja</ng-option>
                      <ng-option value="permiso">Permiso</ng-option>
                    </ng-select>
                    <div class="invalid-ngselect" *ngIf="formSubmitted && fecha.dirty && fecha.get('jornada').hasError('required')">
                      Elige una jornada
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-5" [formGroupName]="i">
                  <div class="input-group">
                    <input type="time" step="900" class="form-control" [ngClass]="{ 'is-invalid': horarioValido( i, 'horaInicio' ) }" formControlName="horaInicio" (blur)="actualizarHorario( 'horaInicio', i )">
                    <input type="time" step="900" class="form-control" [ngClass]="{ 'is-invalid': horarioValido( i, 'descansoInicio' ) }" formControlName="descansoInicio" (blur)="actualizarHorario( 'descansoInicio', i )">
                    <input type="time" step="900" class="form-control" [ngClass]="{ 'is-invalid': horarioValido( i, 'descansoFin' ) }" formControlName="descansoFin" (blur)="actualizarHorario( 'descansoFin', i )">
                    <input type="time" step="900" class="form-control mr-2" [ngClass]="{ 'is-invalid': horarioValido( i, 'horaFin' ) }" formControlName="horaFin" (blur)="actualizarHorario( 'horaFin', i )">
                    <ng-template #tipTraslado>
                      <div class="d-flex flex-column">
                        <span>Traslado</span>
                      </div>
                    </ng-template>
                    <span [ngbTooltip]="tipTraslado" tooltipClass="tooltip-custom" container="body">
                      <ng-toggle class="mr-2" onColor="primary" onText="Si" offColor="secondary" offText="No" size="" formControlName="traslado"></ng-toggle>
                    </span>
                    <ng-template #tipDieta>
                      <div class="d-flex flex-column">
                        <span>Media Dieta</span>
                      </div>
                    </ng-template>
                    <span [ngbTooltip]="tipDieta" tooltipClass="tooltip-custom" container="body">
                      <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" size="" formControlName="mediaDieta"></ng-toggle>
                    </span>
                    <div class="invalid-feedback" *ngIf="formSubmitted && ( horarioValido( i, 'horaInicio' ) || horarioValido( i, 'descansoInicio' ) || horarioValido( i, 'descansoFin' ) || horarioValido( i, 'horaFin' ) )">
                      Horario incorrecto.
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-2" [formGroupName]="i">
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="nota">
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-1" [formGroupName]="i">
                  <div class="btn-group" role="group">
                    <button type="button" class="btn btn-info" (click)="horarioPredeterminado( i )"><i class="mdi mdi-clock-start"></i></button>
                    <button type="button" class="btn btn-secondary" (click)="copiarHorario( i )"><i class="mdi mdi-content-copy"></i></button>
                    <button type="button" class="btn btn-danger" (click)="limpiarHorario( i )"><i class="mdi mdi-eraser"></i></button>
                  </div>
                </div>

              </div>

              <hr>
              <div class="card-body">
                <div class="row">

                  <div class="col text-danger">
                    <p *ngIf="formSubmitted && formErrores">
                      Hay errores en el formulario. Corrígelos para poder guardar la información.
                    </p>
                  </div>

                  <div class="form-group mb-0 text-right">
                      <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                      <button type="button" class="btn btn-secondary waves-effect waves-light mr-1" (click)="guardarParteTrabajoMensual( true )">Imprimir</button>
                      <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarParteTrabajoMensual()">Cancelar</button>
                  </div>

                </div>
              </div>

            </div>

          </form>
      </div>
  </div>
</div>

<pre>{{ parteTrabajoMensualForm.value | json }}</pre>
