import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { map, first } from 'rxjs/operators';

import { DocumentData, Firestore, Query, collection, collectionData, doc, docData, limit, orderBy, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Auth, UserCredential, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from '@angular/fire/auth';

import { AddUserForm } from '../../interfaces/add-user-form.interface';

import { Usuario } from '../../models/usuario.model';

import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuario: Usuario;
  token: string;

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( public http: HttpClient,
               public router: Router,
               private firestore: Firestore,
               public afAuth: Auth ) {

    this.cargarStorage();

  }

  validarToken() { // Observable<boolean>

  //   const token = localStorage.getItem('token') || '';

  //   return this.firestore.collection('usuarios').get();

  }

  get role(): string {
    return this.usuario.role;
  }

  get uid(): string {
    return this.usuario.uid || '';
  }

  // TODO: traer el menu del backend
  getMenu( role: string ) {
    const opcionesMenu: any[] = [
      {
        titulo: 'Inicio',
        icono: 'mdi mdi-home',
        url: '/inicio',
        submenu: []
      }
    ];
    const admin = {
      titulo: 'Panel de Admin',
      icono: 'mdi mdi-lock',
      url: '',
      submenu: [
        { titulo: 'Usuarios', icono: 'mdi mdi-account', url: '/admin/usuarios' },
        { titulo: 'Estadisticas', icono: 'mdi mdi-chart-bar', url: '/admin/estadisticas' },
      ]
    }
    const oficina = {
      titulo: 'Administracion',
      icono: 'mdi mdi-laptop',
      url: '',
      submenu: [
        { titulo: 'Clientes', icono: 'fas fa-address-book', url: '/admin/clientes' },
        { titulo: 'Obras', icono: 'fas fa-road', url: '/admin/obras' },
        { titulo: 'Categorías Documentos', icono: 'fas fa-file-alt', url: '/admin/categorias-documentos' },
      ]
    };
    const personal = {
      titulo: 'RRHH',
      icono: 'mdi mdi-account-multiple',
      url: '',
      submenu: [
        // { titulo: 'Convenios', icono: 'mdi mdi-book-open', url: '/rrhh/convenios' }, // TODO: Añadir
        { titulo: 'Calendarios', icono: 'mdi mdi-calendar', url: '/rrhh/calendario' },
        { titulo: 'Trabajadores', icono: 'mdi mdi-account', url: '/rrhh/trabajadores' },
        { titulo: 'Equipos', icono: 'mdi mdi-account-multiple', url: '/rrhh/equipos' },
        { titulo: 'Partes trabajo', icono: 'mdi mdi-clipboard-text', url: '/rrhh/partes-trabajo' },
        { titulo: 'Saldos', icono: 'mdi mdi-numeric', url: '/rrhh/saldos-personal' },
        // { titulo: 'Vacaciones', icono: 'mdi mdi-beach', url: '/rrhh/vacaciones' }, // TODO: Añadir
      ]
    };
    const maestros = {
      titulo: 'Maestros',
      icono: 'mdi mdi-database',
      url: '',
      submenu: [
        { titulo: 'Camiones', icono: 'mdi mdi-truck', url: '/maestros/camiones' },
        { titulo: 'Proveedores', icono: 'fas fa-address-book', url: '/maestros/proveedores' },
        { titulo: 'Materias primas', icono: 'fas fa-cart-arr', url: '/maestros/materias-primas' },
      ]
    };
    const planta = {
      titulo: 'Planta',
      icono: 'mdi mdi-factory',
      url: '',
      submenu: [
        { titulo: 'Obras', icono: 'fas fa-road', url: '/planta/obras' },
        { titulo: 'Entradas', icono: 'mdi mdi-flip-horizontal mdi-truck-delivery', url: '/planta/entradas' },
        { titulo: 'Salidas', icono: 'mdi mdi-truck-delivery', url: '/planta/salidas' },
        { titulo: 'Informes', icono: 'mdi mdi-file-document', url: '/planta/informes' },
        // TODO: Añadir nuevas secciones
        // { titulo: 'Parte diario', icono: 'mdi mdi-clipboard-text', url: '/planta/parte-diario' },
      ]
    };
    const laboratorio = {
      titulo: 'Laboratorio',
      icono: 'mdi mdi-beaker',
      url: '',
      submenu: [
        { titulo: 'Formulas', icono: 'mdi mdi-flask', url: '/laboratorio/formulas' },
        { titulo: 'Sellos Marcado CE', icono: 'mdi mdi-wallet-membership', url: '/laboratorio/sellosce' },
        // TODO: Añadir nuevas secciones
        // { titulo: 'Calidad', icono: 'mdi mdi-shield', url: '/laboratorio/calidad' },
        // { titulo: 'Ensayos', icono: 'mdi mdi-file-chart', url: '/laboratorio/ensayos' },
      ]
    };
    const taller = {
      titulo: 'Maquinaria',
      icono: 'mdi mdi-wrench',
      url: '',
      submenu: [
        { titulo: 'Maquinaria', icono: 'mdi mdi-truck', url: '/maquinaria/listado' },
        { titulo: 'Averías', icono: 'mdi mdi-alert', url: '/maquinaria/averias' },
        { titulo: 'Mantenimientos', icono: 'mdi mdi-oil', url: '/maquinaria/mantenimientos' },
        { titulo: 'Góndola', icono: 'mdi mdi-truck', url: '/maquinaria/portes-gondola' },
        { titulo: 'Informes', icono: 'mdi mdi-file-document', url: '/maquinaria/informes' },
      ]
    };

    // TODO: Revisar la manera en que se crea el menu
    if ( role === 'admin' ) {
      opcionesMenu.push(admin);
      opcionesMenu.push(oficina); // Administracion
      opcionesMenu.push(personal);
      opcionesMenu.push(maestros);
      opcionesMenu.push(planta);
      opcionesMenu.push(laboratorio);
      opcionesMenu.push(taller);
    } else if ( role === 'oficina' ) {
      opcionesMenu.push(oficina); // Administracion
      opcionesMenu.push(personal);
      opcionesMenu.push(maestros);
      opcionesMenu.push(planta);
      opcionesMenu.push(laboratorio);
      opcionesMenu.push(taller);
    // TODO: Quitar acceso a la planta
    } else if ( role === 'laboratorio' || role === 'planta' ) {
      opcionesMenu.push(maestros);
      opcionesMenu.push(planta);
      opcionesMenu.push(laboratorio);
    } else if ( role === 'seguridad' || role === 'taller' ) {
      opcionesMenu.push(taller);
    }
    // else if ( role === 'planta' ) {
      //opcionesMenu.push(maestros);
      //opcionesMenu.push(planta);
    return opcionesMenu;
  }

  estaLogueado() {
    return ( this.token.length > 5 ) ? true : false;
  }

  cargarStorage() {

    if ( sessionStorage.getItem('token') ) {
      this.token = sessionStorage.getItem('token');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
    } else {
      this.token = '';
      this.usuario = null;
      this.logoutFB();
    }

  }

  guardarStorage( id: string, token: string, usuario: Usuario ) {

    localStorage.setItem('id', id);
    sessionStorage.setItem('token', token);
    localStorage.setItem('usuario', JSON.stringify(usuario) );

    this.usuario = usuario;
    this.token = token;

  }

  // Login Firebase Auth
  loginFB( usuario: Usuario, recordar: boolean = false ) {

    if (recordar) {
      localStorage.setItem( 'email-orsaapp', usuario.email );
    } else {
      localStorage.removeItem( 'email-orsaapp' );
    }

    signInWithEmailAndPassword( this.afAuth, usuario.email, usuario.password)
      .then( (userCredential: UserCredential) => {
        userCredential.user.getIdToken().then( token => {
          const userCollection = collection(this.firestore, 'usuarios');
          const consulta = query( userCollection, where('uid', '==', userCredential.user.uid), limit(1) );
          collectionData( consulta, { idField: 'uid' })
            .pipe(
              first()
            )
            .subscribe( (usuarioBD: any) => {
              // TODO: Una vez autenticado verificar autorizacion de acceso
              let nombreUser = usuario.email;
              let idUser: string;
              let roleUser: string;
              let obraUser: string = '';
              let menuUser: any;
              if ( usuarioBD.length > 0 ) {
                nombreUser = usuarioBD[0].nombre;
                idUser = usuarioBD[0].uid;
                roleUser = usuarioBD[0].role || '';
                if ( usuarioBD[0].obra ) {
                  obraUser = usuarioBD[0].obra.id || '';
                }
                menuUser = this.getMenu( roleUser );
              }
              const usuarioData = new Usuario(
                nombreUser,
                usuario.email,
                '',
                '',
                roleUser,
                obraUser,
                usuarioBD[0]?.operario,
                usuarioBD[0]?.parte,
                usuarioBD[0]?.responsables,
                usuarioBD[0]?.firmar,
                idUser,
                usuarioBD[0]?.estado,
              );
              this.guardarStorage( userCredential.user.uid, token, usuarioData );
              localStorage.setItem( 'menu', JSON.stringify(menuUser) );
              this.router.navigate(['/inicio']);
            });
        });
      })
      .catch( err => {

        let errText: string;
        switch (err.code) {

          case 'auth/wrong-password':
            errText = 'Usuario o contraseña incorrectas';
            break;
          case 'auth/user-not-found':
            errText = 'Usuario no encontrado';
            break;
          case 'auth/user-disabled':
            errText = 'Usuario no encontrado';
            break;
          case 'auth/invalid-email':
            errText = 'Usuario no encontrado';
            break;
          default:
            errText = 'Ocurrio un error';

        }
        Swal.fire('Error', errText, 'error');
      });

  }

  // Logout Firebase Auth
  logoutFB() {

    this.usuario = null;
    this.token = '';

    localStorage.removeItem('id');
    localStorage.removeItem('usuario');
    localStorage.removeItem('menu');
    sessionStorage.removeItem('token');

    this.afAuth.signOut()
      .then( res => {

        this.router.navigate(['/login']);

      })
      .catch( err => {

      });
  }

  crearUsuario( formData: AddUserForm ) {

    // TODO: Comprobar si existe y en caso positivo poner en activo

    return createUserWithEmailAndPassword( this.afAuth, formData.email, formData.password )
      .then( userCredential => {

        const user = userCredential.user;

        // Añadir nombre al perfil de usuario
        updateProfile( userCredential.user, {
          displayName: formData.nombre
        });

        // Crear el usuario en firestore
        if (formData.obra === '') { formData.obra = null; }
        formData.obra = ( formData.obra ) ? doc( this.firestore, 'obras', formData.obra ) : null;
        formData.operario = ( formData.operario ) ? doc( this.firestore, 'personal', formData.operario ) : null;
        formData.nombre = formData.nombre?.toLocaleUpperCase();
        formData.responsables = ( formData.responsables )
          ? formData.responsables.map( responsable => {
              return doc( this.firestore, 'usuarios', responsable );
            })
          : [];
        const usuarioRef = doc( this.firestore, 'usuarios', user.uid );
        // TODO: Cambiar modo de crear el usuario
        setDoc( usuarioRef, {
          uid: user.uid,
          nombre: formData.nombre,
          email: formData.email,
          role: formData.role,
          obra: formData.obra,
          operario: formData.operario,
          parte: formData?.parte || 'no',
          responsables: formData.responsables,
          firmar: formData?.firmar,
          estado: true
        });

      });

  }

  cargarUsuarios( activos: boolean = true, desde: number = 0 ) {

    const usuariosCol = collection( this.firestore, 'usuarios' );
    let consulta: Query<DocumentData>;
    if ( activos ) {
      consulta = query( usuariosCol, where( 'estado', '==', true ), orderBy( 'email', 'asc' ) );
    } else {
      consulta = query( usuariosCol, orderBy( 'email', 'asc' ) );
    }

    return collectionData( consulta, { idField: 'uid' } )
      .pipe(
        map( res => {
          const usuarios = res.map(
            (user: any) => new Usuario( user.nombre, user.email, '', '', user.role, user.obra, user.operario, user.parte, user.responsables, user.firmar, user.uid, user.estado )
          );
          return usuarios;
        })
      );

  }

  obtenerUsuarioPorId( userID: string ) {

    const usuarioRef = doc( this.firestore, 'usuarios', userID );
    return docData( usuarioRef, { idField: 'uid' } );

  }

  actualizarUsuario( userID: string, formData: AddUserForm ) {

    if (formData.obra === '') { formData.obra = null; }
    formData.obra = ( formData.obra ) ? doc( this.firestore, 'obras', formData.obra ) : null;
    formData.operario = ( formData.operario ) ? doc( this.firestore, 'personal', formData.operario ) : null;
    formData.nombre = formData.nombre?.toLocaleUpperCase();
    formData.responsables = ( formData.responsables )
      ? formData.responsables.map( responsable => {
          return doc( this.firestore, 'usuarios', responsable );
        })
      : [];
    const usuarioRef = doc( this.firestore, 'usuarios', userID );
    // TODO: Cambiar modo de actualizar el usuario
    return updateDoc( usuarioRef, {
        nombre: formData.nombre,
        role: formData.role,
        obra: formData.obra,
        operario: formData.operario,
        parte: formData.parte || 'no',
        responsables: formData.responsables,
        firmar: formData?.firmar,
        estado: formData?.estado,
      })
      .catch( error => { console.error( 'Error al actualizar el usuario: ', error ); });

  }

  borrarUsuario( userID: string ) {

    // TODO: Implementar solución igual que en camiones.service.ts
    const usuarioRef = doc( this.firestore, 'usuarios', userID );
    return updateDoc( usuarioRef, {
      estado: false,
      'meta.eliminado': {
        eliminadoel: new Date(),
        eliminadopor: {
          nombre: this.usuario.nombre,
          uid: this.usuario.uid
        }
      }
    });

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
