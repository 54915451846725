import { Injectable } from '@angular/core';
import { Firestore, query, collection, doc, where, orderBy, collectionData } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InformesTallerService {

  constructor( private firestore: Firestore ) { }

  buscarRegistrosMaquinaria( formData: any ) {

    let desdeDate: Date;
    let hastaDate: Date;

    if (formData.rango === 'dh') {
      desdeDate = new Date( formData.desde + ' 00:00' );
      hastaDate = new Date( formData.hasta + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    } else if (formData.rango === 'mes') {
      const anioMes = formData.mes.split('-');
      const diasMes = new Date( anioMes[0], anioMes[1], 0 ).getDate();
      desdeDate = new Date( formData.mes + '-01' + ' 00:00' );
      hastaDate = new Date( formData.mes + '-' + diasMes + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    }

    if (formData.tipo === 'averias') {

      const maquinaRef = doc( this.firestore, 'maquinaria', formData.maquina.id );
      let consulta = query( collection( this.firestore, 'taller_averias'), where('maquina', '==', maquinaRef) );
      if (formData.estado !== 'todos') {
        consulta = query( consulta, where('estado', '==', formData.estado) );
      }
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fechaAviso', '>=', desdeDate), where('fechaAviso', '<', hastaDate) );
      }
      consulta = query( consulta, orderBy('fechaAviso', 'desc') );
      return collectionData( consulta, { idField: 'id' } );

    } else if (formData.tipo === 'mantenimientos') {

      const maquinaRef = doc( this.firestore, 'maquinaria', formData.maquina.id );
      let consulta = query( collection( this.firestore, 'taller_mantenimientos'), where('maquina', '==', maquinaRef) );
      if (formData.estado !== 'todos') {
        consulta = query( consulta, where('estado', '==', formData.estado) );
      }
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fecha', '>=', desdeDate), where('fecha', '<', hastaDate) );
      }
      if (formData.estado !== 'todos') {
        if (formData.estado === 'aviso') {
          consulta = query( consulta, orderBy('fechaAviso', 'desc') );
        } else {
          consulta = query( consulta, orderBy('fecha', 'desc') );
        }
      } else {
        consulta = query( consulta, orderBy('fecha', 'desc'), orderBy('fechaAviso', 'desc') );
      }

      return collectionData( consulta, { idField: 'id' } );

    } else if (formData.tipo === 'horometros') {

      const maquinaHorometrosCol = collection( this.firestore, 'maquinaria', formData.maquina.id, 'registros_horometro' );
      let consulta = query( maquinaHorometrosCol );
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fecha', '>=', desdeDate), where('fecha', '<', hastaDate) );
      }
      consulta = query( consulta, orderBy('fecha', 'desc'), orderBy('horometro', 'desc') );

      return collectionData( consulta, { idField: 'id' } );

    }

  }

  buscarRegistrosGondola( formData: any ) {

    let desdeDate: Date;
    let hastaDate: Date;

    if (formData.rango === 'dh') {
      desdeDate = new Date( formData.desde + ' 00:00' );
      hastaDate = new Date( formData.hasta + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    } else if (formData.rango === 'mes') {
      const anioMes = formData.mes.split('-');
      const diasMes = new Date( anioMes[0], anioMes[1], 0 ).getDate();
      desdeDate = new Date( formData.mes + '-01' + ' 00:00' );
      hastaDate = new Date( formData.mes + '-' + diasMes + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    }

    // const maquinaRef = this.firestore.collection('maquinaria').doc( formData.maquina.id ).ref;
    const maquinariaMovimientosCol = collection( this.firestore, 'maquinaria_movimientos' );
    let consulta = query( maquinariaMovimientosCol, where('gondola', '==', formData.gondola) );
    if (formData.rango !== 'todo') {
      consulta = query( consulta, where('fechaCarga', '>=', desdeDate), where('fechaCarga', '<', hastaDate) );
    }
    consulta = query( consulta, orderBy('fechaCarga', 'desc') );

    return collectionData( consulta, { idField: 'id' } );

  }

}
