import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DocumentData, getDoc } from 'firebase/firestore';
import { SortType } from '@swimlane/ngx-datatable';
import { SubSink } from 'subsink';
import { debounceTime } from 'rxjs';

import Swal from 'sweetalert2';

import { FormulasService } from 'src/app/services/service.index';

@Component({
  selector: 'app-formulas',
  templateUrl: './formulas.component.html',
  styles: [
  ]
})
export class FormulasComponent implements OnInit, OnDestroy {

  formulas: any = [];
  formulasTodas: any = [];

  registrosPagina: number = 10;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = true;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private formulasService: FormulasService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.formulasService.getRegistrosPagina();
    this.sorts = this.formulasService.getSorts();
    this.offset = this.formulasService.getPage();
    this.cargarFormulas();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarFormulas() {

    this.subs.sink = this.formulasService.cargarFormulas()
      .pipe(
        debounceTime(300)
      )
      .subscribe( async (formulasDB: any) => {
        this.totalRegistros = 0;
        this.formulas = [];
        if ( formulasDB.length > 0 ) {
          await Promise.all( formulasDB.map( async ( formula: DocumentData ) => {
            if (formula?.selloce) {
              formula.selloce = ( formula.selloce ) ? (await getDoc( formula.selloce )).data() : null;
            }
          }));
          this.totalRegistros = formulasDB.length; // TODO: Obtener total usuarios de la BD
          this.formulas = formulasDB;
          this.formulasTodas = formulasDB;
          this.searchForm.controls.termino.enable();
        }
        this.cargando = false;

      });

  }

  buscarFormula( termino: string ) {

    if ( termino.length <= 0 ) {
      // this.cargarFormulas();
      this.totalRegistros = this.formulasTodas.length;
      this.formulas = this.formulasTodas;
      return;
    }

    this.cargando = true;

    if ( termino !== '' ) {
      this.formulas = this.formulasTodas.filter( ( formula: any ) =>
        formula?.codigo?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        formula?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) );

      this.totalRegistros = this.formulas.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.formulasTodas.length;
      this.formulas = this.formulasTodas;
    }
  }

  editarFormula( id: string ) {

    this.router.navigate( ['/laboratorio/formula/editar', id ] );

  }

  borrarFormula( id: string ) {

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Una vez eliminado no podrá deshacerse esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.formulasService.borrarFormula( id );
      }

    });

  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {
    this.registrosPagina = Number( event.target.value );
    this.formulasService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.formulasService.setPage( event.offset );
  }

  onSort(event) {
    this.formulasService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
