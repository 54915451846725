import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SortType } from '@swimlane/ngx-datatable';
import { PersonalSaldosService } from 'src/app/services/service.index';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-saldos',
  templateUrl: './saldos.component.html',
  styleUrls: ['./saldos.component.css']
})
export class SaldosComponent implements OnInit, OnDestroy {

  @ViewChild('table') table: any;

  trabajadores: any = [];
  trabajadoresTodos: any = [];

  registrosPagina: number = 100;
  sorts: any = [];
  offset: number = 0;
  totalRegistros: number = 0;
  sortType = SortType;

  cargando: boolean = false;

  private subs = new SubSink();

  public searchForm = this.fb.group({
    termino: [ { value: '', disabled: true } ],
  });

  constructor( private personalSaldosService: PersonalSaldosService,
               private fb: FormBuilder,
               private router: Router ) { }

  ngOnInit(): void {
    this.registrosPagina = this.personalSaldosService.getRegistrosPagina();
    this.sorts = this.personalSaldosService.getSorts();
    this.offset = this.personalSaldosService.getPage();
    this.cargarTrabajadores();
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }

  cargarTrabajadores(): void {

    this.cargando = true;

    this.subs.sink = this.personalSaldosService.cargarOperariosConSaldos()
      .subscribe( (trabajadoresDB: any) => {
        trabajadoresDB?.map( (trabajador: any) => {
          trabajador['saldo_horas'] = { ...trabajador['saldo_horas'], saldo: !isNaN(trabajador['saldo_horas']?.['saldo']) ? Number.parseFloat( trabajador['saldo_horas']['saldo'] ).toFixed(2) : 'N/D' };
          trabajador['saldo_vacaciones'] = { ...trabajador['saldo_vacaciones'], saldo: !isNaN(trabajador['saldo_vacaciones']?.['saldo']) ? Number.parseFloat( trabajador['saldo_vacaciones']['saldo'] ).toFixed(2) : 'N/D' };
        });
        this.trabajadoresTodos = trabajadoresDB;
        this.trabajadores = this.trabajadoresTodos;
        this.totalRegistros = this.trabajadoresTodos.length;
        this.searchForm.controls.termino.enable();
        this.cargando = false;
      });

  }

  buscarTrabajador( termino: string ) {

    if (termino.length <= 0) {
      this.totalRegistros = this.trabajadoresTodos.length;
      this.trabajadores = this.trabajadoresTodos;
      return;
    }

    this.cargando = true;

    if (termino !== '' ) {
      this.trabajadores = this.trabajadoresTodos.filter( ( trabajador: any ) =>
        trabajador?.nombre?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.apellido1?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.apellido2?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.dni?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.telefono?.toLowerCase().includes( termino.toLocaleLowerCase() ) ||
        trabajador?.email?.toLowerCase().includes( termino.toLocaleLowerCase() )  );

      this.totalRegistros = this.trabajadores.length;
      this.cargando = false;
    }

  }

  verificarBusqueda( termino: string ) {
    if ( termino === '' ) {
      this.totalRegistros = this.trabajadoresTodos.length;
      this.trabajadores = this.trabajadoresTodos;
    }
  }

  editarSaldosTrabajador( id: string ) {
    this.router.navigate([ '/rrhh/saldos-personal', id, 'editar' ]);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    // console.log('Detail Toggled', event);
  }

  // Estado de ngx-datatable

  actualizarRegistros(event) {

    this.registrosPagina = Number( event.target.value );
    this.personalSaldosService.setRegistrosPagina( Number( event.target.value ) );
  }

  onPage(event) {
    this.personalSaldosService.setPage( event.offset );
  }

  onSort(event) {
    this.personalSaldosService.setSorts( event.sorts );
  }

  getBtnClass({ row, column, value }): any {
    return 'botones-tabla';
  }

}
