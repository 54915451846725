import { Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { delay, debounceTime, distinctUntilChanged, map, filter, first } from 'rxjs/operators';

import { NgbTypeaheadSelectItemEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CamionModalComponent } from 'src/app/shared/modals/modals.index';
import { UsuarioService, EntradasService, FormulariosService, ObrasService, CamionesService } from '../../../../services/service.index';

import Swal from 'sweetalert2';
import { getDoc } from '@angular/fire/firestore';

type Obra = {id: string, codigo: string, nombre: string};
type Proveedor = {id: string, nombre: string};
type Articulo = {id: string, nombre: string};
type Matricula = {id: string, matricula: string};


@Component({
  selector: 'app-entrada',
  templateUrl: './entrada.component.html',
  styles: [
  ]
})
export class EntradaPlantaComponent implements OnInit, OnDestroy {

  entradaID: string;
  titulo: string = 'Nueva entrada';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  editarCamion: boolean = false;
  bascula: boolean = false;
  verNeto: boolean = false;
  unidad: string;
  codigoUnidad: string;
  obras: any = [];
  obra: any = [];
  proveedores: any = [];
  articulos: any = [];
  matriculas: any = [];

  private subs = new SubSink();

  public formSubmitted = false;

  hoy: Date = new Date( Date.now() );
  ahora: Date = new Date( Date.now() );

  public entradaForm = this.fb.group({

      obra: new FormControl( { value: null, disabled: true }, Validators.required ),

      fecha: [ formatDate( this.hoy, 'yyyy-MM-dd', 'en' ), Validators.required ],
      hora: [ formatDate( this.ahora, 'HH:mm', 'en' ), Validators.required ],
      numinterno: new FormControl( {value: null, disabled: true},  Validators.required ),

      proveedor: [ null, Validators.required ],
      articulo: new FormControl( {value: null, disabled: true},  Validators.required ),

      matricula: [null],
      chofer: new FormControl( {value: null, disabled: true} ),
      transportista: new FormControl( {value: null, disabled: true} ),

      bruto: [null],
      tara: [null],
      neto: [null],

      retorno: [false],

      fechaalbaran: [null, Validators.required],
      horaalbaran: [null],
      numalbaran: [null, Validators.required],

      brutoalbaran: [null],
      taraalbaran: [null],
      netoalbaran: [null, Validators.required],

      facturado: [false],

      comentarios: [null],

  });

  obraformat = (obra: Obra) => obra.codigo + ' - ' + obra.nombre;

  obrasearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.obras.filter( ( obra: any ) => obra?.codigo?.toLowerCase().includes( term.toLowerCase() ) ||
                                              obra?.nombre?.toLowerCase().includes( term.toLowerCase() ) ).slice(0, 10) )
    )

  provformat = (proveedor: Proveedor) => proveedor.nombre;

  provsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map( term => this.proveedores.filter( ( proveedor: any ) => new RegExp(term, 'mi').test( proveedor.nombre )).slice(0, 10) )
  )

  artformat = (articulo: Articulo) => articulo.nombre;

  artsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.articulos.filter( (articulo: any ) => new RegExp(term, 'mi').test(articulo.nombre)).slice(0, 10) )
  )

  matformat = (matricula: Matricula) => matricula.matricula;

  matsearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term => this.matriculas.filter( matricula => new RegExp(term, 'mi').test(matricula.matricula)).slice(0, 10) )
  )

  constructor( private entradasService: EntradasService,
               private usuarioService: UsuarioService,
               private formulariosService: FormulariosService,
               private obraService: ObrasService,
               private camionesService: CamionesService,
               private modalService: NgbModal,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute ) { }

  ngOnInit(): void {

    this.subs.sink = this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
        this.entradaID = params.id;
        this.cargarEntrada( params.id );
        } else {
          this.cargando = false;
          if ( this.entradasService.fechaListadoDesde !== formatDate( new Date( Date.now() ), 'yyyy-MM-dd', 'en' ) ) {
            this.entradaForm.get('fecha').setValue( this.entradasService.fechaListadoDesde );
            this.entradaForm.get('hora').setValue( null );
          }
        }

      });

    if (this.usuarioService.usuario.obra === '') {
      this.entradaForm.get('obra').enable();
      this.entradaForm.get('obra').setValue(null);
    } else {
      this.subs.sink = this.obraService.obtenerObraPorId( this.usuarioService.usuario.obra )
        .subscribe( (obra: any) => {
          this.obra = {
            id: obra.id,
            codigo: obra.codigo,
            nombre: obra.nombre
          };
          this.entradaForm.get('obra').disable();
          this.entradaForm.get('obra').setValue( this.obra );
          this.subs.sink = this.formulariosService.ultimoAlbaranEntrada( obra.id )
            .subscribe( res => {
              let proximoNumero = 1;
              if ( res.length > 0 ) {
                proximoNumero = res[0].numinterno + 1;
              }
              this.entradaForm.get('numinterno').setValue( proximoNumero );
              this.entradaForm.get('numinterno').enable();
            });
        });
    }

    this.subs.sink = this.formulariosService.selectData('obras', true)
      .subscribe( res => {
        this.obras = res;
      });

    this.subs.sink = this.formulariosService.selectData('proveedores')
      .subscribe( res => {
        this.proveedores = res;
      });

    this.subs.sink = this.formulariosService.selectMatriculas()
      .subscribe( res => {
        this.matriculas = res;
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarEntrada( id: string ) {

    this.entradaID = id;

    this.subs.sink = this.entradasService.obtenerEntradaPorId( id )
    .pipe(
      first()
    )
    .subscribe( async (entrada: any) => {

      if ( !entrada ) {
        Swal.fire('Error', 'Error al cargar los datos de la entrada', 'error');
        return this.router.navigateByUrl('/planta/entradas');
      }

      const fechaBD = formatDate( entrada.fecha.toDate(), 'yyyy-MM-dd', 'en' );
      const horaBD = formatDate( entrada.fecha.toDate(), 'HH:mm', 'en' );
      let fechaalbaranBD = entrada.fechaalbaran;
      if ( fechaalbaranBD ) {
        fechaalbaranBD = formatDate( entrada.fechaalbaran.toDate(), 'yyyy-MM-dd', 'en' );
      }
      let horaalbaranBD = entrada.horaalbaran;
      if ( horaalbaranBD ) {
        horaalbaranBD = formatDate( entrada.fechaalbaran.toDate(), 'HH:mm', 'en' );
      }
      entrada.matricula = ( entrada.matricula ) ? (await getDoc( entrada.matricula )).data() : null;
      if (entrada.matricula) {
        this.entradaForm.get('chofer').enable();
        this.entradaForm.get('transportista').enable();
      }
      entrada.obra = ( entrada.obra ) ? (await getDoc( entrada.obra )).data() : null;
      entrada.proveedor = ( entrada.proveedor ) ? (await getDoc( entrada.proveedor )).data() : null;
      if (entrada.proveedor) {
        this.cargarArticulos( entrada.proveedor.id );
      }
      entrada.unidad = ( entrada.unidad ) ? (await getDoc( entrada.unidad )).data() : null;
      if (entrada.unidad) {
        this.cargarUnidades( entrada.unidad.codigo );
      }
      entrada.articulo = ( entrada.articulo ) ? (await getDoc( entrada.articulo )).data() : null;
      this.entradaForm.get('numinterno').enable();
      this.entradaForm.get('articulo').enable();
      this.entradaForm.reset({
        obra: {
          id: entrada.obra.id,
          codigo: entrada.obra.codigo,
          nombre: entrada.obra.nombre
        },
        fecha: fechaBD,
        hora: horaBD,
        numinterno: entrada.numinterno,
        proveedor: {
          id: entrada.proveedor.id,
          nombre: entrada.proveedor.nombre
        },
        articulo: {
          id: entrada.articulo.id,
          nombre: entrada.articulo.nombre,
          ud: {
            id: entrada?.unidad?.id,
            nombre: entrada?.unidad?.nombre,
            codigo: entrada?.unidad?.codigo
          }
        },
        matricula: {
          id: entrada.matricula.id,
          matricula: entrada.matricula.nombre,
          chofer: entrada.matricula.chofer,
          transportista: entrada.matricula.transportista
        },
        chofer: entrada.chofer,
        transportista: entrada.transportista,
        fechaalbaran: fechaalbaranBD,
        horaalbaran: horaalbaranBD,
        numalbaran: entrada.numalbaran,
        bruto: entrada.bruto,
        brutoalbaran: entrada.brutoalbaran,
        tara: entrada.tara,
        taraalbaran: entrada.taraalbaran,
        neto: entrada.neto,
        netoalbaran: entrada.netoalbaran,
        retorno: entrada.retorno,
        facturado: entrada.facturado,
        comentarios: entrada.comentarios
      });
      this.cargando = false;
    });

  }

  crearEntrada() {

    this.formSubmitted = true;

    if ( this.entradaForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    const comprobarCampos = this.comprobarCampos();

    if ( comprobarCampos['comprobar'] ) {

      let html = '';
      comprobarCampos['campos'].forEach( campo => {
        html += `<li>${ campo }</li>`;
      });

      Swal.fire({
        title: '¿Desea continuar?',
        html: `
          <p style="text-align: start">Los siguientes campos están vacíos:</p>
          <ul style="text-align: start">
            ${ html }
          </ul>
        `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {

        if (result.isConfirmed) {
          this.entradasService.crearEntrada( this.entradaForm.getRawValue() )
            .then( () => this.router.navigateByUrl('/planta/entradas') )
            .catch( err => {
              Swal.fire('Error', 'Error: ' + err, 'error');
              this.cargando = false;
              // TODO: Crear un log de errores
            });
        } else {
          this.cargando = false;
        }

      });
    } else {
      this.entradasService.crearEntrada( this.entradaForm.getRawValue() )
        .then( () => this.router.navigateByUrl('/planta/entradas') )
        .catch( err => {
          Swal.fire('Error', 'Error: ' + err, 'error');
          this.cargando = false;
          // TODO: Crear un log de errores
        });
    }

  }

  actualizarEntrada() {

    this.formSubmitted = true;

    if ( this.entradaForm.invalid ) {
      return;
    }

    if ( !this.entradaForm.pristine ) {
      this.txtCargando = 'Guardando...';
      this.cargandoClass = 'alert-secondary';
      this.cargando = true;

      const comprobarCampos = this.comprobarCampos();

      if ( comprobarCampos['comprobar'] ) {
        let html = '';
        comprobarCampos['campos'].forEach( campo => {
          html += `<li>${ campo }</li>`;
        });

        Swal.fire({
          title: '¿Desea continuar?',
          html: `
            <p style="text-align: start">Los siguientes campos están vacíos:</p>
            <ul style="text-align: start">
              ${ html }
            </ul>
          `,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then((result) => {

          if (result.isConfirmed) {
            this.entradasService.actualizarEntrada( this.entradaID, this.entradaForm.getRawValue() )
              .then( () => this.router.navigateByUrl('/planta/entradas') )
              .catch( err => {
                Swal.fire('Error', 'Error: ' + err, 'error');
                this.cargando = false;
                // TODO: Crear un log de errores
              });
          } else {
            this.cargando = false;
          }

        });
      } else {
        this.entradasService.actualizarEntrada( this.entradaID, this.entradaForm.getRawValue() )
          .then( () => this.router.navigateByUrl('/planta/entradas') )
          .catch( err => {
            Swal.fire('Error', 'Error: ' + err, 'error');
            this.cargando = false;
            // TODO: Crear un log de errores
          });
      }
    } else {
      this.cancelarEntrada();
    }

  }

  cancelarEntrada() {

    if ( this.entradaForm.pristine ) {
      this.router.navigate(['/planta/entradas']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {
        this.router.navigate(['/planta/entradas']);
      }

    });

  }

  comprobarCampos() {

    const matricula = this.entradaForm.get('matricula').value;
    const bruto = this.entradaForm.get('bruto').value;
    const tara = this.entradaForm.get('tara').value;
    const neto = this.entradaForm.get('neto').value;
    const brutoalbaran = this.entradaForm.get('brutoalbaran').value;
    const taraalbaran = this.entradaForm.get('taraalbaran').value;

    const campos = [];
    let comprobar = false;
    if ( !matricula ) {
      campos.push('Matrícula');
      comprobar = true;
    }

    if ( !bruto ) {
      campos.push('Bruto');
      comprobar = true;
    }

    if ( !tara ) {
      campos.push('Tara');
      comprobar = true;
    }

    if ( !neto ) {
      campos.push('Neto');
      comprobar = true;
    }

    if ( !brutoalbaran ) {
      campos.push('Bruto albarán');
      comprobar = true;
    }

    if ( !taraalbaran ) {
      campos.push('Tara albarán');
      comprobar = true;
    }

    return { comprobar, campos };

  }

  campoNoValido( campo: string ): boolean {

    if ( this.entradaForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  cargarArticulos( id: string ) {

    this.entradaForm.get('articulo').setValue( null );
    this.entradaForm.get('articulo').enable();
    this.subs.sink = this.formulariosService.selectArticulosProv( id )
    .subscribe( async (articulos) => {
      if ( articulos.length > 0 ) {
        await Promise.all(
          articulos.map( async (articulo: any) => {
            articulo.ud = ( articulo.ud ) ? (await getDoc( articulo.ud )).data() : [];
          })
        );
        this.articulos = articulos;
      }
    });

  }

  proveedorSeleccionado( event: NgbTypeaheadSelectItemEvent ) {

    this.cargarArticulos( event.item.id );

  }

  verificarProv() {
    if ( !this.entradaForm.get('proveedor').value ) {
      this.articulos = [];
      this.entradaForm.get('articulo').setValue( null );
      this.entradaForm.get('articulo').disable();
    }
  }

  // Mostrar modal para crear o editar camion
  camionModal(): void {

    const modalRef = this.modalService.open(CamionModalComponent, { size: 'xl' });

    modalRef.componentInstance.camionId = this.entradaForm.get('matricula').value?.id;

    modalRef.result.then( camionData => {
      if ( camionData ) {
        const camionCreado = {
          id: camionData.id,
          matricula: camionData.matricula,
        };
        this.entradaForm.controls.matricula.setValue(camionCreado);
        this.cargarCamion(camionData);
      }
    })
    .catch( err => {
      if (err) {
        console.error('error ', err);
      }
    });

  }

  // Cargar informacion del camion en el formulario
  cargarCamion( data: any ) {

    let camionData: any;

    if ( data.item ) {
      camionData = data.item;
    } else {
      camionData = data;
    }
    this.entradaForm.get('chofer').setValue( camionData?.chofer || null );
    this.entradaForm.get('transportista').setValue( camionData?.transportista || null );
    this.entradaForm.get('tara').setValue( camionData?.tara ?? null );
    this.entradaForm.get('chofer').enable();
    this.entradaForm.get('transportista').enable();
    this.calcularPesos('t');
    this.editarCamion = true;
  }

  verificarMat() {
    if ( !this.entradaForm.get('matricula').value ) {
      this.entradaForm.get('chofer').setValue( null );
      this.entradaForm.get('transportista').setValue( null );
      this.entradaForm.get('tara').setValue( null );
      this.entradaForm.get('chofer').disable();
      this.entradaForm.get('transportista').disable();
      this.calcularPesos('t');
      this.editarCamion = false;
    }
  }

  cargarNumInterno( event: NgbTypeaheadSelectItemEvent ) {
    this.subs.sink = this.formulariosService.ultimoAlbaranEntrada( event.item.id )
      .subscribe( res => {
        let proximoNumero = 1;
        if ( res.length > 0 ) {
          proximoNumero = res[0].numinterno + 1;
        }
        this.entradaForm.get('numinterno').setValue( proximoNumero );
        this.entradaForm.get('numinterno').enable();
      });
  }

  verificarObra() {
    if ( !this.entradaForm.get('obra').value ) {
      this.entradaForm.get('numinterno').setValue( null );
      this.entradaForm.get('numinterno').disable();
    }
  }

  cargarUnidades( codigo: string ) {

    this.verNeto = true;
    switch ( codigo ) {
      case 'tn':
        this.bascula = true;
        this.unidad = 'Neto';
        this.codigoUnidad = 'TN';
        break;
      case 'kg':
        this.bascula = true;
        this.unidad = 'Neto';
        this.codigoUnidad = 'Kg';
        break;
      case 'l':
        this.unidad = 'Litros';
        this.codigoUnidad = 'L';
        break;
      case 'ud':
        this.unidad = 'Unidades';
        this.codigoUnidad = 'Uds.';
        break;
    }

  }

  articuloSeleccionado( event: NgbTypeaheadSelectItemEvent ) {

    this.cargarUnidades( event.item.ud.codigo );

  }

  verificarArt() {

    if ( !this.entradaForm.get('articulo').value ) {
      this.verNeto = false;
      this.bascula = false;
      this.unidad = '';
      this.entradaForm.get('bruto').setValue(null);
      this.entradaForm.get('tara').setValue(null);
      this.entradaForm.get('neto').setValue(null);
      this.entradaForm.get('brutoalbaran').setValue(null);
      this.entradaForm.get('netoalbaran').setValue(null);
      this.entradaForm.get('taraalbaran').setValue(null);
    }
  }

  // b = bruto, t = tara, n = neto
  // ba = bruto albaran, ta = tara albaran, na = neto albaran
  calcularPesos( campo: string ) {

    if (this.bascula) {

      let bruto = 0;
      let tara = 0;
      let neto = 0;
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.entradaForm.get('bruto').value ) {
        bruto = this.entradaForm.get('bruto').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.entradaForm.get('brutoalbaran').value ) {
        bruto = this.entradaForm.get('brutoalbaran').value;
      }
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.entradaForm.get('tara').value ) {
        tara = this.entradaForm.get('tara').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.entradaForm.get('brutoalbaran').value ) {
        tara = this.entradaForm.get('taraalbaran').value;
      }
      if ( ( campo === 'b' || campo === 't' || campo === 'n' ) && this.entradaForm.get('neto').value ) {
        neto = this.entradaForm.get('neto').value;
      } else if ( ( campo === 'ba' || campo === 'ta' || campo === 'na' ) && this.entradaForm.get('netoalbaran').value ) {
        neto = this.entradaForm.get('netoalbaran').value;
      }
      if ( campo === 'b' || campo === 'ba' ) {
        neto = Math.round( ((bruto - tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 'b' ) {
          this.entradaForm.get('neto').setValue( neto );
        } else {
          this.entradaForm.get('netoalbaran').setValue( neto );
        }
      }
      if ( campo === 't' || campo === 'ta' ) {
        neto = Math.round( ((bruto - tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 't' ) {
          this.entradaForm.get('neto').setValue( neto );
        } else {
          this.entradaForm.get('netoalbaran').setValue( neto );
        }
      }
      if ( campo === 'n' || campo === 'na' ) {
        bruto = Math.round( ((neto + tara) + Number.EPSILON) * 100 ) / 100;
        if ( campo === 'n' ) {
          this.entradaForm.get('bruto').setValue( bruto );
        } else {
          this.entradaForm.get('brutoalbaran').setValue( bruto );
        }
      }

    }

  }

  copiarFecha(): void {
    this.entradaForm.controls.fechaalbaran.setValue(this.entradaForm.controls.fecha.value);
  }

}
