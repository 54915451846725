import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FormulariosService, PartesJspdfService, PartesTrabajoService, UsuarioService } from 'src/app/services/service.index';
import { twoControlRequiredValidator } from 'src/app/validators/validators.index';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parte-trabajo-mensual',
  templateUrl: './parte-trabajo-mensual.component.html',
  styleUrls: ['./parte-trabajo-mensual.component.css']
})
export class ParteTrabajoMensualComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Crear Parte de Trabajo Mensual';
  cargando: boolean = true;
  verLista: boolean = false;
  cargandoLista: boolean = false;
  modoLectura: boolean = false;
  hoy: Date = new Date();
  trabajadores: any[] = [];
  obras: any[] = [];

  private subs = new SubSink();

  public formSubmitted = false;
  public formFiltrosSubmitted = false;
  formErrores: boolean = false;
  tieneDatos: boolean = false;

  public filtroForm = this.fb.group({
    mes: [ formatDate( this.hoy, 'yyyy-MM', 'es' ), Validators.required ],
    trabajador: [ null, Validators.required ],
    tipoParte: [ 'oficina', Validators.required ],
    diaCorte: [ 20, Validators.required ],
  });

  public parteTrabajoMensualForm = this.fb.group({
    fechas: this.fb.array([])
  });

  constructor( private partesTrabajoService: PartesTrabajoService,
               private formulariosService: FormulariosService,
               private usuarioService: UsuarioService,
               private partesJspdfService: PartesJspdfService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title, ) { }

  ngOnInit(): void {

    this.cargarTrabajadores();

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get fechas(): FormArray {
    return this.parteTrabajoMensualForm.get('fechas') as FormArray;
  }

  cargarTrabajadores() {
    this.subs.sink =  this.formulariosService.selectOperarios()
      .subscribe( (trabajadores: any[]) => {
        this.trabajadores = trabajadores;
        this.cargando = false;
      });
  }

  async cargarPartesTrabajoMensual( formData: any ) {

    this.fechas.clear();
    this.formSubmitted = false;
    this.formErrores = false;
    const obras = await firstValueFrom( this.formulariosService.selectObras('enCurso') );
    this.obras = obras;

    let fechaInicio = formData.fechaInicio;
    const fechaFin = formData.fechaFin;
    while ( fechaInicio < fechaFin ) {

      const fecha = new Date( fechaInicio );
      const operario = formData.trabajador;
      const weekDay = fecha.getDay();
      const esDiaLaborable = weekDay > 0 && weekDay < 6;

      // TODO: Comprobar si ya hay registros guardados para esa fecha
      const partesTrabajo = await this.partesTrabajoService.cargarParteTrabajoPorFecha( fecha, operario );
      if ( partesTrabajo.size > 0 ) {

        this.tieneDatos = true;
        for ( const parteTrabajo of partesTrabajo.docs ) {
          const parteTrabajoData = parteTrabajo.data();
          const obra = parteTrabajoData?.obra;
          const jornada = parteTrabajoData?.jornada;
          const tipoJornada = parteTrabajoData?.tipoJornada;
          const horaInicio = parteTrabajoData?.horaInicio ? formatDate( parteTrabajoData.horaInicio.toDate(), 'HH:mm', 'es' ) : null;
          const descansoInicio = parteTrabajoData?.horaInicioDescanso ? formatDate( parteTrabajoData.horaInicioDescanso.toDate(), 'HH:mm', 'es' ) : null;
          const descansoFin = parteTrabajoData?.horaFinDescanso ? formatDate( parteTrabajoData.horaFinDescanso.toDate(), 'HH:mm', 'es' ) : null;
          const horaFin = parteTrabajoData?.horaFin ? formatDate( parteTrabajoData.horaFin.toDate(), 'HH:mm', 'es' ) : null;
          const horasTrabajadas = parteTrabajoData?.horasTrabajadas;
          const horasTeoricas = parteTrabajoData?.horasTeoricas;
          const saldoHoras = parteTrabajoData?.saldoHoras;
          const traslado = parteTrabajoData?.traslado;
          const isFestivo = parteTrabajoData?.isFestivo ? parteTrabajoData.isFestivo : !esDiaLaborable;
          const isRecuperado = parteTrabajoData?.isRecuperado ? parteTrabajoData.isRecuperado : false;
          const mediaDieta = parteTrabajoData?.mediaDieta === 1 ? true : false;
          const nota = parteTrabajoData?.nota ? parteTrabajoData.nota : '';

          this.fechas.push( this.fb.group({
            id: [ parteTrabajo.id ],
            fecha: [ formatDate( fecha, 'yyyy-MM-dd', 'es' ) ],
            obra: [ obra.id ],
            operario: [ operario ],
            jornada: [ jornada, Validators.required ],
            tipoJornada: [ tipoJornada ],
            horaInicio: [ horaInicio ],
            descansoInicio: [ descansoInicio ],
            descansoFin: [ descansoFin ],
            horaFin: [ horaFin ],
            horasTrabajadas: [ horasTrabajadas ],
            horasTeoricas: [ horasTeoricas ],
            saldoHoras: [ saldoHoras ],
            traslado: [ traslado ],
            isFestivo: [ isFestivo ],
            isRecuperado: [ isRecuperado ],
            mediaDieta: [ mediaDieta ],
            nota: [ nota ],
          }, {
            validators: [
              twoControlRequiredValidator( 'descansoInicio', 'descansoFin' ),
              twoControlRequiredValidator( 'horaInicio', 'horaFin' ),
            ]
          }
        ) );

        }

      } else {
        this.fechas.push( this.fb.group({
          id: [ null ],
          fecha: [ formatDate( fecha, 'yyyy-MM-dd', 'es' ) ],
          obra: [ null ],
          operario: [ operario ],
          jornada: [ null, Validators.required ],
          tipoJornada: [ 'otro' ],
          horaInicio: [ null ],
          descansoInicio: [ null ],
          descansoFin: [ null ],
          horaFin: [ null ],
          horasTrabajadas: [ 0 ],
          horasTeoricas: [ 0 ],
          saldoHoras: [ 0 ],
          traslado: [ 0 ],
          isFestivo: [ !esDiaLaborable ],
          isRecuperado: [ false ],
          mediaDieta: [ esDiaLaborable ],
          nota: [ '' ],
        }, {
          validators: [
            twoControlRequiredValidator( 'descansoInicio', 'descansoFin' ),
            twoControlRequiredValidator( 'horaInicio', 'horaFin' ),
          ]
        } ) );
      }
      fechaInicio.setDate( fechaInicio.getDate() + 1 );
    }
    this.cargandoLista = false;

  }

  rellenarParteMensual() {

    this.fechas.controls.forEach( fecha => {

      // TODO

    });

  }

  guardarParteTrabajoMensual( imprimir: boolean = false ) {

    this.formSubmitted = true;
    this.formErrores = false;
    const usuario = this.usuarioService.usuario;
    const fechas = this.fechas.controls;
    const operario = this.filtroForm.controls.trabajador.value;
    const operarioData = this.trabajadores.find( t => t.id === operario );
    const nombreOperario = operarioData.apellido1 + ' ' + operarioData.apellido2 + ', ' + operarioData.nombre;

    fechas.forEach( fecha => {

      if ( fecha.dirty && fecha.valid ) {

        this.tieneDatos = true;
        const formData = fecha.value;
        const obraData = this.obras.find( o => o.id === formData.obra ) || null;
        const nombreObra = obraData?.nombreCompleto || '';
        const fechaDT = new Date( formData.fecha );
        // 0 = Domingo, 1 = Lunes, 2 = Martes, 3 = Miercoles, 4 = Jueves, 5 = Viernes, 6 = Sabado
        const weekDay = fechaDT.getDay();
        const jornada = formData.jornada;
        let tipoJornada = formData.tipoJornada;
        let horasTeoricas = formData.horasTeoricas;
        const horaInicio = formData.horaInicio ? fechaDT.setHours( formData.horaInicio.split(':')[0], formData.horaInicio.split(':')[1], 0, 0 ) : null;
        const descansoInicio = formData.descansoInicio ? fechaDT.setHours( formData.descansoInicio.split(':')[0], formData.descansoInicio.split(':')[1], 0, 0 ) : null;
        const descansoFin = formData.descansoFin ? fechaDT.setHours( formData.descansoFin.split(':')[0], formData.descansoFin.split(':')[1], 0, 0 ) : null;
        let horaFin = formData.horaFin ? fechaDT.setHours( formData.horaFin.split(':')[0], formData.horaFin.split(':')[1], 0, 0 ) : null;
        const isBefore = (horaInicio && horaFin) ? horaFin < horaInicio : false;
        if ( ( jornada === 'nocturna' && isBefore ) || ( jornada === 'diurna' && isBefore ) ) {
          const diaSiguiente = new Date( fechaDT );
          diaSiguiente.setDate( diaSiguiente.getDate() + 1 );
          horaFin = diaSiguiente.setHours( formData.horaFin.split(':')[0], formData.horaFin.split(':')[1], 0, 0 );
        }
        let horasTrabajadas = descansoInicio ? (horaFin - horaInicio - (descansoFin - descansoInicio)) / 3600000 : (horaFin - horaInicio) / 3600000;
        horasTrabajadas = Math.round( (horasTrabajadas + Number.EPSILON) * 100 ) / 100;
        let saldoHoras = horasTrabajadas - horasTeoricas;
        if ( jornada === 'temperil' && horasTrabajadas < 8 ) {
          tipoJornada = tipoJornada === 'completa' ? 'temperil' : tipoJornada;
          const convenio = operarioData.convenio;
          const porcentaje = convenio === 'gipuzkoa' ? 0.8 : (2/3); // TODO: Traer de la base de datos
          horasTeoricas = ((8 - horasTrabajadas) * porcentaje) + horasTrabajadas;
          horasTeoricas = Math.round( (horasTeoricas + Number.EPSILON) * 100 ) / 100;
          saldoHoras = horasTrabajadas - horasTeoricas;
        } else if ( jornada == 'diurna' && tipoJornada !== 'completa' ) {
          horasTeoricas = horasTrabajadas < 8 ? horasTrabajadas : 8;
          horasTeoricas = Math.round( (horasTeoricas + Number.EPSILON) * 100 ) / 100;
          saldoHoras = horasTrabajadas - horasTeoricas;
        }
        if( horasTrabajadas > 8 && ( jornada === 'parcial' || jornada === 'mediaManana' || jornada === 'mediaTarde' ) ) {
          tipoJornada = 'completa';
        }
        const mediaDieta = horasTrabajadas > 4 ? 1 : 0;
        const isFestivo = ( (weekDay === 5 || weekDay === 6 ) && jornada === 'nocturna' ) ? true : ( (weekDay === 6 || weekDay === 0) && jornada === 'diurna' ) ? true : false;
        const data = {
          autor: usuario.uid,
          estado: 'pendiente',
          fecha: fechaDT,
          id: formData.id,
          individual: true,
          cantidadOperarios: 1,
          horasTotales: horasTrabajadas,
          nombreObra: nombreObra,
          obra: formData.obra,
          observaciones: '',
          responsables: operarioData.responsables,
          horarioOperarios: [
            {
              fecha: fechaDT,
              horaFin: horaFin,
              horaFinDescanso: descansoFin,
              horaInicio: horaInicio,
              horaInicioDescanso: descansoInicio,
              horasTeoricas: horasTeoricas,
              horasTrabajadas: horasTrabajadas,
              jornada: jornada,
              meta: this.partesTrabajoService.metaNuevaParteTrabajo,
              nombreObra: nombreObra,
              nombreOperario: nombreOperario,
              nota: formData.nota,
              obra: formData.obra,
              operario: operario,
              saldoHoras: saldoHoras,
              tipoJornada: tipoJornada,
              traslado: formData.traslado ? 1 : 0,
              mediaDieta: mediaDieta,
              isFestivo: isFestivo,
              isRecuperado: false, // TODO
            }
          ]
        }

        console.log( this.parteTrabajoMensualForm );

        if ( formData.id ) {
          // TODO: Actualizar parte de trabajo
          console.log( 'actualizar' );
        } else {
          // TODO: Crear parte de trabajo
          console.log( 'crear' );
        }

      } else {
        if ( fecha.dirty ) {
          console.log( 'error' );
          this.formErrores = true;
        }
      }

    });

    if ( this.formErrores ) {
      return;
    }

    if ( this.tieneDatos && imprimir ) {
      // TODO: Imprimir parte de trabajo
      console.log( 'imprimir' );
      this.partesJspdfService.crearParteMensual( this.parteTrabajoMensualForm.value, this.fechas.controls );
    } else if ( !this.tieneDatos && imprimir ) {
      // TODO Mostrar opcion de imprimir sin datos
      Swal.fire({
        title: 'No hay datos para imprimir',
        text: '¿Desea imprimir la plantilla en blanco?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      })
      .then((result) => {
        if (result.value) {
          this.partesJspdfService.crearParteMensual( this.parteTrabajoMensualForm.value, this.fechas.controls );
        }
      });
    } else {
      this.router.navigate(['/rrhh/partes-trabajo']);
    }

  }

  actualizarJornada( event: any, index: number ): void {

    const tipoParte = this.filtroForm.controls.tipoParte.value;
    const fecha = new Date( this.fechas.controls[index].get('fecha').value );
    const esViernes = fecha.getDay() === 5;

    switch ( event ) {
      case 'diurna':
        this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 8, 0, 0, 0 ), 'HH:mm', 'es' ) );
        this.fechas.controls[index].get('descansoInicio').setValue( esViernes ? null : formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) );
        if ( tipoParte === 'obra' ) {
          this.fechas.controls[index].get('horasTrabajadas').setValue( 8 );
          this.fechas.controls[index].get('horasTeoricas').setValue( 8 );
          this.fechas.controls[index].get('saldoHoras').setValue( 0 );
          this.fechas.controls[index].get('descansoFin').setValue( formatDate( fecha.setHours( 14, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( formatDate( fecha.setHours( 17, 0, 0, 0 ), 'HH:mm', 'es' ) );
        } else if ( tipoParte === 'oficina' ) {
          this.fechas.controls[index].get('horasTrabajadas').setValue( esViernes ? 5 : 9 );
          this.fechas.controls[index].get('horasTeoricas').setValue( esViernes ? 5 : 8 );
          this.fechas.controls[index].get('saldoHoras').setValue( esViernes ? 0 : 1 );
          this.fechas.controls[index].get('descansoFin').setValue( esViernes ? null : formatDate( fecha.setHours( 14, 30, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( esViernes ? formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) : formatDate( fecha.setHours( 18, 30, 0, 0 ), 'HH:mm', 'es' ) );
        }
        break;
      case 'nocturna':
        this.fechas.controls[index].get('horasTrabajadas').setValue( 8 );
        this.fechas.controls[index].get('horasTeoricas').setValue( 8 );
        this.fechas.controls[index].get('saldoHoras').setValue( 0 );
        this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 21, 0, 0, 0 ), 'HH:mm', 'es' ) );
        this.fechas.controls[index].get('descansoInicio').setValue( null );
        this.fechas.controls[index].get('descansoFin').setValue( null );
        const diaSiguiente = new Date( fecha );
        diaSiguiente.setDate( diaSiguiente.getDate() + 1 );
        this.fechas.controls[index].get('horaFin').setValue( formatDate( diaSiguiente.setHours( 5, 0, 0, 0 ), 'HH:mm', 'es' ) );
        break;
      case 'temperil':
      case 'regulacion':
      case 'vacaciones':
      case 'baja':
      case 'permiso':
        this.fechas.controls[index].get('horasTrabajadas').setValue( 0 );
        this.fechas.controls[index].get('horasTeoricas').setValue( 0 );
        this.fechas.controls[index].get('saldoHoras').setValue( 0 );
        this.fechas.controls[index].get('horaInicio').setValue( null );
        this.fechas.controls[index].get('descansoInicio').setValue( null );
        this.fechas.controls[index].get('descansoFin').setValue( null );
        this.fechas.controls[index].get('horaFin').setValue( null );
        this.fechas.controls[index].get('tipoJornada').setValue( 'otro' );
        break;
    }
    this.fechas.controls[index].markAsDirty();

  }

  cancelarParteTrabajoMensual() {

    if ( this.parteTrabajoMensualForm.pristine ) {
      this.router.navigate(['/rrhh/partes-trabajo']);
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/rrhh/partes-trabajo']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.parteTrabajoMensualForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  campoFiltroNoValido( campo: string ): boolean {

    if ( this.filtroForm.get(campo).invalid && this.formFiltrosSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  horarioValido( index: number, campo: string ): boolean {
    return this.formSubmitted && this.fechas.controls[index].get(campo).hasError('twoControlRequired');
  }

  actualizarTipoParte( event: any ) {

    if ( event === 'obra' ) {
      this.filtroForm.controls.diaCorte.setValue( 25 );
    } else if ( event === 'oficina' ) {
      this.filtroForm.controls.diaCorte.setValue( 20 );
    }

  }

  actualizarListaFechas() {

    this.formFiltrosSubmitted = true;

    if ( this.filtroForm.invalid ) {
      return;
    }

    this.verLista = true;
    this.cargandoLista = true;

    const trabajador = this.filtroForm.controls.trabajador.value;
    const tipoParte = this.filtroForm.controls.tipoParte.value;
    const diaCorte = this.filtroForm.controls.diaCorte.value !== '1' ? parseInt( this.filtroForm.controls.diaCorte.value ) + 1 : parseInt( this.filtroForm.controls.diaCorte.value );
    const mesAnio = (this.filtroForm.controls.mes.value).split('-');
    const anio = parseInt( mesAnio[0] );
    const mes = parseInt( mesAnio[1] ) - 1;
    const fechaInicio = new Date( anio, diaCorte !== 1 ? mes - 1 : mes, diaCorte, 0, 0, 0, 0 );
    const fechaFin = new Date( anio, diaCorte !== 1 ? mes : mes + 1, diaCorte, 0, 0, 0, 0 );

    this.cargarPartesTrabajoMensual({ trabajador, tipoParte, fechaInicio, fechaFin });

  }

  actualizarHorario( campo: string, index: number ) {
    const hora = this.fechas.controls[index].get(campo).value;
    const horaNearestQuarter = this.nearestQuarterHour( hora );
    if ( horaNearestQuarter ) {
      this.fechas.controls[index].get(campo).setValue( formatDate( horaNearestQuarter, 'HH:mm', 'es' ) );
    }
  }

  horarioPredeterminado( index: number ) {

    const tipoParte = this.filtroForm.controls.tipoParte.value;
    const fecha = new Date( this.fechas.controls[index].get('fecha').value );
    const isViernes = fecha.getDay() === 5;
    let tipoJornada = this.fechas.controls[index].get('tipoJornada').value;
    this.fechas.controls[index].markAsDirty();
    this.fechas.controls[index].get('jornada').setValue( 'diurna' );
    this.fechas.controls[index].get('mediaDieta').setValue( true );
    switch ( tipoJornada ) {
      case 'completa':
        tipoJornada = 'mediaManana';
        this.fechas.controls[index].get('tipoJornada').setValue( tipoJornada );
        this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 8, 0, 0, 0 ), 'HH:mm', 'es' ) );
        this.fechas.controls[index].get('descansoInicio').setValue( null );
        this.fechas.controls[index].get('descansoFin').setValue( null );
        this.fechas.controls[index].get('horaFin').setValue( formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) );
        this.fechas.controls[index].get('horasTrabajadas').setValue( 5 );
        this.fechas.controls[index].get('horasTeoricas').setValue( 5 );
        this.fechas.controls[index].get('saldoHoras').setValue( 0 );
        break;
      case 'mediaManana':
        tipoJornada = 'mediaTarde';
        this.fechas.controls[index].get('tipoJornada').setValue( tipoJornada );
        this.fechas.controls[index].get('descansoInicio').setValue( null );
        this.fechas.controls[index].get('descansoFin').setValue( null );
        if ( tipoParte === 'obra' ) {
          this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 14, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( formatDate( fecha.setHours( 17, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horasTrabajadas').setValue( 3 );
          this.fechas.controls[index].get('horasTeoricas').setValue( 3 );
          this.fechas.controls[index].get('saldoHoras').setValue( 0 );
        } else if ( tipoParte === 'oficina' ) {
          this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 14, 30, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( formatDate( fecha.setHours( 18, 30, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horasTrabajadas').setValue( 4 );
          this.fechas.controls[index].get('horasTeoricas').setValue( 3 );
          this.fechas.controls[index].get('saldoHoras').setValue( 1 );
        }
        break;
      case 'mediaTarde':
      case 'otro':
      case 'parcial':
        tipoJornada = 'completa';
        this.fechas.controls[index].get('tipoJornada').setValue( tipoJornada );
        this.fechas.controls[index].get('horaInicio').setValue( formatDate( fecha.setHours( 8, 0, 0, 0 ), 'HH:mm', 'es' ) );
        if ( tipoParte === 'obra' ) {
          this.fechas.controls[index].get('descansoInicio').setValue( formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('descansoFin').setValue( formatDate( fecha.setHours( 14, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( formatDate( fecha.setHours( 17, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horasTrabajadas').setValue( 8 );
          this.fechas.controls[index].get('horasTeoricas').setValue( 8 );
          this.fechas.controls[index].get('saldoHoras').setValue( 0 );
        } else if ( tipoParte === 'oficina' ) {
          this.fechas.controls[index].get('descansoInicio').setValue( isViernes ? null : formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('descansoFin').setValue( isViernes ? null : formatDate( fecha.setHours( 14, 30, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horaFin').setValue( isViernes ? formatDate( fecha.setHours( 13, 0, 0, 0 ), 'HH:mm', 'es' ) : formatDate( fecha.setHours( 18, 30, 0, 0 ), 'HH:mm', 'es' ) );
          this.fechas.controls[index].get('horasTrabajadas').setValue( 9 );
          this.fechas.controls[index].get('horasTeoricas').setValue( 8 );
          this.fechas.controls[index].get('saldoHoras').setValue( 1 );
        }
        break;
    }
    let obraPredeterminada = null;
    if ( tipoParte === 'obra' ) {
      obraPredeterminada = this.obras.find( obra => obra.codigo === 'CMP-HERNANI' );
      this.fechas.controls[index].get('obra').setValue( obraPredeterminada.id );
    } else if ( tipoParte === 'oficina' ) {
      obraPredeterminada = this.obras.find( obra => obra.codigo === 'OFI' );
      this.fechas.controls[index].get('obra').setValue( obraPredeterminada.id );
    }
  }

  copiarHorario( index: number ) {

    if ( index > 0 ) {
      this.fechas.controls[index].markAsDirty();
      this.fechas.controls[index].get('obra').setValue( this.fechas.controls[index - 1].get('obra').value );
      this.fechas.controls[index].get('jornada').setValue( this.fechas.controls[index - 1].get('jornada').value );
      this.fechas.controls[index].get('tipoJornada').setValue( this.fechas.controls[index - 1].get('tipoJornada').value );
      this.fechas.controls[index].get('horaInicio').setValue( this.fechas.controls[index - 1].get('horaInicio').value );
      this.fechas.controls[index].get('descansoInicio').setValue( this.fechas.controls[index - 1].get('descansoInicio').value );
      this.fechas.controls[index].get('descansoFin').setValue( this.fechas.controls[index - 1].get('descansoFin').value );
      this.fechas.controls[index].get('horaFin').setValue( this.fechas.controls[index - 1].get('horaFin').value );
      this.fechas.controls[index].get('horasTrabajadas').setValue( this.fechas.controls[index - 1].get('horasTrabajadas').value );
      this.fechas.controls[index].get('horasTeoricas').setValue( this.fechas.controls[index - 1].get('horasTeoricas').value );
      this.fechas.controls[index].get('saldoHoras').setValue( this.fechas.controls[index - 1].get('saldoHoras').value );
      this.fechas.controls[index].get('traslado').setValue( this.fechas.controls[index - 1].get('traslado').value );
      this.fechas.controls[index].get('mediaDieta').setValue( this.fechas.controls[index - 1].get('mediaDieta').value );
    }

  }

  limpiarHorario( index: number ) {

    this.fechas.controls[index].markAsDirty();
    this.fechas.controls[index].get('obra').setValue( null );
    this.fechas.controls[index].get('jornada').setValue( null );
    this.fechas.controls[index].get('tipoJornada').setValue( 'otro' );
    this.fechas.controls[index].get('horaInicio').setValue( null );
    this.fechas.controls[index].get('descansoInicio').setValue( null );
    this.fechas.controls[index].get('descansoFin').setValue( null );
    this.fechas.controls[index].get('horaFin').setValue( null );
    this.fechas.controls[index].get('horasTrabajadas').setValue( 0 );
    this.fechas.controls[index].get('horasTeoricas').setValue( 0 );
    this.fechas.controls[index].get('saldoHoras').setValue( 0 );
    this.fechas.controls[index].get('traslado').setValue( 0 );
    this.fechas.controls[index].get('mediaDieta').setValue( false );
    this.fechas.controls[index].get('nota').setValue( '' );

  }

  nearestQuarterHour( time: string ) {
    return Math.round( new Date( '1970-01-01 ' + time ).getTime() / 900000 ) * 900000;
  }

}
