<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">

                <li class="sidebar-item" *ngFor="let menu of sidebarService.menu" routerLinkActive="selected" [routerLinkActiveOptions]="{exact: true}">
                    <a class="sidebar-link waves-effect waves-dark sidebar-link" *ngIf="menu.submenu.length < 1" [routerLink]="[ menu.url ]" aria-expanded="false">
                        <i [class]="menu.icono"></i>
                        <span class="hide-menu">{{ menu.titulo }}</span>
                    </a>
                    <a class="sidebar-link has-arrow waves-effect waves-dark" *ngIf="menu.submenu.length >= 1" href="javascript:void(0)" aria-expanded="false">
                        <i [class]="menu.icono"></i>
                        <!-- Usar side-badge para notificaciones -->
                        <!-- <span class="hide-menu">{{ menu.titulo }} <span class="side-badge badge badge-info">{{ menu.submenu.length }}</span></span> -->
                        <span class="hide-menu">{{ menu.titulo }} </span>
                    </a>
                    <ul class="collapse  first-level" *ngIf="menu.submenu.length >= 1" aria-expanded="false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">

                        <li class="sidebar-item" *ngFor="let submenu of menu.submenu" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a [routerLink]="[ submenu.url ]" class="sidebar-link">
                                <i [class]="submenu.icono"></i>
                                <span class="hide-menu"> {{ submenu.titulo }}</span>
                            </a>
                        </li>

                    </ul>
                </li>

            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->