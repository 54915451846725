export class Usuario{

    constructor(
        //public uid: string,
        public nombre: string,
        public email: string,
        public password: string,
        public img?: string,
        public role?: string,
        public obra?: string,
        public operario?: string,
        public parte?: string,
        public responsables?: string[],
        public firmar?: boolean,
        public uid?: string,
        public estado?: boolean
    ) {}

}
