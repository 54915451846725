import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../services/shared/sidebar.service';

declare function app();
declare function app_init();
declare function app_init_dark();
declare function feather_init();
declare function sidebar_menu();
declare function init_plugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {

  constructor( private sidebarService: SidebarService ) { }

  ngOnInit(): void {
    app();
    app_init();
    feather_init();
    sidebar_menu();
    init_plugins();
    this.sidebarService.cargaMenu();
  }

}
