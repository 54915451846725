<app-breadcrumbs [modificarTitulo]="tituloBC"></app-breadcrumbs>

<div class="row animated fadeIn fast" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fas fa-spinner fa-spin fa-2x mb-1 mt-1"></i>
            <br>
            <span>Espere por favor</span>
        </div>

    </div>

</div>
<!-- Row -->
<div class="row animated fadeIn fast" *ngIf="!cargando">

  <div class="col-lg-12">

    <div class="card">

      <div class="card-header bg-info">
          <h4 class="card-title text-white">Maquina</h4>
      </div>

      <div class="row">

        <!-- Fotos -->
        <div class="col-lg-4 col-xlg-3 col-md-5">

          <div class="card">

            <div class="card-body">

              <div class="mt-4 text-center">

                <ngb-carousel [showNavigationArrows]="true">

                  <ng-template ngbSlide>
                    <div class="picsum-img-wrapper">
                      <img [src]="foto" class="img-fluid" [alt]="altFoto">
                    </div>
                  </ng-template>

                </ngb-carousel>

              </div>

              <!-- TODO: boton para cambiar imagenes -->
              <!-- <div class="row">
                <div class="col-12">
                  <button class="btn btn-primary mr-1">Anterior</button>
                  <button class="btn btn-primary ml-1">Siguiente</button>
                </div>
              </div> -->

            </div>

          </div>

        </div>

        <!-- Datos -->
        <div class="col-lg-8 col-xlg-9 col-md-7">

          <div class="card-body">

            <!-- Nav tabs -->
            <ul class="nav nav-pills justify-content-end mt-2" role="tablist">
              <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" href="#datos" role="tab" (click)="tabClic('datos')">
                      <span>Datos básicos</span>
                  </a>
              </li>
              <li class="nav-item" *ngIf="maquinaID">
                  <a class="nav-link" data-toggle="tab" href="#taller" role="tab" (click)="tabClic('taller')">
                      <span>Taller</span>
                  </a>
              </li>
              <li class="nav-item" *ngIf="maquinaID">
                  <a class="nav-link" data-toggle="tab" href="#documentos" role="tab" (click)="tabClic('documentos')">
                      <span>Documentos</span>
                  </a>
              </li>
            </ul>

            <div class="tab-content border mt-2">

              <div class="tab-pane active p-3" id="datos" role="tabpanel">

                <form class="form-horizontal" id="provform" autocomplete="off" [formGroup]="maquinaForm" (ngSubmit)="crearMaquina()">
                  <div class="card-body">
                      <h6 class="card-subtitle"> Los campos con * son obligatorios </h6>

                      <!-- Nombre y nº de serie -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-8">
                              <div class="form-group">
                                  <label>Nombre*</label>
                                  <input type="text" class="form-control" placeholder="Nombre" required [ngClass]="{ 'is-invalid': campoNoValido('nombre') }" formControlName="nombre">
                                  <div class="invalid-feedback">
                                      El nombre es obligatorio.
                                  </div>
                              </div>
                          </div>

                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Nº de serie</label>
                                  <input type="text" class="form-control" placeholder="Nº de serie" formControlName="numserie">
                                  <div class="invalid-feedback">
                                      El nº de serie es obligatorio.
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!-- Matrícula, código Navision y código contable -->
                      <div class="row">
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Matrícula</label>
                                  <input type="text" class="form-control" placeholder="Matrícula" formControlName="matricula">

                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Código Navision</label>
                                  <input type="text" class="form-control" placeholder="Código de Navision" formControlName="codnavision">
                              </div>
                          </div>
                          <div class="col-sm-12 col-lg-4">
                              <div class="form-group">
                                  <label>Código contable</label>
                                  <input type="text" class="form-control" placeholder="Código contable" formControlName="codcontable">
                              </div>
                          </div>
                      </div>

                      <!-- Familia y subfamilia -->
                      <div class="row">

                        <div class="col-sm-12 col-lg-4">
                          <div class="form-group">
                              <label for="familia">Familia*</label>
                              <ng-select
                                [items]="familias"
                                bindValue="id"
                                bindLabel="nombre"
                                labelForId="familia"
                                placeholder="Elige una familia..."
                                notFoundText="No hay familias disponibles"
                                clearAllText="Limpiar"
                                formControlName="familia"
                                [clearable]="true"
                                [loadingText]="'Cargando...'"
                                (change)="cambiarFamilia($event)"
                                (clear)="borrarFamilia($event)"
                                required
                              >
                              </ng-select>
                              <div class="invalid-ngselect" *ngIf="formSubmitted && maquinaForm.controls['familia'].hasError('required')">
                                La familia es obligatoria. Elige una.
                              </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-4">
                          <div class="form-group">
                              <label for="subfamilia">Subfamilia*</label>
                              <ng-select
                                [items]="subfamilias"
                                bindValue="nombre"
                                bindLabel="nombre"
                                labelForId="subfamilia"
                                placeholder="Elige una subfamilia..."
                                notFoundText="No hay subfamilias disponibles"
                                clearAllText="Limpiar"
                                formControlName="subfamilia"
                              >
                              </ng-select>
                              <div class="invalid-ngselect" *ngIf="formSubmitted && maquinaForm.controls['subfamilia'].hasError('required')">
                                La subfamilia es obligatoria. Elige una.
                              </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="isAdmin">
                          <div class="form-group">
                            <label>Tipo</label>
                            <input type="text" class="form-control" placeholder="Tipo" formControlName="tipo">
                          </div>
                        </div>

                      </div>

                      <!-- Notas -->
                      <div class="row">
                        <div class="col-sm-12 col-lg-4">
                          <div class="form-group">

                              <label>Notas</label>
                              <input type="text" class="form-control" placeholder="Texto corto..." formControlName="notas">

                          </div>
                        </div>
                      </div>

                      <!-- ITV -->
                      <div class="row">

                        <div class="col-sm-3 col-lg-2 overflow-hidden">
                          <label class="text-nowrap">ITV</label>
                          <div class="form-group">
                              <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="itv">
                              </ng-toggle>
                            </div>
                        </div>

                        <div class="col-sm-3 col-lg-4" *ngIf="maquinaForm.controls?.itv?.value">
                          <label>Fecha próxima ITV</label>
                          <input type="date" class="form-control" formControlName="itvFecha">
                        </div>

                      </div>

                      <!-- Notificaciones -->
                      <div class="row">

                        <div class="col-sm-12 col-lg-2 overflow-hidden">
                          <label class="text-nowrap">Avisos de mantenimiento</label>
                          <div class="form-group">
                            <ng-toggle onColor="primary" onText="Si" offColor="secondary" offText="No" [size]="" formControlName="notificacionMantenimiento">
                            </ng-toggle>
                          </div>
                        </div>

                        <div class="col-sm-12 col-lg-4" *ngIf="maquinaForm.controls?.notificacionMantenimiento?.value">
                          <label>Aviso de mantenimiento cada</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [value]="txtHorometro + ' ' + txtUdHorometro" [readonly]="modoLectura" *ngIf="modoLectura">
                              <input type="number" class="form-control" formControlName="mantenimientoCada" *ngIf="!modoLectura">
                              <select class="custom-select col-3" formControlName="mantenimientoUnidad" *ngIf="!modoLectura">
                                  <option value="h">h.</option>
                                  <option value="km">Km.</option>
                              </select>
                          </div>
                      </div>

                      </div>

                      <!-- Estado -->
                      <div class="row mt-4">

                          <div class="col-sm-12 col-lg-6">
                              <div class="form-group">
                                  <ng-toggle onColor="primary" onText="Activo" offColor="secondary" offText="Inactivo" [size]="" formControlName="estado">
                                  </ng-toggle>
                              </div>
                          </div>

                      </div>

                      <div class="row">
                          <div class="col text-danger">

                              <p *ngIf="campoNoValido('nombre')">
                                  Hay errores en el formulario. Corrígelos para poder guardar la información.
                              </p>

                          </div>
                      </div>

                  </div>
                  <hr>
                  <div class="card-body">
                      <div class="form-group mb-0 text-right">
                          <button *ngIf="!maquinaID" type="submit" class="btn btn-primary waves-effect waves-light mr-1">Guardar</button>
                          <button *ngIf="maquinaID" type="button" class="btn btn-primary waves-effect waves-light mr-1" (click)="actualizarMaquina()">Actualizar</button>
                          <button type="button" class="btn btn-dark-danger waves-effect waves-light" (click)="cancelarMaquina()">Cancelar</button>
                      </div>
                  </div>
                </form>

              </div>

              <div class="tab-pane p-3" id="taller" role="tabpanel" *ngIf="maquinaID"></div>

              <div class="tab-pane p-3" id="documentos" role="tabpanel" *ngIf="maquinaID"></div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>
