import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, doc, orderBy, query, where } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class InformesPlantaService {

  constructor( private firestore: Firestore ) { }

  buscarRegistros( formData: any ) {

    let desdeDate: Date;
    let hastaDate: Date;

    if (formData.rango === 'dh') {
      desdeDate = new Date( formData.desde + ' 00:00' );
      hastaDate = new Date( formData.hasta + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    } else if (formData.rango === 'mes') {
      const anioMes = formData.mes.split('-');
      const diasMes = new Date( anioMes[0], anioMes[1], 0 ).getDate();
      desdeDate = new Date( formData.mes + '-01' + ' 00:00' );
      hastaDate = new Date( formData.mes + '-' + diasMes + ' 00:00' );
      hastaDate.setDate( hastaDate.getDate() + 1 );
    }


    if (formData.tipo === 'entradas') {

      let consulta = query( collection( this.firestore, 'entradas'), where('estado', '==', true) );
      if (formData.planta !== 'todo' && formData.planta !== 'obra') {
        const plantaRef = doc( this.firestore, 'obras', formData.planta );
        consulta = query( consulta, where('obra', '==', plantaRef) );
      } else if (formData.planta === 'obra') {
        if ( formData.obra ) {
          const obraRef = doc( this.firestore, 'obras', formData.obra.id );
          consulta = query( consulta, where('obra', '==', obraRef) );
        }
      }

      if (formData.articulo) {
        const articuloRef = doc( this.firestore, 'materiasprimas', formData.articulo.id );
        consulta = query( consulta, where('articulo', '==', articuloRef) );
      } else {
        if (formData.proveedor) {
          const proveedorRef = doc( this.firestore, 'proveedores', formData.proveedor.id );
          consulta = query( consulta, where('proveedor', '==', proveedorRef) );
        }
      }
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fecha', '>=', desdeDate), where('fecha', '<', hastaDate) );
      }

      consulta = query( consulta, orderBy('fecha', 'desc') );

      return collectionData( consulta, { idField: 'id' } );

    } else if (formData.tipo === 'salidas') {

      let consulta = query( collection( this.firestore, 'salidas'), where('estado', '==', true) );
      if (formData.planta !== 'todo') {
        const plantaRef = doc( this.firestore, 'obras', formData.planta );
        consulta = query( consulta, where('planta', '==', plantaRef) );
      }
      if (formData.obra) {
        const obraRef = doc( this.firestore, 'obras', formData.obra.id );
        consulta = query( consulta, where('obra', '==', obraRef) );
      } else {
        if (formData.cliente) {
          const clienteRef = doc( this.firestore, 'clientes', formData.cliente.id );
          consulta = query( consulta, where('cliente', '==', clienteRef) );
        }
      }
      if (formData.formula) {
        const formulaRef = doc( this.firestore, 'formulas', formData.formula.id );
        consulta = query( consulta, where('formula', '==', formulaRef) );
      }
      if (formData.matricula) {
        const matriculaRef = doc( this.firestore, 'camiones', formData.matricula.id );
        consulta = query( consulta, where('matricula', '==', matriculaRef) );
      }
      if (formData.rango !== 'todo') {
        consulta = query( consulta, where('fecha', '>=', desdeDate), where('fecha', '<', hastaDate) );
      }
      consulta = query( consulta, orderBy('fecha', 'desc') );

      return collectionData( consulta, { idField: 'id' } );

    }

  }

}
