import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { addDoc, collection, collectionData, collectionGroup, deleteDoc, doc, docData, DocumentData, Firestore, getDocs, orderBy, Query, query, updateDoc, where } from '@angular/fire/firestore';
import { UsuarioService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class PartesTrabajoService {

  usuariosCol = collection( this.firestore, 'usuarios' );
  personalCol = collection( this.firestore, 'personal' );
  partesTrabajoCol = collection( this.firestore, 'produccion_parte_trabajo' );
  personalDiarioCol = collection( this.firestore, 'personal_diario' );

  metaNuevaParteTrabajo = {
    creado: {
      creadoel : new Date(),
      creadopor: {
        nombre: this.usuarioService.usuario.nombre,
        uid: this.usuarioService.usuario.uid
      },
    },
    modificado: {
      modificadoel: null,
      modificadopor: {
        nombre: null,
        uid: null
      },
    },
    eliminado: {
      eliminadoel: null,
      eliminadopor: {
        nombre: null,
        uid: null
      },
    }
  }

  private _currentSorts: any[] = [];
  private _currentPage: number = 0;
  private _registrosPagina: number = 10;

  constructor( private firestore: Firestore,
               private auth: Auth,
               private usuarioService: UsuarioService,
             ) { }

  cargarAutores() {
    const consulta = query( this.usuariosCol, where('parte', 'in', ['individual', 'equipo']), orderBy('nombre', 'asc') );
    return collectionData( consulta, { idField: 'uid' } );
  }

  cargarResponsables() {
    const consulta = query( this.usuariosCol, where('firmar', '==', true), orderBy('nombre', 'asc') );
    return collectionData( consulta, { idField: 'uid' } );
  }

  async cargarParteTrabajoPorFecha( fecha: Date, operario: string ) {

    const operarioRef = doc( this.personalCol, operario );
    const consulta = query( collectionGroup( this.firestore, 'horarioOperarios' ), where( 'operario', '==', operarioRef ), where('fecha', '==', fecha) );
    return await getDocs( consulta );

  }

  cargarPartesTrabajo( fechaInicio: Date, fechaFin: Date, estado: string, autor?: string, responsable?: string ) {

    let consulta: Query<DocumentData>;
    const autorRef = autor ? doc( this.usuariosCol, autor ) : null;
    const responsableRef = responsable ? doc( this.usuariosCol, responsable ) : null;

    switch (estado) {
      case 'pendientes':
        consulta = query( this.partesTrabajoCol, where('estado', 'in', ['pendiente', 'modificado']) );
        break;
      case 'aprobados':
        consulta = query( this.partesTrabajoCol, where('estado', 'in', ['aprobado', 'aprobadoConCambios']) );
        break;
      case 'rechazados':
        consulta = query( this.partesTrabajoCol, where('estado', '==', 'rechazado') );
        break;
      default:
        consulta = query( this.partesTrabajoCol, where('estado', 'in', ['pendiente', 'modificado', 'aprobado', 'aprobadoConCambios', 'rechazado']) );
        break;
    }

    if ( autorRef ) {
      consulta = query( consulta, where('autor', '==', autorRef ) );
    }

    if ( responsableRef ) {
      consulta = query( consulta, where('responsables', 'array-contains', responsableRef ) );
    }

    consulta = query( consulta, where('fecha', '>=', fechaInicio), where('fecha', '<', fechaFin), orderBy('fecha', 'desc') );

    return collectionData( consulta, { idField: 'id' } );

  }

  cargarParteTrabajoPorId( parteTrabajoID: string ) {

    const parteTrabajoDoc = doc( this.partesTrabajoCol, parteTrabajoID );
    return docData( parteTrabajoDoc, { idField: 'id' } );

  }

  async crearParteTrabajo( formData: any ) {

    formData.meta = this.metaNuevaParteTrabajo;
    formData.autor = doc( this.usuariosCol, formData.autor );
    formData.obra = doc( this.firestore, 'obras', formData.obra );
    const horarioOperarios: any[] = formData.horarioOperarios;
    const parteRef = await addDoc( this.partesTrabajoCol, formData );
    await updateDoc( parteRef, { id: parteRef.id });
    const horarioOperariosCol = collection( this.firestore, 'produccion_parte_trabajo', parteRef.id, 'horarioOperarios' );

    for ( const horario of horarioOperarios ) {

      horario.operario = doc( this.personalCol, horario.operario );
      horario.obra = doc( this.firestore, 'obras', horario.obra );
      const docHorarioRef = await addDoc( horarioOperariosCol, {
        ...horario,
        'parte': parteRef,
        'estado': 'pendiente',
        'meta': this.metaNuevaParteTrabajo
      });

      await updateDoc( docHorarioRef, { id: docHorarioRef.id });

      // TODO: Borrar?

      const docRef = await addDoc( this.personalDiarioCol, {
        ...horario,
        'parte': parteRef,
        'estado': 'pendiente',
        'meta': this.metaNuevaParteTrabajo
      });

      await updateDoc( docRef, { id: docRef.id });

    }

    return parteRef;

  }

  async actualizarParteTrabajo( parteTrabajoID: string, formData: any ) {

    const parteRef = doc( this.firestore, 'produccion_partes_trabajo', parteTrabajoID );
    const horarioOperarios: any[] = formData.horarioOperarios;

    for ( const horario of horarioOperarios ) {

      const consulta = query( this.personalDiarioCol,
        where('operario', '==', horario.operario ),
        where('fecha', '==', horario.fecha ),
        where('parte', '==', parteRef ),
      );

      const querySnapshot = await getDocs( consulta );

      if (querySnapshot.size > 0) {

        const docRef = querySnapshot.docs[0].ref;

        await updateDoc( docRef, {
          ...horario,
          'meta.modificado': {
            modificadoel: new Date(),
            modificadopor: {
              nombre: this.usuarioService.usuario.nombre,
              uid: this.usuarioService.usuario.uid
            }
          }
        });

      } else {

        const docRef = await addDoc( this.personalDiarioCol, {
          ...horario,
          'parte': parteRef,
          'estado': 'pendiente',
          'meta': this.metaNuevaParteTrabajo
        });

        await updateDoc( docRef, { id: docRef.id });

      }

    }

    return updateDoc( parteRef, {
      ...formData,
      'meta.modificado': {
        modificadoel: new Date(),
        modificadopor: {
          nombre: this.usuarioService.usuario.nombre,
          uid: this.usuarioService.usuario.uid
        }
      }
    });

  }

  async eliminarParteTrabajo( parteTrabajoID: string ) {

    const parteRef = doc( this.firestore, 'produccion_partes_trabajo', parteTrabajoID );

    const consulta = query( this.personalDiarioCol, where('parte', '==', parteRef ) );

    const querySnapshot = await getDocs( consulta );

    querySnapshot.forEach( doc => {
      deleteDoc( doc.ref );
    });

    return deleteDoc( parteRef );

  }

  // Estado de ngx-datatable

  public setSorts(sorts: any[]): void {
    this._currentSorts = sorts;
  }

  public getSorts(): any[] {
    return this._currentSorts;
  }

  public setPage(page: number): void {
    this._currentPage = page;
  }

  public getPage(): number {
    return this._currentPage;
  }

  public setRegistrosPagina(registros: number): void {
    this._registrosPagina = registros;
  }

  public getRegistrosPagina(): number {
    return this._registrosPagina;
  }

}
