import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, first } from 'rxjs';
import { EquiposService, PersonalService } from 'src/app/services/service.index';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css']
})
export class EquipoComponent implements OnInit, OnDestroy {

  tituloBC: string = 'Nuevo equipo';
  txtCargando = 'Cargando...';
  cargandoClass = 'alert-warning';
  cargando: boolean = true;
  equipoID: string;
  operarios: any[] = [];
  encargados: any[] = [];

  modoLectura: boolean = false;

  private subs = new SubSink();

  public formSubmitted = false;

  public equipoForm = this.fb.group({
    nombre: ['', Validators.required ],
    encargado: [ null ],
  });

  constructor( private equiposService: EquiposService,
               private personalService: PersonalService,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute,
               private title: Title,
              ) { }

  ngOnInit(): void {

    this.activatedRoute.params
      .subscribe( params => {

        if ( params.id ) {
          this.tituloBC = params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() + ' Equipo';
          this.title.setTitle( this.title.getTitle().replace('#', params.accion[0].toUpperCase() + params.accion.slice(1).toLowerCase() ) );
          this.equipoID = params.id;
          this.cargarOperariosPorEquipo( params.id );
          if (params.accion === 'editar') {
            this.modoLectura = false;
            this.cargarEquipo( params.id );
          } else if (params.accion === 'ver') {
            this.modoLectura = true;
            this.cargarEquipo( params.id );
          } else {
            this.router.navigate(['/rrhh/equipos']);
          }
        } else {
          this.cargando = false;
        }

      });

      this.cargarEncargados();

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cargarOperariosPorEquipo( equipoID: string ) {
    this.subs.sink = this.equiposService.obtenerOperariosPorEquipo( equipoID )
      .subscribe( (operariosBD: any) => {
        this.operarios = operariosBD;
      });
  }

  cargarEncargados() {
    this.subs.sink = this.personalService.cargarUsuariosParteEquipo()
      .subscribe( (encargadosBD: any) => {
        this.encargados = encargadosBD;
      });
  }

  cargarEquipo( id: string ) {

    this.subs.sink = this.equiposService.obtenerEquipoPorId( id )
      .pipe(
        delay(100)
      )
      .subscribe( (equipo: any) => {

        if ( !equipo ) {
          Swal.fire('Error', 'Error al cargar los datos del equipo', 'error');
          return this.router.navigateByUrl('/rrhh/equipos');
        }

        this.equipoForm.reset(
          {
            nombre: equipo.nombre,
            encargado: equipo?.encargado?.id,
          });
        this.cargando = false;
      });

  }

  crearEquipo() {

    this.formSubmitted = true;

    if ( this.equipoForm.invalid ) {
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.equiposService.crearEquipo( this.equipoForm.value )
      .then( () => this.router.navigateByUrl('/rrhh/equipos') )
      .catch( err => {
        console.log( err );
        Swal.fire('Error', 'Error', 'error');
      });

  }

  actualizarEquipo() {

    this.formSubmitted = true;

    if ( this.equipoForm.invalid ) {
      return;
    }

    if ( this.equipoForm.pristine ) {
      this.cancelarEquipo();
      return;
    }

    this.txtCargando = 'Guardando...';
    this.cargandoClass = 'alert-secondary';
    this.cargando = true;

    this.equiposService.actualizarEquipo( this.equipoID, this.equipoForm.value )
      .then( () => this.router.navigateByUrl('/rrhh/equipos') )
      .catch( err => {
        Swal.fire('Error', 'error', 'error' );
      });

  }

  cancelarEquipo() {

    if ( this.equipoForm.pristine ) {
      this.router.navigateByUrl('/rrhh/equipos');
      return;
    }

    Swal.fire({
      title: '¿Está seguro?',
      text: 'Hay cambios sin guardar, si cancela se perderán.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    })
    .then((result) => {

      if (result.value) {

        this.router.navigate(['/rrhh/equipos']);

      }

    });

  }

  campoNoValido( campo: string ): boolean {

    if ( this.equipoForm.get(campo).invalid && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

  selectNoValido( campo: string ): boolean {

    if ( this.equipoForm.get(campo).value === '' && this.formSubmitted ) {
      return true;
    } else {
      return false;
    }

  }

}
