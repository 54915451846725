import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentData, Firestore, Query, addDoc, collection, collectionData, orderBy, query, updateDoc, where } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import * as firebase from 'firebase/compat/app';

import { UsuarioService } from '../service.index';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportadorService {

  constructor( private http: HttpClient,
               private firestore: Firestore,
               private usuarioService: UsuarioService,
               public auth: Auth  ) {

  }

  importarClientesDesdeURL() {

    const url = '../../assets/json/clientes.json';

    this.http.get(url).subscribe( (res: any) => {

      res.forEach( ( json ) => {

        json.forEach( (doc, i) => {

          // if (i === 0) {

            const auxAlias = doc.alias ? doc.alias : '';
            const auxCIF = doc.cif ? doc.cif : '';
            const auxCodPostal = doc.codpostal ? doc.codpostal.toString() : '';
            const auxDireccion = doc.direccion ? doc.direccion : '';
            const auxEmail = doc.email ? doc.email : '';
            const auxLocalidad = doc.localidad ? doc.localidad : '';
            const auxNumNavision = doc.numnavision ? doc.numnavision.toString() : '';
            const auxTelefono = doc.telefono ? doc.telefono.toString() : '';
            //TODO Corregir meta
            const auxCreado = {
              creadoel : new Date(),
              creadopor: {
                nombre: this.usuarioService.usuario.nombre,
                uid: this.usuarioService.usuario.uid
              }
          };

            const cliente = {
              alias: auxAlias,
              cif: auxCIF,
              codpostal: auxCodPostal,
              creado: auxCreado,
              direccion: auxDireccion,
              email: auxEmail,
              estado: true,
              localidad: auxLocalidad,
              nombre: doc.nombre,
              numnavision: auxNumNavision,
              telefono: auxTelefono
            };
            const clieCol = collection( this.firestore, 'clientes' );
            addDoc( clieCol, cliente )
              .then( docRef => {
                updateDoc( docRef, { id: docRef.id } );
              });

          // }

        });

      });

    });

  }

  UpdateMeta() {

    const salidasCol = collection( this.firestore, 'salidas' );
    let consulta: Query<DocumentData>;
    consulta = query( salidasCol, where( 'estado', '==', true ) );
    collectionData( consulta, { idField: 'id' } )
      .pipe(
        first()
      )
      .subscribe( (res: any) => {

        // Cambiar forEach por for
        res.forEach( (doc: any) => {

          if ( true ) {

            if ( doc.meta ) {

              const auxModificadoel = doc.modificado ? doc.modificado.modificadoel : null;
              const auxModificadonombre = doc.modificado ? doc.modificado.modificadopor.nombre : null;
              const auxModificadouid = doc.modificado ? doc.modificado.modificadopor.uid : null;

              const docRef = doc( this.firestore, 'salidas', doc.id );
              updateDoc( docRef, {
                meta: {
                  creado: {
                    creadoel: doc.creado.creadoel,
                    creadopor: {
                      nombre: doc.creado.creadopor.nombre,
                      uid: doc.creado.creadopor.uid
                    }
                  },
                  modificado: {
                    modificadoel: auxModificadoel,
                    modificadopor: {
                      nombre: auxModificadonombre,
                      uid: auxModificadouid
                    }
                  },
                  eliminado: {
                    creadoel: null,
                    creadopor: {
                      nombre: null,
                      uid: null
                    }
                  }
                },
              })
              .then( () => {

                  updateDoc( docRef, {
                    creado: firebase.default.firestore.FieldValue.delete(),
                    modificado: firebase.default.firestore.FieldValue.delete(),
                    eliminado: firebase.default.firestore.FieldValue.delete()
                  });

                });

            }

          }

        });

      });

  }

}
